import React, { useEffect, useState, useRef } from 'react';
import SetupService from '../../services/setup.service';
import UserService from '../../services/users.service';
import './employee.scss';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';

import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import employeeService from '../../services/employee.service';
import CurrencyService from '../../services/currency.service';
import SupplierService from '../../services/supplier.service';
import { set } from 'date-fns';
import { create } from 'yup/lib/Reference';
import UsersList from '../users/users-list';
import { decryptData } from '../decryption/decryption';


let currencyElem: HTMLElement;
let currencyObj: AutoComplete;

let supplierElem: HTMLElement;
let supplierObj: AutoComplete;
var SupplierID=0
var CurrencyID=0
export default function EmployeeAdd(props) {
  // var duplicateText = ''
  

  const userId = SecurityService.getLoggedInUserId();
  let EmployeeSupplierWisePerUnitCommissionDetailGrid: Grid | null;
  let EmployeeSupplierWisePrimaryCommissionDetailGrid: Grid | null;
  let EmployeeSupplierWiseSecondaryCommissionDetailGrid: Grid | null;
  const [employee, setEmployee] = useState([]);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [country, setCountry] = useState('');
  const [loadingCountry, setLoadingCountry] = useState('');
  const [countryID, setCountryID] = useState(props.CountryID);
  const [countryValue, setCountryValue] = useState(props.CountryName);
  const [city, setCity] = useState('');
  const [loadingCity, setLoadingCity] = useState('');
  const [cityID, setCityID] = useState(props.CityID);
  const [cityValue, setCityValue] = useState(props.CityName);
  const [designation, setDesignation] = useState('');
  const [loadingDesignation, setLoadingDesignation] = useState('');
  const [designationID, setDesignationID] = useState(props.DesignationID);
  const [designationValue, setDesignationValue] = useState(props.DesignationName);
  const [users, setUsers] = useState('');
  const [loadingUser, setLoadingUser] = useState('');
  const [UserID, setUserID] = useState(props.UserID);
  const [UserValue, setUserValue] = useState(props.UserName);

  const [employeeID, setEmployeeID] = useState(props?.EmployeeID);
  const [employeeName, setEmployeeName] = useState(props?.EmployeeName);
  const [employeeEmail, setEmployeeEmail] = useState(props?.EmployeeEmail);
  // const [contactNo1, setContactNo1] = useState(props?.ContactNo1);
  // const [contactNo2, setContactNo2] = useState(props?.ContactNo2);
  // const [contactNo3, setContactNo3] = useState(props?.ContactNo3);
  const [contactNo1, setContactNo1] = useState(props?.ContactNo1 ? props?.ContactNo1 : '');
  const [CheckContact1, setCheckContact1] = useState(false);
  const [contactNo2, setContactNo2] = useState(props?.ContactNo2 ? props?.ContactNo2 : '');
  const [CheckContact2, setCheckContact2] = useState(false);
  const [contactNo3, setContactNo3] = useState(props?.ContactNo3 ? props?.ContactNo3 : '');
  const [CheckContact3, setCheckContact3] = useState(false);
  const [address1, setAddress1] = useState(props?.Address1 ? props?.Address1 : '-');
  const [address2, setAddress2] = useState(props?.Address2 ? props?.Address2 : '-');
  const [citizenShipNo, setCitizenShipNo] = useState(props?.CitizenShipNo ? props?.CitizenShipNo : '-');
  const [commissionPercent, setCommissionPercent] = useState(
    props?.CommissionPercent 
  );

  // const [port, setPort] = useState('');
  // const [loadingPort, setLoadingPort] = useState('');
  // const [portID, setPortID] = useState(props.PortID);
  // const [portValue, setPortValue] = useState(props.port);
  const [currency, setCurrency] = useState([]);
  // const [currencyID, setCurrencyID] = useState(0);

  const [supplier, setSupplier] = useState([]);
  // const [supplierID, setSupplierID] = useState(0);

  // const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  const [employeeSupplierWisePerUnitCommissionDetail, setEmployeeSupplierWisePerUnitCommissionDetail] = useState([]);
  const [employeeSupplierWisePrimaryCommissionDetail, setEmployeeSupplierWisePrimaryCommissionDetail] = useState([]);
  const [employeeSupplierWiseSecondaryCommissionDetail, setEmployeeSupplierWiseSecondaryCommissionDetail] = useState([]);
  // const [duplicateText, setDuplicateText] = useState(false);
var duplicateText = false
  useEffect(() => {
  setTimeout(() => {
    
  
    if( employeeEmail != undefined  && checkDuplicate == true){
      console.log(props.dataList)
      console.log()
      // let check = CheckDuplicate.withCombination(props.dataList,['EmployeeEmail'],{
        
      //   EmployeeEmail: employeeEmail,
      //   // ContactNo1: contactNo1,
      //   // ContactNo2: contactNo2,
      //   // ContactNo3: contactNo3,
      //   // Address1: address1,
      //   // Address2: address2,
      //   // Country: countryValue,
      //   // City: cityValue,
      // })
     
      // props.dataList,['Country', 'City', 'Port'],)
      var check = false;
      if(props.isAdd == true){
        
        check = CheckDuplicate.withOutCombination(props.dataList,['EmployeeEmail'],{
          EmployeeEmail: employeeEmail,
 
       })
      }else{
        let array = props.dataList.filter(obj => obj.EmployeeID !== props.EmployeeID)

        check = CheckDuplicate.withOutCombination(array,['EmployeeEmail'],{
          EmployeeEmail: employeeEmail,
 
       })

      }
      console.log(check)
      
    //   if(!check){
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
    //     setCheckDuplicateValue('')
    //   }else{
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
    //     setCheckDuplicateValue('Duplicate Value Detected!')
    // }
      console.log(check)
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#EmployeeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#EmployeeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [employeeEmail]);

  useEffect(() => {
    setTimeout(() => {
      setCheckDuplicate(true)
    },300)
    if(props.isAdd == false){
     setTimeout(() => {
      
       document.querySelector('#EmployeeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
     }, 400);
    }
    getCountry();
    getCity();
    getDesignation();
    getUsers();
    getCurrency();
    getSupplier();
    // getPort()
    getEmployeeSupplierWisePerUnitCommissionDetail(props.EmployeeID)
    getEmployeeSupplierWisePrimaryCommissionDetail(props.EmployeeID)
    getEmployeeSupplierWiseSecondaryCommissionDetail(props.EmployeeID)
  }, []);

  let headertext = [
    { text: 'Supplier Wise Per Unit Commission Detail' },
    { text: 'Supplier Wise Primary Commission Detail' },
    { text: 'Supplier Wise Secondary Commission Detail' },
  ];

  const getEmployeeSupplierWisePerUnitCommissionDetail = async (id) => {
    employeeService
      .getEmployeeSupplierWisePerUnitCommissionDetail(id)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('employee get response', decryptedData);
        setEmployeeSupplierWisePerUnitCommissionDetail(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEmployeeSupplierWisePrimaryCommissionDetail = async (id) => {
    employeeService
      .getEmployeeSupplierWisePrimaryCommissionDetail(id)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('employee get response', decryptedData);
        setEmployeeSupplierWisePrimaryCommissionDetail(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEmployeeSupplierWiseSecondaryCommissionDetail = async (id) => {
    employeeService
      .getEmployeeSupplierWiseSecondaryCommissionDetail(id)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('employee get response', decryptedData);
        setEmployeeSupplierWiseSecondaryCommissionDetail(decryptedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEmployee = async (type: string) => {
    employeeService
      .getEmployeeForList()
      .then((response) => {
        console.log('employee get response', response.data.recordset);
        setEmployee(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCountry = async (type: string) => {
    setLoadingCountry(true);
    SetupService.getAll('Country')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('Country get response', decryptedData);
        setCountry(decryptedData);
        setLoadingCountry(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCity = async (type: string) => {
    setLoadingCity(true);
    SetupService.getAll('City')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('City get response',decryptedData);
        setCity(decryptedData);
        setLoadingCity(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDesignation = async (type: string) => {
    setLoadingDesignation(true);
    SetupService.getAll('Designation')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('Designation get response', decryptedData);
        setDesignation(decryptedData);
        setLoadingDesignation(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getUsers = async (type: string) => {
    setLoadingUser(true);
    employeeService.getUserForEmployeeInput()
      .then((response) => {
const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setUsers(decryptedData);
        setLoadingUser(false);
      })
      .catch((e) => {
        
      });
  };

  // const getPort = async (type: string) => {
  //   setLoadingPort(true);
  //   SetupService.getAll('Port')
  //     .then((response) => {
  
  //       setPort(response.data.recordset);
  //       setLoadingPort(false);
  //     })
  //     .catch((e) => {
  
  //     });
  // };

  const getCurrency = async () => {
    CurrencyService.getCurrencyForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setCurrency(decryptedData);
        // companies = [...response.data.recordset];
      })
      .catch((error) => {
        
      });
  };

  const getSupplier = async () => {
    SupplierService.getSupplierForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setSupplier(decryptedData);
        // companies = [...decryptedData];
      })
      .catch((error) => {
        
      });
  };
  const ddlFields: object = { value: 'value' };

  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const formatOptions = { type: 'date', format: 'dd/MM/yy' };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    // 'Delete',
    'Update',
    'Cancel',
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];

  const EmployeeSupplierWisePerUnitCommissionDetailActionComplete = (
    args: DialogEditEventArgs
  ) => {
    
    

    if (args.form) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        
        document
          .getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridCommissionAmountPerUnit")
          ?.addEventListener("blur", (e) => {
          let strr =   document
          .getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridCommissionAmountPerUnit").value
            if(strr > 0){

            setTimeout(() => EmployeeSupplierWisePerUnitCommissionDetailGrid?.endEdit(), 200);
            }
            // else{
            //   setTimeout(() =>  document
            //   .getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridCommissionAmountPerUnit").focus(),150)
            // }
            
          });
      }
      
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      

      // }

      if (args.requestType === 'save' && args.action == 'add' && props.isAdd ==  false) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
           Address1:address1,
 Address2:address2,
 City:cityValue,
 CityID:cityID,
 CommissionPercent:0,
 ContactNo1:contactNo1,
 ContactNo2:contactNo2,
 ContactNo3:contactNo3,
 Country:countryValue,
 CountryID:countryID,
 Designation:designationValue,
 DesignationID:designationID,
 EmployeeEmail:employeeEmail,
 EmployeeName:employeeName,
 UserID:UserID,
 CreatedByUserID:userId,
 ...args.data,
          SupplierID,
          CurrencyID:0,
          EmployeeID: props.EmployeeID
        }
        
        employeeService
          .addEmployeeSupplierWisePerUnitCommissionDetailGrid(data)
          .then((response) => {
            getEmployeeSupplierWisePerUnitCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
            }, 600);
            
          }).catch(()=>{
            EmployeeSupplierWisePerUnitCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd ==  true) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
           Address1:address1,
 Address2:address2,
 City:cityValue,
 CityID:cityID,
 CommissionPercent:0,
 ContactNo1:contactNo1,
 ContactNo2:contactNo2,
 ContactNo3:contactNo3,
 Country:countryValue,
 CountryID:countryID,
 Designation:designationValue,
 DesignationID:designationID,
 EmployeeEmail:employeeEmail,
 EmployeeName:employeeName,
 UserID:UserID,
 CreatedByUserID:userId,
 ...args.data,
          SupplierID,
          CurrencyID:0
        }
        
        employeeService
          .addEmployeeSupplierWisePerUnitCommissionDetail(data)
          .then((response) => {
            getEmployeeSupplierWisePerUnitCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
            }, 600);
            
          }).catch(()=>{
            EmployeeSupplierWisePerUnitCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        
        let data ={
          ...args.data,
          SupplierID,
          CurrencyID:0
  
        }
        
        employeeService
          .updateEmployeeSupplierWisePerUnitCommissionDetail(data)
          .then((response) => {
            
            getEmployeeSupplierWisePerUnitCommissionDetail(response.data.EmployeeID)
          }).catch(()=>{
            EmployeeSupplierWisePerUnitCommissionDetailGrid.refresh()
          })
      }
    }
  };

  const EmployeeSupplierWisePrimaryCommissionDetailActionComplete = (
    args: DialogEditEventArgs
  ) => {
    

    if (args.form) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {

      
      document
        .getElementById("EmployeeSupplierWisePrimaryCommissionDetailGridPrimaryCommissionPercentOnAdjustment")
        ?.addEventListener("blur", (e) => {
        let strr =   document
        .getElementById("EmployeeSupplierWisePrimaryCommissionDetailGridPrimaryCommissionPercentOnAdjustment").value
          if(strr > 0){

          setTimeout(() => EmployeeSupplierWisePrimaryCommissionDetailGrid?.endEdit(), 200);
          }
          // else{
          //   setTimeout(() =>  document
          //   .getElementById("EmployeeSupplierWisePrimaryCommissionDetail").focus(),150)
          // }
          
        });
    }
      
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      

      // }

      if (args.requestType === 'save' && args.action == 'add' && props.isAdd ==  false) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
          Address1:address1,
Address2:address2,
City:cityValue,
CityID:cityID,
CommissionPercent:0,
ContactNo1:contactNo1,
ContactNo2:contactNo2,
ContactNo3:contactNo3,
Country:countryValue,
CountryID:countryID,
Designation:designationValue,
DesignationID:designationID,
EmployeeEmail:employeeEmail,
EmployeeName:employeeName,
UserID:UserID,
 CreatedByUserID:userId,
...args.data,
         SupplierID,
         CurrencyID,
         EmployeeID: props.EmployeeID
       }
        employeeService
          .addEmployeeSupplierWisePrimaryCommissionDetailGrid(data)
          .then((response) => {
            getEmployeeSupplierWisePrimaryCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWisePrimaryCommissionDetailGrid.addRecord()
            }, 600);
            
          }).catch(()=>{
            EmployeeSupplierWisePrimaryCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWisePrimaryCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd == true) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
          Address1:address1,
Address2:address2,
City:cityValue,
CityID:cityID,
CommissionPercent:0,
ContactNo1:contactNo1,
ContactNo2:contactNo2,
ContactNo3:contactNo3,
Country:countryValue,
CountryID:countryID,
Designation:designationValue,
DesignationID:designationID,
EmployeeEmail:employeeEmail,
EmployeeName:employeeName,
UserID:UserID,
 CreatedByUserID:userId,
...args.data,
         SupplierID,
         CurrencyID,
       }
        employeeService
          .addEmployeeSupplierWisePrimaryCommissionDetail(data)
          .then((response) => {
            getEmployeeSupplierWisePrimaryCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWisePrimaryCommissionDetailGrid.addRecord()
            }, 600);
            
          }).catch(()=>{
            EmployeeSupplierWisePrimaryCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWisePrimaryCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        
        let data ={
        
UserID:userId,
...args.data,
         SupplierID,
         CurrencyID,
       }
        employeeService
          .updateEmployeeSupplierWisePrimaryCommissionDetail(data)
          .then((response) => {
            
            getEmployeeSupplierWisePrimaryCommissionDetail(response.data.EmployeeID)
          }).catch(()=>{
            EmployeeSupplierWisePerUnitCommissionDetailGrid.refresh()
          })
      }
    }
  };

  const EmployeeSupplierWiseSecondaryCommissionDetailActionComplete = (  args: DialogEditEventArgs  ) => {
    

    if (args.form) {
      
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      

      // }
      if (args.requestType === "beginEdit" || args.requestType === "add") {

        
        document
          .getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridSecondaryCommissionPercentOnAdjustment")
          ?.addEventListener("blur", (e) => {
          let strr =   document
          .getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridSecondaryCommissionPercentOnAdjustment").value
            if(strr > 0){
  
            setTimeout(() => EmployeeSupplierWiseSecondaryCommissionDetailGrid?.endEdit(), 200);
            }
            // else{
            //   setTimeout(() =>  document
            //   .getElementById("EmployeeSupplierWisePrimaryCommissionDetail").focus(),150)
            // }
            
          });
      }
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd == false) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
          Address1:address1,
          Address2:address2,
          City:cityValue,
          CityID:cityID,
          CommissionPercent:0,
          ContactNo1:contactNo1,
          ContactNo2:contactNo2,
          ContactNo3:contactNo3,
          Country:countryValue,
          CountryID:countryID,
          Designation:designationValue,
          DesignationID:designationID,
          EmployeeEmail:employeeEmail,
          EmployeeName:employeeName,
          UserID:UserID,
 CreatedByUserID:userId,
          ...args.data,
          SupplierID,
         CurrencyID,
         EmployeeID: props.EmployeeID
       }
        employeeService
          .addEmployeeSupplierWiseSecondaryCommissionDetailGrid(data)
          .then((response) => {
            
            getEmployeeSupplierWiseSecondaryCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWiseSecondaryCommissionDetailGrid.addRecord()
            }, 600);
          }).catch(()=>{
            EmployeeSupplierWiseSecondaryCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWiseSecondaryCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd == true) {
        
        let data ={
          CitizenShipNo:citizenShipNo,
          Address1:address1,
          Address2:address2,
          City:cityValue,
          CityID:cityID,
          CommissionPercent:0,
          ContactNo1:contactNo1,
          ContactNo2:contactNo2,
          ContactNo3:contactNo3,
          Country:countryValue,
          CountryID:countryID,
          Designation:designationValue,
          DesignationID:designationID,
          EmployeeEmail:employeeEmail,
          EmployeeName:employeeName,
          UserID:UserID,
 CreatedByUserID:userId,
          ...args.data,
          SupplierID,
         CurrencyID,
       }
        employeeService
          .addEmployeeSupplierWiseSecondaryCommissionDetail(data)
          .then((response) => {
            
            getEmployeeSupplierWiseSecondaryCommissionDetail(response.data.EmployeeID)
            setTimeout(() => {
              
              return EmployeeSupplierWiseSecondaryCommissionDetailGrid.addRecord()
            }, 600);
          }).catch(()=>{
            EmployeeSupplierWiseSecondaryCommissionDetailGrid.refresh()
            setTimeout(() => {
              
              EmployeeSupplierWiseSecondaryCommissionDetailGrid.addRecord()
            }, 300);
          })
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        
        let data ={
          
         SupplierID,
         CurrencyID,
         ...args.data
       }
        employeeService
          .updateEmployeeSupplierWiseSecondaryCommissionDetail(data)
          .then((response) => {
            
            getEmployeeSupplierWiseSecondaryCommissionDetail(response.data.EmployeeID)
          }).catch(()=>{
            EmployeeSupplierWiseSecondaryCommissionDetailGrid.refresh()
          })
      }
    }
  };

  const onAddNewClick = (type) => {
    
    if (type !== '') {
      setAddNewClicked(true);
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      }, 100);
    }
  };

  const header = () => {
    if (showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(' ')} </div>;
    } else {
      return <></>;
    }
  };

  const onClose = () => {
    setAddNewClicked(false);
    setShowAddNewDialog(false);
    switch (dialogType) {
      case 'Country':
        getCountry();
        break;
      case 'Users':
        getUsers();
        break;
      case 'City':
        getCity();
      case 'Designation':
        getDesignation();
        break;
      default:
        break;
    }
  };

  const footerTemplate = () => {
    if (showAddNewDialog) {
      return (
        <div>
          <button
            id='btnSave'
            className='e-control e-btn e-primary'
            data-ripple='true'
            onClick={onClose}>
            Close
          </button>
        </div>
      );
    } else {
      <h3 style={{ textAlign: 'center', width: '80%' }}>Employee - Add</h3>;
      return <></>;
    }
  };

  const contentTemplate = (props) => {
    
    if (showAddNewDialog) {
      
      if (dialogType === 'Users') {
        return <UsersList isDialog={true} />;
      } else {
        return (
          <div>
            <SetupAdd type={dialogType} isDialog={true} />
            {/* <SetupAdd onChange={value => setWord(value)}*/}
          </div>
        );
      }
      // return (
      //   <div>
      //     <SetupAdd type={dialogType} isDialog={true} />
      //     {/* <SetupAdd onChange={value => setWord(value)}*/}
      //   </div>
      // );
    } else {
      return <></>;
    }
  };

  const AddNewSecurity = (addNewType) => {
    var res = '';
    switch (SecurityService.canUserAccess(`${addNewType}List`)) {
      case true:
        //('true')
        return (res = 'ADD NEW');
      default:
        break;
    }
    return res;
  };

  const countryChange = (e) => {
    
    setCountryValue(e?.itemData?.value ? e?.itemData?.value : '');
    setCountryID(0);
    if (e?.e?.srcElement?.id) {
      
      setCountryID(e?.itemData?.id);
    }
  };

  const cityChange = (e) => {
    
    setCityValue(e?.itemData?.value ? e?.itemData?.value : '');
    setCityID(0);
    if (e?.e?.srcElement?.id) {
      
      setCityID(e?.itemData?.id);
    }
  };

  const designationChange = (e) => {
    
    setDesignationValue(e?.itemData?.value ? e?.itemData?.value : '');
    setDesignationID(0);
    if (e?.e?.srcElement?.id) {
      
      setDesignationID(e?.itemData?.id);
    }
  };
  const usersChange = (e) => {
    
    setUserValue(e?.itemData?.value ? e?.itemData?.value : '');
    setUserID(0);
    if (e?.e?.srcElement?.id) {
      
      setUserID(e?.itemData?.id);
    }
  };

  // const portChange = (e) => {
  
  //     setPortValue(e?.itemData?.value  ? e?.itemData?.value :"")
  //     setPortID(0)
  //   if(e?.e?.srcElement?.id)  {
  
  //       setPortID(e?.itemData?.id);
  //     }
  //   };
  // const callDuplicate = (e)=>{
  //   setDuplicateText(e)
    // if(e == true){
    //   setDuplicateText('Duplicate Value Detected')
    // }else{
    //   setDuplicateText('')
    // }
  //  }
  const currencyParams1: IEditCell = {
    create: () => {
      currencyElem = document.createElement('input');
      return currencyElem;
    },
    destroy: () => {
      currencyObj?.destroy();
    },
    read: () => {
      return currencyObj?.text;
    },
    write: (args) => {
      currencyObj = new AutoComplete({
        created: (e) => {
          if(args.rowData){
            CurrencyID =  args.rowData.CurrencyID
          }
        },
        select: (e) => {
          
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          
          CurrencyID = e.itemData.id;
        },
        change:(e)=>{
          
let resstr = currencyObj.dataSource.some(s=>s.value ==  e.value);

if(resstr === false){
  CurrencyID =0;
  args.rowData.CurrencyID =0
// var elem = document.getElementById("productDetailGridFinishSize");
// elem.style.border = "NONE";
}else{
  CurrencyID = e?.itemData?.id;
  args.rowData.CurrencyID = e?.itemData?.id
}
},        // ref={shipmentRef}
        blur:()=>{
          if (CurrencyID == undefined || currencyObj.element.value == null || currencyObj.element.value == '' || CurrencyID == 0 ) {
            // finishSizeRef.current.element.focus()
          
              
            // if(gridAddNewClicked == false){
              currencyObj.element.focus()
                  // setCountryErorr(`${countryValue} Value Not Find ` )
                  
                  document.getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridCurrency").parentElement.style.border ='3px solid red'
                  setTimeout(() => {
                    // setProductItemTypeError('')
                    document.getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridCurrency").parentElement.style.border ='1px solid #c8c8c8'

                  }, 1000);
                  document.getElementById("duplicate-value-detected").style.opacity ='1'
                  setTimeout(() => {
                    // callDuplicate(false)
                    // duplicateText =  false
                    document.getElementById("duplicate-value-detected").style.opacity ='0'

                  }, 2000);
                  // }
                }
              }
              
          
        ,
        dataSource: currency,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a Currency',
        highlight: true,
        value:args.rowData.Currency
      });
      currencyObj.appendTo(currencyElem);
    },
  };


  const currencyParams: IEditCell = {
    create: () => {
      currencyElem = document.createElement('input');
      return currencyElem;
    },
    destroy: () => {
      currencyObj?.destroy();
    },
    read: () => {
      return currencyObj?.text;
    },
    write: (args) => {
      currencyObj = new AutoComplete({
        created: (e) => {
          if(args.rowData){
            CurrencyID =  args.rowData.CurrencyID
          }
        },
        select: (e) => {
          
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          
          CurrencyID = e.itemData.id;
        },
        change:(e)=>{
          
          // let storeExitingSupplier = EmployeeSupplierWiseSecondaryCommissionDetailGrid.dataSource.map(res=>{return res.Currency})
          
          let resstr = false;
      let storeExitingSupplier =    EmployeeSupplierWiseSecondaryCommissionDetailGrid.dataSource.filter((res)=>{
            
            
            
            let supplierVal = document.getElementById('EmployeeSupplierWiseSecondaryCommissionDetailGridSupplier').value
            return res.Supplier == supplierVal && res.Currency ==  e.itemData.value
          })
          // let inds = storeExitingSupplier.indexOf(args.rowData.Currency)
          // delete storeExitingSupplier[inds]
          // let DataFilter = currencyObj.dataSource.filter(res=>{
          //   return !storeExitingSupplier.includes(res.value)
          // })

          // return resstr = currencyObj.dataSource.some(s=>s.value ==  e.value);
          
          // currencyObj.dataSource.filter(res)



if(storeExitingSupplier.length > 0){
  resstr = false
}else{

 return resstr = currencyObj.dataSource.some(s=>s.value ==  e.value);
}

// let resstr = currencyObj.dataSource.some(s=>s.value ==  e.value);

if(resstr === false){
  CurrencyID =0;
  args.rowData.CurrencyID =0
// var elem = document.getElementById("productDetailGridFinishSize");
// elem.style.border = "NONE";
}else{
  CurrencyID = e?.itemData?.id;
  args.rowData.CurrencyID = e?.itemData?.id
}
},        // ref={shipmentRef}
        blur:()=>{
          if (CurrencyID == undefined || currencyObj.element.value == null || currencyObj.element.value == '' || CurrencyID == 0 ) {
            // finishSizeRef.current.element.focus()
          
              
            // if(gridAddNewClicked == false){
              currencyObj.element.focus()
                  // setCountryErorr(`${countryValue} Value Not Find ` )
                  
                  document.getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridCurrency").parentElement.style.border ='3px solid red'
                  setTimeout(() => {
                    // setProductItemTypeError('')
                    document.getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridCurrency").parentElement.style.border ='1px solid #c8c8c8'

                  }, 1000);
                  document.getElementById("duplicate-value-detected").style.opacity ='1'
                  setTimeout(() => {
                    // callDuplicate(false)
                    // duplicateText =  false
                    document.getElementById("duplicate-value-detected").style.opacity ='0'

                  }, 2000);
                  // }
                }
              }
              
          
        ,
        dataSource: currency,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a Currency',
        highlight: true,
        value:args.rowData.Currency
      });
      currencyObj.appendTo(currencyElem);
    },
  };
  
  const supplierParams1: IEditCell = {
    create: () => {
      supplierElem = document.createElement('input');
      return supplierElem;
    },
    destroy: () => {
      supplierObj?.destroy();
    },
    read: () => {
      return supplierObj?.text;
    },
    write: (args) => {
      supplierObj = new AutoComplete({
        created: (e) => {
          if(args.rowData){
            SupplierID =  args.rowData.SupplierID
          }
        },
        select: (e) => {
          
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          SupplierID = e.itemData.id;
          
          
          // setSupplierID(e.itemData.id);
        },
        change:(e)=>{
          
          
          let storeExitingSupplier = EmployeeSupplierWisePerUnitCommissionDetailGrid.dataSource.map(res=>{return res.Supplier})
          
          let inds = storeExitingSupplier.indexOf(args.rowData.Supplier)
          delete storeExitingSupplier[inds]
          let DataFilter = supplierObj.dataSource.filter(res=>{
            return !storeExitingSupplier.includes(res.value)
          })
          // supplierObj.dataSource.filter(res)
          
          
let resstr = DataFilter.some(s=>s.value ==  e.value);

if(resstr === false){
  SupplierID =0;
  args.rowData.StitchingStyleID =0
// var elem = document.getElementById("productDetailGridFinishSize");
// elem.style.border = "NONE";
}else{
  SupplierID = e?.itemData?.id;
  args.rowData.StitchingStyleID = e?.itemData?.id
}
},        // ref={shipmentRef}
        blur:()=>{
          if (SupplierID == undefined || supplierObj.element.value == null || supplierObj.element.value == '' || SupplierID == 0 ) {
            // finishSizeRef.current.element.focus()
          
              // callDuplicate(true)
              // if(gridAddNewClicked == false){
                supplierObj.element.focus()
                  // setCountryErorr(`${countryValue} Value Not Find ` )
                // duplicateText = true
                  
                
                document.getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridSupplier").parentElement.style.border ='3px solid red'
                setTimeout(() => {
                  // setProductItemTypeError('')
                    document.getElementById("EmployeeSupplierWisePerUnitCommissionDetailGridSupplier").parentElement.style.border ='1px solid #c8c8c8'
                    
                    
                    
                  }, 1000);
                  document.getElementById("duplicate-value-detected").style.opacity ='1'
                  setTimeout(() => {
                    // callDuplicate(false)
                    // duplicateText =  false
                    document.getElementById("duplicate-value-detected").style.opacity ='0'

                  }, 2000);
                  // }
                }
              }
              
          
        ,
        dataSource: supplier,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a supplier',
        highlight: true,
        value:args.rowData.Supplier
      });
      supplierObj.appendTo(supplierElem);
    },
  };
  const supplierParams2: IEditCell = {
    create: () => {
      supplierElem = document.createElement('input');
      return supplierElem;
    },
    destroy: () => {
      supplierObj?.destroy();
    },
    read: () => {
      return supplierObj?.text;
    },
    write: (args) => {
      supplierObj = new AutoComplete({
        created: (e) => {
          if(args.rowData){
            SupplierID =  args.rowData.SupplierID
          }
        },
        select: (e) => {
          
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          SupplierID = e.itemData.id;
          
          
          // setSupplierID(e.itemData.id);
        },
        change:(e)=>{
          
          
          let storeExitingSupplier = EmployeeSupplierWisePrimaryCommissionDetailGrid.dataSource.map(res=>{return res.Supplier})
          
          let inds = storeExitingSupplier.indexOf(args.rowData.Supplier)
          delete storeExitingSupplier[inds]
          let DataFilter = supplierObj.dataSource.filter(res=>{
            return !storeExitingSupplier.includes(res.value)
          })
          // supplierObj.dataSource.filter(res)
          
          
let resstr = DataFilter.some(s=>s.value ==  e.value);
          
// let resstr = supplierObj.dataSource.some(s=>s.value ==  e.value);

if(resstr === false){
  SupplierID =0;
  args.rowData.StitchingStyleID =0
// var elem = document.getElementById("productDetailGridFinishSize");
// elem.style.border = "NONE";
}else{
  SupplierID = e?.itemData?.id;
  args.rowData.StitchingStyleID = e?.itemData?.id
}
},        // ref={shipmentRef}
        blur:()=>{
          if (SupplierID == undefined || supplierObj.element.value == null || supplierObj.element.value == '' || SupplierID == 0 ) {
            // finishSizeRef.current.element.focus()
          
              
            // if(gridAddNewClicked == false){
              supplierObj.element.focus()
                  // setCountryErorr(`${countryValue} Value Not Find ` )
                  
                  document.getElementById("EmployeeSupplierWisePrimaryCommissionDetailGridSupplier").parentElement.style.border ='3px solid red'
                  setTimeout(() => {
                    // setProductItemTypeError('')
                    document.getElementById("EmployeeSupplierWisePrimaryCommissionDetailGridSupplier").parentElement.style.border ='1px solid #c8c8c8'

                  }, 1000);
                  document.getElementById("duplicate-value-detected").style.opacity ='1'
                  setTimeout(() => {
                    // callDuplicate(false)
                    // duplicateText =  false
                    document.getElementById("duplicate-value-detected").style.opacity ='0'

                  }, 2000);
                  // }
                }
              }
              
          
        ,
        dataSource: supplier,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a supplier',
        highlight: true,
        value:args.rowData.Supplier
      });
      supplierObj.appendTo(supplierElem);
    },
  };
  const supplierParams3: IEditCell = {
    create: () => {
      supplierElem = document.createElement('input');
      return supplierElem;
    },
    destroy: () => {
      supplierObj?.destroy();
    },
    read: () => {
      return supplierObj?.text;
    },
    write: (args) => {
      supplierObj = new AutoComplete({
        created: (e) => {
          if(args.rowData){
            SupplierID =  args.rowData.SupplierID
          }
        },
        select: (e) => {
          
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          SupplierID = e.itemData.id;
          
          
          // setSupplierID(e.itemData.id);
        },
        change:(e)=>{
          
          // let storeExitingSupplier = EmployeeSupplierWiseSecondaryCommissionDetailGrid.dataSource.map(res=>{return res.Supplier})
          
          // let inds = storeExitingSupplier.indexOf(args.rowData.Supplier)
          // delete storeExitingSupplier[inds]
          // let DataFilter = supplierObj.dataSource.filter(res=>{
          //   return !storeExitingSupplier.includes(res.value)
          // })
          // // supplierObj.dataSource.filter(res)
          
          
          let resstr = false;
          let storeExitingSupplier =    EmployeeSupplierWiseSecondaryCommissionDetailGrid.dataSource.filter((res)=>{
                
                
                
                let currencyVal = document.getElementById('EmployeeSupplierWiseSecondaryCommissionDetailGridCurrency').value
                return res.Currency == currencyVal && res.Supplier ==  e.itemData.value
              })
              
              
if(storeExitingSupplier.length > 0){
  resstr = false
}else{

 return resstr = supplierObj.dataSource.some(s=>s.value ==  e.value);
}
// let resstr = supplierObj.dataSource.some(s=>s.value ==  e.value);

if(resstr === false){
  SupplierID =0;
  args.rowData.StitchingStyleID =0
// var elem = document.getElementById("productDetailGridFinishSize");
// elem.style.border = "NONE";
}else{
  SupplierID = e?.itemData?.id;
  args.rowData.StitchingStyleID = e?.itemData?.id
}
},        // ref={shipmentRef}
        blur:()=>{
          if (SupplierID == undefined || supplierObj.element.value == null || supplierObj.element.value == '' || SupplierID == 0 ) {
            // finishSizeRef.current.element.focus()
          
              
            // if(gridAddNewClicked == false){
              supplierObj.element.focus()
                  // setCountryErorr(`${countryValue} Value Not Find ` )
                  
                  document.getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridSupplier").parentElement.style.border ='3px solid red'
                  setTimeout(() => {
                    // setProductItemTypeError('')
                    document.getElementById("EmployeeSupplierWiseSecondaryCommissionDetailGridSupplier").parentElement.style.border ='1px solid #c8c8c8'

                  }, 1000);
                  document.getElementById("duplicate-value-detected").style.opacity ='1'
                  setTimeout(() => {
                    // callDuplicate(false)
                    // duplicateText =  false
                    document.getElementById("duplicate-value-detected").style.opacity ='0'

                  }, 2000);
                  // }
                }
              }
              
          
        ,
        dataSource: supplier,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a supplier',
        highlight: true,
        value:args.rowData.Supplier
      });
      supplierObj.appendTo(supplierElem);
    },
  };

  const EmployeeSupplierWisePerUnitCommissionDetailGridComponent = () => (
    <GridComponent
      ref={(grid) => (EmployeeSupplierWisePerUnitCommissionDetailGrid = grid)}
      id='EmployeeSupplierWisePerUnitCommissionDetailGrid'
       dataSource={employeeSupplierWisePerUnitCommissionDetail}
      editSettings={editOptions}
      toolbar={toolbarOptions}
      delayUpdate={true}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 5 }}
      filterSettings={filterOptions}
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      // toolbarClick={toolbarClick}
      allowFiltering={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      actionComplete={EmployeeSupplierWisePerUnitCommissionDetailActionComplete}
      // load={setTimeout(() => fabricContractEditGrid ? fabricContractEditGrid.dataSource = fabricEditPlanData : null, 2000)}
      // dataBound={databound}
      // height={315}
      // created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}
      // height={315}
    >
      <ColumnsDirective>
        <ColumnDirective
          headerTextAlign='center'
          headerText='Supplier'
          field='Supplier'
          width='220'
          editType='dropdownedit'
          edit={supplierParams1}
          id='supplier'
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Supplier ID'
          field='SupplierID'
          width='220'
          visible={false}
          // editType='dropdownedit'
          // edit={supplierParams}
          // id='supplier'
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Currency'
          field='Currency'
          width='220'
          editType='dropdownedit'
          edit={currencyParams1}
          visible={false}
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Currency ID'
          field='CurrencyID'
          width='220'
          visible={false}
          // editType='dropdownedit'
          // edit={currencyParams}
          textAlign='Left'
        />
        <ColumnDirective
          field='CommissionAmountPerUnit'
          type='number'
          headerText='Commission Amount Per Unit'
          width='250'
          editType='numericedit'
          format={'n2'}
          textAlign='right'
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
        ]}
      />
    </GridComponent>
  );

  const EmployeeSupplierWisePrimaryCommissionDetailGridComponent = () => (
    <GridComponent
      ref={(grid) => (EmployeeSupplierWisePrimaryCommissionDetailGrid = grid)}
      id='EmployeeSupplierWisePrimaryCommissionDetailGrid'
      dataSource={employeeSupplierWisePrimaryCommissionDetail}
      editSettings={editOptions}
      toolbar={toolbarOptions}
      // delayUpdate={true}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 5 }}
      filterSettings={filterOptions}
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      // toolbarClick={toolbarClick}
      allowFiltering={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      actionComplete={EmployeeSupplierWisePrimaryCommissionDetailActionComplete}
      // load={setTimeout(() => fabricContractEditGrid ? fabricContractEditGrid.dataSource = fabricEditPlanData : null, 2000)}
      // dataBound={databound}
      // height={315}
      // created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}
      // height={315}
    >
      <ColumnsDirective>
        <ColumnDirective
          headerTextAlign='center'
          headerText='Supplier'
          field='Supplier'
          width='220'
          id='supplier'
          editType='dropdownedit'
          edit={supplierParams2}
          // id='supplier'
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='SupplierID'
          field='SupplierID'
          visible={false}
          textAlign='Left'
        />
        <ColumnDirective
          field='PrimaryCommissionPercentOnAdjustment'
          type='number'
          headerText='Primary Commission % On Adjustment'
          width='250'
          editType='numericedit'
          format={'n2'}
          textAlign='right'
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
        ]}
      />
    </GridComponent>
  );

  const EmployeeSupplierWiseSecondaryCommissionDetailGridComponent = () => (
    <GridComponent
      ref={(grid) => (EmployeeSupplierWiseSecondaryCommissionDetailGrid = grid)}
      id='EmployeeSupplierWiseSecondaryCommissionDetailGrid'
      dataSource={employeeSupplierWiseSecondaryCommissionDetail}
      editSettings={editOptions}
      toolbar={toolbarOptions}
      delayUpdate={true}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 5 }}
      filterSettings={filterOptions}
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      // toolbarClick={toolbarClick}
      allowFiltering={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      actionComplete={
        EmployeeSupplierWiseSecondaryCommissionDetailActionComplete
      }
      // load={setTimeout(() => fabricContractEditGrid ? fabricContractEditGrid.dataSource = fabricEditPlanData : null, 2000)}
      // dataBound={databound}
      // height={315}
      // created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}
      // height={315}
    >
      <ColumnsDirective>
        <ColumnDirective
          headerTextAlign='center'
          headerText='Supplier'
          field='Supplier'
          width='220'
          editType='dropdownedit'
          edit={supplierParams3}
          id='supplier'
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Supplier'
          field='SupplierID'
          width='220'
          visible={false}
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Currency'
          field='Currency'
          width='220'
          editType='dropdownedit'
          edit={currencyParams}
          textAlign='Left'
        />
        <ColumnDirective
          headerTextAlign='center'
          headerText='Currency'
          field='CurrencyID'
          width='220'
          visible={false}
        />
        <ColumnDirective
          headerText='Adjustment Target'
          field='AdjustmentTarget'
          type='number'
          editType='numericedit'
          width='200'
          format={'n2'}
          textAlign='right'

        />
        <ColumnDirective
          field='SecondaryCommissionPercentOnAdjustment'

          headerText='Secondary Commission % On Adjustment'
          width='250'
          editType='numericedit'
          format={'n2'}
          textAlign='right'
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
        ]}
      />
    </GridComponent>
  );

  // Tabs
  // const EmployeeSupplierWisePerUnitCommissionDetailTab = () => {
  //   return (
  //     <TabComponent
  //       heightAdjustMode='Content'
  //       id='EmployeeSupplierWisePerUnitCommissionDetailTab'
  //       selected={(tab) => {
  //         if (
  //           tab.selectedIndex === 1 &&
  //           EmployeeSupplierWisePerUnitCommissionDetail.dataSource.length === 0
  //         ) {
  
  //           // EmployeeSupplierWisePerUnitCommissionDetail.dataSource = EmployeeSupplierWisePerUnitCommissionDetailListPlanData;
  //           // EmployeeSupplierWisePerUnitCommissionDetail.dataSource = EmployeeSupplierWisePerUnitCommissionDetailListData;
  //         }
  //       }} /*created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}*/
  //     >
  //       <TabItemsDirective>
  //         {/* <TabItemDirective header={headertext[3]} content={EmployeeSupplierWisePerUnitCommissionDetailEditGridComponent} /> */}
  //         <TabItemDirective
  //           header={headertext[0]}
  //           content={EmployeeSupplierWisePerUnitCommissionDetailGridComponent}
  //         />
  //       </TabItemsDirective>
  //     </TabComponent>
  //   );
  // };

  // const EmployeeSupplierWisePrimaryCommissionDetailTab = () => {
  //   return (
  //     <TabComponent
  //       heightAdjustMode='Content'
  //       id='EmployeeSupplierWisePrimaryCommissionDetailTab'
  //       selected={(tab) => {
  //         if (
  //           tab.selectedIndex === 1 &&
  //           EmployeeSupplierWisePrimaryCommissionDetail.dataSource.length === 0
  //         ) {
  
  //           // EmployeeSupplierWisePrimaryCommissionDetail.dataSource = EmployeeSupplierWisePrimaryCommissionDetailListPlanData;
  //           // EmployeeSupplierWisePrimaryCommissionDetail.dataSource = EmployeeSupplierWisePrimaryCommissionDetailListData;
  //         }
  //       }} /*created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}*/
  //     >
  //       <TabItemsDirective>
  //         {/* <TabItemDirective header={headertext[3]} content={EmployeeSupplierWisePrimaryCommissionDetailEditGridComponent} /> */}
  //         <TabItemDirective
  //           header={headertext[1]}
  //           content={EmployeeSupplierWisePrimaryCommissionDetailGridComponent}
  //         />
  //       </TabItemsDirective>
  //     </TabComponent>
  //   );
  // };

  // const EmployeeSupplierWiseSecondaryCommissionDetailTab = () => {
  //   return (
  //     <TabComponent
  //       heightAdjustMode='Content'
  //       id='EmployeeSupplierWiseSecondaryCommissionDetailTab'
  //       selected={(tab) => {
  //         if (
  //           tab.selectedIndex === 1 &&
  //           EmployeeSupplierWiseSecondaryCommissionDetail.dataSource.length === 0
  //         ) {
  
  //           // EmployeeSupplierWiseSecondaryCommissionDetail.dataSource = EmployeeSupplierWiseSecondaryCommissionDetailListPlanData;
  //           // EmployeeSupplierWiseSecondaryCommissionDetail.dataSource = EmployeeSupplierWiseSecondaryCommissionDetailListData;
  //         }
  //       }} /*created={document.getElementById('projectionPlanTab').ej2_instances[0].refresh()}*/
  //     >
  //       <TabItemsDirective>
  //         {/* <TabItemDirective header={headertext[3]} content={EmployeeSupplierWiseSecondaryCommissionDetailEditGridComponent} /> */}
  //         <TabItemDirective
  //           header={headertext[2]}
  //           content={EmployeeSupplierWiseSecondaryCommissionDetailGridComponent}
  //         />
  //       </TabItemsDirective>
  //     </TabComponent>
  //   );
  // };

  let countryRef = useRef(null);
  let cityRef = useRef(null);
  let designationRef = useRef(null);
  let usersRef = useRef(null);
  // let portRef = useRef(null)
  return (
    <div
    onKeyDown={(e)=>{
      
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}
    // className='employee-add-container' onKeyDown={(e)=>{
    
    //   if(e.keyCode == 13){
    //     e.stopPropagation()

    //   }
    // }
    // }
    >
      <div>
        <div>
          <div className='heading'>
            <h3>Employee - {props.isAdd ? `Add` : `Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>

          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate={footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className='row'>
                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Employee Name'
                        name='EmployeeName'
                        floatLabelType='Auto'
                        value={employeeName}
                        onChange={(e) => {
                          
                          setCheckDuplicate(true);
                          setEmployeeName(e.value);
                        }}
                      />
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='email'
                        placeholder='Employee Email'
                        name='EmployeeEmail'
                        floatLabelType='Auto'
                        value={employeeEmail}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (emailRegex.test(e.value)) {
                            setEmployeeEmail(e.value);
                          } else {
                          
                          // setEmployeeEmail(employeeName)
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          
                              onBlur={()=>{
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                if(employeeEmail == '' || !emailRegex.test(employeeEmail)){ 
                              const emailInput = document.querySelector('input[name="EmployeeEmail"]');
                                    
                                    emailInput.focus()
                                    emailInput.style.border = '2px solid red'
                                    setTimeout(() => {
                                      emailInput.style.border = '1px solid #c8c8c8'
                                      
                                    }, 400);
                                    
                                    }
                                  }}
                      />
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 1'
                        name='ContactNo1'
                        floatLabelType='Auto'
                        value={contactNo1}
                        onChange={(e)=>{
                            
                          // const inputValue = ;
                           // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(e.value)) {
                            setContactNo1(e.value);
                            setCheckContact1(false)
                          } else {
                            setCheckContact1(true)
                            
                          const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                          // if(contactNo1 != ''){contactNo1Input.value = contactNo1; }else{
                          //   contactNo1Input.value = ''
                          //   setContactNo1('')
                          //   setCheckContact1(true)
                          // }
                          contactNo1Input.focus()

                          contactNo1Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo1Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo1 == ''){
                          const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                                
                                contactNo1Input.focus()
                                setCheckContact1(true)
                                contactNo1Input.style.border = '2px solid red'
                                setTimeout(() => {
                                  contactNo1Input.style.border = '1px solid #c8c8c8'
                                  
                                }, 400);
                                
                                }else{
                                  setCheckContact1(false)
                                }
                              }}
                      />
                                              {
                           CheckContact1 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 2'
                        name='ContactNo2'
                        floatLabelType='Auto'
                        value={contactNo2}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(inputValue)) {
                            setContactNo2(inputValue);
                            setCheckContact2(false)
                          } else {
                          
                          const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                          // if(contactNo2 != ''){contactNo2Input.value = contactNo2; }else{
                          //   contactNo2Input.value = ''
                          //   setCheckContact2(true)

                          //   setContactNo2('')
                          // }
                          contactNo2Input.focus()
                          setCheckContact2(true)

                          contactNo2Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo2Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo2 == ''){
                          const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                          contactNo2Input.focus()
                      setCheckContact2(true)

                          contactNo2Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo2Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                                }else{
                                  setCheckContact1(false)
                                }
                              }}
                      />
                      {
                           CheckContact2 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 3'
                        name='ContactNo3'
                        floatLabelType='Auto'
                        value={contactNo3}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(inputValue)) {
                            setContactNo3(inputValue);
                            setCheckContact3(false)
                          } else {
                          
                          const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                          // if(contactNo3 != ''){contactNo3Input.value = contactNo3; }else{
                          //   contactNo3Input.value = ''
                          //   setCheckContact3(true)

                          //   setContactNo3('')
                          // }
                          contactNo3Input.focus()
                          setCheckContact3(true)

                          contactNo3Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo3Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo3 == ''){
                          const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                          contactNo3Input.focus()
                      setCheckContact3(true)

                          contactNo3Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo3Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                                }else{
                                  setCheckContact1(false)
                                }
                              }}
                      />
                      {
                         CheckContact3 == true? 
                      <div className='' id= 'check-contact-nos'>
                      <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                      </div></div>:null
                      }
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Address 1'
                        name='Address1'
                        floatLabelType='Auto'
                        value={address1}
                        onChange={(e) => {
                          
                          setCheckDuplicate(true);
                          setAddress1(e.value);
                        }}
                      />
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Address 2'
                        name='Address2'
                        floatLabelType='Auto'
                        value={address2}
                        onChange={(e) => {
                          
                          setCheckDuplicate(true);
                          setAddress2(e.value);
                        }}
                      />
                    </mat-form-field>
                  </div>
                  <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Citizen Ship No'
                        name='CitizenShipNo'
                        floatLabelType='Auto'
                        value={citizenShipNo}
                        onChange={(e) => {
                          
                          setCheckDuplicate(true);
                          setCitizenShipNo(e.value);
                        }}
                      />
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      {loadingCountry ? (
                        <div>... Loading Country </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='Country'
                            dataSource={country}
                            placeholder='Select Country'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={countryChange}
                            ref={countryRef}
                            change={countryChange}
                            value={countryValue}
                              blur={()=>{
                                
                                if (countryID  == 0  || countryRef.current.value == null || countryRef.current.value == '' || countryID == undefined || countryID == null) {
                                 if(countryValue != ''){
                                   let resstr = country.some(e=>e.value ==  countryValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${countryValue} Value Not Find ` )
                                     document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  countryRef.current.element.focus()

                                 }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Country Id'
                            name='CountryID'
                            floatLabelType='Auto'
                            value={countryID}
                          />
                          <span
                            className='add-new'
                            onClick={() => onAddNewClick('Country')}>
                            {AddNewSecurity('Country')}
                          </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      {loadingCity ? (
                        <div>... Loading City </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='City'
                            dataSource={city}
                            placeholder='Select City'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={cityChange}
                            ref={cityRef}
                            change={cityChange}
                            value={cityValue}
                              blur={()=>{
                                
                                if (cityID  == 0  || cityRef.current.value == null || cityRef.current.value == '' || cityID == undefined || cityID == null) {
                                 if(cityValue != ''){
                                   let resstr = city.some(e=>e.value ==  cityValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${cityValue} Value Not Find ` )
                                     document.getElementById("City").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("City").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  cityRef.current.element.focus()

                                 }
                               }
                             }
                            }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='City Id'
                            name='CityID'
                            floatLabelType='Auto'
                            value={cityID}
                          />
                          <span
                            className='add-new'
                            onClick={() => onAddNewClick('City')}>
                            {AddNewSecurity('City')}
                          </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                  <div className='col-sm-6'>
                    <mat-form-field>
                      {loadingDesignation ? (
                        <div>... Loading Designation </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='Designation'
                            dataSource={designation}
                            placeholder='Select Designation'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={designationChange}
                            ref={designationRef}
                            change={designationChange}
                            value={designationValue}
                              blur={()=>{
                                
                                if (designationID  == 0  || designationRef.current.value == null || designationRef.current.value == '' || designationID == undefined || designationID == null) {
                                 if(designationValue != ''){
                                   let resstr = designation.some(e=>e.value ==  designationValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("Designation").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("Designation").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  designationRef.current.element.focus()

                                 }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Designation Id'
                            name='DesignationID'
                            floatLabelType='Auto'
                            value={designationID}
                          />
                          <span
                            className='add-new'
                            onClick={() => onAddNewClick('Designation')}>
                            {AddNewSecurity('Designation')}
                          </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>
                  <div className='col-sm-6'>
                    <mat-form-field>
                      {loadingUser ? (
                        <div>... Loading Users </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='Users'
                            dataSource={users}
                            placeholder='Select Users'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={usersChange}
                            ref={usersRef}
                            change={usersChange}
                            value={UserValue}
                              blur={()=>{
                                
                                if (UserID  == 0  || usersRef.current.value == null || usersRef.current.value == '' || UserID == undefined || UserID == null) {
                                 if(UserValue != ''){
                                   let resstr = users.some(e=>e.value ==  UserValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("Users").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("Users").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  usersRef.current.element.focus()

                                 }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='USERS Id'
                            name='UserID'
                            floatLabelType='Auto'
                            value={UserID}
                          />
                          <span
                            className='add-new'
                            onClick={() => onAddNewClick('Users')}>
                            {AddNewSecurity('Users')}
                          </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                  {/* <div className='col-sm-6'>
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='number'
                        placeholder='Commission Percent'
                        name='CommissionPercent'
                        floatLabelType='Auto'
                        value={commissionPercent}
                                  
                        onChange={(e) => {
                          
                          setCheckDuplicate(true);
                          setCommissionPercent(e.value);
                        }}
                      />
                    </mat-form-field>
                  </div> */}
                  <div className='col-sm-6'>
                  <h3
        className='duplicate '
        style={checkDuplicateValue != '' ? { transform: 'scale(1)' } : {}}>
        {checkDuplicateValue}
      </h3>
                    </div>
                </div>
                <br />
              </mat-card-content>
              <mat-card-action></mat-card-action>
            </mat-card>
          </div>
        </div>
      </div>
      <h3 id='duplicate-value-detected'>Duplicate Value Detected</h3>
      <TabComponent
        /*heightAdjustMode='None' height='415px'*/ id='employeeDetailTab'
        delayUpdate='true'>
        <TabItemsDirective>
          <TabItemDirective
            header={headertext[0]}
            content={EmployeeSupplierWisePerUnitCommissionDetailGridComponent}
          />
          <TabItemDirective
            header={headertext[1]}
            content={EmployeeSupplierWisePrimaryCommissionDetailGridComponent}
          />
          <TabItemDirective
            header={headertext[2]}
            content={EmployeeSupplierWiseSecondaryCommissionDetailGridComponent}
          />
        </TabItemsDirective>
      </TabComponent>
      <br />
      

      
    </div>
  );
}
