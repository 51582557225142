import React, { useEffect, useState } from 'react';
// import ReceiveUnAllocatedPaymentService from '../../services/product.service';
import SecurityService from '../security/security.service';
import './receive-unAllocated-payment.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import PIService from '../../services/PI.service';
import {decryptData} from '../decryption/decryption'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import ReceiveUnAllocatedPaymentAdd from './receive-unAllocated-payment-add';
import ReceiveUnAllocatedPaymentEdit from './receive-unAllocated-payment-edit';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import {image} from './image';
import logoUrl from './logo192.png';
import ReceiveUnAllocatedPaymentService from '../../services/receive-unAllocated-payment.service';
import receiveUnAllocatedPaymentService from '../../services/receive-unAllocated-payment.service';
import { useRef } from 'react';

var selectedRows = []
let ReceiveUnAllocatedPaymentGrid: Grid;
export default function ReceiveUnAllocatedPaymentList(props) {
  
    // var img =  imagess;
  
  const [ReceiveUnAllocatedPaymentDataSource, setReceiveUnAllocatedPaymentDataSource] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [showDialogEmail, setShowDialogEmail] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isDesigantion, setIsDesigantion] = useState(true);
  const [paramsType, setParamsType] = useState();
  const [isAdd, setIsAdd] = useState(false);

 

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (
      ReceiveUnAllocatedPaymentGrid &&
      args.item.properties.id === 'ReceiveUnAllocatedPaymentGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_ReceiveUnAllocatedPaymentGrid_information.pdf`
      // };
      
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_receive-unallocated_payment.pdf`
      };
      ReceiveUnAllocatedPaymentGrid.pdfExport(exportProperties);
    
    
    }
    if (
      ReceiveUnAllocatedPaymentGrid &&
      args.item.properties.id === 'ReceiveUnAllocatedPaymentGrid_excelexport'
    ) {
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_ReceiveUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // const customizeHeader = ({ element }) => {
      //   // Insert the logo in the header
      //   const logoImage = new Image();
      //   logoImage.src = image;
      //   logoImage.width = 100; // Adjust the width as per your requirement
      //   logoImage.height = 50; // Adjust the height as per your requirement
    
      //   // Add the logo to the header cell
      //   const headerCell = element.sheet.rows[0].cells[0];
      //   headerCell.appendChild(logoImage);
    
      //   // Merge cells for the logo
      //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // };
      
      // ReceiveUnAllocatedPaymentGrid.excelExport(exportProperties);
      // ReceiveUnAllocatedPaymentGrid.beforeDataBound(customizeHeader)
      // ReceiveUnAllocatedPaymentGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_receive_unallocated-payment.xlsx`
    };
    ReceiveUnAllocatedPaymentGrid.excelExport(exportProperties);
      
    }
    if (
      ReceiveUnAllocatedPaymentGrid &&
      args.item.properties.id === 'ProductFinishSizeGrid_columnchooser'
    ) {
    }
    if (ReceiveUnAllocatedPaymentGrid && args.item.properties.id === 'Delete') {
      
      if (ReceiveUnAllocatedPaymentGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`ReceiveUnAllocatedPayment`,
          ColumnName:`ReceiptID`,
          RowID:ReceiveUnAllocatedPaymentGrid.getSelectedRecords()[0]?.ReceiptID,
          SelectedRow:true,
          SelectedRowData:{
            ...ReceiveUnAllocatedPaymentGrid.getSelectedRecords()[0]
          }
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    let paths =   window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');
    
    setParamsType(paths[1])
    getReceiveUnAllocatedPayment();
    SecurityService.getUserAccessList(userId).then((res)=>{
      
      const decryptedData = decryptData(res.data); // Decrypt response
      
      
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByEmail(decryptedData[0].Email)
     });
    //  checkIsCRMDesignation()

  }, []);
  // const checkIsCRMDesignation = async () => {
  //   PIService.getAllEmployee()
  //     .then((response) => {
  
  
  //       let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId && val.DesignationID == 2).length == 1 ? true : false;
  
  //       setIsDesigantion(isCRMDesignation)     
       
  //             })
  //     .catch((e) => {
  
  //       setIsDesigantion(false)     
       
  //     });
  // };
  
  const getReceiveUnAllocatedPayment = async (type: string) => {
    ReceiveUnAllocatedPaymentService.getList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        const data = decryptedData.map((v, k) => {
          v.ReceiptDate = new Date(v.ReceiptDate.split("T", 1)[0]);
          v.PaymentVerificationStatusDate = v.PaymentVerificationStatusDate == null ? null :   new Date(v.PaymentVerificationStatusDate.split("T", 1)[0]);
         return v;
        });
        // setReceiveUnAllocatedPaymentDataSource(decryptedData);
        setReceiveUnAllocatedPaymentDataSource(data);
      })
      .catch((e) => {
        
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    
    return props.isAdd ? (
      // <div dataList={ReceiveUnAllocatedPaymentDataSource} {...props}> </div>
      <ReceiveUnAllocatedPaymentAdd isSaleAgent={paramsType} dataList={ReceiveUnAllocatedPaymentDataSource} {...props}> </ReceiveUnAllocatedPaymentAdd>
      
      ) :
      (
        <ReceiveUnAllocatedPaymentEdit isSaleAgent={paramsType} dataList={ReceiveUnAllocatedPaymentDataSource} {...props}> </ReceiveUnAllocatedPaymentEdit>
        
        // <div></div>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess(`ReceiveUnAllocatedPayment${paramsType}Edit`),
    allowAdding: SecurityService.canUserAccess(`ReceiveUnAllocatedPayment${paramsType}Add`),
    allowDeleting: SecurityService.canUserAccess(`ReceiveUnAllocatedPayment${paramsType}Delete`),
    // allowEditing: paramsType == "CRM" ? true  : false,
    // allowAdding: true,
    // allowDeleting: true,
    mode: 'Dialog',
    showDeleteConfirmDialog: false, // Conditionally set showDeleteConfirmDialog
    template: dialogTemplate, // Conditionally set template
    // mode: isAdd == true ? 'Dialog' : 'Normal',
    // showDeleteConfirmDialog: isAdd, // Conditionally set showDeleteConfirmDialog
    // template: isAdd == true  ? dialogTemplate : '', // Conditionally set template
    
    footerTemplate: ''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  
  
  const actionComplete = (args: DialogEditEventArgs) => {
    
    if (args?.requestType === 'columnstate') {
      
      ReceiveUnAllocatedPaymentGrid?.autoFitColumns();
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save' && args.action ==  "add"){
        
        const data = {
          CreatedByUserID : userId,
          ...args.data,
          PaymentTypeID: '1',
          ReceiptDate:moment(args.data.ReceiptDate).format('YYYY-MM-DD')
        }
        ReceiveUnAllocatedPaymentService.addReceiveUnAllocatedPayment(data)
          .then((response) => {
        getReceiveUnAllocatedPayment()
          })
      }
      if (args.requestType === 'save' && args.action ==  "edit"){
        
        const data = {
          CRMID : args.data.CRMName != "" ?  args.data.CRMID : null,
          Remarks : args.data.Remarks,
          CRMName : args.data.CRMName,
          PaymentVerificationStatusDate: args.data.PaymentVerificationStatusDate == null ? null :  moment(args.data.PaymentVerificationStatusDate).format('YYYY-MM-DD'),
          PaymentVerificationStatus:args.data.PaymentVerification,
          ReceiptID: args.data.ReceiptID
        }
        
        if(args.data.CRMName !=  ""){

          ReceiveUnAllocatedPaymentService.ApproveReceiveUnAllocatedPayment(data)
          .then((response) => {
            getReceiveUnAllocatedPayment()
          })
        }else{
          // ReceiveUnAllocatedPaymentService.ApproveReceiveUnAllocatedPayment(data)
          // .then((response) => {
          //   getReceiveUnAllocatedPayment()
          // })

        
        }
      }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     // delete productFinishSize[0]

      
    //   ReceiveUnAllocatedPaymentService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setReceiveUnAllocatedPaymentDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     ReceiveUnAllocatedPaymentService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getReceiveUnAllocatedPayment();
    
    //   }

    }

  };
  const databound = () =>
  {
      if (ReceiveUnAllocatedPaymentGrid) {
        ReceiveUnAllocatedPaymentGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);

//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };

let refreshColumn =(columnName) =>{
  if (ReceiveUnAllocatedPaymentGrid) {
    ReceiveUnAllocatedPaymentGrid.columns.forEach(column => {
      if (column.field === columnName) {
        ReceiveUnAllocatedPaymentGrid.refreshColumns(column);
      }
    });
  }
}
const handleCheckboxChange = (e,data) => {
   
  receiveUnAllocatedPaymentService.ApprovePaymentVerificationStatus(data.ReceiptID,userId).then((res)=>{

    let dataFin = ReceiveUnAllocatedPaymentDataSource.map((Res)=>{
      if(data.ReceiptID == Res.ReceiptID){
        
        if(Res.PaymentVerificationStatus == true){
          Res.PaymentVerificationStatus = false;
          Res.CRMID = 0;
          Res.CRMName = null
          Res.PaymentVerificationStatusDate = null
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
 
        }else{
          Res.PaymentVerificationStatus = true;
          Res.CRMID = userId
          Res.CRMName = printedByUserName
          Res.PaymentVerificationStatusDate = new Date()
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
        }
      }
    })
    

  })
};
const actionBegin = (args)=>{
  

  if(args.requestType == "cancel"){
    // setIsAdd(true)
    args.cancel = false
  }
  // if(args.requestType == "beginEdit"){
  //   setIsAdd(false)
  // }
}

let RemarksUseRef =  useRef(null)
  const ReceiveUnAllocatedPaymentGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (ReceiveUnAllocatedPaymentGrid = grid)}
      id='ReceiveUnAllocatedPaymentGrid'
      dataSource={ReceiveUnAllocatedPaymentDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      actionBegin={actionBegin}
      rowSelected={
        (args)=>{
          
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
      	

        {/* <ColumnDirective
          headerText='ID'
          field='ReceiptID'
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='Serial No'
          headerTextAlign='CENTER'    
          field='SerialNo'
          width='140'
          textAlign='Right'
        />
        <ColumnDirective
          headerText="Receipt Date"
          field='ReceiptDate'
          type="date"
          textAlign="Right"
          headerTextAlign="center"
          format={formatOptions}
        />        
        <ColumnDirective
          headerText='Customer'
          headerTextAlign='CENTER'    
          field='CustomerName'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Payment Type'
          headerTextAlign='CENTER'    
          field='PaymentType'
          width='145'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Reference No'
          headerTextAlign='CENTER'    
          field='ReferenceNo'
          width='160'
          textAlign='Left'
         
        />
        <ColumnDirective
          headerText='Sender Name '
          headerTextAlign='CENTER'    
          field='SenderName'
          width='140'
          textAlign='Left'
        />
          <ColumnDirective
            headerText='Description'
            headerTextAlign='CENTER'    
            field='Description'
            width='170'
            textAlign='Left'
          />
          <ColumnDirective
            headerText='Debit Amt'
            headerTextAlign='CENTER'    
            field='DebitAmount'
            width='145'
            format='n0'
            textAlign='Right'
          />
          <ColumnDirective
            headerText='Credit Amt'
            headerTextAlign='CENTER'    
            field='CreditAmount'
            width='145'
            format='n0'
            textAlign='Right'
          />
          <ColumnDirective
            headerText='Currency'
            headerTextAlign='CENTER'    
            field='CurrencyName'
            width='150'
            textAlign='Left'
          />
        <ColumnDirective
          headerText='User Name'
          headerTextAlign='CENTER'    
          field='UserName'
          width='130'
          textAlign='Left'
        />
{/*         
PaymentVerificationStatus	
PaymentVerificationStatusDate	
CRMID
CRMName */}
        
        {/* <ColumnDirective
          headerText='Remarks'
          headerTextAlign='CENTER'    
          field='Remarks'
          width='160'
          editType='tex'
          textAlign='Left'
        /> */}
        {/* PaymentVerificationStatus	
PaymentVerificationStatusDate	
CRMName	
Remarks */}

    
 <ColumnDirective 
        field='PaymentVerificationStatus'
         headerText='Verified'
         headerTextAlign='CENTER'    
         width='175' 
          textAlign='center'
          template={(props) =>{ 
            
            
            return (
            <div className={props.PaymentVerificationStatus == false ? `pendingPayment` : ''}>
        {props.PaymentVerificationStatus == true ? "YES" : "NO"}
            </div>
           
          )}} 

            
            />

   
            <ColumnDirective
            headerText='Verification Date'
            field='PaymentVerificationStatusDate'
            width='170'
            headerTextAlign='CENTER'    
            textAlign='right'
            format={formatOptions}
            type='date'

            

            />
            <ColumnDirective
            headerText='CRM'
            field='CRMName'
            width='145'
            textAlign='left'
            headerTextAlign='CENTER'    
            />

   <ColumnDirective 
        field='Remarks'
         headerText='Remarks'
          width='180' 
          headerTextAlign='CENTER'    
          textAlign='left'
          allowEditing={paramsType == "CRM" ? true : false} 
            editTemplate={(props) => (
             <>
              <TextBoxComponent
                        className='e-input'
                        
                        name='Remarks'
                        readOnly={paramsType == "CRM" ? false : true}
                        readonly={paramsType == "CRM" ? false : true}
                        enabled={paramsType == "CRM" ? true : false}
            
                        value={props.Remarks}
                        onChange={(e)=>{
                        
                        
                        RemarksUseRef.current =  e.value
                       
                          // setCutWidth(e.value)
                        }}
                        onBlur={()=>{
                          receiveUnAllocatedPaymentService.UpdateRemarks(  RemarksUseRef.current,props.ReceiptID,props.CRMID).then((res)=>{

                            let dataFin = ReceiveUnAllocatedPaymentDataSource.map((Res)=>{
                              if(props.ReceiptID == Res.ReceiptID){
                                
                                Res.Remarks =    RemarksUseRef.current;
                                Res.CRMID = userId
                                Res.CRMName = printedByUserName
                                  refreshColumn('Remarks')
                                  refreshColumn('CRMName')
                                
                              }
                            })
                            
                        
                          })
                        }}
                      />
             </>
            )} 
            
            />      

      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
            
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getReceiveUnAllocatedPayment()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <ReceiveUnAllocatedPaymentGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>RECEIVE UNALLOCATED PAYMENT - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getReceiveUnAllocatedPayment()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <ReceiveUnAllocatedPaymentGridComponent />
            </div>
          </div>
          
        </div>
      )
  );
}
