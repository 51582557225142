import React, { useEffect, useState } from 'react';
import './setup-add.scss';
import SetupService from '../../services/setup.service';
import {decryptData}  from '../decryption/decryption';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  Grid,
  FilterSettingsModel,
  Filter,
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
} from '@syncfusion/ej2-react-grids';
import SecurityService from '../security/security.service';

export default function SetupAdd(props) {
  const [setup, setSetup] = useState([]);
  const [title, setTitle] = useState('');

  let setupGrid: Grid;

  const setHeading = () => {
    const type = props.type;
    let title: string;
    switch (type) {
      case 'Broker':
        title = 'Broker';
        break;
      case 'YarnCount':
        title = 'Yarn Count';
        break;
      case 'YarnQuality':
        title = 'Yarn Quality';
        break;
      case 'YarnType':
        title = 'Yarn Type';
        break;
      case 'YarnBrand':
        title = 'Yarn Brand';
        break;
      case 'YarnGodown':
        title = 'Yarn Godown';
        break;
      case 'ImportLC':
        title = 'Import LC';
        break;
      case 'HSCode':
        title = 'HS Code';
        break;
      case 'FabricType':
        title = 'Fabric Type';
        break;
      case 'FabricYarnDescription':
        title = 'Fabric Yarn Description';
        break;
      case 'FabricCategory':
        title = 'Fabric Category';
        break;
      case 'FabricThreadCount':
        title = 'Fabric Thread Count';
        break;
      case 'Vendors':
        title = 'Vendors';
        break;
      case 'CompanyType':
        title = 'Company Type';
        break;
      case 'Currency':
        title = 'Currency';
        break;
      case 'ImportExpenses':
        title = 'Import Expenses';
        break;
      case 'FabricPacking':
        title = 'Fabric Packing';
        break;
        case 'FabricAdjustmentType':
        title = 'Fabric Adjustment Type';
        break;
      case 'PaymentTerms':
        title = 'Payment Terms';
        break;
        case 'Department':
        title = 'Department';
        break;
      case 'Designation':
        title = 'Designation';
        break;
      case 'DeliveryStatus':
        title = 'Delivery Status';
        break;
      case 'ConversionContractType':
        title = 'Conversion Contract Type';
        break;
      case 'BrokerageType':
        title = 'Brokerage Type';
        break;
      case 'ProcurementType':
        title = 'Procurement Type';
        break;
      case 'AccessoryCategory':
        title = 'Accessory Category';
        break;
        case 'Country':
        title = 'Country';
        break;
      case 'Port':
        title = 'Port';
        break;
      case 'StitchingStyle':
        title = 'Stitching Style';
        break;
      case 'UnitOfMeasurement':
        title = 'Unit Of Measurement';
        break;
      case 'Color':
        title = 'Color';
        break;
      case 'FabricDesign':
        title = 'Fabric Design';
        break;
      case 'FabricVersion':
        title = 'Fabric Version';
        break;
      case 'ThirdPartyGodown':
        title = 'Third Party Godown';
        break;
      case 'RejectionSubCategory':
        title = 'Rejection Sub Category';
        break;
      case 'RejectionCategory':
        title = 'Rejection Category';
        break;
      case 'ProductCategory':
        title = 'Product Category';
        break;
      case 'ProductSize':
        title = 'Product Size';
        break;
      case 'ProductCutSize':
        title = 'Product Cut Size';
        break;
      case 'ProductFinishSize':
        title = 'Product Finish Size';
        break;
      case 'ShipmentType':
        title = 'Shipment Type';
        break;
        case 'ProductDesc':
          title = 'Product Description';
          break;
        default:
        title = '';
        break;
    }
    setTitle(title);
  };

  useEffect(() => {
    if (props.type !== '') {
      console.log('setup add type: ', props.type);
      if (setup.length === 0) {
        setHeading();
        getSetup(props.type);
      }
    }

  }, []);

  const getSetup = async (type) => {
    SetupService.getAll(type)
      .then((response) => {
        console.log('get all setup', response)
        const decryptedData = decryptData(response.data); // Decrypt response
                    
                setSetup(decryptedData);
        // setSetup(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
        setSetup({});
      });
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    'Delete',
    'Update',
    'Cancel',
  ];

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('toolbar click: ', args);
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess(`${props.type}Edit`),
    // allowAdding: SecurityService.canUserAccess(`${props.type}Add`),
    // allowDeleting: SecurityService.canUserAccess(`${props.type}Delete`),
    
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,

    mode: 'Normal',
    showDeleteConfirmDialog: true,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'id', direction: 'Descending' }]
  }

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('action complete: ', args);
    if (args.form) {
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        /** Add Validation Rules */
        // args.form.ej2_instances[0].addRules('id', { disabled: true });

        // args.form.ej2_instances[0].addRules('OrderDate', {
        //   required: true,
        //   date: true,
        // });
      }

      if (args.requestType === 'save') {
        SetupService.insert(props.type, args.data).then((response) => {
          console.log('response: ', response);
          // setSetup(response.data.recordset);
          setSetup([]);
          getSetup(props.type);  
          // refresh grid to show new record
          // getSetup(props.type);
        });
      }
    }
  };

  // const onSave =() => {
  //   SetupService.insert('YarnBrand',57)
  //   .then((response) => {
  //       console.log('new value has been added: ', response);
  //     //  setShowAddNewDialog(false);
  //   })
  //   .catch((error) => {
  //       // add to existing Yarn Brand
  //     console.log(error);
  //     // setShowAddNewDialog(false);
  //   });
  // }

  const SetupGrid = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (setupGrid = grid)}
      id='setupGrid'
      dataSource={setup}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      actionComplete={actionComplete}
      toolbarClick={toolbarClick}
      allowPaging={true}
      allowSorting={true}
      allowFiltering={true}
      sortSettings={sortingOptions}
      idMapping='id'
    >
      <ColumnsDirective>
        <ColumnDirective
          headerText='ID'
          field='id'
          width='130'
          textAlign='Left'
          // isPrimaryKey={true}
          allowEditing={false}
        // enabled={false}
        />
        <ColumnDirective
          headerText='Description'
          field='value'
          width='250'
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          Filter,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div className='setup-add-container'>
        <SetupGrid restrictPageSize={5} />
      </div>
    ) :
      (
        <div className='setup-add-container'>
          {/* <div className='row'>
          <TextBoxComponent
            className='e-input'
            type='text'
            placeholder='Yarn Brand'
            name='BarndName'
            onChange={onChange}
            floatLabelType='Auto'
          />
        </div>
        <div>
          <button id="btnSave" className="e-control e-btn e-primary" onClick={()=> props.newValue(brandName)}>Save</button>
        </div>
        <div>
        </div> */}
          {/* <button id="btnSave" className="e-control e-btn e-primary" onClick={onSave}>Save</button> */}
          {/* <button onClick={()=> props.changeWord('Child')}>Change title</button>  */}
          <SetupGrid />
        </div>
      )
  );
}

// import React, { Component } from 'react';
// import SetupService from '../../services/setup.service';

// import './setup-edit.scss';



// export default class SetupAdd extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentSetup: {
//         description: '',
//       },
//       isSuccess: '',
//       message: '',
//       heading: '',
//       action: '',
//     };
//   }

//   componentDidMount = () => {
//     // this.getSetupById(this.route[1], this.props.match.params.id);
//     // this.setState({
//     //   heading: this.props.location.state.action,
//     // });
//   };

//   onChangeSetup = (e) => {
//     this.setState({
//       currentSetup: {
//         value: e.target.value,
//       },
//     });
//   };

//   setTitle = (route) => {
//     let title: string;
//     switch (route) {
//       case 'Broker':
//         title = 'Broker';
//         break;
//       case 'YarnCount':
//         title = 'Yarn Count';
//         break;
//       case 'YarnQuality':
//         title = 'Yarn Quality';
//         break;
//       case 'YarnType':
//         title = 'Yarn Type';
//         break;
//       case 'YarnBrand':
//         title = 'Yarn Brand';
//         break;
//       case 'YarnGodown':
//         title = 'Yarn Godown';
//         break;

//       case 'ImportLC':
//         title = 'Import LC';
//         break;

//       case 'HSCode':
//         title = 'HS Code';
//         break;

//       case 'FabricType':
//         title = 'Fabric Type';
//         break;

//       case 'FabricYarnDescription':
//         title = 'Fabric Yarn Description';
//         break;

//       case 'FabricCategory':
//         title = 'Fabric Category';
//         break;

//       case 'FabricThreadCount':
//         title = 'Fabric Thread Count';
//         break;

//       case 'Vendors':
//         title = 'Vendors';
//         break;

//       case 'CompanyType':
//         title = 'Company Type';
//         break;

//       case 'Currency':
//         title = 'Currency';
//         break;

//       case 'ImportExpenses':
//         title = 'ImportExpenses';
//         break;

//       default:
//         break;
//     }
//     return title;
//   };

//   addSetup = () => {
//     SetupService.insert(this.route[1], this.state.currentSetup)
//       .then((response) => {
//         if (response.status === 200) {
//           this.setState({
//             isSuccess: 'true',
//             message: `<strong>Success! </strong> ${this.setupType} has been added.`,
//           });
//           this.redirect();
//         }
//       })
//       .catch((error) => {
//         this.setState({
//           isSuccess: 'false',
//           message: `<strong>Failure! </strong> An error has occured while updating ${this.setupType}.`,
//         });
//         console.log(error);
//       });
//   };

//   redirect = () => {
//     setTimeout(() => {
//       this.props.history.push('/Setup/' + this.route[1]);
//     }, 2000);
//   };

//   route = window.location.pathname
//     .substr(1, window.location.pathname.length)
//     .split('/');

//   setupType = this.setTitle(this.route[1]);

//   render() {
//     const { currentSetup } = this.state;
//     return (
//       <div className='setup-container'>
//         <div className='list row'>
//           <div className='col-md-6'>
//             <h4 style={{ textAlign:'center', width: '80%' }}> Add - {this.setupType}</h4>
//           </div>
//         </div>
//         {this.state.isSuccess === 'true' ? (
//           <div className='alert alert-success' id='success-alert'>
//             <button type='button' className='close' data-dismiss='alert'>
//               x
//             </button>
//             {this.state.message}
//           </div>
//         ) : null}
//         {this.state.isSuccess === 'false' ? (
//           <div className='alert alert-danger' id='danger-alert'>
//             <button type='button' className='close' data-dismiss='alert'>
//               x
//             </button>
//             {this.state.message}
//           </div>
//         ) : null}

//         <form>
//           <div className='form-group'>
//             {/* <label htmlFor='godown'>ID</label>
//             <input
//                 type='text'
//                 className='form-control'
//                 id='id'
//                 value={currentSetup.id}
//                 readOnly
//             /> */}
//             <label htmlFor='godown'>Description</label>
//             <input
//               type='text'
//               className='form-control'
//               id='description'
//               value={currentSetup.value}
//               onChange={this.onChangeSetup}
//             />
//           </div>
//         </form>
//         <button className='m-3 btn btn-sm btn-warning' onClick={this.addSetup}>
//           Add
//         </button>
//       </div>
//     );
//   }
// }
