import React, { useState, useEffect, useRef } from 'react'
import './change-access.scss'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogUtility } from '@syncfusion/ej2-popups';
import SecurityService from '../security.service';
import moment from 'moment';
import usersService from '../../../services/users.service';
import {decryptData} from '../../decryption/decryption';
let userSecurityItemGrid: Grid;
var storeEdit  = [];
export default function ChangeAccess(props) {
  let userId = SecurityService.getLoggedInUserId()
  const [userSecurityItems, setUserSecurityItems] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const [userID, setUserID] = useState(0);
  const [editIDs, setEditIDs] = useState([]);
  let headerCheckbox = useRef();


  useEffect(() => {
    getUserIDs();
  }, []);

  const getUserSecurityItems = async (id) => {
    SecurityService.getUserAccessList(id)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setUserSecurityItems(decryptedData);
        userSecurityItemGrid?.refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserIDs = async () => {
    let data = {
      TableName:'Users',
      Columns:['UserID','UserName']
    }
    usersService.getThreeInputsForList(data)
      .then((response) => {
       let storeDta= response?.data?.recordset.map(res =>{
          return {
            id:res.UserID,
            value:res.UserName

          }
        })
        setUserIDs(storeDta);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const userIDChange = (e) => {
    storeEdit = []
    setEditIDs([])
    if (e?.itemData?.id) {
      setUserID(e.itemData.id);
      getUserSecurityItems(e?.itemData?.id)
    }
  }

  const toolbarClick = (args: ClickEventArgs) => {
    // console.log(args);
    if (
      userSecurityItemGrid &&
      args.item.properties.id === 'UserSecurityItemGrid_PDFExport'
    ) {

      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        pageSize: 'A3',
        fileName: `${moment().format('YYYYMMDD')}_yarn_purchase_contract.pdf`
      };

      // console.log('clicked on pdf export');
      userSecurityItemGrid.pdfExport(exportProperties);

    }

    if (
      userSecurityItemGrid &&
      args.item.properties.id === 'UserSecurityItemGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_yarn_purchase_contract.xlsx`
      };
      userSecurityItemGrid.excelExport(exportProperties);
    }
    // if (
    //   userSecurityItemGrid &&
    //   args.item.properties.id === 'UserSecurityItemGrid_columnchooser'
    // ) {
    // }
    if (args?.item?.properties?.id === 'Cancel') {
      // console.log(userSecurityItems);
      // to reload the grid
      setUserSecurityItems([]);
      getUserSecurityItems(userID);

      // userSecurityItemGrid?.refresh();
      // userSecurityItemGrid?.refreshDataSource();
      userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
    if (args?.item?.properties?.id === 'Update') {
      // console.log(userSecurityItemGrid?.getCurrentViewRecords());
      userSecurityItemGrid?.editModule?.batchSave();
      // console.log(userSecurityItemGrid?.getCurrentViewRecords());
      // userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
  };

  const toolbarOptions: ToolbarItems[] = [
    // 'Edit',
    // 'Update',
    // 'Cancel',
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
    {
      text: "Cancel", tooltipText: "Cancel", id: "Cancel", disabled: true
    },
    {
      text: "Update", tooltipText: "Update", id: "Update", disabled: true
    }
  ];

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    mode: 'Batch',
    showConfirmDialog: false
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'equal',
    //     value: 'False'
    //   }
    // ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'UserSecurityItemID', direction: 'Descending' }]
  }

  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const actionComplete = (args: DialogEditEventArgs) => {
    if (args?.requestType === 'columnstate') {
      // console.log('adjusting column');
      userSecurityItemGrid?.autoFitColumns();
    }
    if (args?.requestType === 'paging' || args?.requestType === 'refresh') {
      const currentData = userSecurityItemGrid.getCurrentViewRecords();
      if (currentData.every((el) => el.CanAccess === true)) {
        headerCheckbox.current.checked = true;
        // document.getElementById('userCanAcessCheckBox').checked = true;
      } else {
        headerCheckbox.current.checked = false;
        // document.getElementById('userCanAcessCheckBox').checked = false;
      }
    }
    // if (args?.requestType === 'paging') {
    //   alert('Confirm changes');
    // }
    // console.log('check', args);
    if (args.form && args.form.ej2_instances) {
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      //   /** Add Validation Rules */
      //   // args.form.ej2_instances[0].addRules('OrderID', { max: 500 });
      //   args.form.ej2_instances[0].addRules('OrderDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractStartDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractEndDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('CompanyName', { required: true });
      //   args.form.ej2_instances[0].addRules('FullYarnCount', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('YarnBrand', { required: true });
      //   args.form.ej2_instances[0].addRules('ImportLCNo', { required: true });
      //   args.form.ej2_instances[0].addRules('OrderBags', { required: true });
      //   args.form.ej2_instances[0].addRules('WeightPerBag', { required: true });
      //   args.form.ej2_instances[0].addRules('ProcurementType', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('Currency', { required: true });
      //   args.form.ej2_instances[0].addRules('ForeignCurrencyRate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ForeignCurrencyAmount', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractExchangeRate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('Rate', { required: true });
      //   args.form.ej2_instances[0].addRules('RatePer', { required: true });

      //   args.form.ej2_instances[0].addRules('TaxType', { required: true });
      //   args.form.ej2_instances[0].addRules('TaxPercent', { required: true });
      //   args.form.ej2_instances[0].addRules('PaymentTerms', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('DeliveryStatus', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('PaymentDays', {
      //     required: true,
      //   });
      // }
    }
    if (args.requestType === 'batchsave') {
      const data = {
        rows: 1
      };
      // console.log('current data:', userSecurityItemGrid?.dataSource);
      // console.log('data: ', args.rows.map((elem) => elem.data));
      console.log(userId)
      console.log(userSecurityItemGrid?.dataSource)
      console.log(editIDs)
      console.log(storeEdit)
      let totalID = [...editIDs , ...storeEdit]
      console.log(totalID)
      const uniqueArray = [...new Set(totalID)];
      console.log(uniqueArray)
      let arrayofobjects = userSecurityItemGrid?.dataSource;
      let filterObj = arrayofobjects.filter((res)=>{ return  uniqueArray.includes(res.UserSecurityItemID)})
      console.log(filterObj)
      SecurityService.changeAccess(filterObj, userId)
        .then((response) => {
          // setUserSecurityItems([]);
          // getUserSecurityItems(userID);
          userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
          storeEdit = []
    setEditIDs([])
        });

    }
  };

  const databound = () =>
  {
      if (userSecurityItemGrid) {
        userSecurityItemGrid.autoFitColumns();
      }
  }

  const actionBegin = (args: DialogEditEventArgs) => {
    // console.log(userSecurityItemGrid?.toolbarModule?.toolbar?.tbarEle[1]);
    if (args.requestType === 'paging' &&
      userSecurityItemGrid?.toolbarModule?.toolbar?.tbarEle[0]?.getAttribute("aria-disabled") == "false") {
      args.cancel = true;
      // alert('Save changes before paging');
      DialogUtility.confirm({
        title: 'Save Changes',
        content: 'Save Changes Before Paging!',
        position: { Y: 100 },
        okButton: { text: 'Save', click: () => document.getElementById('Update').click() },
        cancelButton: { text: 'Cancel', click: () => document.getElementById('Cancel').click() },
        showCloseIcon: true,
        closeOnEscape: true
      });

      // userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
  }

  const ddlFields: object = { value: 'value' };

  const checkboxChange = (args) => {
    let UserSecurityItemIDs =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemID;
    })
    console.log(UserSecurityItemIDs)
    console.log(userSecurityItemGrid?.getCurrentViewRecords())
    // UserSecurityItemIDs
    setEditIDs([...editIDs , ...UserSecurityItemIDs])
    console.log(editIDs)
    let currentData =  userSecurityItemGrid?.getCurrentViewRecords();
    currentData && currentData.map((el) => {
      el.CanAccess = args.checked
    });
    // console.log(currentData);
    userSecurityItemGrid.refreshColumns();
    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
    // userSecurityItemGrid.editModule.batchSave();
  }

  const headerTemplate = () => (
    <div>
      <CheckBoxComponent ref={headerCheckbox} id='userCanAcessCheckBox' change={checkboxChange} />
      &nbsp;
      <span>Can Access</span>
    </div>
  )

  const spaceOutItemName = (field: string, data: object, column: Column) => {
    const value = data[column?.field];
    return value?.match(/[A-Z][a-z]+/g)?.join(" ");
  };
 
  useEffect(() => {
    console.log(storeEdit)
    
  }, [storeEdit]);
// useEffect(() => {
//   setTimeout(() => {
    
//     console.log(editIDs)
//   }, 2000);
  
// }, [editIDs]);
  return (
    <div className='content-wrapper'>
      <div>
        <h3 style={{ textAlign: 'left' }}> Change Access - Edit</h3>
        <div className='user-selection-container'>
          <AutoCompleteComponent
            id="User" dataSource={userIDs}
            placeholder="Select User" fields={ddlFields}
            floatLabelType='Auto' highlight={true}
            select={userIDChange}
            change={userIDChange}
          />
        </div>
        <br />
        <GridComponent
          id="UserSecurityItemGrid"
          ref={(grid) => userSecurityItemGrid = grid}
          dataSource={userSecurityItems}
          editSettings={editSettings}
          toolbar={toolbarOptions}
          pageSettings={{ pageCount: 4, pageSizes: true }}
          filterSettings={filterOptions}
          groupSettings={groupOptions}
          actionComplete={actionComplete}
          allowPaging={true}
          allowSorting={true}
          toolbarClick={toolbarClick}
          allowFiltering={true}
          allowGrouping={true}
          showColumnChooser={true}
          allowReordering={true}
          allowResizing={true}
          // dataBound={databound}
          sortSettings={sortingOptions}
          created={() => {
            userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
          }}
        // actionBegin={actionBegin}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="UserSecurityItemID"
              headerText="ID"
              width="30"
              textAlign="Right"
              allowEditing={false}
              isPrimaryKey={true}
            />
            <ColumnDirective
            headerText='Module Name'
            field="ModuleName"
            textAlign='left'
            width="50"
            headerTextAlign='center'

            />
            <ColumnDirective
            headerText='Input Name'
            field="InputName"
            width="80"
            textAlign='left'
            headerTextAlign='center'

            />
            <ColumnDirective
              field="ItemName"
              headerText="Item Name"
              width="90"
              valueAccessor={spaceOutItemName}
              allowEditing={false}
            />
            <ColumnDirective
              field="ItemTypeName"
              headerText="Item Type"
              width="30"
              allowEditing={false}
            />
            <ColumnDirective
              field="UserID"
              headerText="User ID"
              width="2"
              textAlign="Right"
              allowEditing={false}
              visible={false}
            />
            <ColumnDirective
              field="UserName"
              headerText="User Name"
              width="50"
              allowEditing={false}
            />
            <ColumnDirective
              field="CanAccess"
              headerText="Can Access"
              width="40"
              // allowEditing={true}
              // displayAsCheckBox={true}
              // editType="booleanedit"
              template={(data) => (
                <CheckBoxComponent checked={data.CanAccess} change={(args) => {
                  //userSecurityItemGrid.editModule.updateCell(args.event.target.closest('tr').rowIndex, 'CanAccess', args.checked);
                  // userSecurityItemGrid.editModule.saveCell();
                  console.log('irtiza',data)
                  // console.log(userSecurityItemGrid?.getCurrentViewRecords())
                  // console.log(data)
                   storeEdit = [...storeEdit , data.UserSecurityItemID];
                   console.log(storeEdit)
                  // setEditIDs([...editIDs , data.UserSecurityItemID])
                  
                  // console.log(editIDs)
                  const currentData = userSecurityItemGrid?.getCurrentViewRecords();
                  if (currentData) {
                    currentData[args.event.target.closest('tr').rowIndex].CanAccess = args.checked;
                    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
                    // userSecurityItemGrid?.editModule?.batchSave();
                  }
                }} />
              )}
              allowFiltering={false}
              allowSorting={false}
              headerTemplate={headerTemplate}
            />
          </ColumnsDirective>
          <Inject services={[
            Resize,
            Edit,
            Toolbar,
            Page,
            Sort,
            PdfExport,
            ExcelExport,
            Filter,
            Group,
            Aggregate,
            Freeze,
            ColumnChooser,
            Reorder,
            CommandColumn,
          ]} />
        </GridComponent>
      </div>
    </div >
  )
}
