import React, { useEffect, useState } from 'react';
import SupplierService from '../../services/supplier.service';
import SecurityService from '../security/security.service';
import './supplier.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';
import { decryptData } from '../decryption/decryption';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import SupplierAdd from './supplier-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
var selectedRows = []

export default function SupplierList(props) {
  const [supplier, setSupplier] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  let supplierGrid: Grid;

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (
      supplierGrid &&
      args.item.properties.id === 'SupplierGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Supplier_information.pdf`
      };
      
      supplierGrid.pdfExport(exportProperties);
    }
    if (
      supplierGrid &&
      args.item.properties.id === 'SupplierGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Supplier_information.xlsx`
      };
      supplierGrid.excelExport(exportProperties);
    }
    if (
      supplierGrid &&
      args.item.properties.id === 'SupplierGrid_columnchooser'
    ) {
    }
    if (supplierGrid && args.item.properties.id === 'Delete') {
      
      if (supplierGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`Supplier`,
          ColumnName:`SupplierID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`Supplier`,
          ColumnName:`SupplierID`,
          RowID:supplierGrid.getSelectedRecords()[0]?.SupplierID,
          SelectedRow:true,
          SelectedRowData:supplierGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    getSupplier();
  }, []);

  const getSupplier = async (type: string) => {
    SupplierService.getSupplierForList()
      .then((response) => {
         const decryptedData = decryptData(response.data); // Decrypt response
                
        
        setSupplier(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('SupplierDelete')    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    
    return props.isAdd ? (
      <SupplierAdd dataList={supplier} {...props}> </SupplierAdd>
    ) :
      (
        <SupplierAdd dataList={supplier} {...props}> </SupplierAdd>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess('SupplierEdit'),
    allowAdding: SecurityService.canUserAccess('SupplierAdd'),
    allowDeleting: SecurityService.canUserAccess('SupplierDelete'),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
  const userName = SecurityService.getLoggedInUser();

  const actionComplete = (args: DialogEditEventArgs) => {
    
    if (args?.requestType === 'columnstate') {
      
      supplierGrid?.autoFitColumns();
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'  && args.action   === 'add') {
        const data = {
          UserID: userId,
          ...args.data,
        };
        
        delete supplier[0]

      
      SupplierService.addSupplier(data)
      .then((response) => {
        AuditTableInput.auditAdd(`Supplier`,`Supplier`,`SupplierID`,data)
        setSupplier([])
        getSupplier()
        // setSupplier(response?.data?.recordset)
      });
  
    }
       
      if (args.requestType === 'save'  && args.action   === 'edit') {
        const data = {
          UserID: userId,
          ...args.data,
        };
        
        SupplierService.updateSupplier(args.data.SupplierID,data)
          .then((response) => {
            let totalColumns = Object.keys(args.data);
            
            AuditTableInput.auditEdit(totalColumns,selectedRows,args.data,`Supplier`,args.data.SupplierID)
            getSupplier();
            });
      }

    }

  };
  const databound = () =>
  {
      if (supplierGrid) {
        supplierGrid.autoFitColumns();
      }
  }

  let refreshColumn =(columnName) =>{
    if (   supplierGrid) {
      supplierGrid.columns.forEach(column => {
        if (column.field === columnName) {
          supplierGrid.refreshColumns(column);
        }
      });
    }
  }
  const handleCheckboxChange = (e,data) => {
    
    let dataS = {
      UserID:data.UserID,
      DeletionDate : e.target.checked == true? `${new Date()}`: null,
      DeletedByUserName : userName,
      DeletedByUserID : e.target.checked == true? userId: null,
      IsDeleted:e.target.checked == true? 1: 0
    }
   SupplierService.CustomerIsDeletedService(dataS,data.SupplierID).then((res)=>{
 
    let dataFin = supplier.map((Res)=>{
      if(data.SupplierID == Res.SupplierID){
        
        if(Res.IsDeleted == true){
          Res.IsDeleted = false;
         //  Res.CRMID = 0;
         //  Res.CRMName = null
         //  Res.PaymentVerificationStatusDate = null
         Res.DeletionDate = new Date()
         Res.DeletedByUserName = userName
         Res.DeletedByUserID = userId
         refreshColumn('IsDeleted')
         refreshColumn('DeletionDate')
          refreshColumn('DeletedByUserName')
         
          Res.DeletionDate = new Date()
         //  Res.IsDeleted = true
          refreshColumn('IsDeleted')
          refreshColumn('DeletionDate')
          Res.DeletedByUserName = 'NULL'
           refreshColumn('DeletedByUserName')
       }else{
        //  Res.IsDeleted = true;
        //  Res.CRMID = userId
        //  Res.CRMName = printedByUserName
         
        Res.IsDeleted = true;
        //  Res.CRMID = 0;
        //  Res.CRMName = null
        //  Res.PaymentVerificationStatusDate = null
        Res.DeletionDate = new Date()
        Res.DeletedByUserName = userName
        Res.DeletedByUserID = userId
        refreshColumn('IsDeleted')
        refreshColumn('DeletionDate')
         refreshColumn('DeletedByUserName')
        
       
          
        }
       }
     })
     
 
   })
 };
 const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const SupplierGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (supplierGrid = grid)}
      id='SupplierGrid'
      dataSource={supplier}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      dataBound={databound}
      rowSelected={
        (args)=>{
          
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
      <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        />
      
         <ColumnDirective
          headerText='Supplier Name'
          headerTextAlign='CENTER'    
          field='SupplierName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Supplier Email'
          headerTextAlign='CENTER'    
          field='SupplierEmail'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo1'
          headerTextAlign='CENTER'    
          field='ContactNo1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo2'
          headerTextAlign='CENTER'    
          field='ContactNo2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo3'
          headerTextAlign='CENTER'    
          field='ContactNo3'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address1'
          headerTextAlign='CENTER'    
          field='Address1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address2'
          headerTextAlign='CENTER'    
          field='Address2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Country Name'
          headerTextAlign='CENTER'    
          field='CountryName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='City Name'
          headerTextAlign='CENTER'    
          field='CityName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective 
        field='IsDeleted'
         headerText='InActive'
          width='170' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'

                className={`checkbox ${props.IsDeleted ? 'yellow': ''}`}
                id={`checkbox_${props.SerialNo}`}
                 checked={props.IsDeleted}
                 
                  onChange={(e) => handleCheckboxChange(e, props)} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            />
        {/* <ColumnDirective
                headerText='InActive
                 Date'
                field='DeletionDate'
                headerTextAlign='Center'
                width='135'
                type='date'
                format={formatOptions}
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />
               <ColumnDirective
                headerText='InActive
                 User Name'
                field='DeletedByUserName'
                headerTextAlign='Center'
                width='145'
                textAlign='left'
                
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />  */}
        
        {/* <ColumnDirective
          headerText='Is Deleted'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='IsDeleted'
          width='150'
        /> */}
        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          // CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getSupplier()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <SupplierGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Supplier - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getSupplier()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <SupplierGridComponent />
            </div>
          </div>
        </div>
      )
  );
}
