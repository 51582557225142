import React, { useEffect, useState, useRef } from 'react';
import SetupService from '../../services/setup.service';
import './country-city-port.scss';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import countryCityPortService from '../../services/country-city-port.service';
import {decryptData} from '../decryption/decryption'
export default function CountryCityPortAdd(props) {
  
  // document.addEventListener('keydown', function (event) {
  //   // Check if the pressed key is the Escape key (key code 27)
  //   if (event.keyCode === 27) {
  //     // Prevent the default behavior to stop the popup from closing
  //     event.preventDefault();
  //   }
  // });
  
  
  const [countrycityport, setCountryCityPort] = useState([]);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [country, setCountry] = useState('');
  const [loadingCountry, setLoadingCountry] = useState('');
  const [countryID, setCountryID] = useState(props.CountryID);
  const [countryValue, setCountryValue] = useState(props.CountryName);

  const [city, setCity] = useState('');
  const [loadingCity, setLoadingCity] = useState('');
  const [cityID, setCityID] = useState(props.CityID);
  const [cityValue, setCityValue] = useState(props.CityName);

  const [port, setPort] = useState('');
  const [loadingPort, setLoadingPort] = useState('');
  const [portID, setPortID] = useState(props.PortID);
  const [portValue, setPortValue] = useState(props.PortName);

  const [countryCityPortName, setCountryCityPortName] = useState(props?.countryCityPortName);
  // const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  
  useEffect(() => {
    
    return () => {
      document.getElementById('CountryCityPortGrid_dialogEdit_wrapper').addEventListener('keydown',  (e)=>{
        
        if(e.code == 'Escape' || e.keyCode == 27){
          // e.preventDefault()
          e.preventDefault();
          e.stopPropagation();
        }
      })
    };
  }, [countryValue,cityValue,portValue]);
  useEffect(() => {
  setTimeout(() => {
    
  
    
    if(countryValue != undefined && cityValue != undefined && portValue != undefined && checkDuplicate == true){
      
      
      let check = CheckDuplicate.withCombination(props.dataList,['Country','City','Port'],{
        CountryName:countryValue,
        CityName:cityValue,
        PortName:portValue,
      })
     
      // props.dataList,['Country', 'City', 'Port'],)
      
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#CountryCityPortGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#CountryCityPortGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [countryValue,cityValue,portValue]);

  useEffect(() => {
    setCheckDuplicate(true)
  
    getCountry()
    getCity()
    getPort()
  }, []);

  const getCountryCityPort = async (type: string) => {
    countryCityPortService.getCountryCityPortForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                
        
        setCountryCityPort(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  // const getProductCategory = async (type: string) => {
  //   setLoadingProductCategory(true);
  //   SetupService.getAll('ProductCategory')
  //     .then((response) => {
  //       
  //       setProductCategory(response.data.recordset);
  //       setLoadingProductCategory(false);
  //     })
  //     .catch((e) => {
  //       
  //     });
  // };
  const getCountry = async (type: string) => {
    setLoadingCountry(true);
    SetupService.getAll('Country')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setCountry(decryptedData);
        setLoadingCountry(false);
      })
      .catch((e) => {
        
      });
  };

  const getCity = async (type: string) => {
    setLoadingCity(true);
    SetupService.getAll('City')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setCity(decryptedData);
        setLoadingCity(false);
      })
      .catch((e) => {
        
      });
  };

  const getPort = async (type: string) => {
    setLoadingPort(true);
    SetupService.getAll('Port')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setPort(decryptedData);
        setLoadingPort(false);
      })
      .catch((e) => {
        
      });
  };

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      case 'Country':
        getCountry();
      break;
      case 'City':
        getCity();
      break;
      case 'Port':
        getPort();
      break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>CountryCityPort - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    
    if(showAddNewDialog){
      
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }

    const countryChange = (e) => {
        
        setCountryValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setCountryID(0)
      if(e?.e?.srcElement?.id)  {
        
          setCountryID(e?.itemData?.id);
        }
      };

    const cityChange = (e) => {
        
        setCityValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setCityID(0)
      if(e?.e?.srcElement?.id)  {
        
          setCityID(e?.itemData?.id);
        }
      };


    const portChange = (e) => {
        
        setPortValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setPortID(0)
      if(e?.e?.srcElement?.id)  {
        
          setPortID(e?.itemData?.id);
        }
      };



  let countryRef = useRef(null)
  let cityRef = useRef(null)
  let portRef = useRef(null)
  return (
    <div className='country-city-port-add-container' onKeyDown={(e)=>{
      
      // if(e.keyCode == 13 || e.keyCode == 27 ||  e.code == "Escape"){
      if(e.keyCode == 13 ){
        e.stopPropagation()
        // e.preventDefault()
      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>CountryCityPort - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
                {/* <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Country City Port'
                        name='CountryCityPortName'
                        floatLabelType='Auto'
                        value={countryCityPortName}
                        onChange={(e)=>{
                          
                          setCheckDuplicate(true)
                          setCountryCityPortName(e.value)}}
                      />
                    </mat-form-field>
                  </div> */}
                  <div className="col-sm-6">
                  <mat-form-field>
                      { loadingCountry? (
                        <div>... Loading Country </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="Country" 
                            dataSource={country}
                            placeholder="Select Country" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={countryChange}
                            ref={countryRef}
                            change={countryChange}
                            value={countryValue}
                            created={()=> countryRef.current.element.focus()}
                            blur={()=>{
                              
                              if (countryID  == 0  || countryRef.current.value == null || countryRef.current.value == '' || countryID == undefined || countryID == null) {
                               if(countryValue != ''){
                                 let resstr = country.some(e=>e.value ==  countryValue);
                                 
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${countryValue} Value Not Find ` )
                                   document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                countryRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Country Id'
                            name='CountryID'
                            floatLabelType='Auto'
                            value={countryID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('Country')}>
                          {AddNewSecurity('Country')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                   <div className="col-sm-6">
                    
                    <mat-form-field>
                      { loadingCity? (
                        <div>... Loading City </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="City" 
                            dataSource={city}
                            placeholder="Select City" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={cityChange}
                            ref={cityRef}
                            change={cityChange}
                            value={cityValue}
                            blur={()=>{
                              
                              if (cityID  == 0  || cityRef.current.value == null || cityRef.current.value == '' || cityID == undefined || cityID == null) {
                               if(cityValue != ''){
                                 let resstr = city.some(e=>e.value ==  cityValue);
                                 
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${cityValue} Value Not Find ` )
                                   document.getElementById("City").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("City").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                cityRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='City Id'
                            name='CityID'
                            floatLabelType='Auto'
                            value={cityID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('City')}>
                          {AddNewSecurity('City')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">

                    <mat-form-field>
                      { loadingPort? (
                        <div>... Loading Port </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="Port" 
                            dataSource={port}
                            placeholder="Select Port" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={portChange}
                            ref={portRef}
                            change={portChange}
                            value={portValue}
                            blur={()=>{
                              
                              if (portID  == 0  || portRef.current.value == null || portRef.current.value == '' || portID == undefined || portID == null) {
                               if(portValue != ''){
                                 let resstr = port.some(e=>e.value ==  portValue);
                                 
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${portValue} Value Not Find ` )
                                   document.getElementById("Port").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("Port").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                portRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Port Id'
                            name='PortID'
                            floatLabelType='Auto'
                            value={portID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('Port')}>
                          {AddNewSecurity('Port')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field>

                  </div>
                </div>
                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>
<h3 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>

    </div>
  )
}
