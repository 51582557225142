import React, { useEffect, useState, useRef } from 'react';
import SetupService from '../../services/setup.service';
import './supplier.scss';
import { decryptData } from '../decryption/decryption';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import supplierService from '../../services/supplier.service';

export default function SupplierAdd(props) {
  const [supplier, setSupplier] = useState([]);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [country, setCountry] = useState('');
  const [loadingCountry, setLoadingCountry] = useState('');
  const [countryID, setCountryID] = useState(props.CountryID);
  const [countryValue, setCountryValue] = useState(props.CountryName);
  
  const [supplierName, setSupplierName] = useState(props?.SupplierName);
  const [supplierEmail, setSupplierEmail] = useState(props?.SupplierEmail);
  const [contactNo1, setContactNo1] = useState(props?.ContactNo1 ? props?.ContactNo1 : '');
  const [CheckContact1, setCheckContact1] = useState(false);
  const [contactNo2, setContactNo2] = useState(props?.ContactNo2 ? props?.ContactNo2 : '');
  const [CheckContact2, setCheckContact2] = useState(false);
  const [contactNo3, setContactNo3] = useState(props?.ContactNo3 ? props?.ContactNo3 : '');
  const [CheckContact3, setCheckContact3] = useState(false);
  const [address1, setAddress1] = useState(props?.Address1 ? props?.Address1 : '-');
  const [address2, setAddress2] = useState(props?.Address2 ? props?.Address2 : '-');
  const [city, setCity] = useState('');
  const [loadingCity, setLoadingCity] = useState('');
  const [cityID, setCityID] = useState(props.CityID);
  const [cityValue, setCityValue] = useState(props.CityName);

  // const [port, setPort] = useState('');
  // const [loadingPort, setLoadingPort] = useState('');
  // const [portID, setPortID] = useState(props.PortID);
  // const [portValue, setPortValue] = useState(props.port);

  // const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  
  useEffect(() => {
  setTimeout(() => {
    
  
    if( supplierEmail != undefined  && checkDuplicate == true){
      
      
      // let check = CheckDuplicate.withCombination(props.dataList,['SupplierEmail'],{
        
      //   SupplierEmail: customerEmail,
      //   // ContactNo1: contactNo1,
      //   // ContactNo2: contactNo2,
      //   // ContactNo3: contactNo3,
      //   // Address1: address1,
      //   // Address2: address2,
      //   // Country: countryValue,
      //   // City: cityValue,
      // })
     
      // props.dataList,['Country', 'City', 'Port'],)
      var check = false;
      if(props.isAdd == true){
        
        check = CheckDuplicate.withOutCombination(props.dataList,['SupplierEmail'],{
          SupplierEmail: supplierEmail,
 
       })
      }else{
        let array = props.dataList.filter(obj => obj.SupplierID !== props.SupplierID)

        check = CheckDuplicate.withOutCombination(array,['SupplierEmail'],{
          SupplierEmail: supplierEmail,
 
       })

      }
      
      
    //   if(!check){
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
    //     setCheckDuplicateValue('')
    //   }else{
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
    //     setCheckDuplicateValue('Duplicate Value Detected!')
    // }
      
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#SupplierGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#SupplierGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [supplierEmail]);

  // useEffect(() => {
  // setTimeout(() => {
    
  
  //   if( supplierEmail != undefined && checkDuplicate == true){
  //     
  //     
  //     let check = CheckDuplicate.withCombination(props.dataList,['SupplierEmail'],{
  //       // SupplierName: supplierName,
  //       SupplierEmail: supplierEmail,
  //       // ContactNo1: contactNo1,
  //       // ContactNo2: contactNo2,
  //       // ContactNo3: contactNo3,
  //       // Address1: address1,
  //       // Address2: address2,
  //       // Country: countryValue,
  //       // City: cityValue,
  //     })
     
  //     // props.dataList,['Country', 'City', 'Port'],)
  //     
  //     // setCheckDuplicate(check)
  //     if(!check){
  //       document.querySelector('#SupplierGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
  //       setCheckDuplicateValue('')
  //     }else{

  //       document.querySelector('#SupplierGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
  //       setCheckDuplicateValue('Duplicate Value Detected!')
        
  //   }
  //  }
  // }, 500);
  // // }, [supplierName,supplierEmail,contactNo1,contactNo2,contactNo3,address1,address2,countryID,cityID]);
  // }, [supplierEmail]);

  useEffect(() => {
    setTimeout(() => {
      
      setCheckDuplicate(true)
    }, 200);
    if(props.isAdd == false){
      setTimeout(() => {
       
        document.querySelector('#SupplierGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 400);
     }
    getCountry()
    getCity()
    // getPort()
  }, []);

  const getSupplier = async (type: string) => {
    supplierService.getSupplierForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        setSupplier(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  const getCountry = async (type: string) => {
    setLoadingCountry(true);
    SetupService.getAll('Country')
      .then((response) => {
         const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        setCountry(decryptedData);
        setLoadingCountry(false);
      })
      .catch((e) => {
        
      });
  };

  const getCity = async (type: string) => {
    setLoadingCity(true);
    SetupService.getAll('City')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        setCity(decryptedData);
        setLoadingCity(false);
      })
      .catch((e) => {
        
      });
  };

  // const getPort = async (type: string) => {
  //   setLoadingPort(true);
  //   SetupService.getAll('Port')
  //     .then((response) => {
  //       
  //       setPort(response.data.recordset);
  //       setLoadingPort(false);
  //     })
  //     .catch((e) => {
  //       
  //     });
  // };

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      case 'Country':
        getCountry();
      break;
      case 'City':
        getCity();
      break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Supplier - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    
    if(showAddNewDialog){
      
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }

    const countryChange = (e) => {
        
        setCountryValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setCountryID(0)
      if(e?.e?.srcElement?.id)  {
        
          setCountryID(e?.itemData?.id);
        }
      };

    const cityChange = (e) => {
        
        setCityValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setCityID(0)
      if(e?.e?.srcElement?.id)  {
        
          setCityID(e?.itemData?.id);
        }
      };


    // const portChange = (e) => {
    //     
    //     setPortValue(e?.itemData?.value  ? e?.itemData?.value :"")
    //     setPortID(0)
    //   if(e?.e?.srcElement?.id)  {
    //     
    //       setPortID(e?.itemData?.id);
    //     }
    //   };



  let countryRef = useRef(null)
  let cityRef = useRef(null)
  // let portRef = useRef(null)
  return (
    <div className='supplier-add-container' onKeyDown={(e)=>{
      
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Supplier - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">

                <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Supplier Name'
                        name='SupplierName'
                        floatLabelType='Auto'
                        value={supplierName}
                        onChange={(e)=>{
                          
                          setCheckDuplicate(true)
                          setSupplierName(e.value)}}
                      />
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='email'
                        placeholder='Supplier Email'
                        name='SupplierEmail'
                        floatLabelType='Auto'
                        value={supplierEmail}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (emailRegex.test(e.value)) {
                            setSupplierEmail(e.value);
                          } else {
                          
                          // setEmployeeEmail(employeeName)
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          
                              onBlur={()=>{
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                if(supplierEmail == '' || !emailRegex.test(supplierEmail)){ 
                              const emailInput = document.querySelector('input[name="SupplierEmail"]');
                                    
                                    emailInput.focus()
                                    emailInput.style.border = '2px solid red'
                                    setTimeout(() => {
                                      emailInput.style.border = '1px solid #c8c8c8'
                                      
                                    }, 400);
                                    
                                    }
                                  }}
                      />
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 1'
                        name='ContactNo1'
                        floatLabelType='Auto'
                        value={contactNo1}
                        onChange={(e)=>{
                            
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(inputValue)) {
                            setContactNo1(inputValue);
                            setCheckContact1(false)
                          } else {
                            setCheckContact1(true)
                            
                          const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                          // if(contactNo1 != ''){contactNo1Input.value = contactNo1; }else{
                          //   contactNo1Input.value = ''
                          //   setContactNo1('')
                          //   setCheckContact1(true)
                          // }
                          contactNo1Input.focus()

                          contactNo1Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo1Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo1 == ''){
                          const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                                
                                contactNo1Input.focus()
                                setCheckContact1(true)
                                contactNo1Input.style.border = '2px solid red'
                                setTimeout(() => {
                                  contactNo1Input.style.border = '1px solid #c8c8c8'
                                  
                                }, 400);
                                
                                }else{
                                  setCheckContact1(false)
                                }
                              }}
                        
                      />
                      {
                           CheckContact1 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 2'
                        name='ContactNo2'
                        floatLabelType='Auto'
                        value={contactNo2}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(inputValue)) {
                            setContactNo2(inputValue);
                            setCheckContact2(false)
                          } else {
                          
                          const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                          // if(contactNo2 != ''){contactNo2Input.value = contactNo2; }else{
                          //   contactNo2Input.value = ''
                          //   setCheckContact2(true)

                          //   setContactNo2('')
                          // }
                          contactNo2Input.focus()
                          setCheckContact2(true)

                          contactNo2Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo2Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo2 == ''){
                          const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                          contactNo2Input.focus()
                      setCheckContact2(true)

                          contactNo2Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo2Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                                }
                              }}   />
                      {
                           CheckContact2 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Contact No 3'
                        name='ContactNo3'
                        floatLabelType='Auto'
                        value={contactNo3}
                        onChange={(e)=>{
                          const inputValue = e.value; // Assuming e.value contains the input value
                          const emailRegex = /^[0-9+]+$/;
                          if (emailRegex.test(inputValue)) {
                            setContactNo3(inputValue);
                            setCheckContact3(false)
                          } else {
                          
                          const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                          // if(contactNo3 != ''){contactNo3Input.value = contactNo3; }else{
                          //   contactNo3Input.value = ''
                          //   setCheckContact3(true)

                          //   setContactNo3('')
                          // }
                          contactNo3Input.focus()
                          setCheckContact3(true)

                          contactNo3Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo3Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                          }
                          
                          setCheckDuplicate(true)
                          }}
                          onBlur={()=>{
                            if(contactNo3 == ''){
                          const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                          contactNo3Input.focus()
                      setCheckContact3(true)

                          contactNo3Input.style.border = '2px solid red'
                          setTimeout(() => {
                            contactNo3Input.style.border = '1px solid #c8c8c8'
                            
                          }, 400);
                                }
                              }}
                      />
                    </mat-form-field>
                    {
                           CheckContact3 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Address 1'
                        name='Address1'
                        floatLabelType='Auto'
                        value={address1}
                        onChange={(e)=>{
                          
                          setCheckDuplicate(true)
                          setAddress1(e.value)}}
                      />
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Address 2'
                        name='Address2'
                        floatLabelType='Auto'
                        value={address2}
                        onChange={(e)=>{
                          
                          setCheckDuplicate(true)
                          setAddress2(e.value)}}
                      />
                    </mat-form-field>
                  </div>
                  
                  <div className="col-sm-6">
                  <mat-form-field>
                      { loadingCountry? (
                        <div>... Loading Country </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="Country" 
                            dataSource={country}
                            placeholder="Select Country" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={countryChange}
                            ref={countryRef}
                            change={countryChange}
                            value={countryValue}
                            blur={()=>{
                              
                              if (countryID  == 0  || countryRef.current.value == null || countryRef.current.value == '' || countryID == undefined || countryID == null) {
                               if(countryValue != ''){
                                 let resstr = country.some(e=>e.value ==  countryValue);
                                 
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${countryValue} Value Not Find ` )
                                   document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("Country").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                countryRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Country Id'
                            name='CountryID'
                            floatLabelType='Auto'
                            value={countryID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('Country')}>
                          {AddNewSecurity('Country')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                   <div className="col-sm-6">
                    
                    <mat-form-field>
                      { loadingCity? (
                        <div>... Loading City </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="City" 
                            dataSource={city}
                            placeholder="Select City" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={cityChange}
                            ref={cityRef}
                            change={cityChange}
                            value={cityValue}
                            blur={()=>{
                              
                              if (cityID  == 0  || cityRef.current.value == null || cityRef.current.value == '' || cityID == undefined || cityID == null) {
                               if(cityValue != ''){
                                 let resstr = city.some(e=>e.value ==  cityValue);
                                 
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${cityValue} Value Not Find ` )
                                   document.getElementById("City").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("City").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                cityRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='City Id'
                            name='CityID'
                            floatLabelType='Auto'
                            value={cityID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('City')}>
                          {AddNewSecurity('City')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                  </div>

                  <div className="col-sm-6">

<h3 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>
                  </div>
                </div>
                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>

    </div>
  )
}
