import http from '../../services/http-common';

class SecurityService {
  constructor() {
    this.authenticated = false;
  }

  isAuthenticated() {
    return localStorage.getItem('login') !== null;
  }

  // I want to set this.authenticated to true on successful login
  authenticateUser(data) {
    return http.post('/security/authenticateUser', data);
  }

  changePassword(data) {
    return http.post('/security/changePassword', data);
  }

  verifyEmail(data) {
    return http.post('/security/verifyEmail', data);
  }

  getLoggedInUser() {
    const loggedInUser = JSON.parse(localStorage.getItem('login'));
    if(loggedInUser?.username){
      return loggedInUser.username;
    }
    return 'Unknown';
  }

  getLoggedInUserId() {
    const loggedInUser = JSON.parse(localStorage.getItem('login'));
    return loggedInUser.userId;
  }

  canUserAccess(itemName) {
    if (itemName) {
      const accessList = JSON.parse(localStorage.getItem('userAccessList'));
      
      
      const itemFound = accessList?.filter(item => item.ItemName === itemName && item.CanAccess);
      
      return itemFound?.length > 0;
    }
    else {
      return false;
    }
  }

  userLog = async () => {
    var dat
 await  http.get('security/os').then((res)=>{
      
 dat = res.data
    })
    return dat
  }
  // inputName(itemName) {
  //   if (itemName) {
  //     const accessList = JSON.parse(localStorage.getItem('userAccessList'));
  
  
  //     const itemFound = accessList?.filter(item => item.ItemName === itemName && item.CanAccess);
  
  //     return itemFound.InputName
  //   }
  //   else {
  //     return '';
  //   }
  // }

  getUserAccessList(userId) {
    return http.get(`/security/accessList/${userId}`);
  }

  getSecurityItems() {
    return http.get(`/security/items`);
  }

  getSecurityItemTypes() {
    return http.get(`/security/itemTypes`);
  }

  addSecurityItem(data) {
    return http.post(`/security/items/add`, data);
  }

  changeAccess(data , id) {
    
    return http.post(`/security/changeAccess/${id}`, data);
  }

  emailSend(data) {
    return http.post('/mail/pass', data);
  }
  getEmployeeEmailByUserID(USERID) {
    return http.get(`/security/EmployeeEmailByUserID/${USERID}`);
  }
  getSaleAgentIDByUserID =   (userid) => {
    return http.get(`/security/SaleAgentID/${userid}`);
    
  }
isSaleAgent = async (userID)=>{
  return  http.get(`/security/IsSaleAgentID/${userID}`);

}
}

export default new SecurityService();
