import axios from "axios";
export default axios.create({
    //baseURL: "http://10.10.2.86:5000/api/v1",
    // baseURL: "http://192.168.0.72:5000/api/v1",

    // baseURL: "http://10.10.7.180:5000/api/v1",
    // baseURL: "http://erp.dalalindustries.com:5000/api/v1",

    // baseURL: "http://68.178.160.180/api/v1",
    
    baseURL: "https://myprosource.app/api/v1",
    // baseURL: "http://localhost:5001/api/v1",
    
    headers: {
        "Content-type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        'X-Requested-By': 'MyApp'  // Custom header to identify application requests

    }
})