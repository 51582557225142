import React , {useState , useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SRC from './pro-source.jpeg'
import moment from 'moment';
import SecurityService from '../components/security/security.service';
import { useMemo } from 'react';
import MessageService from '../services/message.service';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import PIService /from '../services/PI.service'
import PIView from '../components/pi-view/pi-view';
import PIService from '../services/PI.service'
import PIMessageButtonList from '../components/messages-button-list/messages-button-list';
import './content.scss';
import DueDateService from '../services/due-date.service'
import { decryptData } from '../components/decryption/decryption';
import {
    GridComponent,
    Inject,
    Toolbar,
    ToolbarItems,
    Edit,
    EditSettingsModel,
    Page,
    Sort,
    PdfExport,
    PdfExportProperties,
    ExcelExport,
    Grid,
    Resize,
    FilterSettingsModel,
    Filter,
    Group,
    GroupSettingsModel,
    AggregateColumnDirective,
    ColumnDirective,
    ColumnsDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    Aggregate,
    Freeze,
    ColumnChooser,
    Reorder,
    DialogEditEventArgs,
    CommandColumn,
    CommandModel,
    rowDeselected,
  } from '@syncfusion/ej2-react-grids';
  

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '93vh',
        height: 'calc(100vh - 53px)'
    },
    image: {
        backgroundImage: `url(${SRC})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        minHeight: '658.547px'
        //opacity: '0.5'
    },
    header:{
        
        textAlign:'center',
        fontSize:'40px !important'

    }
}));

let selectedRows = []

let messageGrid: Grid;
let SalesRecordGrid: Grid;
let messageDataSource = []
export default function Content(props) {
    const userId = SecurityService.getLoggedInUserId();
    const classes = useStyles();    
    const [message, setMessage] = useState([]);
    const [pi, setPi] = useState([]);
    const [piValue, setPIValue] = useState('');
    const [piID, setPiID] = useState(0);
    const [printedByUserName, setPrintedByUserName] = useState('');
    const [printedByUserID, setPrintedByUserID] = useState('');
  
    const [showDialogs, setShowDialogs] = useState();
    const [deletingProps, setDeletingProps] = useState();
  
    const [deletingID, setDeletingID] = useState(0);
    const [showDialogView, setShowDialogView] = useState(false);
    const [showDialogMessage, setShowDialogMessage] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [piprops, setPiprops] = useState([]);
    const [piData, setPiData] = useState([]);
    // const [employeeID, setEmployeeID] = useState(0);
    var employeeID = 0

    
  useEffect(() => {
    getEmployeeByUserID(userId)
    
  }, []);
  useEffect(() => {
    
    console.log(messageGrid)
    console.log(messageGrid.dataSource)
    console.log(messageDataSource)

    return () => {
      console.log('irtizahassan rajput')
      let data = {
        PIMessageToEmployeeID:selectedRows.PIMessageToEmployeeID,
        MessageReadDate:new Date()
      }
      MessageService.messageReadUpdate(messageDataSource).then((res)=>{
        console.log(res)
        setTimeout(() => {
          // getMassage(0 ,'UNREAD')
          // getEmployeeByUserID(userId)
        }, 3000);
        // messageGrid.refresh()
        
      })
    };
  }, []);
  const getEmployeeByUserID = async (id) => {
    PIService.getAllEmployee()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('productFinishSize get response', decryptedData);
        console.log(userId)
        let employeeID = decryptedData.filter((val)=> val.UserID == userId);
        console.log(employeeID[0].EmployeeID)         
        // setEmployeeID() 
        getMassage(0 ,'UNREAD', employeeID[0].EmployeeID);
        
              })
      .catch((e) => {
        console.log(e);
        
      });
  };
  const getMassage = async (id , status = 'UNREAD',employeeID) => {
    MessageService.getMessageDashboardList(0,status,employeeID)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('get all yarn count', response)
        setMessage(decryptedData);
        messageDataSource = decryptedData;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
   
    'Edit',
    {
      text: "Delete",
      tooltipText: "Delete",
      id: "Delete",
      disabled: !SecurityService.canUserAccess('YarnCountDelete'),
    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const databound = () =>
  {
      if (messageGrid) {
        messageGrid.autoFitColumns();
      }
  }

//   const editSettings: EditSettingsModel = {

//     // allowEditing: SecurityService.canUserAccess('YarnCountEdit'),
//     // allowAdding:true,
//     // allowDeleting: SecurityService.canUserAccess('YarnCountDelete'),

//     allowEditing: false,
//     allowAdding: true,
//     allowDeleting: false,
//     mode: 'Dialog',
//     template: dialogTemplate,
//   };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'PIMessageID', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };
  const getProformaInvoice = async (id) => {
    console.log(id)
    PIService.getList()    
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('ProductFinishSize get response', decryptedData);
        let filterData = decryptedData.filter(res =>{ if( res.PIID == id){
          return res
        }})
        console.log(filterData)
        setPiData(decryptedData)
        setPiprops(filterData[0])
        // setPI(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'view-pi',
        content: 'View PI',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    },
    {
    buttonOption: {
      id: 'message',
      content: 'Message',
      cssClass: 'e-flat',
      isPrimary: true,
    }
  },
  
  ];
  const commandClick = (args: CommandClickEventArgs) => {
    if (args.commandColumn.buttonOption.id === 'view-pi') {
      setShowDialogView(false);
      console.log('command click args: ', args.commandColumn.buttonOption.id);
      setSelectedRow(args.rowData);
      console.log(args.rowData)
      getProformaInvoice(args.rowData.PIID)
      setShowDialogView(true);
    }else if (args.commandColumn.buttonOption.id === 'message') {
      setShowDialogMessage(false);
      console.log('command click args: ', args);
      setSelectedRow(args.rowData);
      setShowDialogMessage(true);
    }
  }
  const viewHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'View PI':'VIEW pi'}</div>;
  };
  const getpiid = (e)=>{

    
  }
  const contentViewTemplate = () => {
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
      console.log(piprops)
      console.log(selectedRow.PIID)
    return (
      <div className='dashboard-pi-view'>
        <PIView PiID={selectedRow.PIID} dataList={piData}  userName={printedByUserName} type={"Supervision"} isPreview={true}  isAdd={false}  {...piprops}> </PIView>
        

      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };
  const contentMessageTemplate = () => {
    if(SecurityService.canUserAccess('PIMessageList')){
      console.log(piprops)
    return (
      <div className='dashboard-pi-view'>
      <PIMessageButtonList isDialog={false}  {...selectedRow} />
        

      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };   
  const viewDialogClose = () => {
    this.buttonInstance.style.display = 'inline-block';
    setShowDialogView(false);
  };
  const messageDialogClose = () => {
    getEmployeeByUserID(userId)
    this.buttonInstance.style.display = 'inline-block';
    setShowDialogMessage(false);
  };

    const MessageGridComponent = useMemo(() => () => (
        <GridComponent
        ref={(grid) => (messageGrid = grid)}
        id='messageGrid'
        dataSource={message}
        // editSettings={editSettings}
        // toolbar={toolbarOptions}
        pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 12 }}
        filterSettings={filterOptions}
        groupSettings={groupOptions}
        // actionComplete={actionComplete}
        allowTextWrap='true'
        commandClick={commandClick}
    
        allowPaging={true}
        allowSorting={true}
        allowPdfExport={true}
        allowExcelExport={true}
        // toolbarClick={toolbarClick}
        // allowFiltering={true}
        // allowGrouping={true}
        // showColumnChooser={true}
        // allowReordering={true}
        allowResizing={true}
        sortSettings={sortingOptions}
       dataBound={databound}
    
       rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
         

          
                    setTimeout(() => {
                      console.log(args.data)
                      
          }, 3000);
        }
        }
        rowDeselected={
          (args)=>{
            console.log(args)
          }

        
      }
    
      >
      
        <ColumnsDirective>
        <ColumnDirective
                    headerText='Commands'
                    width='150'
                    commands={commands}
                    textAlign='center'
                    headerTextAlign='center'
                  />
        <ColumnDirective
            headerText='Serial 
            No'
            field='SerialNo'
            width='130'
            headerTextAlign='Center'
            textAlign='Right'
          />
                  
        <ColumnDirective
            headerText='PI No'
            field='PINo'
            width='130'
            headerTextAlign='Center'
            textAlign='Left'
          />
         <ColumnDirective
            headerText='Date'
            field='MessageDate'
            width='200'
            type='date'
           format={'dd/MM/yy'}
                /> 
          <ColumnDirective
            headerText='From Employee'
            field='FromEmployeeName'
            width='100'
            headerTextAlign='Center'
            textAlign='left'
            
          />
          <ColumnDirective
            headerText='To Employee'
            field='ToEmployeeName'
            width='100'
            headerTextAlign='Center'
            textAlign='left'
            
          />
          <ColumnDirective
            headerText='Message '
            field='MessageComment'
            width='100'
            headerTextAlign='Center'
            textAlign='left'
            
          />
        
        </ColumnsDirective>
    
              <Inject
          services={[
            Edit,
            Toolbar,
            Page,
            Sort,
            PdfExport,
            ExcelExport,
            Filter,
            Group,
            Aggregate,
            Freeze,
            ColumnChooser,
            Reorder,
            Resize,
            PdfExport,
            CommandColumn,
          ]}
        />
      </GridComponent>
      ),[message]);


      useEffect(() => {
            PIService.getAllEmployee()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('productFinishSize get response', decryptedData);
        console.log(userId)
        let employee = decryptedData.filter((val)=> val.UserID == userId && val.DesignationID == 3);
        console.log(employee.length)       
        console.log(employee[0].EmployeeID)       
        if(employee.length != 0){

          getSalesRecord(employee[0].EmployeeID)
        }else{

          setSetShow(false)
        }
              })
      .catch((e) => {
        console.log(e);
        setSetShow(false)
        // dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:false})
      });
        
      }, []);
      const [SalesRecordDataSource, setSalesRecordDataSource] = useState([]);
      const [setShow, setSetShow] = useState(false);
      const getSalesRecord = async (salesAgentID) => {
        // '${req.params.FromDate}' , '${req.params.ToDate}' , ${req.params.SalesAgentID} , ${req.params.CustomerID} , ${req.params.SupplierID} , ${req.params.CurrencyID} , ${req.params.SaleStatus}
        setSetShow(true)
        DueDateService.getDueDate(salesAgentID , 0 ,0 )
          .then((response) => {
            const decryptedData = decryptData(response.data); // Decrypt response

            console.log('getSalesRecord get response', decryptedData);
          // setIsCommissionCalculator(true)
            setSalesRecordDataSource(decryptedData);
            // backupCommissionCalculatorDataSource =  decryptedData;
          })
          .catch((e) => {
            console.log(e);
          });
      };
      const formatOptions = { type: 'date', format: 'dd/MM/yy' }

      const SalesRecordGridComponent = useMemo(()=>{ return ({ restrictPageSize = 12 }) => (


        <GridComponent
          ref={(grid) => (SalesRecordGrid = grid)}
          id='SalesRecordGrid'
          dataSource={SalesRecordDataSource}
          // editSettings={editSettings}
          // toolbar={toolbarOptions}
          pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
          filterSettings={filterOptions}
          groupSettings={groupOptions}
        
          // actionComplete={actionComplete}
          allowTextWrap='true'
          allowPaging={true}
          allowSorting={true}
          // allowPdfExport={true}
          // allowExcelExport={true}
          // toolbarClick={toolbarClick}
          allowFiltering={true}
          // allowGrouping={true}
          // showColumnChooser={true}
          // allowReordering={true}
          allowResizing={true}
          // sortSettings={sortingOptions}
          // commandClick={commandClick}
          // dataBound={databound}
          // created={() => {
          //   SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
          // }}
          rowSelected={
            (args)=>{
              console.log(args.data)
              selectedRows = args.data
              
                        setTimeout(() => {
                console.log('ss')
              }, 200);
            }
          }
        >
          <ColumnsDirective>
            {/* <ColumnDirective
              headerText='PI ID'
              field='PIID'
              allowEditing={false}
              width='90'
              headerTextAlign='CENTER'
              textAlign='right'
            /> */}
            {/* SerialNo	
    MonthYearPIDate	
    SalesAgentName	
    PIID	
      
    PIReferenceNo	
    SupplierName	
    CustomerName	
    CurrencyName	
    BookAmount	
    TotalReceiptAmount	
    AdjustmentAmount	
    CRMStatus	 */}
    
            <ColumnDirective
              headerText='S.No'
              field='SerialNo'
              allowEditing={false}
              width='120'
              headerTextAlign='CENTER'
              textAlign='right'
            />
            {/* <ColumnDirective
              headerText='Month Year'
              headerTextAlign='CENTER'    
              field='MonthYearPIDate'
              allowEditing={false}
              width='120'
              textAlign='Right'
            /> */}
            {/* <ColumnDirective
              headerText="Agent"
              headerTextAlign='CENTER'    
              field='SalesAgentName'
              allowEditing={false}
              width='120'
              textAlign='left'
              // format={formatOptions}
              // type='date'
              
            /> */}
            <ColumnDirective
              headerText='Reference No'
              headerTextAlign='CENTER'    
              field='PIReferenceNo'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='PI '
              headerTextAlign='CENTER'    
              field='PINo'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='Customer Name'
              headerTextAlign='CENTER'    
              field='CustomerName'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='Email'
              headerTextAlign='CENTER'    
              field='CustomerEmail'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='Country '
              headerTextAlign='CENTER'    
              field='CountryName'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='ETA '
              headerTextAlign='CENTER'    
              field='ETA'
              allowEditing={false}
              width='155'
              format={formatOptions}
              type='date'
              textAlign='right'
            />
            <ColumnDirective
              headerText='Port'
              headerTextAlign='CENTER'    
              field='PortName'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='Currency '
              headerTextAlign='CENTER'    
              field='CurrencyName'
              allowEditing={false}
              width='155'
              textAlign='Left'
            />
            <ColumnDirective
              headerText='Booking Amount'
              headerTextAlign='CENTER'    
              field='BookAmount'
              allowEditing={false}
              width='150'
              textAlign='right'
              format='n0'
            />
            <ColumnDirective
              headerText='Received'
              headerTextAlign='CENTER'    
              field='TotalReceiptAmount'
              allowEditing={false}
              width='150'
              textAlign='right'
              format='n0'
            />
            <ColumnDirective
              headerText='Balance'
              headerTextAlign='CENTER'    
              field='BalanceAmount'
              allowEditing={false}
              width='150'
              textAlign='right'
              format='n0'
            />
            <ColumnDirective
              headerText='Days Due'
              headerTextAlign='CENTER'    
              field='DueDays'
              allowEditing={false}
              width='150'
              textAlign='left'
              // format='n0'
            />
            <ColumnDirective
              headerText="Agent"
              headerTextAlign='CENTER'    
              field='SalesAgentName'
              allowEditing={false}
              width='120'
              textAlign='left'
              // format={formatOptions}
              // type='date'
              
            />
            
          </ColumnsDirective>
           <AggregatesDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      <AggregateColumnDirective
                        field='BookAmounts'
                        type='Sum'
                        footerTemplate={footerSum}
                        format={'n0'}
                      />
                      <AggregateColumnDirective
                        field='AdjustmentAmounts'
                        type='Sum'
                        format={'n0'}
                        footerTemplate={footerSum}
                      />
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      <AggregateColumnDirective
                        field='BookAmount'
                        type='Sum'
                        groupFooterTemplate={footerSum}
                        format={'n0'}
                      />
                      <AggregateColumnDirective
                        field='AdjustmentAmount'
                        type='Sum'
                        format={'n0'}
                        groupFooterTemplate={footerSum}
                      />
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
          <Inject
            services={[
              Edit,
              Toolbar,
              Page,
              Sort,
              PdfExport,
              ExcelExport,
              Filter,
              Group,
              Aggregate,
              Freeze,
              ColumnChooser,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </GridComponent>
      )},[setShow == true, SalesRecordDataSource]);
    
        return (
            <div>
                 <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogView}
              close={viewDialogClose}
              className='pi-view'
              //open={openReturnDialog}
              content={contentViewTemplate}
              isModal={true}
              showCloseIcon={true}
              header={viewHeader}
              height={800}
              zIndex={8958}
              // minwidth={'950%'}
            />
          </div>
          <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogMessage}
              close={messageDialogClose}
              //open={openImportDialog}
              content={contentMessageTemplate}
              isModal={true}
              showCloseIcon={true}
              header={()=>{
              return (<p></p>)}}
              zIndex={8958}

              height={900}
              width={'80vw'}
            />
          </div>
                {/* <div className={`content-wrapper ${classes.image}`} > */}
                <div className={`content-wrapper`} >
                    {/* Content Header (Page header) */}
                    <div className="content-header">
         <div className="container-fluid">
             <div className="row mb-2">
                 <div className="col-sm-12 dashboard-massage">
                
                     <h3 className={`heading-dashboard-massage`}>UNREAD MESSAGES</h3>
          <MessageGridComponent />
                     
                 </div>{/* /.col */}
                 
             </div>{/* /.row */}
             {setShow == true ?
             <>
                  <h3 className={`heading-dashboard-massage`}>DUE DATE </h3>
              <SalesRecordGridComponent/>
             </>
               : <></>}
         </div>{/* /.container-fluid */}
     </div>
                </div>
            </div>

        )
        }


//         <div className="content-header">
//         <div className="container-fluid">
//             <div className="row mb-2">
//                 <div className="col-sm-6">
//                     <h1 className="m-0">Dashboard</h1>
//                 </div>{/* /.col */}
//                 <div className="col-sm-6">
//                     <ol className="breadcrumb float-sm-right">
//                         <li className="breadcrumb-item"><a href="#">Home</a></li>
//                         <li className="breadcrumb-item active">Dashboard v1</li>
//                     </ol>
//                 </div>{/* /.col */}
//             </div>{/* /.row */}
//         </div>{/* /.container-fluid */}
//     </div>
//     {/* /.content-header */}
//     {/* Main content */}
//     <section className="content">
//         <div className="container-fluid">
//             {/* Small boxes (Stat box) */}
//             <div className="row">
//                 <div className="col-lg-3 col-6">
//                     {/* small box */}
//                     <div className="small-box bg-info">
//                         <div className="inner">
//                             <h3>150</h3>
//                             <p>New Orders</p>
//                         </div>
//                         <div className="icon">
//                             <i className="ion ion-bag" />
//                         </div>
//                         <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
//                     </div>
//                 </div>
//                 {/* ./col */}
//                 <div className="col-lg-3 col-6">
//                     {/* small box */}
//                     <div className="small-box bg-success">
//                         <div className="inner">
//                             <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3>
//                             <p>Bounce Rate</p>
//                         </div>
//                         <div className="icon">
//                             <i className="ion ion-stats-bars" />
//                         </div>
//                         <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
//                     </div>
//                 </div>
//                 {/* ./col */}
//                 <div className="col-lg-3 col-6">
//                     {/* small box */}
//                     <div className="small-box bg-warning">
//                         <div className="inner">
//                             <h3>44</h3>
//                             <p>User Registrations</p>
//                         </div>
//                         <div className="icon">
//                             <i className="ion ion-person-add" />
//                         </div>
//                         <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
//                     </div>
//                 </div>
//                 {/* ./col */}
//                 <div className="col-lg-3 col-6">
//                     {/* small box */}
//                     <div className="small-box bg-danger">
//                         <div className="inner">
//                             <h3>65</h3>
//                             <p>Unique Visitors</p>
//                         </div>
//                         <div className="icon">
//                             <i className="ion ion-pie-graph" />
//                         </div>
//                         <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
//                     </div>
//                 </div>
//                 {/* ./col */}
//             </div>
//             {/* /.row */}
//             {/* Main row */}
//             <div className="row">
//                 {/* Left col */}
//                 <section className="col-lg-7 connectedSortable">
//                     {/* Custom tabs (Charts with tabs)*/}
//                     <div className="card">
//                         <div className="card-header">
//                             <h3 className="card-title">
//                                 <i className="fas fa-chart-pie mr-1" />
//                                 Sales
// </h3>
//                             <div className="card-tools">
//                                 <ul className="nav nav-pills ml-auto">
//                                     <li className="nav-item">
//                                         <a className="nav-link active" href="#revenue-chart" data-toggle="tab">Area</a>
//                                     </li>
//                                     <li className="nav-item">
//                                         <a className="nav-link" href="#sales-chart" data-toggle="tab">Donut</a>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>{/* /.card-header */}
//                         <div className="card-body">
//                             <div className="tab-content p-0">
//                                 {/* Morris chart - Sales */}
//                                 <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 300 }}>
//                                     <canvas id="revenue-chart-canvas" height={300} style={{ height: 300 }} />
//                                 </div>
//                                 <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}>
//                                     <canvas id="sales-chart-canvas" height={300} style={{ height: 300 }} />
//                                 </div>
//                             </div>
//                         </div>{/* /.card-body */}
//                     </div>
//                     {/* /.card */}
//                     {/* DIRECT CHAT */}
//                     <div className="card direct-chat direct-chat-primary">
//                         <div className="card-header">
//                             <h3 className="card-title">Direct Chat</h3>
//                             <div className="card-tools">
//                                 <span title="3 New Messages" className="badge badge-primary">3</span>
//                                 <button type="button" className="btn btn-tool" data-card-widget="collapse">
//                                     <i className="fas fa-minus" />
//                                 </button>
//                                 <button type="button" className="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
//                                     <i className="fas fa-comments" />
//                                 </button>
//                                 <button type="button" className="btn btn-tool" data-card-widget="remove">
//                                     <i className="fas fa-times" />
//                                 </button>
//                             </div>
//                         </div>
//                         {/* /.card-header */}
//                         <div className="card-body">
//                             {/* Conversations are loaded here */}
//                             <div className="direct-chat-messages">
//                                 {/* Message. Default to the left */}
//                                 <div className="direct-chat-msg">
//                                     <div className="direct-chat-infos clearfix">
//                                         <span className="direct-chat-name float-left">Alexander Pierce</span>
//                                         <span className="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
//                                     </div>
//                                     {/* /.direct-chat-infos */}
//                                     <img className="direct-chat-img" src="dist/img/user1-128x128.jpg" alt="message user image" />
//                                     {/* /.direct-chat-img */}
//                                     <div className="direct-chat-text">
//                                         Is this template really for free? That's unbelievable!
//     </div>
//                                     {/* /.direct-chat-text */}
//                                 </div>
//                                 {/* /.direct-chat-msg */}
//                                 {/* Message to the right */}
//                                 <div className="direct-chat-msg right">
//                                     <div className="direct-chat-infos clearfix">
//                                         <span className="direct-chat-name float-right">Sarah Bullock</span>
//                                         <span className="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
//                                     </div>
//                                     {/* /.direct-chat-infos */}
//                                     <img className="direct-chat-img" src="dist/img/user3-128x128.jpg" alt="message user image" />
//                                     {/* /.direct-chat-img */}
//                                     <div className="direct-chat-text">
//                                         You better believe it!
//     </div>
//                                     {/* /.direct-chat-text */}
//                                 </div>
//                                 {/* /.direct-chat-msg */}
//                                 {/* Message. Default to the left */}
//                                 <div className="direct-chat-msg">
//                                     <div className="direct-chat-infos clearfix">
//                                         <span className="direct-chat-name float-left">Alexander Pierce</span>
//                                         <span className="direct-chat-timestamp float-right">23 Jan 5:37 pm</span>
//                                     </div>
//                                     {/* /.direct-chat-infos */}
//                                     <img className="direct-chat-img" src="dist/img/user1-128x128.jpg" alt="message user image" />
//                                     {/* /.direct-chat-img */}
//                                     <div className="direct-chat-text">
//                                         Working with AdminLTE on a great new app! Wanna join?
//     </div>
//                                     {/* /.direct-chat-text */}
//                                 </div>
//                                 {/* /.direct-chat-msg */}
//                                 {/* Message to the right */}
//                                 <div className="direct-chat-msg right">
//                                     <div className="direct-chat-infos clearfix">
//                                         <span className="direct-chat-name float-right">Sarah Bullock</span>
//                                         <span className="direct-chat-timestamp float-left">23 Jan 6:10 pm</span>
//                                     </div>
//                                     {/* /.direct-chat-infos */}
//                                     <img className="direct-chat-img" src="dist/img/user3-128x128.jpg" alt="message user image" />
//                                     {/* /.direct-chat-img */}
//                                     <div className="direct-chat-text">
//                                         I would love to.
//     </div>
//                                     {/* /.direct-chat-text */}
//                                 </div>
//                                 {/* /.direct-chat-msg */}
//                             </div>
//                             {/*/.direct-chat-messages*/}
//                             {/* Contacts are loaded here */}
//                             <div className="direct-chat-contacts">
//                                 <ul className="contacts-list">
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user1-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     Count Dracula
//             <small className="contacts-list-date float-right">2/28/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">How have you been? I was...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user7-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     Sarah Doe
//             <small className="contacts-list-date float-right">2/23/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">I will be waiting for...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user3-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     Nadia Jolie
//             <small className="contacts-list-date float-right">2/20/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">I'll call you back at...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user5-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     Nora S. Vans
//             <small className="contacts-list-date float-right">2/10/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">Where is your new...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user6-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     John K.
//             <small className="contacts-list-date float-right">1/27/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">Can I take a look at...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                     <li>
//                                         <a href="#">
//                                             <img className="contacts-list-img" src="dist/img/user8-128x128.jpg" alt="User Avatar" />
//                                             <div className="contacts-list-info">
//                                                 <span className="contacts-list-name">
//                                                     Kenneth M.
//             <small className="contacts-list-date float-right">1/4/2015</small>
//                                                 </span>
//                                                 <span className="contacts-list-msg">Never mind I found...</span>
//                                             </div>
//                                             {/* /.contacts-list-info */}
//                                         </a>
//                                     </li>
//                                     {/* End Contact Item */}
//                                 </ul>
//                                 {/* /.contacts-list */}
//                             </div>
//                             {/* /.direct-chat-pane */}
//                         </div>
//                         {/* /.card-body */}
//                         <div className="card-footer">
//                             <form action="#" method="post">
//                                 <div className="input-group">
//                                     <input type="text" name="message" placeholder="Type Message ..." className="form-control" />
//                                     <span className="input-group-append">
//                                         <button type="button" className="btn btn-primary">Send</button>
//                                     </span>
//                                 </div>
//                             </form>
//                         </div>
//                         {/* /.card-footer*/}
//                     </div>
//                     {/*/.direct-chat */}
//                     {/* TO DO List */}
//                     <div className="card">
//                         <div className="card-header">
//                             <h3 className="card-title">
//                                 <i className="ion ion-clipboard mr-1" />
//                                 To Do List
// </h3>
//                             <div className="card-tools">
//                                 <ul className="pagination pagination-sm">
//                                     <li className="page-item"><a href="#" className="page-link">«</a></li>
//                                     <li className="page-item"><a href="#" className="page-link">1</a></li>
//                                     <li className="page-item"><a href="#" className="page-link">2</a></li>
//                                     <li className="page-item"><a href="#" className="page-link">3</a></li>
//                                     <li className="page-item"><a href="#" className="page-link">»</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                         {/* /.card-header */}
//                         <div className="card-body">
//                             <ul className="todo-list" data-widget="todo-list">
//                                 <li>
//                                     {/* drag handle */}
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     {/* checkbox */}
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo1" id="todoCheck1" />
//                                         <label htmlFor="todoCheck1" />
//                                     </div>
//                                     {/* todo text */}
//                                     <span className="text">Design a nice theme</span>
//                                     {/* Emphasis label */}
//                                     <small className="badge badge-danger"><i className="far fa-clock" /> 2 mins</small>
//                                     {/* General tools such as edit or delete*/}
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo2" id="todoCheck2" defaultChecked />
//                                         <label htmlFor="todoCheck2" />
//                                     </div>
//                                     <span className="text">Make the theme responsive</span>
//                                     <small className="badge badge-info"><i className="far fa-clock" /> 4 hours</small>
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo3" id="todoCheck3" />
//                                         <label htmlFor="todoCheck3" />
//                                     </div>
//                                     <span className="text">Let theme shine like a star</span>
//                                     <small className="badge badge-warning"><i className="far fa-clock" /> 1 day</small>
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo4" id="todoCheck4" />
//                                         <label htmlFor="todoCheck4" />
//                                     </div>
//                                     <span className="text">Let theme shine like a star</span>
//                                     <small className="badge badge-success"><i className="far fa-clock" /> 3 days</small>
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo5" id="todoCheck5" />
//                                         <label htmlFor="todoCheck5" />
//                                     </div>
//                                     <span className="text">Check your messages and notifications</span>
//                                     <small className="badge badge-primary"><i className="far fa-clock" /> 1 week</small>
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <span className="handle">
//                                         <i className="fas fa-ellipsis-v" />
//                                         <i className="fas fa-ellipsis-v" />
//                                     </span>
//                                     <div className="icheck-primary d-inline ml-2">
//                                         <input type="checkbox" defaultValue name="todo6" id="todoCheck6" />
//                                         <label htmlFor="todoCheck6" />
//                                     </div>
//                                     <span className="text">Let theme shine like a star</span>
//                                     <small className="badge badge-secondary"><i className="far fa-clock" /> 1
//       month</small>
//                                     <div className="tools">
//                                         <i className="fas fa-edit" />
//                                         <i className="fas fa-trash-o" />
//                                     </div>
//                                 </li>
//                             </ul>
//                         </div>
//                         {/* /.card-body */}
//                         <div className="card-footer clearfix">
//                             <button type="button" className="btn btn-info float-right"><i className="fas fa-plus" /> Add
//   item</button>
//                         </div>
//                     </div>
//                     {/* /.card */}
//                 </section>
//                 {/* /.Left col */}
//                 {/* right col (We are only adding the ID to make the widgets sortable)*/}
//                 <section className="col-lg-5 connectedSortable">
//                     {/* Map card */}
//                     <div className="card bg-gradient-primary">
//                         <div className="card-header border-0">
//                             <h3 className="card-title">
//                                 <i className="fas fa-map-marker-alt mr-1" />
//                                 Visitors
// </h3>
//                             {/* card tools */}
//                             <div className="card-tools">
//                                 <button type="button" className="btn btn-primary btn-sm daterange" title="Date range">
//                                     <i className="far fa-calendar-alt" />
//                                 </button>
//                                 <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse" title="Collapse">
//                                     <i className="fas fa-minus" />
//                                 </button>
//                             </div>
//                             {/* /.card-tools */}
//                         </div>
//                         <div className="card-body">
//                             <div id="world-map" style={{ height: 250, width: '100%' }} />
//                         </div>
//                         {/* /.card-body*/}
//                         <div className="card-footer bg-transparent">
//                             <div className="row">
//                                 <div className="col-4 text-center">
//                                     <div id="sparkline-1" />
//                                     <div className="text-white">Visitors</div>
//                                 </div>
//                                 {/* ./col */}
//                                 <div className="col-4 text-center">
//                                     <div id="sparkline-2" />
//                                     <div className="text-white">Online</div>
//                                 </div>
//                                 {/* ./col */}
//                                 <div className="col-4 text-center">
//                                     <div id="sparkline-3" />
//                                     <div className="text-white">Sales</div>
//                                 </div>
//                                 {/* ./col */}
//                             </div>
//                             {/* /.row */}
//                         </div>
//                     </div>
//                     {/* /.card */}
//                     {/* solid sales graph */}
//                     <div className="card bg-gradient-info">
//                         <div className="card-header border-0">
//                             <h3 className="card-title">
//                                 <i className="fas fa-th mr-1" />
//                                 Sales Graph
// </h3>
//                             <div className="card-tools">
//                                 <button type="button" className="btn bg-info btn-sm" data-card-widget="collapse">
//                                     <i className="fas fa-minus" />
//                                 </button>
//                                 <button type="button" className="btn bg-info btn-sm" data-card-widget="remove">
//                                     <i className="fas fa-times" />
//                                 </button>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <canvas className="chart" id="line-chart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
//                         </div>
//                         {/* /.card-body */}
//                         <div className="card-footer bg-transparent">
//                             <div className="row">
//                                 <div className="col-4 text-center">
//                                     <input type="text" className="knob" data-readonly="true" defaultValue={20} data-width={60} data-height={60} data-fgcolor="#39CCCC" />
//                                     <div className="text-white">Mail-Orders</div>
//                                 </div>
//                                 {/* ./col */}
//                                 <div className="col-4 text-center">
//                                     <input type="text" className="knob" data-readonly="true" defaultValue={50} data-width={60} data-height={60} data-fgcolor="#39CCCC" />
//                                     <div className="text-white">Online</div>
//                                 </div>
//                                 {/* ./col */}
//                                 <div className="col-4 text-center">
//                                     <input type="text" className="knob" data-readonly="true" defaultValue={30} data-width={60} data-height={60} data-fgcolor="#39CCCC" />
//                                     <div className="text-white">In-Store</div>
//                                 </div>
//                                 {/* ./col */}
//                             </div>
//                             {/* /.row */}
//                         </div>
//                         {/* /.card-footer */}
//                     </div>
//                     {/* /.card */}
//                     {/* Calendar */}
//                     <div className="card bg-gradient-success">
//                         <div className="card-header border-0">
//                             <h3 className="card-title">
//                                 <i className="far fa-calendar-alt" />
//                                 Calendar
// </h3>
//                             {/* tools card */}
//                             <div className="card-tools">
//                                 {/* button with a dropdown */}
//                                 <div className="btn-group">
//                                     <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" data-offset={-52}>
//                                         <i className="fas fa-bars" />
//                                     </button>
//                                     <div className="dropdown-menu" role="menu">
//                                         <a href="#" className="dropdown-item">Add new event</a>
//                                         <a href="#" className="dropdown-item">Clear events</a>
//                                         <div className="dropdown-divider" />
//                                         <a href="#" className="dropdown-item">View calendar</a>
//                                     </div>
//                                 </div>
//                                 <button type="button" className="btn btn-success btn-sm" data-card-widget="collapse">
//                                     <i className="fas fa-minus" />
//                                 </button>
//                                 <button type="button" className="btn btn-success btn-sm" data-card-widget="remove">
//                                     <i className="fas fa-times" />
//                                 </button>
//                             </div>
//                             {/* /. tools */}
//                         </div>
//                         {/* /.card-header */}
//                         <div className="card-body pt-0">
//                             {/*The calendar */}
//                             <div id="calendar" style={{ width: '100%' }} />
//                         </div>
//                         {/* /.card-body */}
//                     </div>
//                     {/* /.card */}
//                 </section>
//                 {/* right col */}
//             </div>
//             {/* /.row (main row) */}
//         </div>{/* /.container-fluid */}
//     </section>

//     {/* /.content */}

