import React, { useEffect, useState } from 'react';
import CountryCityPortService from '../../services/country-city-port.service';
import SecurityService from '../security/security.service';
import './country-city-port.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
  actionBegin,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import CountryCityPortAdd from './country-city-port-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import {decryptData} from '../decryption/decryption'
var selectedRows = []

export default function CountryCityPortList(props) {
  const [countryCityPort, setCountryCityPort] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  let countryCityPortGrid: Grid;

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (
      countryCityPortGrid &&
      args.item.properties.id === 'CountryCityPortGrid_PDFExport'
    ) {
      
      //  countryCityPortGrid.autoFitColumns();
      // countryCityPortGrid.columns.forEach((column) => {
      //   column.autoFit = false;
      // });
      const exportProperties: PdfExportProperties = {
        // pageOrientation: 'Landscape',
        // hierarchyExportMode:true,
        // isFitToWidth : true,
        // isFitToWidth: true,
        pageOrientation: 'Portrait',
        fileName: `${moment().format('YYYYMMDD')}_CountryCityPort_information.pdf`
      };
      
      // const pdfExport = new PdfExport();
      countryCityPortGrid.pdfExport(exportProperties);
    }
    if (
      countryCityPortGrid &&
      args.item.properties.id === 'CountryCityPortGrid_excelexport'
    ) {
      
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_CountryCityPort_information.xlsx`
      };
      countryCityPortGrid.excelExport(exportProperties);
    }
    if (
      countryCityPortGrid &&
      args.item.properties.id === 'CountryCityPortGrid_columnchooser'
    ) {
    }
    if (countryCityPortGrid && args.item.properties.id === 'Delete') {
      
      if (countryCityPortGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`CountryCityPort`,
          ColumnName:`CountryCityPortID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`CountryCityPort`,
          ColumnName:`CountryCityPortID`,
          RowID:countryCityPortGrid.getSelectedRecords()[0]?.CountryCityPortID,
          SelectedRow:true,
          SelectedRowData:countryCityPortGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    getCountryCityPort();
  }, []);

  const getCountryCityPort = async (type: string) => {
    CountryCityPortService.getCountryCityPortForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setCountryCityPort(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('CountryCityPortDelete')    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
   
    
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    
    return props.isAdd ? (
      <CountryCityPortAdd dataList={countryCityPort} {...props}> </CountryCityPortAdd>
    ) :
      (
        <CountryCityPortAdd dataList={countryCityPort} {...props}> </CountryCityPortAdd>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess('CountryCityPortEdit'),
    allowAdding: SecurityService.canUserAccess('CountryCityPortAdd'),
    allowDeleting: SecurityService.canUserAccess('CountryCityPortDelete'),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions= {
    type: 'Excel',
    
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
  const actionBegin = (args)=>{
    
    if(args.action == 'cancel'){
      args.cancel = false;
    }
  }

  const actionComplete = (args: DialogEditEventArgs) => {
    
    if (args.action === 'filtering') {
      const filteredColumn = args.columns.filter((col) => col.isComplex === false);
      const headers = document.querySelectorAll('.e-headercell');
      headers.forEach((header) => {
        header.classList.remove('e-filtered');
      }); 
      filteredColumn.forEach((column) => {
        const header = document.querySelector(`.e-headercell[aria-label="${column.field}"]`);
        if (header) {
          header.classList.add('e-filtered');
        }
      });
    }
    if (args?.requestType === 'columnstate') {
      
      countryCityPortGrid?.autoFitColumns();
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'  && args.action   === 'add') {
        const data = {
          UserID: userId,

          ...args.data,
          PortName:args.data.Port,
          CityName:args.data.City,
          CountryName:args.data.Country
        };
        
        delete countryCityPort[0]

      
      CountryCityPortService.addCountryCityPort(data)
      .then((response) => {
        setCountryCityPort([]);
        getCountryCityPort();
        AuditTableInput.auditAdd(`CountryCityPort`,`CountryCityPort`,`CountryCityPortID`,data)

        // setCountryCityPort(response?.data?.recordset)
      });
  
    }
       
      if (args.requestType === 'save'  && args.action   === 'edit') {
        const data = {
          UserID: userId,
          ...args.data,
          PortName:args.data.Port,
          CityName:args.data.City,
          CountryName:args.data.Country
        };
        
        CountryCityPortService.updateCountryCityPort(args.data.CountryCityPortID,data)
          .then((response) => {
            let totalColumns = Object.keys(args.data);
            
            AuditTableInput.auditEdit(totalColumns,selectedRows,args.data,`CountryCityPort`,args.data.CountryCityPortID)
            getCountryCityPort();
            
          });
      }

    }

  };
  const databound = () =>
  {
      if (countryCityPortGrid) {
        countryCityPortGrid.autoFitColumns();
      }
  }

  // setTimeout(() => {
    
  //   // const elementsWithClass = document.querySelector('.e-contextmenu-wrapper > ul li:nth-last-child(2)');
  //   var elementsWithClass =  document.querySelectorAll('.e-contextmenu-wrapper > ul li:nth-last-child(2)')
    
  //   useEffect(() => {
      
  
  //     if(elementsWithClass){
      
  //     // elementsWithClass.forEach((element) => {
  //       elementsWithClass.addEventListener('click', () => {
  
  //       });
  //     }
  //     // });
  //   }, 250);
  // },[elementsWithClass])
    const CountryCityPortGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (countryCityPortGrid = grid)}
      id='CountryCityPortGrid'
      dataSource={countryCityPort}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      
      
      toolbarClick={toolbarClick}
      allowFiltering={true}
      actionBegin={actionBegin}
      allowGrouping={true}
      // frozenColumns={1}
      showColumnChooser={true}
      
      allowReordering={true}
      allowResizing={true}
      // allowFiltering={true}
      sortSettings={sortingOptions}
      // dataBound={databound}
      rowSelected={
        (args)=>{
          
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='ID'
          field='CountryCityPortID'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
          allowFiltering={true}
        />
        <ColumnDirective
          headerText='Country Name'
          headerTextAlign='CENTER'    
          field='CountryName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='City Name'
          headerTextAlign='CENTER'    
          field='CityName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='Port Name'
          headerTextAlign='CENTER'    
          field='PortName'
          width='150'
          textAlign='Left'
        />


        {/* <ColumnDirective
          headerText='Is Deleted'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='IsDeleted'
          width='150'
        /> */}
        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          
          Resize,
          // CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getCountryCityPort()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <CountryCityPortGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Country City Port - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getCountryCityPort()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <CountryCityPortGridComponent />
            </div>
          </div>
        </div>
      )
  );
}
