import React, { useEffect, useState , useRef } from 'react';
// import AdjustmentCalculatorService from '../../services/product.service';
import SecurityService from '../security/security.service';
import PIService from '../../services/PI.service';
import './adjustment-calculator.scss';
import HardDelete from '../hard-delete/hard-delete';
// import CheckDuplicate  from '../check-duplicate/check-duplicate';
// import AuditTableInput from '../audit-table/audit-table';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import {decryptData} from '../decryption/decryption';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperfties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import TransferUnAllocatedPaymentAdd from './Transfer-unAllocated-payment-add';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
// import {image} from './image';
// import logoUrl from './logo192.png';
import AdjustmentCalculatorService from '../../services/adjustment-calculator.service';
import EmployeeService from '../../services/employee.service';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';
// import { dataBind } from 'jodit/types/core/helpers';
var selectedRows = []
export default function AdjustmentCalculatorList(props) {
  
    // var img =  imagess;
 var backupCommissionCalculatorDataSource = []
  const [CommissionCalculatorDataSource, setCommissionCalculatorDataSource] = useState([]);
  const [isCommissionCalculator, setIsCommissionCalculator] = useState(false);
  const [salesAgent, setSalesAgent] = useState('');
  const [loadingSalesAgent, setLoadingSalesAgent] = useState('');
  const [salesAgentID, setSalesAgentID] = useState(0);
  const [salesAgentValue, setSalesAgentValue] = useState('');
  const [customerDataSource, setCustomerDataSource] = useState([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customerID, setCustomerID] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [showDropDown, setShowDropDown] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [deletingProps, setDeletingProps] = useState();
  // const [showDialog, setShowDialog] = useState(false);
  // const [showDialogs, setShowDialogs] = useState(false);
  // const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [proccessedVal, setProccessedVal] = useState('');
  // const [showDialogEmail, setShowDialogEmail] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  let CommissionCalculatorGrid: Grid;
  const ddlFields: object = { value: 'value' };

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (args?.item?.properties?.id === 'Cancel') {
      
      // to reload the grid
      // setUserSecurityItems([]);
      // getUserSecurityItems(userID);

      // CommissionCalculatorGrid?.refresh();
      // CommissionCalculatorGrid?.refreshDataSource();
      CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      if(isCommissionCalculator == true){
        getCommissionCalculator();
      }else{
        getUnProcessedCommissionCalculator();
      }
    }
    if (args?.item?.properties?.id === 'Update') {
      
      
      AdjustmentCalculatorService.update(CommissionCalculatorGrid.getCurrentViewRecords()).then((res)=>{
        
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
        if(isCommissionCalculator == true){
          getCommissionCalculator();
        }else{
          getUnProcessedCommissionCalculator();
        }
      }).catch((e)=>{
        
        // CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      })
      CommissionCalculatorGrid?.editModule?.batchSave();
      
      // CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
    if (
      CommissionCalculatorGrid &&
      args.item.properties.id === 'CommissionCalculatorGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.pdf`
      // };
      
      // CommissionCalculatorGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_commission-calculator.pdf`
    };
    
    
      
       CommissionCalculatorGrid.pdfExport(exportProperties);

    }
    if (
      CommissionCalculatorGrid &&
      args.item.properties.id === 'CommissionCalculatorGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_commission-calculator.xlsx`
    };
    CommissionCalculatorGrid.excelExport(exportProperties);
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // // const customizeHeader = ({ element }) => {
      // //   // Insert the logo in the header
      // //   const logoImage = new Image();
      // //   logoImage.src = image;
      // //   logoImage.width = 100; // Adjust the width as per your requirement
      // //   logoImage.height = 50; // Adjust the height as per your requirement
    
      // //   // Add the logo to the header cell
      // //   const headerCell = element.sheet.rows[0].cells[0];
      // //   headerCell.appendChild(logoImage);
    
      // //   // Merge cells for the logo
      // //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // // };
      
      // CommissionCalculatorGrid.excelExport(exportProperties);
      // // CommissionCalculatorGrid.beforeDataBound(customizeHeader)
      
    }
    if (
      CommissionCalculatorGrid &&
      args.item.properties.id === 'CommissionCalculatorGrid_columnchooser'
    ) {
    }
    if (CommissionCalculatorGrid && args.item.properties.id === 'Delete') {
      
      if (CommissionCalculatorGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        // setDeletingProps(obj)
        // setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`TransferUnAllocatedPayment`,
          ColumnName:`TransferID`,
          RowID:CommissionCalculatorGrid.getSelectedRecords()[0]?.TransferID,
          SelectedRow:true,
          SelectedRowData:{
            ...CommissionCalculatorGrid.getSelectedRecords()[0]
          }
          
          }
        //   setDeletingProps(obj)
        
        // // deleteDialog.current.show();
        // setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    // getCommissionCalculator();
    SecurityService.getUserAccessList(userId).then((res)=>{
      
      const decryptedData = decryptData(res.data); // Decrypt response
                
      
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByEmail(decryptedData[0].Email)
     });
     getCustomer()
     getSalesAgent()
  }, []);

  const getCommissionCalculator = async () => {
    setProccessedVal('- PROCESSED')

    // :customerid/:salesAgentID
    
    
    AdjustmentCalculatorService.getList(customerID, salesAgentID, "Processed")
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
      setIsCommissionCalculator(true)
        setCommissionCalculatorDataSource(decryptedData);
        backupCommissionCalculatorDataSource =  decryptedData;
      })
      .catch((e) => {
        
      });
  };

  const getUnProcessedCommissionCalculator = async () => {
    // :customerid/:salesAgentID
    setProccessedVal('- UNPROCESSED')
    
    
    AdjustmentCalculatorService.getList(customerID, salesAgentID, 'UnProcessed')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        setIsCommissionCalculator(false)
        setCommissionCalculatorDataSource(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  const getSalesAgent = async () => {
    setLoadingSalesAgent(true);
    EmployeeService.getEmployeeForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSalesAgent(mapData);
        setLoadingSalesAgent(false);
      })
      .catch((e) => {
        
      });
  };

  const getCustomer = async (type: string) => {
    PIService.getCustomerForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        

        setCustomerDataSource(decryptedData)
        setLoadingCustomer(false)
        // dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:decryptedData,LoadingCustomer:false})
        
      })
      .catch((e) => {
        
        setCustomerDataSource([])
        setLoadingCustomer(true)
        // dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    // 'Add',
    // 'Edit',
    // {
    //   // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
    //   text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false},
    // 'Update',
    // 'Cancel',
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
     {
      text: "Cancel", tooltipText: "Cancel", id: "Cancel", disabled: true
    },
    {
      text: "Update", tooltipText: "Update", id: "Update", disabled: true
    }
  ];

  const dialogTemplate = (props) => {
    
    return props.isAdd ? (
      // <div dataList={CommissionCalculatorDataSource} {...props}> </div>
      // <TransferUnAllocatedPaymentAdd dataList={CommissionCalculatorDataSource} {...props}> </TransferUnAllocatedPaymentAdd>
      <></>
      
    ) :
      (
        // <PIAdd dataList={productFinishSize} {...props}> </PIAdd>
        <></>
      );
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess('ProductFinishSizeEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductFinishSizeAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductFinishSizeDelete'),
          // allowEditing: false,
          // allowAdding: true,
          // allowDeleting: true,
          // mode: 'Dialog',
          // showDeleteConfirmDialog: true,
          // template: dialogTemplate,
    allowEditing: SecurityService.canUserAccess('AdjustmentCommissionCalculatorEdit'),
    mode: 'Batch',
    showConfirmDialog: false      
    // headerTemplate: '<p></p>',
    // footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  
  
  const actionComplete = (args: DialogEditEventArgs) => {
    
    
    if (args?.requestType === 'columnstate') {
      
      CommissionCalculatorGrid?.autoFitColumns();
    }
    if(args.requestType == "batchsave"){
      
    
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      // if (args.requestType === 'save'){
      
      //   const data = {
      //     CreatedByUserID : userId,
      //     ...args.data,
      //     TransferDate: moment(args.data.TransferDate).format('YYYY-MM-DD')
      //   }
      //   AdjustmentCalculatorService.addTransferUnAllocatedPayment(data)
      //     .then((response) => {
      //   getCommissionCalculator()
      //     })
      // }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     // delete productFinishSize[0]

      
    //   AdjustmentCalculatorService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setCommissionCalculatorDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     AdjustmentCalculatorService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getCommissionCalculator();
    
    //   }

    }

  };
  const databound = () =>
  {
      if (CommissionCalculatorGrid) {
        CommissionCalculatorGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);

//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };
let refreshColumn =(columnName) =>{
  if (CommissionCalculatorGrid) {
    CommissionCalculatorGrid.columns.forEach(column => {
      if (column.field === columnName) {
        CommissionCalculatorGrid.refreshColumns(column);
      }
    });
  }
}
const handleCheckboxChange = (e,data) => {
   
   

   
  AdjustmentCalculatorService.updateApproveCommissionAmountPerUnit(data.PIID,userId).then((res)=>{

    let dataFin = CommissionCalculatorGrid.map((Res)=>{
      if(data.PIID == Res.PIID){
        
        if(Res.PaymentVerificationStatus == true){
          Res.PaymentVerificationStatus = false;
          Res.CRMID = 0;
          Res.CRMName = null
          Res.PaymentVerificationStatusDate = null
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
 
        }else{
          Res.PaymentVerificationStatus = true;
          Res.CRMID = userId
          Res.CRMName = printedByUserName
          Res.PaymentVerificationStatusDate = new Date()
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
        }
      }
    })
    

  })
};

let RemarksUseRef =  useRef(null)
let FinalCommissionAmountPerUnitApprovedUseRef =  useRef(null)
  const CommissionCalculatorGridComponent = ({ restrictPageSize = 12 }) => (


    <GridComponent
      ref={(grid) => (CommissionCalculatorGrid = grid)}
      id='CommissionCalculatorGrid'
      dataSource={CommissionCalculatorDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
    
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      created={() => {
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
      }}
      rowSelected={
        (args)=>{
          
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='PI ID'
          field='PIID'
          allowEditing={false}
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='PI No'
          field='PINo'
          allowEditing={false}
          width='120'
          headerTextAlign='CENTER'
          textAlign='left'
        />
        <ColumnDirective
          headerText='PI 
          Month '
          headerTextAlign='CENTER'    
          field='MonthYearPIDate'
          allowEditing={false}
          width='120'
          textAlign='Right'
        />
        <ColumnDirective
          headerText="PI 
          Date"
          headerTextAlign='CENTER'    
          field='PIDate'
          allowEditing={false}
          width='120'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />
        <ColumnDirective
          headerText='PI Reference No '
          headerTextAlign='CENTER'    
          field='PIReferenceNo'
          allowEditing={false}
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Sales Agent'
          headerTextAlign='CENTER'    
          field='SalesAgent'
          allowEditing={false}
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Customer Name'
          headerTextAlign='CENTER'    
          field='CustomerName'
          allowEditing={false}
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Supplier Name'
          headerTextAlign='CENTER'    
          field='SupplierName'
          allowEditing={false}
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Purchase 
          Source'
          headerTextAlign='CENTER'    
          field='PurchaseSource'
          allowEditing={false}
          width='150'
          textAlign='left'
          format='n0'
        />
        <ColumnDirective
          headerText='Currency Name'
          headerTextAlign='CENTER'    
          field='CurrencyName'
          allowEditing={false}
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Adj. 
          Amount'
          headerTextAlign='CENTER'    
          field='AdjustmentAmount'
          allowEditing={false}
          width='130'
          textAlign='right'
          format='n0'
        />
        	
{/* ApprovePrimaryCommissionPercentOnAdjustment	
SecondaryCommissionPercentOnAdjustment	
ApproveSecondaryCommissionPercentOnAdjustment
FinalCommissionAmountApproved */}
        <ColumnDirective
          headerText='Primary Comm % On Adjustment'
          headerTextAlign='CENTER'    
          field='PrimaryCommissionPercentOnAdjustment'
          allowEditing={false}
          width='165'
          textAlign='right'
          format='n0'

        />
        {/* <ColumnDirective
          headerText='Approve 
          Commission
           Amount 
           Per Unit'
          headerTextAlign='CENTER'    
          field='ApprovePrimaryCommissionPercentOnAdjustment'
          allowEditing={false}
          width='165'
        
          textAlign='Left'
        /> */}
<ColumnDirective 
field='ApprovePrimaryCommissionPercentOnAdjustment'
// allowEditing={false}
 headerText='Approve
 Primary Comm % On Adjustment'
 width='165'
 
 template={(data) => (
  <div className='Container-ApprovePrimaryCommissionPercentOnAdjustment'>
   <CheckBoxComponent 
   id={`ApprovePrimaryCommissionPercentOnAdjustment-${data.index}`}
    checked={  data.ApprovePrimaryCommissionPercentOnAdjustment} 
    //  disabled={data.ApproveAbsoluteCommissionAmount}
    // ={true}
    
    change={(args) => {
      //CommissionCalculatorGrid.editModule.updateCell(data.index, 'ApprovePrimaryCommissionPercentOnAdjustment', args.checked);
      // CommissionCalculatorGrid.editModule.saveCell();
      
      // let currentDataBackup = CommissionCalculatorGrid?.getCurrentViewRecords();
      
      // if(currentDataBackup[data.index].ApproveAbsoluteCommissionAmount == false){
        const currentData = CommissionCalculatorGrid?.getCurrentViewRecords();
      if (currentData) {
        

        currentData[data.index].ApprovePrimaryCommissionPercentOnAdjustment = args.checked;
        // currentData[data.index].ApproveAbsoluteCommissionAmount = args.checked;
        currentData[data.index].FinalCommissionAmountApproved = args.checked ==  true 
        ?
        data.AdjustmentAmount  * data.PrimaryCommissionPercentOnAdjustment / 100

         
         :
          0;
        
        document.getElementById(`FinalCommissionAmountApproved-${data.index}`).value =  
        args.checked ==  true 
        ? 
        data.AdjustmentAmount  * data.PrimaryCommissionPercentOnAdjustment / 100
        :
         0;
        
        // document.getElementById(`ApproveAbsoluteCommissionAmount-${data.index}`).check = args.checked;
        if(args.checked == true){
          
          document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top = 0
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  true
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  false
          
        }else{
          document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  ''
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  true
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  false

        }
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
        // CommissionCalculatorGrid?.editModule?.batchSave();
      }
    // }else{
    //  alert('ONLY ONE COMMISSION CAN BE APPROVED')
    //  let currentDataBackup = CommissionCalculatorGrid?.getCurrentViewRecords();
    //   currentDataBackup[data.index].ApprovePrimaryCommissionPercentOnAdjustment =  false;
    
      
    // }
    }}
    created={(e)=>{
      
      
      if(data.ApprovePrimaryCommissionPercentOnAdjustment == true){
        if( document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`)) {
          
          document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  0
        }
      }else{
        if( document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`)) {
        document.getElementById(`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  ''
        }

       

      }
    }}
     />
    <div 
    id={`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`}
    className={`ApprovePrimaryCommissionPercentOnAdjustmentWrap`}
    ></div>
    </div>
  )}
  allowFiltering={false}
  allowSorting={false}
  allowEditing={false}
  // headerTemplate={headerTemplate}
/> 
{/* 
<ColumnDirective 
field='ApprovePrimaryCommissionPercentOnAdjustment'
allowEditing={false}
 headerText='Approve
 (Per Unit)'
  width='150' 
  textAlign='center'
    template={(props) => (
      <label className="checkbox-container">
      <input
       type='checkbox'

        className={`checkbox ${props.ApprovePrimaryCommissionPercentOnAdjustment ? 'yellow': ''}`}
        id={`checkbox_${props.PIID}`}
         checked={props.ApprovePrimaryCommissionPercentOnAdjustment}
         
          onChange={(e) =>{
            if(props.ApproveAbsoluteCommissionAmount == false){
            
            
            let data={
                FinalCommissionAmountApproved:props.ApproveAbsoluteCommissionAmount ==  false ? props.CommissionAmountPerUnit : 0,
                PIID:props.PIID,
                ApprovePrimaryCommissionPercentOnAdjustment : props.ApprovePrimaryCommissionPercentOnAdjustment ==  false ? '1' : '0'
            }
            AdjustmentCalculatorService.updateApproveCommissionAmountPerUnit(data).then((res)=>{
              CommissionCalculatorDataSource.map((Res)=>{
                  if(props.PIID == Res.PIID){
                    
                    Res.ApprovePrimaryCommissionPercentOnAdjustment  =  props.ApprovePrimaryCommissionPercentOnAdjustment ==  false ? true : false
                    Res.FinalCommissionAmountApproved = props.ApprovePrimaryCommissionPercentOnAdjustment ==  false ? props.CommissionAmountPerUnit : 0;
                      FinalCommissionAmountPerUnitApprovedUseRef.current = props.ApprovePrimaryCommissionPercentOnAdjustment ==  false ? props.CommissionAmountPerUnit : 0;               
                    refreshColumn('FinalCommissionAmountApproved')
                      refreshColumn('ApprovePrimaryCommissionPercentOnAdjustment')
                      // refreshColumn('CRMName')
                    
                  }
                })
              })
            }else{
              window.alert('ONLY ONE COMMISSION CAN BE APPROVED')

            }
                  
                  }} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            /> */}
        <ColumnDirective
          headerText='Secondary Comm % On Adjustment'
          headerTextAlign='CENTER'    
          field='SecondaryCommissionPercentOnAdjustment'
          allowEditing={false}
          width='165'
          textAlign='right'
          format='n0'

        />
        {/* <ColumnDirective
          headerText='Approve 
          Absolute 
          Commission 
          Amount'
          headerTextAlign='CENTER'    
          field='ApproveAbsoluteCommissionAmount'
          allowEditing={false}
          width='165'
        
          textAlign='Left'
        /> */}
        <ColumnDirective 
        field='ApproveSecondaryCommissionPercentOnAdjustment'
        // allowEditing={false}
         headerText='Approve 
         Secondary Comm % On Adjustment'
          width='165' 
          // type='checkbox'
      template={(data) => (
      <div className='Container-ApprovePrimaryCommissionPercentOnAdjustment'>

    <CheckBoxComponent 
    
    checked={  data.ApproveSecondaryCommissionPercentOnAdjustment} 
   
    id={`ApproveSecondaryCommissionPercentOnAdjustment-${data.index}`}
    change={(args) => {
      //CommissionCalculatorGrid.editModule.updateCell(data.index, 'ApproveSecondaryCommissionPercentOnAdjustment', args.checked);
      // CommissionCalculatorGrid.editModule.saveCell();
      const currentData = CommissionCalculatorGrid?.getCurrentViewRecords();
      if (currentData) {
        
        
        currentData[data.index].ApproveSecondaryCommissionPercentOnAdjustment = args.checked;
        // currentData[data.index].ApprovePrimaryCommissionPercentOnAdjustment = args.checked;
        currentData[data.index].FinalCommissionAmountApproved = args.checked ==  true 
        ? 
        data.AdjustmentAmount  * data.SecondaryCommissionPercentOnAdjustment / 100

        : 
        0;
        
        // document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustment-${data.index}`).check
        //  = !args.checked;

        
        document.getElementById(`FinalCommissionAmountApproved-${data.index}`).value =  args.checked ==  true ? 
        data.AdjustmentAmount  * data.SecondaryCommissionPercentOnAdjustment / 100
         : 0;
        if(args.checked == true){

          document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  0
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  true
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  false
        }else{
          document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top = ''
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  true
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  false

        }
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
        if(args.checked ==  false){
          
          // document.getElementById('ApprovePrimaryCommissionPercentOnAdjustment').disabled =  false
        }
        // CommissionCalculatorGrid?.editModule?.batchSave();
      }
    }} 
    created={(e)=>{
      
      
      if(data.ApproveSecondaryCommissionPercentOnAdjustment == true){
        setTimeout(() => {
          if( document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`)) {

          document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  0
          }
        }, 200);

        // document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  true
        // document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  false
      }else{
        setTimeout(() => {
         if( document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`)) {

           document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  ''
          }
        }, 200);
        // document.getElementById(`ApprovePrimaryCommissionPercentOnAdjustmentWrap-${data.index}`).style.top =  1000
        // document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  false
        // document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  true

      }
    }}
    />
    <div 
    
    className={`ApproveSecondaryCommissionPercentOnAdjustmentWrap`}
    id={`ApproveSecondaryCommissionPercentOnAdjustmentWrap-${data.index}`}
    ></div>
    </div>
  )}
  allowFiltering={false}
  allowSorting={false}
  allowEditing={false}
/>
        {/* 
        <ColumnDirective 
        field='SecondaryCommissionPercentOnAdjustment'
        allowEditing={false}
         headerText='Approve 
         (Absolute)'
          width='160' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'

                className={`checkbox ${props.ApproveAbsoluteCommissionAmount ? 'yellow': ''}`}
                id={`checkbox_${props.PIID}`}
                 checked={props.ApproveAbsoluteCommissionAmount}
                 
                  onChange={(e) =>{
                    if(props.ApprovePrimaryCommissionPercentOnAdjustment == false){
                    
                    
                    let data={
                        FinalCommissionAmountApproved:props.ApproveAbsoluteCommissionAmount ==  false ? props.AbsoluteCommissionAmount : 0,
                        PIID:props.PIID,
                        ApproveAbsoluteCommissionAmount : props.ApproveAbsoluteCommissionAmount ==  false ? '1' : '0'
                    }
                    AdjustmentCalculatorService.updateApproveAbsoluteCommissionAmount(data).then((res)=>{
                      
                      CommissionCalculatorDataSource.map((Res)=>{
                          if(props.PIID == Res.PIID){
                            
                            Res.ApproveAbsoluteCommissionAmount  =  props.ApproveAbsoluteCommissionAmount ==  false ? true : false
                            // Res.FinalCommissionAmountApproved =    FinalCommissionAmountPerUnitApprovedUseRef.current;
                            Res.FinalCommissionAmountApproved = props.ApproveAbsoluteCommissionAmount ==  false ? props.AbsoluteCommissionAmount : 0 ;
                            FinalCommissionAmountPerUnitApprovedUseRef.current = props.ApproveAbsoluteCommissionAmount ==  false ? props.AbsoluteCommissionAmount : 0 ;
                                  refreshColumn('FinalCommissionAmountApproved')
                              // refreshColumn('FinalCommissionAmountApproved')
                              refreshColumn('ApproveAbsoluteCommissionAmount')
                              // refreshColumn('CRMName')
                            
                          }
                        })
                      })
                      // setCheckboxChecked(!props.ApproveAbsoluteCommissionAmount);
                    }else{
                      window.alert('ONLY ONE COMMISSION CAN BE APPROVED')

                    }
                    

                    
                  }} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            /> */}
        {/* <ColumnDirective
          headerText='Final 
          Commission
           Amount
            Per
             Unit
              Approved'
          headerTextAlign='CENTER'    
          field='FinalCommissionAmountApproved'
          allowEditing={false}
          width='165'
        
          textAlign='Left'
        /> */}
        {/* 
        <ColumnDirective 
        field='FinalCommissionAmountApproved'
        allowEditing={false}
         headerText='Commission 
          Approved'
          editType='numericedit'
          width='160' 
          textAlign='right'
          format='n0'
          
            template={(props) => (
             <>
              <TextBoxComponent
                        className='e-input'
                        
                        name='FinalCommissionAmountApproved'
                        
                        value={props.FinalCommissionAmountApproved}
                        enabled={!props.ApproveAbsoluteCommissionAmount && !props.ApprovePrimaryCommissionPercentOnAdjustment}
                        onChange={(e)=>{
                        
                        
                        FinalCommissionAmountPerUnitApprovedUseRef.current =  e.value
                       
                          // setCutWidth(e.value)
                        }}
                        onBlur={()=>{
                          let data={
                            FinalCommissionAmountApproved:FinalCommissionAmountPerUnitApprovedUseRef.current,
                            PIID:props.PIID
                          }
                          AdjustmentCalculatorService.updateFinalCommissionAmountPerUnitApproved(data).then((res)=>{

                            let dataFin = CommissionCalculatorDataSource.map((Res)=>{
                              if(props.PIID == Res.PIID){
                                
                                Res.FinalCommissionAmountApproved =    FinalCommissionAmountPerUnitApprovedUseRef.current;
                              
                                  refreshColumn('FinalCommissionAmountApproved')
                              setTimeout(() => {
                                // RemarksUseRef.current.element.focus() 
                                document.getElementById(`Remarks_${props.PIID}`).focus()
                                document.getElementById(`Remarks_${props.PIID}`).select()
                              }, 800);
                                  // refreshColumn('CRMName')
                                
                              }
                            })
                            
                        
                          })
                        }}
                      />
             </>
            )} 
            
          />       */}
          <ColumnDirective 
          field='FinalCommissionAmountApproved'
          // allowEditing={false}
           headerText='Commission 
            Approved'
          width='160' 
          textAlign='right'
          editType='numericedit'
          format='n0'
        //     field='ApproveAbsoluteCommissionAmount'
        allowEditing={false}
        //  headerText='Approve 
        //  (Absolute)'
        id='FinalCommissionAmountApproved'
        //   width='160' 
      template={(data) => (
        <TextBoxComponent
         type='number'
          format='n0'
            // className='e-input'
            id={`FinalCommissionAmountApproved-${data.index}`}
            // name='FinalCommissionAmountApproved'
            value={data.FinalCommissionAmountApproved}
            // disabled={data.ApproveAbsoluteCommissionAmount == true || data.ApprovePrimaryCommissionPercentOnAdjustment == true ?   true : false}
        change={(args)=>{
  const currentData = CommissionCalculatorGrid?.getCurrentViewRecords();
      if (currentData) {
        currentData[data.index].FinalCommissionAmountApproved = args.value;
        
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
        // CommissionCalculatorGrid?.editModule?.batchSave();
      }}}
      created={(e)=>{
        
        
        if(data.ApproveAbsoluteCommissionAmount == true || data.ApprovePrimaryCommissionPercentOnAdjustment == true){
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  true
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  false
        }else{
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).disabled =  false
          document.getElementById(`FinalCommissionAmountApproved-${data.index}`).enabled =  true

        }
      }}
    
  />)}
  allowFiltering={false}
  allowSorting={false}
  />
        {/* <ColumnDirective
          headerText='Remarks'
          headerTextAlign='CENTER'    
          field='Remarks'
          allowEditing={false}
          width='200'
          textAlign='Left'
        /> */}
         {/* <ColumnDirective 
        field='Remarks'
        allowEditing={false}
        headerText='Remarks'
          width='150' 
          textAlign='center'
            template={(props) => (
             <>
              <TextBoxComponent
                        className='e-input'
                        
                        name='Remarks'
                        id={`Remarks_${props.PIID}`}
                        
                        value={props.Remarks}
                        onChange={(e)=>{
                        
                        
                        RemarksUseRef.current =  e.value
                       
                          // setCutWidth(e.value)
                        }}
                        onBlur={()=>{
                          let data = {
                            Remarks:RemarksUseRef.current,
                            PIID:props.PIID
                          }
                          AdjustmentCalculatorService.updateRemarks(data).then((res)=>{

                            let dataFin = CommissionCalculatorDataSource.map((Res)=>{
                              if(props.PIID == Res.PIID){
                                
                                Res.Remarks =    RemarksUseRef.current;
                                // Res.CRMID = userId
                                // Res.CRMName = printedByUserName
                                  refreshColumn('Remarks')
                                  // refreshColumn('CRMName')
                                
                              }
                            })
                            
                        
                          })
                        }}
                      />
             </>
            )} 
            
          />       */}
    <ColumnDirective 
          field='Remarks'
          allowEditing={false}
          headerText='Remarks'
           width='150' 
           textAlign='center'
           
        //     field='ApproveAbsoluteCommissionAmount'
        // allowEditing={false}
        //  headerText='Approve 
        //  (Absolute)'
        //   width='160' 
      template={(data) => (
        <TextBoxComponent
            className='e-input'
            name='Remarks'
            value={data.Remarks}
            // enabled={!data.ApproveAbsoluteCommissionAmount && !data.ApprovePrimaryCommissionPercentOnAdjustment}
        onChange={(args)=>{
  const currentData = CommissionCalculatorGrid?.getCurrentViewRecords();
      if (currentData) {
        
        
        
        
        currentData[data.index].Remarks = args.value;
        CommissionCalculatorGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
        // CommissionCalculatorGrid?.editModule?.batchSave();
      }}}
    
  />)}
  allowFiltering={false}
  allowSorting={false}
  />
    
        {/* <ColumnDirective
          headerText='Supplier 
          Commission'
          headerTextAlign='CENTER'    
          field='CommissionStatus'
          allowEditing={false}
          width='180'
          textAlign='Left'
        /> */}

        <ColumnDirective
          headerText='Supplier 
          Commission'
          headerTextAlign='CENTER'    
          field='MaxReceiptMonth'
          allowEditing={false}
          width='180'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
         
       
         <ColumnDirective
          headerText="BL  
          HOLD"
          headerTextAlign='CENTER'    
          field='PaymentVerifiedMonth'
          allowEditing={false}
          width='145'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />

        <ColumnDirective
          headerText="Release / Surrender
          Date"
          headerTextAlign='CENTER'    
          field='MinReleaseOrSurrenderDate'
          allowEditing={false}
          width='165'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />
        <ColumnDirective
          headerText="Processed
          Date"
          headerTextAlign='CENTER'    
          field='AdjustmentCommissionProcessedDate'
          allowEditing={false}
          width='175'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />

        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
        <div className='drop-downs-div'>
        {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
            {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              // ref={salesAgentRef}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
                        <button id='get-data-btn' onClick={getCommissionCalculator}> Get Processed Commission</button>
                        <button id='get-data-btn' onClick={getUnProcessedCommissionCalculator}> Get UnProcessed Commission</button>
                        </div>
                 {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getCommissionCalculator()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
       {CommissionCalculatorDataSource.length > 0  ? <CommissionCalculatorGridComponent restrictPageSize={5} /> : null}
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            {/* {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null} */}
            <div>
            {/* <button id='get-data-btn' onClick={()=>{
                setShowDropDown(!showDropDown)
              }}>Per Unit</button> */}
              <h3> Adjustment Commission Calculator  - List  {proccessedVal}</h3>
              {showDropDown == true ?
        <div className='drop-downs-div'>
              {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
            {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              // ref={salesAgentRef}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
                                                <button id='get-data-btn' onClick={getCommissionCalculator}> Get Processed Commission</button>
                                                <button id='get-data-btn' onClick={getUnProcessedCommissionCalculator}> Get UnProcessed Commission</button>

                        </div>: <></>}
              {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getCommissionCalculator()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
              <div>
              </div>
              {CommissionCalculatorDataSource.length > 0  ? <CommissionCalculatorGridComponent /> : null}
            </div>
          </div>
          
        </div>
      )
  );
}
