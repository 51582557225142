import React, { useEffect, useState } from 'react';
// import TransferUnAllocatedPaymentService from '../../services/product.service';
import SecurityService from '../security/security.service';
import './Transfer-unAllocated-payment.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';
import {decryptData} from  '../decryption/decryption'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import TransferUnAllocatedPaymentAdd from './Transfer-unAllocated-payment-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import { TbCopy } from "react-icons/tb";
import {image} from './image';
import logoUrl from './logo192.png';
import TransferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';

var selectedRows = []
export default function ProformaInvoiceList(props) {
  
    // var img =  imagess;
  
  const [TransferUnAllocatedPaymentDataSource, setTransferUnAllocatedPaymentDataSource] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [showDialogEmail, setShowDialogEmail] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  let TransferUnAllocatedPaymentGrid: Grid;

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (
      TransferUnAllocatedPaymentGrid &&
      args.item.properties.id === 'TransferUnAllocatedPaymentGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.pdf`
      // };
      
      // TransferUnAllocatedPaymentGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_transfer-unallocated_payment.pdf`
    };
    
    
      
       TransferUnAllocatedPaymentGrid.pdfExport(exportProperties);

    }
    if (
      TransferUnAllocatedPaymentGrid &&
      args.item.properties.id === 'TransferUnAllocatedPaymentGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_transfer-unallocated_payment.xlsx`
    };
    TransferUnAllocatedPaymentGrid.excelExport(exportProperties);
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // // const customizeHeader = ({ element }) => {
      // //   // Insert the logo in the header
      // //   const logoImage = new Image();
      // //   logoImage.src = image;
      // //   logoImage.width = 100; // Adjust the width as per your requirement
      // //   logoImage.height = 50; // Adjust the height as per your requirement
    
      // //   // Add the logo to the header cell
      // //   const headerCell = element.sheet.rows[0].cells[0];
      // //   headerCell.appendChild(logoImage);
    
      // //   // Merge cells for the logo
      // //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // // };
      
      // TransferUnAllocatedPaymentGrid.excelExport(exportProperties);
      // // TransferUnAllocatedPaymentGrid.beforeDataBound(customizeHeader)
      
    }
    if (
      TransferUnAllocatedPaymentGrid &&
      args.item.properties.id === 'ProductFinishSizeGrid_columnchooser'
    ) {
    }
    if (TransferUnAllocatedPaymentGrid && args.item.properties.id === 'Delete') {
      
      if (TransferUnAllocatedPaymentGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`TransferUnAllocatedPayment`,
          ColumnName:`TransferID`,
          RowID:TransferUnAllocatedPaymentGrid.getSelectedRecords()[0]?.TransferID,
          SelectedRow:true,
          SelectedRowData:{
            ...TransferUnAllocatedPaymentGrid.getSelectedRecords()[0]
          }
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    getTransferUnAllocatedPayment();
    SecurityService.getUserAccessList(userId).then((res)=>{
      
      const decryptedData = decryptData(res.data); // Decrypt response
      
      
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByEmail(decryptedData[0].Email)
     });
  }, []);

  const getTransferUnAllocatedPayment = async (type: string) => {
    TransferUnAllocatedPaymentService.getList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        
        
        const data = decryptedData.map((v, k) => {
          v.TransferDate = new Date(v.TransferDate.split("T", 1)[0]);
          return v;
        });
        setTransferUnAllocatedPaymentDataSource(data);
      })
      .catch((e) => {
        
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    // 'Edit',
    {
      // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false},
    'Update',
    'Cancel',
    // 'Copy',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    
    return(
      // <div dataList={TransferUnAllocatedPaymentDataSource} {...props}> </div>
      <TransferUnAllocatedPaymentAdd dataList={TransferUnAllocatedPaymentDataSource} {...props}> </TransferUnAllocatedPaymentAdd>
      
    ) 
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess('TransferUnAllocatedPaymentEdit'),
    allowAdding: SecurityService.canUserAccess('TransferUnAllocatedPaymentAdd'),
    allowDeleting: SecurityService.canUserAccess('TransferUnAllocatedPaymentDelete'),
    // allowEditing: false,
    // allowAdding: true,
    // allowDeleting: true,
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  
  
  const actionComplete = (args: DialogEditEventArgs) => {
    
    if (args?.requestType === 'columnstate') {
      
      TransferUnAllocatedPaymentGrid?.autoFitColumns();
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'){
        
        const data = {
          CreatedByUserID : userId,
          ...args.data,
          TransferDate: moment(args.data.TransferDate).format('YYYY-MM-DD')
        }
        TransferUnAllocatedPaymentService.addTransferUnAllocatedPayment(data)
          .then((response) => {
            setTransferUnAllocatedPaymentDataSource([])
        getTransferUnAllocatedPayment()
          })
      }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     // delete productFinishSize[0]

      
    //   TransferUnAllocatedPaymentService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setTransferUnAllocatedPaymentDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    
    //     TransferUnAllocatedPaymentService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getTransferUnAllocatedPayment();
    
    //   }

    }

  };
  const databound = () =>
  {
      if (TransferUnAllocatedPaymentGrid) {
        TransferUnAllocatedPaymentGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);

//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };
// const copyColumnData = (column) => {
//   if (TransferUnAllocatedPaymentGrid) {

//     const selectedColumnData = TransferUnAllocatedPaymentGrid.getCurrentViewRecords().map(row =>{

//       return (
//       row[column])
//       }); // Replace 'ColumnName' with the actual name of your column

//     const textToCopy = selectedColumnData.join('\n');
//     navigator.clipboard.writeText(textToCopy);
//   }
// };
  const TransferUnAllocatedPaymentGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (TransferUnAllocatedPaymentGrid = grid)}
      id='TransferUnAllocatedPaymentGrid'
      dataSource={TransferUnAllocatedPaymentDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      rowDeselecting={
        (args)=>{
            
          navigator.clipboard.writeText(args.target?.innerText)
        }
      }
      rowSelected={
        (args)=>{
          console.log(args.target?.innerText)
          if(args.target?.innerText && navigator){
          navigator.clipboard.writeText(args.target?.innerText)
          }
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='ID'
          field='TransferID'
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='Serial
          No'
          headerTextAlign='CENTER'    
          field='SerialNo'
          width='130'
          textAlign='Right'
        />
        <ColumnDirective
          headerText="Transfer 
          Date"
          headerTextAlign='CENTER'    
          field='TransferDate'
          width='145'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText={`From 
          Customer `}
          // headerText={
          //   <div>
          //     From Customer <button>button</button>
          //   </div>
          // }
          // headerTemplate={(e)=>{
          //   return  (<>
          //      <span className='e-headertext' > <TbCopy id='FromCustomerSvg' onClick={(e)=>{
          
          //       document.getElementById('FromCustomerSvg').style.color = 'red';
          //       copyColumnData('FromCustomerName')
          //       setTimeout(() => {
          //         document.getElementById('FromCustomerSvg').style.color = '';
                  
          //       }, 500);
          //      }} /> From Customer</span>
          //      </>)
          // }

          // }
          headerTextAlign='CENTER'    
          field='FromCustomerName'
          width='165'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='To
           Customer'
          headerTextAlign='CENTER'    
          field='ToCustomerName'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Transfer Amount'
          headerTextAlign='CENTER'    
          field='TransferAmount'
          width='145'
          textAlign='Right'
          format='n0'
        />
        <ColumnDirective
          headerText='Currency'
          headerTextAlign='CENTER'    
          field='CurrencyName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='User Name'
          headerTextAlign='CENTER'    
          field='UserName'
          width='130'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Remarks'
          headerTextAlign='CENTER'    
          field='Remarks'
          width='260'
          textAlign='Left'
        />
         
       
        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
            
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getTransferUnAllocatedPayment()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <TransferUnAllocatedPaymentGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Transfer UnAllocated Payment - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        
        // FabricProcessingContractService.create(data);
        getTransferUnAllocatedPayment()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <TransferUnAllocatedPaymentGridComponent />
            </div>
          </div>
          
        </div>
      )
  );
}
