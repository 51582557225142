
import React , {useState , useEffect , useRef} from 'react';
import UserService from '../../services/users.service';
import UserReplicateService from '../../services/user-replicate.service';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import './user-replicate.scss';
import { decryptData } from '../decryption/decryption';
const UserReplicate = (props) => {
    let fromusersRef = useRef(null)
    let tousersRef = useRef(null)
    const [loadingUser, setLoadingUser] = useState(true);
    const [users, setUsers] = useState([]);
    const [formUserValue, setFromUserValue] = useState('');
    const [fromUserID, setFromUserID] = useState(0);
    const [toUserValue, setToUserValue] = useState('');
    const [toUserID, setToUserID] = useState(0);

    const ddlFields: object = { value: 'value' };

    useEffect(() => {
        getUsers();

    }, []);
    
    useEffect(() => {
        if(fromUserID == toUserID && toUserID != 0 &&  fromUserID != 0 ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
        }

    }, [fromUserID , toUserID]);
    const getUsers = async (type: string) => {
        setLoadingUser(true);
        UserService.getForList()
          .then((response) => {
            const decryptedData = decryptData(response.data); // Decrypt response
            
            
            setUsers(decryptedData);
            setLoadingUser(false);
          })
          .catch((e) => {
            
          });
      };

    const fromUsersChange = (e)=>{
        
        
        setFromUserValue(e?.itemData?.value ? e?.itemData?.value : '');
        setFromUserID(0);
    if (e?.e?.srcElement?.id) {
      
      setFromUserID(e?.itemData?.id);
    }
    }
    const toUsersChange = (e)=>{
        
        
        setToUserValue(e?.itemData?.value ? e?.itemData?.value : '');
        setToUserID(0);
    if (e?.e?.srcElement?.id) {
      
      setToUserID(e?.itemData?.id);
    }
    }

    const formSubmit = (e) =>{
        e.preventDefault()
        if(fromUserID == toUserID && toUserID != 0 &&  fromUserID != 0 ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
            setToUserID(0)
            setToUserValue('')
            tousersRef.current.element.focus()
        }else{
            
            
            let data = {
              Fromid:fromUserID,
              Toid:toUserID
            }
            UserReplicateService.userReplicate(data).then((res)=>{
              
              setToUserID(0)
            setToUserValue('')
              setFromUserID(0)
            setFromUserValue('')
            document.getElementById('user-replicate').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('user-replicate').style.display = 'none'
            },1500)
            }).catch(()=>{

            })

        }
        
    }

    return (
        (props.isDialog) ? (
        
        <div>
            
        </div>
        ) : 
        (
            <div className="content-wrapper">
                  <div>
                    <h3> User - Replicate</h3>
                    <form onSubmit={formSubmit}>

                    
                    <div className='user-replicate'>
                  <div className='first-dropdown'>

                    <mat-form-field>
                      {loadingUser ? (
                        <div>... Loading  From Users </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='FromUser'
                            dataSource={users}
                            placeholder='Select  From User'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={fromUsersChange}
                            ref={fromusersRef}
                            change={fromUsersChange}
                            value={formUserValue}
                              blur={()=>{
                                
                                if (fromUserID  == 0  || fromusersRef.current.value == null || fromusersRef.current.value == '' || fromUserID == undefined || fromUserID == null) {
                                 if(formUserValue != ''){
                                   let resstr = users.some(e=>e.value ==  formUserValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("FromUser").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("FromUser").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromusersRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='FromUserID'
                            floatLabelType='Auto'
                            value={fromUserID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                  <div className='second-dropdown'>

                    <mat-form-field>
                      {loadingUser ? (
                        <div>... Loading  To Users </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='ToUser'
                            dataSource={users}
                            placeholder='Select To User'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={toUsersChange}
                            ref={tousersRef}
                            change={toUsersChange}
                            value={toUserValue}
                              blur={()=>{
                                
                                if (toUserID  == 0  || fromusersRef.current.value == null || fromusersRef.current.value == '' || toUserID == undefined || toUserID == null) {
                                 if(toUserValue != ''){
                                   let resstr = users.some(e=>e.value ==  toUserValue);
                                   
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("ToUser").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("ToUser").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromusersRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='ToUserID'
                            floatLabelType='Auto'
                            value={toUserID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                        <div className='div-btn'>
                        <button 
                        type='submit'
                        id='get-data-btn'
                        className='user-replicate-btn' 
                        disabled={
                            toUserID != 0 && fromUserID != 0 ? false : true
                        }
                        > User Replicate</button>
                        <p id='same-user-id'> Cannot Replicate Same User!</p>
                        <p id='user-replicate'> User Replicated SuccessFully!</p>
                        </div>
                      </div>
                      </form>
                 </div>
            </div>
        )
    );
}

export default UserReplicate;
