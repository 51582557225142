// Yarn Count List
import React, { useEffect, useState, useRef } from 'react';
import CustomerLastSaleService from '../../services/customer-last-sale.service';
import './customer-last-sale.scss';
import { decryptData } from '../decryption/decryption';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import MessageAdd from './customer-last-sale-add';
// import YarnCountEdit from './yarn-count-edit';
// import YarnPurchaseContractEdit from './yarn-purchase-contract-edit';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import YarnContractDeliveryScheduleList from '../yarn-contract-delivery-schedule/yarn-contract-delivery-schedule-list';
import moment from 'moment';
import SecurityService from '../security/security.service';
import { useMemo } from 'react';
import AuditTableInput from '../audit-table/audit-table';
import HardDelete from '../hard-delete/hard-delete';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
let selectedRows = []
let messageGrid: Grid;

var customerID2 = 0

export default function YarnCountList(props) {
  let piRef = useRef(null)
  const [message, setMessage] = useState([]);
  const [loadingPi, setLoadingPi] = useState(false);
  const [pi, setPi] = useState([]);
  const [piValue, setPIValue] = useState('');
  const [piID, setPiID] = useState(0);
  
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  let customerRef = useRef(null)
  const [customer, setCustomer] = useState([]);
  const [customerValue, setCustomerValue] = useState('');
  const [customerID, setCustomerID] = useState(0);
  
  const [customerLastSale, setCustomerLastSale] = useState([]);

  const [printedByUserName, setPrintedByUserName] = useState('');
  const [printedByUserID, setPrintedByUserID] = useState('');

  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();

  const [deletingID, setDeletingID] = useState(0);

  const userId = SecurityService.getLoggedInUserId();

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_messages.pdf`
      };
      console.log('clicked on pdf export');
      // fabricQualityGrid.pdfExport(exportProperties);
      messageGrid.pdfExport(exportProperties);
    }
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_messages.xlsx`
      };
      messageGrid.excelExport(exportProperties);
    }
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_columnchooser'
    ) {
    }
    if (messageGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (messageGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', messageGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`YarnCount`,
          ColumnName:`YarnCountID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        // setShowDialogs(true)
        // setWarningMessage("Please select a row first!");
        // warningDialog.current.show();

      } else {
        console.log('delete: ', messageGrid.getSelectedRecords())
        console.log(selectedRows)
        // deleteDialog.current.hide();
        let obj={
          TableName:`YarnCount`,
          ColumnName:`YarnCountID`,
          RowID:messageGrid.getSelectedRecords()[0]['YarnCountID'],
          SelectedRow:true,
          SelectedRowData:{
            YarnCount:  messageGrid.getSelectedRecords()[0].YarnCount,
            WarpWastagePercent: messageGrid.getSelectedRecords()[0].WarpWastagePercent,
            WeftWastagePercent: messageGrid.getSelectedRecords()[0].WeftWastagePercent
          }

          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
    
    // if (
    //   transporterGrid &&
    //   args.item.properties.id === 'transporterGrid_Delivery Schedule'
    // ) {
    //   console.log('clicked delivery');
    // }
  };

  useEffect(() => {
    // getMassage(1);
    getCustomerDropDown();
    SecurityService.getUserAccessList(userId).then((res)=>{
      const decryptedData = decryptData(res.data); // Decrypt response

      console.log(res)
      console.log(decryptedData[0])
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByUserID(decryptedData[0].UserID)
      // setPrintedByEmail(decryptedData[0].Email)
     });
  }, []);

  

  const getCustomerDropDown = async () => {
    CustomerLastSaleService.getCustomerDropdownList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('get all customers', response)

        setCustomer(decryptedData);

      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const getCustomerDropDown = async () => {
  //   CustomerLastSaleService.getCustomerDropDownList()
  //     .then((response) => {
  //       console.log('get all pi', response)
  //       setPi(response.data.recordset);

  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getCustomerLastSale = async (customerId,salesAgentId,countryId) => {
    CustomerLastSaleService.getCustomerLastSaleList(customerId,salesAgentId,countryId)
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('get customer last sale', response)
        console.log('get customer last sale response.data.recordset', decryptedData)
        // setMessage(decryptedData);
        setCustomerLastSale(decryptedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getMassage = async (id) => {
    // CustomerLastSaleService.getMessageList(id)
    //   .then((response) => {
    //     console.log('get all yarn count', response)
    //     setMessage(response.data.recordset);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  const addYarnCount = () => {
    props.history.push('/YarnCount/Add');
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    // 'Add',
   
    // 'Edit',
    // {
    //   text: "Delete",
    //   tooltipText: "Delete",
    //   id: "Delete",
    //   disabled: !SecurityService.canUserAccess('YarnCountDelete'),
    // },
    // 'Update',
    // 'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const databound = () =>
  {
      if (messageGrid) {
        messageGrid.autoFitColumns();
      }
  }


  // Grey Area
  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ?
      (
        <MessageAdd dataList={message} From={printedByUserName} FromID={printedByUserID} {...props} > </MessageAdd>
      )
      :
      (
        <MessageAdd dataList={message} From={printedByUserName} FromID={printedByUserID} {...props} > </MessageAdd>
    
      )
    
  };

  const editSettings: EditSettingsModel = {

    // allowEditing: SecurityService.canUserAccess('YarnCountEdit'),
    // allowAdding:true,
    // allowDeleting: SecurityService.canUserAccess('YarnCountDelete'),

    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: 'Dialog',
    template: dialogTemplate,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'PIMessageID', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };
  // const header = () => {
  //   return <div>Yarn Contract Delivery Schedule</div>;
  // };

  //   const contentTemplate = () => {
  //     <div>
  //       <YarnContractDeliveryScheduleList
  //         {...selectedRow}
  //       ></YarnContractDeliveryScheduleList>
  //     </div>
  //   );
  // };

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      messageGrid?.autoFitColumns();
    }
    if (args.form) {
      console.log('data: ', args);
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      //   console.log('e: ', args.form.ej2_instances[0]);
      //   /** Add Validation Rules */

      //   args.form.ej2_instances[0].addRules('YarnCount', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('WarpWastagePercent', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('WeftWastagePercent', {
      //     required: true,
      //   });

      // }

      if (args?.requestType === 'save') {
        console.log('Adding ......');
        const data = {
          UserId: userId,
          ...args.data,
          PIID:piID,
          FromEmployeeID:printedByUserID,
          ToEmployeeID:args.data.ToEmployeeID.includes(',')
          ? args.data.ToEmployeeID.split(',').map(item => item.trim())
          : [args.data.ToEmployeeID]

        };
        console.log('add data: ', data);
        CustomerLastSaleService.insert(data).then((response) => {
          console.log(response)
          getMassage(piID)
        })
        // CustomerLastSaleService.create(data).then((response) => {
        //   console.log('add response: ', response);
        //   let AddData = {
        //     YarnCount: args.data.YarnCount,
        //     WarpWastagePercent: args.data.WarpWastagePercent,
        //     WeftWastagePercent: args.data.WeftWastagePercent
        //   }
        //   AuditTableInput.auditAdd(`YarnCount`,`YarnCount`,`YarnCountID`,AddData)
        //   setMessage(response.data.recordset);
        //   // refresh grid to show new record
        //   // const type = getroute()[1];
        //   getYarnCount();


        // });
      } 
      if (args?.action === 'editdddd' && args?.requestType === 'save') {
        console.log('Updating ......');
        const data = {
          UserId: userId,
          ...args.data,
          UserID:userId,
          YarnCountId:args.data.YarnCountID
        };
        console.log('update data: ', data);
        // CustomerLastSaleService.update(data).then((response) => {
        //   console.log('update response: ', response);
        //   let EditData = {
        //     YarnCount: args.data.YarnCount,
        //     WarpWastagePercent: args.data.WarpWastagePercent,
        //     WeftWastagePercent: args.data.WeftWastagePercent
        //   }
        //   let totalColumns = Object.keys(EditData);
        //   console.log(totalColumns)

        //   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`YarnCount`,args.data.YarnCountID)
        //   setMessage(response.data.recordset);
        //   // refresh grid to show new record          
        //   getYarnCount();

        // });
      }      
    }

  };


 
  // const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  const MessageGridComponent = () => (
    <GridComponent
    ref={(grid) => (messageGrid = grid)}
    id='messageGrid'
    dataSource={customerLastSale}
    editSettings={editSettings}
    toolbar={toolbarOptions}
    pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 12 }}
    filterSettings={filterOptions}
    groupSettings={groupOptions}
    actionComplete={actionComplete}
    allowTextWrap='true'
    // commandClick={commandClick}

    allowPaging={true}
    allowSorting={true}
    allowPdfExport={true}
    allowExcelExport={true}
    toolbarClick={toolbarClick}
    allowFiltering={true}
    allowGrouping={true}
    showColumnChooser={true}
    allowReordering={true}
    allowResizing={true}
    sortSettings={sortingOptions}
   dataBound={databound}

   rowSelected={
    (args)=>{
      console.log(args.data)
      selectedRows = args.data
      
                setTimeout(() => {
        console.log('ss')
      }, 200);
    }
  }

  >
  
    <ColumnsDirective>
    <ColumnDirective
        headerText='Serial 
        No'
        field='SerialNo'
        width='130'
        headerTextAlign='Center'
        textAlign='Right'
      />

      <ColumnDirective
        headerText='Customer Name'
        field='CustomerName'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      <ColumnDirective
        headerText='Customer Email'
        field='CustomerEmail'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />

      <ColumnDirective
        headerText='Country Name'
        field='CountryName'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />

      <ColumnDirective
        headerText='Sales Agent Name'
        field='SalesAgentName'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />

     <ColumnDirective
        headerText='Last Order Date'
        field='LastOrderDate'
        width='200'
        type='date'
       format={'dd/MM/yy'}
            /> 

      <ColumnDirective
        headerText='Last Order Amount '
        field='LastOrderAmount'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />

      <ColumnDirective
        headerText='Last Adjustment Amount '
        field='LastAdjustmentAmount'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />

    </ColumnsDirective>

          <Inject
      services={[
        Edit,
        Toolbar,
        Page,
        Sort,
        PdfExport,
        ExcelExport,
        Filter,
        Group,
        Aggregate,
        Freeze,
        ColumnChooser,
        Reorder,
        Resize,
        // CommandColumn,
      ]}
    />
  </GridComponent>
  );

  const ddlFields: object = { value: "value" };

  const customerChange = (e) => {
    setCustomerValue(e?.itemData?.value ? e?.itemData?.value :'');
    if (e?.e?.srcElement?.id) {
      console.log("customerChange: ", e?.itemData?.id);
      // setCountryID(e?.itemData?.id);
      setCustomerID(e?.itemData?.id);
      customerID2 = e?.itemData?.id;
      console.log("customerID2: ", customerID2);
      
      console.log("customerValue: ", customerValue);
      console.log("CustomerID: ", customerID);
      getCustomerLastSale(customerID2,0,0);
      // getMassage(e?.itemData?.id);
      // getCustomerLastSale(e?.itemData?.id);
    }
    else {
      setCustomerLastSale([]);
      // setMessage([]);
      setCustomerID(0)
    }
  };


  return (
    (props.isDialog) ? (
      <div>
        <div className="col-sm-4 ">
                  {loadingCustomer ? (
                    <div>... loading Customer </div>
                  ) : (
                    <div>
                      <AutoCompleteComponent
                        id="customer"
                        dataSource={customer}
                        placeholder="Select Customer"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={customerRef}
                        // select={piChange}
                        value={customerValue}
                        change={customerChange}
                        blur={()=>{
                          console.log(customerRef.current)
                           if (customerID == 0  || customerRef.current.value == null || customerRef.current.value == '' || customerID == undefined ) {
                            customerRef.current.element.focus()
                            if(customerValue != ''){
                            let resstr = customer.some(e=>e.value ==  customerValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("customer").parentElement.parentElement.firstChild)
                              document.getElementById("customer").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("customer").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }}
                        

                      />
                      <TextBoxComponent
                        name="customerID"
                        value={customerID}
                        type="hidden"
                      />
                    </div>
                  )}
                </div>
        {customerID != 0?<MessageGridComponent />
:null}
      </div>
    ) :
      (
        <div className="content-wrapper">
          <div>
            
          {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
          setShowDialogs(false)
          if(e == false){
        setShowDialogs(false)
        setTimeout(() => {
        getMassage();

        }, 200);
                                      }
              }} 
                ></HardDelete>
                  :null}

            <div>
              <h3>Customer Last Sale - List</h3>
              <div>
              </div>
              {/* <messageGridComponent /> */}
              <div className="col-sm-8 " >
                  {loadingCustomer ? (
                    <div>... loading Customer </div>
                  ) : (
                    <div className='customer'>
                      <AutoCompleteComponent
                        id="customer"
                        dataSource={customer}
                        placeholder="Select Customer"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={customerRef}
                        // select={piChange}
                        value={customerValue}
                        change={customerChange}
                        blur={()=>{
                          console.log(customerRef.current)
                           if (customerID == 0  || customerRef.current.value == null || customerRef.current.value == '' || customerID == undefined ) {
                            customerRef.current.element.focus()
                            if(customerValue != ''){
                            let resstr = customer.some(e=>e.value ==  customerValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("customer").parentElement.parentElement.firstChild)
                              document.getElementById("customer").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("customer").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }
                      }
                        

                      />
                      <TextBoxComponent
                        name="customerID"
                        value={customerID}
                        type="hidden"
                      />
                    </div>
                  )}
                </div>
                {customerID != 0?
                <MessageGridComponent />
                :null}
            </div>
          </div>
        </div>
      )
  );
}
