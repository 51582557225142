import React , {useEffect , useState} from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent, RadioButtonComponent} from '@syncfusion/ej2-react-buttons'; // Import necessary components from Syncfusion
import listTempleteService from '../../services/list-templete.service';
import SecurityService from '../security/security.service';
import { useDispatch } from 'react-redux';
import {  gridColumnsList, gridSortingOptions , getprintedByUserName  } from '../../store/slices/list-tempate'
import './template-code.scss'
import HardDelete from '../hard-delete/hard-delete';
import {decryptData} from '../decryption/decryption'
// import { dataBound } from '@syncfusion/ej2-react-grids';

const TemplateCode = ({ 
  isToggled ,
  ColumnProps ,
  getListService ,
  getColumns , 
  InputListSecurity ,
  visibleSortPassFields ,
  visibleSortPass ,
  groupingArray ,
  defaultSortColumnName ,
  defaultSortColumnType,
  onLoad= false,
  dataBound
}) => {
    
    const dispatch = useDispatch()

    const [templateDropdownList, setTemplateDropdownList] = useState('');
    const [loadingDropDown, setLoadingDropDown] = useState('');
    const [templateList, setTemplateList] = useState();
    const userId = SecurityService.getLoggedInUserId();

    // 1=  PrintedByUserName use IN toolbar pdf and excel export
    const [printedByUserName, setPrintedByUserName] = useState();
    
    // 2=  visibleFields use IN render grid columns with all details
    const [visibleFields, setVisibleFields] = useState(ColumnProps);
    
    // 3=  visibleSortFields use IN sorted columns name of grid 
    const [visibleSortFields, setVisibleSortFields] = useState([defaultSortColumnName]);
    
    // 4=  visibleSort use IN sorted type of grid 
    const [visibleSort, setVisibleSort] = useState([defaultSortColumnType]);

    // 5= sortingOptions  use IN  sortingOptions of Grid
    const [sortingOptions, setSortingOptions] = useState({
      columns: [{ field: defaultSortColumnName, direction: defaultSortColumnType }]
    });

    // 6= selectedOrientation use IN orientation of pdf Export in grid 
    var selectedOrientation = 'Landscape'
    
    let headerCheck = false;
    // 7= securityItemID use IN get Template list by securityItemID of input
    const [securityItemID, setSecurityItemID] = useState();
    
    // 8= listTemplatedropDownValue use IN template drop down value
    const [listTemplatedropDownValue, setListTemplatedropDownValue] = useState('');

    // 9= listTemplatedropDownID use IN template drop down ID
    const [listTemplatedropDownID, setListTemplatedropDownID] = useState(0);
    
    // 10= groupColumn use IN render grid columns with all details
    const [groupColumn, setGroupColumn] =useState([])
    
    
    const [existValue, setExistValue] =useState(false)
    
    const [defaultTemplete, setDefaultTemplete] = useState(false);
    const [deletingProps, setDeletingProps] = useState();
    const [showDialogs, setShowDialogs] = useState(false);

      const ddlFields: object = { value: 'value' };


      useEffect(()=>{
          console.log(onLoad);
          if(onLoad == true){
            console.log(templateDropdownList)
              let filterData = templateDropdownList.filter((e)=> e.defaultTemplete == true);
              console.log(filterData);
              if(filterData.length > 0 ){
                setListTemplatedropDownID(filterData[0].id);
                setListTemplatedropDownValue(filterData[0].value);

                getVisibleColumn(filterData[0].id);
              }
            
          }
      },[onLoad])
    useEffect(() => {
        SecurityService.getUserAccessList(userId).then((res)=>{
            console.log(res)
            console.log(InputListSecurity)
            const decryptedData = decryptData(res.data); // Decrypt response
            console.log(decryptedData);
         let securityItemID =    decryptedData.filter(res=> res.ItemName.toLowerCase() == InputListSecurity.toLowerCase())
            console.log(decryptedData[0].UserName)
            console.log(securityItemID)
            setSecurityItemID(securityItemID[0].SecurityItemID)
            setPrintedByUserName(decryptedData[0].UserName)
            dispatch(getprintedByUserName(decryptedData[0].UserName))
            getListTemplate(securityItemID[0].SecurityItemID ,userId,true);
           });
          //  react redux code in useEffect

          dispatch(gridColumnsList(ColumnProps))
          dispatch(gridSortingOptions({
            columns: [{ field: defaultSortColumnName, direction: defaultSortColumnType }]
          }))
          // dispatch(callDataBound(false))
    }, []);

    useEffect(() => {
        const updatedSortingOptions = {
          columns: visibleSortFields.map((field, index) => ({
            field: field,
            direction: visibleSort[index]
          }))
        };
        setSortingOptions(updatedSortingOptions);
        dispatch(gridSortingOptions(updatedSortingOptions))
      }, [visibleFields, visibleSort]);
     
    const getListTemplate = (id,CreatedByUserID = userId, isFirstTime = false)=>{
        try{
        listTempleteService.getAllBySecurityItemID(id, CreatedByUserID).then((res)=>{
          console.log(res);
          setLoadingDropDown(false);
          let data = [...res.data.recordset];
          const listForDropdown = data.map((val)=>{return {id: val.ListTemplateID, value:val.TemplateName,defaultTemplete:val.DefaultTemplete}});
          console.log(listForDropdown);
          setTemplateDropdownList(listForDropdown);
          setTemplateList(res.data.recordset);
          console.log(templateList);
         
         })
        } catch (error) {
          setLoadingDropDown(false)
    
          
        }
      }

// SEND TO GRID COLUMNS DETAIL 
const deleteTemplate = (e) => {
  console.log(e);
  let filterData = templateDropdownList.filter((ee)=> ee.value == e);
  console.log(filterData);
  if(filterData.length == 0 ){
    alert('PLEASE SELECT  TEMPLATE')
    return null
  }
  let obj={
    TableName:`ListTemplete`,
    ColumnName:`ListTemplateID`,
    RowID:filterData[0].id,
    SelectedRow:true
    
  }
  setDeletingProps(obj)
  setShowDialogs(true)
}
const getVisibleColumn = (e) =>{

    let filterTemplate = templateList.filter((val)=>{ return val.ListTemplateID == e})
    console.log(filterTemplate)
  // visiblity
    const visibleColumns = filterTemplate.map((res) => {
      let templateColumnsHeaderText = res.TemplateColumnsHeaderText.split(',')
      let templateColumnsWidth = res.TemplateColumnsWidth.split(',')
      let templateColumnsTextAlign = res.TemplateColumnsTextAlign.split(',')
      let templateColumnsHeaderTextAlign = res.TemplateColumnsHeaderTextAlign.split(',')
      console.log(res);
      if (res.TemplateColumns.includes(',')) {
          return res.TemplateColumns.split(',').map((item,index) => {
              return {
                  field: item.trim(),
                  headerText: templateColumnsHeaderText[index],
                  width: templateColumnsWidth[index],
                  textAlign: templateColumnsTextAlign[index],
                  headerTextAlign: templateColumnsHeaderTextAlign[index],
                  other: ColumnProps.find(obj => obj.field == item.trim()).other
              };
          });
      } else {
          return [{
              field: res.TemplateColumns.trim(),
              headerText: templateColumnsHeaderText[templateColumnsHeaderText.length],
              width: templateColumnsWidth[templateColumnsHeaderTextAlign.length],
              textAlign: templateColumnsTextAlign[templateColumnsHeaderTextAlign.length],
              headerTextAlign: templateColumnsHeaderTextAlign[templateColumnsHeaderTextAlign.length],
              other: ColumnProps.find(obj => obj.field === res.TemplateColumns.trim()).other
            }];
      }
  });
  console.log(visibleColumns);
  setVisibleFields(visibleColumns[0]) 
  dispatch(gridColumnsList(visibleColumns[0]))
  // [
  //   {  field: "ProductFinishSizeID",headerText:'ID',width:'80px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
  //   {  field: "FinishSize",headerText:'Finish Size',width:'110px',textAlign:'left' ,headerTextAlign:'center',other:[]},
  //   {  field: "ProductDesc",headerText:'Product Desc',width:'140px',textAlign:'left' ,headerTextAlign:'center',other:[]},
  //   {  field: "IsDeleted",headerText:'Cancel',width:'100px',textAlign:'right' ,headerTextAlign:'center',other:[]},
  // ]
      
  // Sorting columns 
  const visibleSortField =filterTemplate.map((res)=>{return res.SortedByColumn.includes(',')
  ? res.SortedByColumn.split(',').map(item => item.trim())
  : [res.SortedByColumn]})
  console.log([...visibleSortField][0])
    setVisibleSortFields([...visibleSortField][0]);
    
    // Sorting types
    const visibleSrt =filterTemplate.map((res)=>{return res.SortingType.includes(',')
    ? res.SortingType.split(',').map(item => item.trim())
    : [res.SortingType]})
    console.log(visibleSrt[0])
    setVisibleSort(visibleSrt[0]);

// pdf orientation
    let templateOrientation = filterTemplate[0].PageAlignment;
    setTimeout(() => {
      console.log(document.getElementsByClassName('orientation'),templateOrientation)
      if(templateOrientation ==  'Landscape'){
        document.getElementsByClassName('orientation')[1].checked = true;
         selectedOrientation = templateOrientation
        }else{
          document.getElementsByClassName('orientation')[0].checked = true;
          selectedOrientation = templateOrientation
        }
      }, 200);
  
      // document.getElementById('reportName').value = filterTemplate[0].ReportName

    console.log(templateList)
    setTimeout(() => {
      let groups = templateList.filter((val)=>{ return val.ListTemplateID == e}).map((res)=>{return res.GroupedByColumn.includes(',')
      ? res.GroupedByColumn.split(',').map(item => item.trim())
      : [res.GroupedByColumn]})
      console.log(groups)
      setGroupColumn(groups[0])
      getListService()
      
  }, 330);
  setTimeout((e)=>{
    dataBound(true)
  },3500)
  }


// SAVE Template
  const saveTemplate = () => {
    const visibleFalseColumns =ColumnProps.filter(col => col.other && col.other[0] && col.other[0].visible !== undefined && col.other[0].visible === false);
     console.log(visibleFalseColumns)
    const  visibleTrueColumns = getColumns().filter((col) => col.visible);
    const visibleColumns =[...visibleTrueColumns, ...visibleFalseColumns]
    let TemplateName = document.getElementById('templateName').value
    // let reportName = document.getElementById('reportName').value;
    let reportName ='';
    console.log(TemplateName)
    let getOrientation = document.getElementsByClassName('orientation')
     selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape'
    console.log(selectedOrientation) 
    const template = {
    TemplateName,
      TemplateColumns:  visibleColumns.map((col,index) => col.headerText == 'Commands'? col.headerText : col.field),
      TemplateColumnsHeaderText: visibleColumns.map((col) => col.headerText),
      TemplateColumnsHeaderTextAlign: visibleColumns.map((col) => col.headerTextAlign),
      TemplateColumnsTextAlign: visibleColumns.map((col) => col.textAlign),
      TemplateColumnsWidth: visibleColumns.map((col) => col.width),
      UserID:userId,
      SecurityItemID:securityItemID,
      SortedByColumn: visibleSortPassFields.current.filter(res => res != '' && res != undefined),
      SortingType: visibleSortPass.current.filter(res => res != '' && res != undefined),
      PageAlignment:selectedOrientation,
      ReportName: reportName,
      GroupedByColumn:groupingArray,
      GroupingType:	[],
      DefaultTemplete:defaultTemplete == true ? 1 :0
    };
    console.log('saving template', template)

    listTempleteService.addListTemplete(template).then((res)=>{
      getListTemplate(securityItemID)
      getListService()
      setDefaultTemplete(false)
      document.getElementById('templateName').value = ''
      console.log(res)
    })
    
  };
  
  


    //   CHANGE
    const listTemplateChange = (e)=>{
      console.log(e)
      if(e.itemData  == null ){
        console.log('null')
        getListService()
        
        setVisibleFields(ColumnProps)
        let filteredArr = ColumnProps.filter(obj => Object.keys(obj).length !== 0);
        dispatch(gridColumnsList(filteredArr))
        setVisibleSortFields([defaultSortColumnName]);
        setVisibleSort([defaultSortColumnType]);
        setListTemplatedropDownValue('')
        setListTemplatedropDownID(0)
        // setGroupColumn([])
        // dispatch(callDataBound(false))
        // getListService()
      }else{
        if(onLoad == true){
        setListTemplatedropDownValue(e.itemData.value ? e.itemData.value : '')
        setListTemplatedropDownID(e?.itemData?.id ? e?.itemData.id : 0)
        }
      }
  //     if(e.itemData  == null){
  //       console.log('null')
  //       getListService()
       
  //       setVisibleFields(ColumnProps)
  //       let filteredArr = ColumnProps.filter(obj => Object.keys(obj).length !== 0);
  //       dispatch(gridColumnsList(filteredArr))
  //       setVisibleSortFields([defaultSortColumnName]);
  // setVisibleSort([defaultSortColumnType]);
  // // setGroupColumn([])
  // // dispatch(callDataBound(false))
  // getListService()
  //     }else{
  //     setListTemplatedropDownValue(e.itemData.value)
      
  //   if(e?.itemData?.id){
  //     setListTemplatedropDownID(e?.itemData.id)
  //   getVisibleColumn(e?.itemData?.id)
    
  // }else{
  //   setListTemplatedropDownID(0
  //     )
  //     getListService()
  //   setVisibleFields(ColumnProps)
  //   // dispatch(gridColumnsList(ColumnProps))
  //   // dispatch(callDataBound(false))
  //   // setGroupColumn([])
  
  // }
      
  //   }
        }
      // useEffect(() => {
      //   console.log(listTemplatedropDownID)
      //   if(listTemplatedropDownID == '' || listTemplatedropDownID == 0){

        //     dispatch(callDataBound(false))
        //   }else{
      //     dispatch(callDataBound(true))

      //   }
      // }, [listTemplatedropDownID]);
  return (
    <>
    {/* <div className='col-sm-12'>
    fdfdfdfddfadfdferer
    </div> */}
    <div className={`template-list ${isToggled ? 'active' : 'inactive'}`}>
  {loadingDropDown ? (
    <div>... Loading Product Desc </div>
  ) : (
    <div className='drop-down-div'>
    <div className='template-dropdown-div'>

      <AutoCompleteComponent
        id="listTemplate" 
        dataSource={templateDropdownList}
        placeholder="Select template" 
        fields={ddlFields}
        floatLabelType='Auto' 
        highlight={true}
        select={listTemplateChange}
        change={listTemplateChange}
        // style={{width:'275px'}}
        value={listTemplatedropDownValue}
        blur={(e)=>{
          console.log(listTemplatedropDownValue)
          if(listTemplatedropDownID != 0){
            // setListTemplatedropDownValue('')
            // dispatch(callDataBound(true))
          }else{
            setTimeout(() => {
              setListTemplatedropDownValue('')
              // dispatch(callDataBound(false))
            }, 200);
          }
        }}
      />
      </div>
        <button onClick={(e) => { e.preventDefault(); 
        console.log(listTemplatedropDownID)
        console.log(listTemplatedropDownValue)
        
        
        
      if(listTemplatedropDownID != 0){
        
      getVisibleColumn(listTemplatedropDownID)
      }
    // }else{
      
    //     getListService()
    //   setVisibleFields(ColumnProps)
    //   // dispatch(gridColumnsList(ColumnProps))
    //   // dispatch(callDataBound(false))
    //   // setGroupColumn([])
    
    // }
        
      
        }} id='save-btn' style={{width:'80px'}}>Load</button>
        <button onClick={(e) => { e.preventDefault(); 
        console.log(listTemplatedropDownID)
        console.log(listTemplatedropDownValue)
        
        
        
      // if(listTemplatedropDownID != 0){
        
      // getVisibleColumn(listTemplatedropDownID)
      deleteTemplate(listTemplatedropDownValue)
      // }
    // }else{
      
    //     getListService()
    //   setVisibleFields(ColumnProps)
    //   // dispatch(gridColumnsList(ColumnProps))
    //   // dispatch(callDataBound(false))
    //   // setGroupColumn([])
    
    // }
        
      
        }} id='save-btn' style={{width:'80px'}}>Delete</button>
        <CheckBoxComponent
        name='DefaultTemplete'
      checked={defaultTemplete}
      onChange={(e)=>{
        console.log(e);
        setDefaultTemplete(e.target.checked);
      }}
      label='set Default'
      
        />
      <div style={{display:'flex',flexDirection:'column'}}>
      <RadioButtonComponent
        label="Portrait" 
        name="orientation"
        value="portrait"
        className='orientation'
        checked={selectedOrientation === 'Portrait'}
        // change={handleOrientationChange}
        style={{marginLeft:'10px'}}
      />
      <RadioButtonComponent
        label="Landscape"
        name="orientation"
        className='orientation'
        value="landscape"
        checked={selectedOrientation === 'Landscape'}
        // change={handleOrientationChange}
        style={{marginLeft:'10px'}}
      />
    </div>
    </div>
  )}
  {/* &nbsp;
  &nbsp;
  &nbsp;
  &nbsp; */}
  <div className='' style={{display:'flex' , justifyContent:'flex-start',alignItems:'flex-start'}}>
    <div>
    <TextBoxComponent
          type="text"
          placeholder="Template Name"
          id='templateName'
          name='TemplateName'
          change={(e)=>{
            console.log(e.value)
            console.log(templateDropdownList)
            let existValues = templateDropdownList.some(res=> e.value == res.value)
            setExistValue(existValues)
            
          }}
          blur={(e)=>{
            console.log(existValue)
            if(existValue){

              const TemplateNameInput = document.querySelector('input[name="TemplateName"]');
              TemplateNameInput.focus()
              TemplateNameInput.style.border = '2px solid red'
              setTimeout(() => {
                TemplateNameInput.style.border = 'none'
              }, 400);
            }
            }}
        />
    </div>
    {
      existValue ? 
      <div className='' id= 'check-contact-nos'>
        <div className='tip-content' id='tip-content-irtiza'>Template name already exists!</div>
      </div>
      : null
    }
    <button onClick={(e) => { e.preventDefault(); saveTemplate() }} id='save-btn'> Save Template</button>
    </div>
    {1== 3 ?<TextBoxComponent
      type="text"
      placeholder="Report Name"
      id='reportName'
    />:null}
    <div id='header-checkbox'>
      <RadioButtonComponent
        label="only data"
        name="headerCheck"
        value="headerOff"
        className='headerCheck'
        checked={headerCheck === false}
        // change={handleOrientationChange}
        style={{marginLeft:'10px'}}
      />
      <RadioButtonComponent
        label="with format"
        name="headerCheck"
        className='headerCheck'
        value="headerOn"
        checked={headerCheck === true}
        // change={handleOrientationChange}
        style={{marginLeft:'10px'}}
      />
    </div>
  {showDialogs && (
            <HardDelete
              {...deletingProps}
              showResult={(e) => {
                setShowDialogs(false);
                if (e === false) {
                  setShowDialogs(false);
                
                  getListTemplate(securityItemID)
                  getListService()
        
                  setVisibleFields(ColumnProps)
                  let filteredArr = ColumnProps.filter(obj => Object.keys(obj).length !== 0);
                  dispatch(gridColumnsList(filteredArr))
                  setVisibleSortFields([defaultSortColumnName]);
                  setVisibleSort([defaultSortColumnType]);
                  setListTemplatedropDownValue('')
                  setListTemplatedropDownID(0)
                }
              }}
            />
          )}
  {/* Additional code for 'template-save' section can be added here if needed */}
</div>
</>
    // <div className={`template-list ${isToggled ? 'active' : ''}`}>
    //   {loadingDropDown ? (
    //     <div>... Loading Product Desc </div>
    //   ) : (
    //     <div className='drop-down-div'>
    //       <AutoCompleteComponent
    //         id="listTemplate" 
    //         dataSource={templateDropdownList}
    //         placeholder="Select template" 
    //         fields={ddlFields}
    //         floatLabelType='Auto' 
    //         highlight={true}
    //         select={listTemplateChange}
    //         change={listTemplateChange}
    //         style={{width:'275px'}}
    //         value={listTemplatedropDownValue}
    //         blur={(e)=>{
    //           console.log(listTemplatedropDownValue)
    //           if(listTemplatedropDownID != 0){
    //             // setListTemplatedropDownValue('')
    //             // dispatch(callDataBound(true))
    //           }else{
    //             setTimeout(() => {
    //               // dispatch(callDataBound(false))
                  
               
    //             }, 200);
    //           }

    //         }}
    //       />
    //       <RadioButtonComponent
    //         label="Portrait" 
    //         name="orientation"
    //         value="portrait"
    //         className='orientation'
    //         checked={selectedOrientation === 'Portrait'}
    //         // change={handleOrientationChange}
    //         style={{marginLeft:'10px'}}
    //       />
    //       <RadioButtonComponent
    //         label="Landscape"
    //         name="orientation"
    //         className='orientation'
    //         value="landscape"
    //         checked={selectedOrientation === 'Landscape'}
    //         // change={handleOrientationChange}
    //       />
    //     </div>
    //   )}
    //   <div className='template-save'>
    //     <TextBoxComponent
    //       type="text"
    //       placeholder="Template Name"
    //       id='templateName'
    //       name='TemplateName'
    //       change={(e)=>{
    //         console.log(e.value)
    //         console.log(templateDropdownList)
    //         let existValues = templateDropdownList.some(res=> e.value == res.value)
    //         setExistValue(existValues)
            
    //       }}
    //       blur={(e)=>{
    //         console.log(existValue)
    //         if(existValue){

    //           const TemplateNameInput = document.querySelector('input[name="TemplateName"]');
    //           TemplateNameInput.focus()
    //           TemplateNameInput.style.border = '2px solid red'
    //           setTimeout(() => {
    //             TemplateNameInput.style.border = 'none'
    //           }, 400);
    //         }
    //         }}
    //     />
    //     {
    //                        existValue == true? 
    //                     <div className='' id= 'check-contact-nos'>
    //                     <div className='tip-content' id='tip-content-irtiza'>Template name already exists!
    //                     </div></div>:null
    //                     }
    //     <button onClick={(e) => { e.preventDefault(); saveTemplate() }} id='save-btn'> Save Template</button>
    //     {1== 3 ?<TextBoxComponent
    //       type="text"
    //       placeholder="Report Name"
    //       id='reportName'
          
    //     />:null}
    //     <div id='header-checkbox'>
    //       <RadioButtonComponent
    //         label="only data"
    //         name="headerCheck"
    //         value="headerOff"
    //         className='headerCheck'
    //         checked={headerCheck === false}
    //         // change={handleOrientationChange}
    //         style={{marginLeft:'10px'}}

    //       />
    //       <RadioButtonComponent
    //         label="with format"
    //         name="headerCheck"
    //         className='headerCheck'
    //         value="headerOn"
    //         checked={headerCheck === true}
    //         // change={handleOrientationChange}
    //       />
    //     </div>
    //   </div>
    //   <div className='template-save'>
    //     {/* Additional code for 'template-save' section can be added here if needed */}
    //   </div>
    // </div>
  );
};

export default TemplateCode;
