import React, { useEffect, useState , useRef, useMemo } from 'react';
// import CommissionCalculatorService from '../../services/product.service';
import SecurityService from '../security/security.service';
import PIService from '../../services/PI.service';
import LedgerService from '../../services/ledger.service';
import './ledger.scss';
import HardDelete from '../hard-delete/hard-delete';
// import CheckDuplicate  from '../check-duplicate/check-duplicate';
// import AuditTableInput from '../audit-table/audit-table';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperfties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import TransferUnAllocatedPaymentAdd from './Transfer-unAllocated-payment-add';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
// import {image} from './image';
// import logoUrl from './logo192.png';
import CommissionCalculatorService from '../../services/commission-calculator.service';
import EmployeeService from '../../services/employee.service';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';
import CurrencyAdd from '../currency/currency-add';
import supplierService from '../../services/supplier.service';
import currencyService from '../../services/currency.service';
// import { dataBind } from 'jodit/types/core/helpers';
import { decryptData } from '../decryption/decryption';
var selectedRows = []
export default function  SalesRecordList(props) {
 
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [salesAgent, setSalesAgent] = useState('');
  const [loadingSalesAgent, setLoadingSalesAgent] = useState('');
  const [salesAgentID, setSalesAgentID] = useState(0);
  const [salesAgentValue, setSalesAgentValue] = useState('');

  const [customerDataSource, setCustomerDataSource] = useState([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customerID, setCustomerID] = useState(0);
  const [customerName, setCustomerName] = useState('');
  
  const [supplierDataSource, setSupplierDataSource] = useState([]);
  const [loadingSupplier, setLoadingSupplier] = useState(false);
  const [supplierID, setSupplierID] = useState(0);
  const [supplierName, setSupplierName] = useState('');
  
  const [currencyDataSource, setCurrencyDataSource] = useState([]);
  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [currencyID, setCurrencyID] = useState(null);
  const [currencyName, setCurrencyName] = useState('');
  
  const [saleStatusDataSource, setSaleStatusDataSource] = useState([]);
  const [loadingSaleStatus, setLoadingSaleStatus] = useState(false);
  const [saleStatusID, setSaleStatusID] = useState(0);
  const [salesStatusName, setSaleStatusName] = useState('');
var data  = [];
  useEffect(() => {
    const toDateget = new Date(toDate);
    const formattedToDate = moment(toDateget).format('DD-MMM-yy');
      const fromDateget = new Date(fromDate);
    const formattedFromDate = moment(fromDateget).format('DD-MMM-yy');
     data = {
      FromDate : formattedFromDate,
      ToDate   : formattedToDate,
      SalesAgentID : salesAgentID,
      CustomerID : customerID,
      SupplierID : supplierID,
      CurrencyID : currencyID,
      SaleStatus : saleStatusID
    }
    
    // getLedger(data)
  }, [fromDate , toDate , salesAgentID , customerID , supplierID  , saleStatusID]);
// var img =  imagess;
 var backupCommissionCalculatorDataSource = []

  const [SalesRecordDataSource, setSalesRecordDataSource] = useState([]);
  const [isCommissionCalculator, setIsCommissionCalculator] = useState(false);
  
  
  const [showDropDown, setShowDropDown] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [deletingProps, setDeletingProps] = useState();
  // const [showDialog, setShowDialog] = useState(false);
  // const [showDialogs, setShowDialogs] = useState(false);
  // const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [proccessedVal, setProccessedVal] = useState('');
  // const [showDialogEmail, setShowDialogEmail] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  let SalesRecordGrid: Grid;
  const ddlFields: object = { value: 'value' };

  const toolbarClick = (args: ClickEventArgs) => {
    
    if (args?.item?.properties?.id === 'Cancel') {
  // 
  // to reload the grid
  // setUserSecurityItems([]);
  // getUserSecurityItems(userID);

  // SalesRecordGrid?.refresh();
  // SalesRecordGrid?.refreshDataSource();
      SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      if(isCommissionCalculator == true){
        getLedger();
      }else{
        getUnProcessedCommissionCalculator();
      }
    }
    if (args?.item?.properties?.id === 'Update') {
      
      
      CommissionCalculatorService.update(SalesRecordGrid.getCurrentViewRecords()).then((res)=>{
        
        SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
        if(isCommissionCalculator == true){
          getLedger();
        }else{
          getUnProcessedCommissionCalculator();
        }
      }).catch((e)=>{
        
  // SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      })
      SalesRecordGrid?.editModule?.batchSave();
  // 
  // SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MMM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.pdf`
      // };
      // 
      // SalesRecordGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_ledger.pdf`
    };
    
    
      
       SalesRecordGrid.pdfExport(exportProperties);

    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_ledger.xlsx`
    };
    SalesRecordGrid.excelExport(exportProperties);
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MMM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // // const customizeHeader = ({ element }) => {
      // //   // Insert the logo in the header
      // //   const logoImage = new Image();
      // //   logoImage.src = image;
      // //   logoImage.width = 100; // Adjust the width as per your requirement
      // //   logoImage.height = 50; // Adjust the height as per your requirement
    
      // //   // Add the logo to the header cell
      // //   const headerCell = element.sheet.rows[0].cells[0];
      // //   headerCell.appendChild(logoImage);
    
      // //   // Merge cells for the logo
      // //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // // };
      
      // SalesRecordGrid.excelExport(exportProperties);
      // // SalesRecordGrid.beforeDataBound(customizeHeader)
      
    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_columnchooser'
    ) {
    }
    if (SalesRecordGrid && args.item.properties.id === 'Delete') {
      
      if (SalesRecordGrid.selectedRowIndex === -1) {
        // no rows selected
        
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        // setDeletingProps(obj)
        // setShowDialogs(true)
       
      } else {
        
        // deleteDialog.current.hide();
        let obj={
          TableName:`TransferUnAllocatedPayment`,
          ColumnName:`TransferID`,
          RowID:SalesRecordGrid.getSelectedRecords()[0]?.TransferID,
          SelectedRow:true,
          SelectedRowData:{
            ...SalesRecordGrid.getSelectedRecords()[0]
          }
          
          }
        //   setDeletingProps(obj)
        
        // // deleteDialog.current.show();
        // setShowDialogs(true)
      }
    } 
  };

  useEffect(() => {
    // getLedger();

    SecurityService.getUserAccessList(userId).then((res)=>{
      
      const decryptedData = decryptData(res.data); // Decrypt response
                        
      
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByEmail(decryptedData[0].Email)
    });
    getCustomer()
    getSalesAgent()
    getSupplier()
    getCurrency()
    getCrmStatus()
  }, []);
const [setShow, setSetShow] = useState(false);
  const getLedger = async () => {
    setSetShow(true)
    const toDateget = new Date(toDate);
    const formattedToDate = moment(toDateget).format('DD-MMM-yy');
      const fromDateget = new Date(fromDate);
    const formattedFromDate = moment(fromDateget).format('DD-MMM-yy');
    let  datas = {
      FromDate : formattedFromDate,
      ToDate   : formattedToDate,
      SalesAgentID : salesAgentID,
      CustomerID : customerID,
      SupplierID : supplierID,
      CurrencyID : currencyID,
      SaleStatus : saleStatusID
    }
    // '${req.params.FromDate}' , '${req.params.ToDate}' , ${req.params.SalesAgentID} , ${req.params.CustomerID} , ${req.params.SupplierID} , ${req.params.CurrencyID} , ${req.params.SaleStatus}
    LedgerService.getAllLedger(datas.FromDate, datas.ToDate , datas.CustomerID , datas.CurrencyID )
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
      setIsCommissionCalculator(true)
        setSalesRecordDataSource(decryptedData);
        backupCommissionCalculatorDataSource =  decryptedData;
      })
      .catch((e) => {
        
      });
  };

  const getUnProcessedCommissionCalculator = async () => {
    // :customerid/:salesAgentID
    setProccessedVal('- UNPROCESSED')
    
    
    CommissionCalculatorService.getList(customerID, salesAgentID, 'UnProcessed')
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        setIsCommissionCalculator(false)
        setSalesRecordDataSource(decryptedData);
      })
      .catch((e) => {
        
      });
  };

  const getSalesAgent = async () => {
    setLoadingSalesAgent(true);
    EmployeeService.getEmployeeForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSalesAgent(mapData);
        setLoadingSalesAgent(false);
      })
      .catch((e) => {
        
      });
  };
 
  const getCurrency = async () => {
    setLoadingCurrency(true);
    currencyService.getCurrencyForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        // let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setCurrencyDataSource(decryptedData);
        setLoadingCurrency(false);
      })
      .catch((e) => {
        
      });
  };
 
  const getCrmStatus = async () => {
    setLoadingSaleStatus(true);
    PIService.getCRMStatusForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        // let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSaleStatusDataSource(decryptedData);
        setLoadingSaleStatus(false);
      })
      .catch((e) => {
        
      });
  };

  const getSupplier = async () => {
    setLoadingSupplier(true);
    supplierService.getSupplierForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        
        // let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSupplierDataSource(decryptedData);
        setLoadingSupplier(false);
      })
      .catch((e) => {
        
      });
  };

  const getCustomer = async (type: string) => {
    PIService.getCustomerForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
                        
        

        setCustomerDataSource(decryptedData)
        setLoadingCustomer(false)
        // dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:decryptedData,LoadingCustomer:false})
        
      })
      .catch((e) => {
        
        setCustomerDataSource([])
        setLoadingCustomer(true)
        // dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    // 'Add',
    // 'Edit',
    // {
    //   // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
    //   text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false},
    // 'Update',
    // 'Cancel',
    'PDFExport',
    'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
     {
      text: "Cancel", tooltipText: "Cancel", id: "Cancel", disabled: true
    },
    {
      text: "Update", tooltipText: "Update", id: "Update", disabled: true
    }
  ];

  const dialogTemplate = (props) => {
    
    return props.isAdd ? (
      // <div dataList={SalesRecordDataSource} {...props}> </div>
      // <TransferUnAllocatedPaymentAdd dataList={SalesRecordDataSource} {...props}> </TransferUnAllocatedPaymentAdd>
      <></>
      
    ) :
      (
        // <PIAdd dataList={productFinishSize} {...props}> </PIAdd>
        <></>
      );
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess('CommissionCalculatorPerUnitEdit'),
    // allowAdding: SecurityService.canUserAccess('CommissionCalculatorPerUnitAdd'),
    // allowDeleting: SecurityService.canUserAccess('CommissionCalculatorPerUnitDelete'),
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          // mode: 'Dialog',
          // showDeleteConfirmDialog: true,
          // template: dialogTemplate,
    // allowEditing: true,
    mode: 'dialog',
    showConfirmDialog: false      
    // headerTemplate: '<p></p>',
    // footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
    // columns:[{field:'CurrencyName',  direction: 'Descending' }]
    // columns:['CurrencyName']
  };

  const sortingOptions: SortSettingsModel = {
    // columns: [{ field: 'TransactionDate', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  // 
  
  const actionComplete = (args: DialogEditEventArgs) => {
    
    
    if (args?.requestType === 'columnstate') {
      
      SalesRecordGrid?.autoFitColumns();
    }
    if(args.requestType == "batchsave"){
      
    
    }
    if (args.form) {
      
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      // if (args.requestType === 'save'){
      //   
      //   const data = {
      //     CreatedByUserID : userId,
      //     ...args.data,
      //     TransferDate: moment(args.data.TransferDate).format('YYYY-MM-DD')
      //   }
      //   CommissionCalculatorService.addTransferUnAllocatedPayment(data)
      //     .then((response) => {
      //   getLedger()
      //     })
      // }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     
    //     // delete productFinishSize[0]

      
    //   CommissionCalculatorService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setSalesRecordDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     
    //     CommissionCalculatorService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    //                   
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getLedger();
    //         
    //   }

    }

  };
  const databound = () =>
  {
      if (SalesRecordGrid) {
        SalesRecordGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);
//       
//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };
let refreshColumn =(columnName) =>{
  if (SalesRecordGrid) {
    SalesRecordGrid.columns.forEach(column => {
      if (column.field === columnName) {
        SalesRecordGrid.refreshColumns(column);
      }
    });
  }
}
const handleCheckboxChange = (e,data) => {
   
   

   
  CommissionCalculatorService.updateApproveCommissionAmountPerUnit(data.PIID,userId).then((res)=>{

    let dataFin = SalesRecordGrid.map((Res)=>{
      if(data.PIID == Res.PIID){
        
        if(Res.PaymentVerificationStatus == true){
          Res.PaymentVerificationStatus = false;
          Res.CRMID = 0;
          Res.CRMName = null
          Res.PaymentVerificationStatusDate = null
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
 
        }else{
          Res.PaymentVerificationStatus = true;
          Res.CRMID = userId
          Res.CRMName = printedByUserName
          Res.PaymentVerificationStatusDate = new Date()
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
        }
      }
    })
    

  })
};

let RemarksUseRef =  useRef(null)
let FinalCommissionAmountPerUnitApprovedUseRef =  useRef(null)
  const LedgerGridComponent = useMemo(()=>{ return({ restrictPageSize = 12 }) => (


    <GridComponent
      ref={(grid) => (SalesRecordGrid = grid)}
      id='SalesRecordGrid'
      dataSource={SalesRecordDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
    
      actionComplete={actionComplete}
      allowTextWrap='true'
      // allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      // created={() => {
      //   SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
      // }}
      rowSelected={
        (args)=>{
          
          selectedRows = args.data
          
                    setTimeout(() => {
            
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='PI ID'
          field='PIID'
          allowEditing={false}
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        {/* SerialNo	
MonthYearPIDate	
SalesAgentName	
PIID	
	
PIReferenceNo	
SupplierName	
CustomerName	
CurrencyName	
BookAmount	
TotalReceiptAmount	
AdjustmentAmount	
CRMStatus	 */}

        <ColumnDirective
          headerText='Date'
          field='TransactionDate'
          allowEditing={false}
          width='135'
          headerTextAlign='CENTER'
          textAlign='right'
          // type='date'
          type='date'
          format={formatOptions}
          // format='dd/MM/yy'
        />
       
        <ColumnDirective
          headerText='Opening Balance'
          field='OpeningBalance'
          allowEditing={false}
          width='145'
          headerTextAlign='CENTER'
          textAlign='right'
          format={'n0'}
        />
        <ColumnDirective
          headerText='Debit'
          field='DebitAmount'
          allowEditing={false}
          width='145'
          headerTextAlign='CENTER'
          textAlign='right'
          format={'n0'}
        />
        <ColumnDirective
          headerText='Credit'
          field='CreditAmount'
          allowEditing={false}
          width='145'
          headerTextAlign='CENTER'
          textAlign='right'
          format={'n0'}
        />
        <ColumnDirective
          headerText='Balance'
          field='BalanceAmount'
          allowEditing={false}
          width='145'
          headerTextAlign='CENTER'
          textAlign='right'
          format={'n0'}
          template={(rowData) => {
            const balanceAmount = rowData.BalanceAmount;
            const formattedAmount = Math.abs(balanceAmount).toLocaleString();
            
            return balanceAmount < 0 ? `(${formattedAmount})` : formattedAmount;
          }}
        />
       
        <ColumnDirective
          headerText="Remarks"
          headerTextAlign='CENTER'    
          field='LedgerRemarks'
          allowEditing={false}
          width='200'
          textAlign='left'
          // format={formatOptions}
          // type='date'
          
        />
        <ColumnDirective
          headerText="Sales Agent"
          headerTextAlign='CENTER'    
          field='SalesAgentName'
          allowEditing={false}
          width='120'
          textAlign='left'
          // format={formatOptions}
          // type='date'
          
        />
        <ColumnDirective
          headerText="Sales Agent Date"
          headerTextAlign='CENTER'    
          field='SalesAgentDate'
          allowEditing={false}
          width='120'
          textAlign='right'
          // type='date'
          type='date'
          format={formatOptions}
          // format='dd/MM/yy'
          
        />
        <ColumnDirective
          headerText="CRM Name"
          headerTextAlign='CENTER'    
          field='CRMName'
          allowEditing={false}
          width='120'
          textAlign='left'
          // format={formatOptions}
          // type='date'
          
        />
        <ColumnDirective
          headerText="CRM Date"
          headerTextAlign='CENTER'    
          field='CRMDate'
          allowEditing={false}
          width='120'
          textAlign='right'
          type='date'
          format={formatOptions}
          // format='dd/MM/yy'
          
        />
        
      </ColumnsDirective>
       <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='CreditAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='DebitAmount'
                    type='Sum'
                    format={'n0'}
                    footerTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='CreditAmount'
                    type='Sum'
                    groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='DebitAmount'
                    type='Sum'
                    format={'n0'}
                    groupFooterTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  )},[SalesRecordDataSource , setShow == true]);

  return (
    (props.isDialog) ? (
      <div>
        <div className='drop-downs-div'>
        <div className='drop-downs-div'>
          <div className='required'>
        <DatePickerComponent
              id='FromDate'
              placeholder='Select From Date'
              floatLabelType='Auto'
              // min={minDate}
              name='FromDate'
              format='dd-MMM-yy'
              width={150}
              value={fromDate}
            change={(e)=>{
            
            setFromDate(e.value)
           }}
            />
          </div>
          <div className='required'>
        <DatePickerComponent
              id='ToDate'
              placeholder='Select To Date'
              floatLabelType='Auto'
              // min={minDate}
              name='ToDate'
              // format='dd-MMM-yy'
               format='dd-MMM-yy'

              width={150}
              value={toDate}
            change={(e)=>{
            
            setToDate(e.value)
           }}
            />
          </div>
          
           {loadingSalesAgent || 1==1 ? (
                          // <div>... Loading Sales Agent </div>
                          <></>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
<div className='required'>
              {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
                      </div>
{loadingSupplier || 1==1 ? (
                          // <div>... Loading Supplier </div>
                          <></>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Supplier'
                              dataSource={supplierDataSource}
                              placeholder='Select Supplier'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                              setSupplierID(0);
                              if (e?.itemData?.id) {
                                
                                setSupplierID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSupplierID(0);
                                if (e?.itemData?.id) {
                                  
                                  setSupplierID(e?.itemData?.id);
                                }
                              }}
                              value={supplierName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SupplierID'
                              floatLabelType='Auto'
                              value={supplierID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}


<div className='required'>
{loadingCurrency ? (
                          <div>... Loading Currency </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Currency'
                              dataSource={currencyDataSource}
                              placeholder='Select Currency'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setCurrencyName(e?.itemData?.value ? e?.itemData?.value : '');
                                setCurrencyID(0);
                              if (e?.itemData?.id) {
                                
                                setCurrencyID(e?.itemData?.id);
                              }else{
                                setCurrencyID(null);

                              }
                              }}
                              change={(e)=>{
                                setCurrencyName(e?.itemData?.value ? e?.itemData?.value : '');
                                setCurrencyID(0);
                                if (e?.itemData?.id) {
                                  
                                  setCurrencyID(e?.itemData?.id);
                                }else{
                                  setCurrencyID(null);

                                }
                              }}
                              value={currencyName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='CurrencyID'
                              floatLabelType='Auto'
                              value={currencyID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
                        </div>
{loadingSaleStatus  || 1==1 ? (
                          // <div>... Loading Sales Status </div>
                          // <div></div>
                          <></>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesStatus'
                              // type='hidden'
                              dataSource={saleStatusDataSource}
                              placeholder='Select Sale Status'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSaleStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSaleStatusID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSaleStatusID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSaleStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSaleStatusID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSaleStatusID(e?.itemData?.id);
                                }
                              }}
                              value={salesStatusName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesStatusID'
                              floatLabelType='Auto'
                              value={saleStatusID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}





            
                                                <button id='get-data-btn' onClick={getLedger}> Get Ledger</button>
                                                {/* <button id='get-data-btn' onClick={getUnProcessedCommissionCalculator}> Get UnProcessed Commission</button> */}

                        </div>
  
                        </div>
            
       {setShow == true  ? <LedgerGridComponent restrictPageSize={5} /> : null}
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            
            <div>
            
              <h3> Customer Ledger - List </h3>
              {showDropDown == true ?
              <>
        <div className='drop-downss-div'>
          <div className='required'>
          <DatePickerComponent
              id='FromDate1'
              placeholder='Select From Date'
              floatLabelType='Auto'
              // min={minDate}
              
              name='FromDate1'
              format='dd-MMM-yy'
              width={150}
              value={fromDate}
            change={(e)=>{
            
            setFromDate(e.value)
           }}
            />
            </div>
          <div className='required'>
        <DatePickerComponent
              id='ToDate1'
              placeholder='Select To Date'
              floatLabelType='Auto'
              // min={minDate}
              name='ToDate1'
              format='dd-MMM-yy'
              width={150}
              value={toDate}
            change={(e)=>{
            
            setToDate(e.value)
           }}
            />
            </div>
           {loadingSalesAgent || 1==1 ? (
                          // <div>... Loading Sales Agent </div>
                          <></>
                          // <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}

                      </div>
                      <div className='drop-downss-div'>
                      <div className='required'>
              {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
                      </div>
{loadingSupplier  || 1==1 ? (
                          // <div>... Loading Supplier </div>
                          <></>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Supplier'
                              dataSource={supplierDataSource}
                              placeholder='Select Supplier'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                              setSupplierID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSupplierID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSupplierID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSupplierID(e?.itemData?.id);
                                }
                              }}
                              value={supplierName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SupplierID'
                              floatLabelType='Auto'
                              value={supplierID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}


<div className='required'>
{loadingCurrency ? (
                          <div>... Loading Currency </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Currency'
                              dataSource={currencyDataSource}
                              placeholder='Select Currency'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setCurrencyName(e?.itemData?.value ? e?.itemData?.value : '');
                                setCurrencyID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setCurrencyID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setCurrencyName(e?.itemData?.value ? e?.itemData?.value : '');
                                setCurrencyID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setCurrencyID(e?.itemData?.id);
                                }
                              }}
                              value={currencyName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='CurrencyID'
                              floatLabelType='Auto'
                              value={currencyID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
                        </div>
{loadingSaleStatus || 1==1 ? (
                  //  <div>... Loading Sales Status </div>
                          // <div></div>
                   <></>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesStatus'
                              dataSource={saleStatusDataSource}
                              placeholder='Select Sale Status'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSaleStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSaleStatusID(0);
                              if (e?.e?.srcElement?.id) {
                                
                                setSaleStatusID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSaleStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                                setSaleStatusID(0);
                                if (e?.e?.srcElement?.id) {
                                  
                                  setSaleStatusID(e?.itemData?.id);
                                }
                              }}
                              value={salesStatusName}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesStatusID'
                              floatLabelType='Auto'
                              value={saleStatusID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}





            
                                                <button id='get-data-btn' 
                                                disabled={customerID != 0 && currencyID != null && toDate != '' && fromDate != '' ? false : true}
                                                
                                                onClick={getLedger}> Get Ledger</button>
                                                {/* <button id='get-data-btn' onClick={getUnProcessedCommissionCalculator}> Get UnProcessed Commission</button> */}

                        </div> </>: <></>}
              
              <div>
              </div>
              {setShow == true  ? <LedgerGridComponent /> : null}
            </div>
          </div>
          
        </div>
      )
  );
}
