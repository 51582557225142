import http from "./http-common";

class SalesSummaryService {
  
  getSalesSummary(FromDate , ToDate , ReportType ,SalesAgentID , SupplierID , CurrencyID , SaleStatus) {
    // http://localhost:5001/api/v1/SalesRecord/list/01-JAN-23/31-DEC-23/7/18/12/1/5
    // FromDate ToDate SalesAgentID  CustomerID SupplierID CurrencyID SaleStatus
    return http.get(`/SalesSummary/list/${FromDate}/${ToDate}/${SalesAgentID}/${ReportType}/${SupplierID}/${CurrencyID}/${SaleStatus}`);
  }
  
}

export default new SalesSummaryService();
