/* THIRD TIME  */
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'YOUR_SECRET_KEY'; // Same key used in the backend

export function decryptData(encryptedData) {
    try {
        const [ivPart, encryptedPart] = encryptedData.split(':');

        if (!ivPart || !encryptedPart) {
            throw new Error('Invalid encrypted data format');
        }

        const iv = CryptoJS.enc.Base64.parse(ivPart);
        const key = CryptoJS.SHA256(SECRET_KEY);

        const decrypted = CryptoJS.AES.decrypt(encryptedPart, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

        if (!decryptedText) {
            throw new Error('Decryption failed: Could not convert to UTF-8');
        }

        return JSON.parse(decryptedText); // Parse back to original data format
    } catch (error) {
        console.error('Decryption failed:', error.message);
        return null;
    }
}


/* THIRD TIME  */


// import CryptoJS from "crypto-js";

// const SECRET_KEY = "YOUR_32_CHARACTER_SECRET_KEY9089"; // Must match backend

// export function decryptData(encryptedData: string) {
//     try {
//         const [ivHex, encryptedText] = encryptedData.split(":");
//         const iv = CryptoJS.enc.Hex.parse(ivHex);
        
//         // Parse key to match backend formatting (convert to WordArray)
//         const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);

//         // Decrypt the data
//         const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
//             iv: iv,
//             mode: CryptoJS.mode.CBC,
//             padding: CryptoJS.pad.Pkcs7,
//         });

//         const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        
//         // Check if decryption was successful
//         if (!decryptedText) throw new Error("Decryption failed: Malformed data");

//         const data = JSON.parse(decryptedText);
//         console.log(data);
//         return data;
//     } catch (error) {
//         console.error("Decryption failed:", error.message);
//         return null;
//     }
// }

/* SECOND TIME  */
// import CryptoJS from "crypto-js";

// const SECRET_KEY = CryptoJS.enc.Utf8.parse("YOUR_32_CHARACTER_SECRET_KEY9089"); // Must match backend

// export function decryptData(encryptedData: string) {

//     console.log(encryptedData)
//     const [ivHex, encryptedText] = encryptedData.split(":");
//     const iv = CryptoJS.enc.Hex.parse(ivHex);

//     const decrypted = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY, {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7,
//     });

//     try {
//         let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
//         console.log(data);
//         return data;
//     } catch (error) {
//         console.error("Decryption failed:", error.message);
//         return null;
//     }
// }

/* FIRST TIME  */
// import CryptoJS from "crypto-js";

// const SECRET_KEY = "YOUR_32_CHARACTER_SECRET_KEY9089"; // Must match backend

// export function decryptData(encryptedData: string) {
//     const [ivHex, encryptedText] = encryptedData.split(":");
//     const iv = CryptoJS.enc.Hex.parse(ivHex);
//     const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    
//     const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7,
//     });

//     let data =  JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
//     console.log(data)
//     return data
// }
