import React, { useEffect, useState, useRef, useReducer } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import './PI.scss';
// import PIGrid from './pi-grid'
import HardDelete from '../hard-delete/hard-delete'
 import {decryptData} from '../decryption/decryption';
 

import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CustomerService from '../../services/customer.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import PIService from '../../services/PI.service';
import EmployeeService from '../../services/employee.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { CheckBox } from '@syncfusion/ej2-react-buttons';
import TransferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';
import CurrencyService from '../../services/currency.service';
import UserService from '../../services/users.service'

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
  actionBegin,
  // columnSelectionComplete,
} from '@syncfusion/ej2-react-grids';
import { set } from 'date-fns';
// import { format } from 'date-fns';
// import { Receipt } from '@material-ui/icons';
// import is from 'date-fns/esm/locale/is/index.js';

let paymentTypeElem: HTMLElement;
let paymentTypeObj: AutoComplete;
let salesAgentStatusElem: HTMLElement;
let salesAgentStatusObj: AutoComplete;
let designationNameElem: HTMLElement;
let designationNameObj: AutoComplete;
let paymentVerificationStatusElem: HTMLElement;
let paymentVerificationStatusObj: AutoComplete;
let cRMStatusElem: HTMLElement;
let cRMStatusObj: AutoComplete;
var piRefValue = ''
var saleCheck = 0

const complexReducer = (state, action) => {
    console.log(state)
    console.log(action)
  switch (action.type) {
    case 'PIID':
      return {...state,PIID: action.PIID  };
      break;
    case 'PINo':
      return {...state,PINo: action.PINo  };
      break; 
    case 'PIReferenceNo':
      return {...state, PIReferenceNo: action.PIReferenceNo};
      break;
    case 'PIDate':
      return {...state, PIDate: action.PIDate};
      break;
    case 'SupplierName':
      return {
        ...state,
       
        SupplierName:action.SupplierName,
        SupplierID:action.SupplierID
       };
       break;
    case 'SupplierDataSource':
      return {
      
        ...state,
        LoadingSupplier:action.LoadingSupplier,
        SupplierDataSource: action.SupplierDataSource,
       };
       break;
       case 'CustomerName':
        return {
          ...state,
         
          CustomerName:action.CustomerName,
          CustomerID:action.CustomerID
          

         };
       break;
    case 'CustomerDataSource':
      return {
        ...state,
       
        LoadingCustomer:action.LoadingCustomer,
        CustomerDataSource: action.CustomerDataSource,
       };
       break;
    case 'EmployeeDataSource':
      return {
        ...state,
       
        LoadingEmployee:action.LoadingEmployee,
        EmployeeDataSource: action.EmployeeDataSource,
       };
       break;
    case 'CountryCityPortDataSource':
      return {
        ...state,
       
        LoadingCountryCityPort:action.LoadingCountryCityPort,
        CountryCityPortDataSource: action.CountryCityPortDataSource,
       };
       break;
    case 'ShipmentTypeDataSource':
      return {
        ...state,
       
        LoadingShipmentType:action.LoadingShipmentType,
        ShipmentTypeDataSource: action.ShipmentTypeDataSource,
       };
       break;
    case 'CurrencyNameDataSource':
      return {
        ...state,
       
        LoadingCurrencyName:action.LoadingCurrencyName,
        CurrencyNameDataSource: action.CurrencyNameDataSource,
       };
       break;
    case 'PurchaseSourceDataSource':
      return {
        ...state,
       
        LoadingPurchaseSource:action.LoadingPurchaseSource,
        PurchaseSourceDataSource: action.PurchaseSourceDataSource,
       };
       break;
    case 'TradeBoardLetterStatusDataSource':
      return {
        ...state,
       
        LoadingTradeBoardLetterStatus:action.LoadingTradeBoardLetterStatus,
        TradeBoardLetterStatusDataSource: action.TradeBoardLetterStatusDataSource,
       };
       break;
       case 'EmployeeName':
        return {
          ...state,
         
          EmployeeName:action.EmployeeName,
          EmployeeID:action.EmployeeID
         };
         break;
       case 'ConsigneeName':
        return {
          ...state,
          ConsigneeName:action.ConsigneeName
         };
         break;
       case 'ConfirmConsigneeName':
        return {
          ...state,
          ConfirmConsigneeName:action.ConfirmConsigneeName
         };
         break;
       case 'CountryCityPort':
        return {
          ...state,
          CountryCityPort:action.CountryCityPort,
          CountryCityPortID:action.CountryCityPortID
         };
         break;
       case 'ShipmentType':
        return {
          ...state,
          ShipmentType:action.ShipmentType,
          ShipmentTypeID:action.ShipmentTypeID
         };
         break;
       case 'CurrencyName':
        return {
          ...state,
          CurrencyName:action.CurrencyName,
          CurrencyID:action.CurrencyID
         };
         break;
       case 'CommissionAmountPerUnit':
        return {
          ...state,
          CommissionAmountPerUnit:action.CommissionAmountPerUnit
          
         };
         break;
       case 'ApproveCommissionAmountPerUnit':
        return {
          ...state,
          ApproveCommissionAmountPerUnit:action.ApproveCommissionAmountPerUnit
          
         };
         break;
       case 'AbsoluteCommissionAmount':
        return {
          ...state,
          AbsoluteCommissionAmount:action.AbsoluteCommissionAmount
          
         };
         break;
       case 'ApproveAbsoluteCommissionAmount':
        return {
          ...state,
          ApproveAbsoluteCommissionAmount:action.ApproveAbsoluteCommissionAmount
          
         };
         break;
       case 'BookAmount':
        return {
          ...state,
          BookAmount:action.BookAmount
          
         };
       case 'BookAmt':
        return {
          ...state,
          BookAmt:action.BookAmt
          
         };
         break;
       case 'PrimaryCommissionPercentOnAdjustment':
        return {
          ...state,
          PrimaryCommissionPercentOnAdjustment:action.PrimaryCommissionPercentOnAdjustment
          
         };
         break;
       case 'ApprovePrimaryCommissionPercentOnAdjustment':
        return {
          ...state,
          // ApprovePrimaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment,
          ApprovePrimaryCommissionPercentOnAdjustment:false,
          // ApproveSecondaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment ? false : true
          ApproveSecondaryCommissionPercentOnAdjustment:false
          
         };
         break;
       case 'SecondaryCommissionPercentOnAdjustment':
        return {
          ...state,
          SecondaryCommissionPercentOnAdjustment:action.SecondaryCommissionPercentOnAdjustment
          
         };
         break;
       case 'ApproveSecondaryCommissionPercentOnAdjustment':
        return {
          ...state,
          // ApproveSecondaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment,
          ApproveSecondaryCommissionPercentOnAdjustment:false,
          // ApprovePrimaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment ? false: true,
          ApprovePrimaryCommissionPercentOnAdjustment:false,
         };
         break;
       case 'AdjustmentAmount':
        return {
          ...state,
          AdjustmentAmount:action.AdjustmentAmount
         };
         break;
       case 'BasePriceAgent':
        return {
          ...state,
          BasePriceAgent:action.BasePriceAgent
         };
       case 'BasePriceCompany':
        return {
          ...state,
          BasePriceCompany:action.BasePriceCompany
         };
       case 'ShowBasePriceCompany':
        return {
          ...state,
          IsShowBasePriceCompany:action.IsShowBasePriceCompany
         };
         break;
       case 'PurchaseSource':
        return {
          ...state,
          PurchaseSource:action.PurchaseSource,
          PurchaseSourceID:action.PurchaseSourceID
         };
         break;
       case 'TradeBoardLetterStatus':
        return {
          ...state,
          TradeBoardLetterStatus:action.TradeBoardLetterStatus,
          TradeBoardLetterStatusID:action.TradeBoardLetterStatusID
         };
         break;
       case 'Remarks':
        return {
          ...state,
          Remarks:action.Remarks,
         
         };
         break;
       case 'PaymentType':
        return {
          ...state,
          PaymentType:action.PaymentType,
          PaymentTypeID:action.PaymentTypeID,
         
         };
         break;
       case 'PaymentTypeDataSource':
        return {
          ...state,
          PaymentTypeDataSource:action.PaymentTypeDataSource
         };
         break;
      
       case 'ReceiptAmount':
        return {
          ...state,
          ReceiptAmount:action.ReceiptAmount
         };
         break;
         case 'SalesAgentStatus':
          return {
            ...state,
            SalesAgentStatus:action.SalesAgentStatus,
            SalesAgentStatusID:action.SalesAgentStatusID,
           
           };
           break;
         case 'SalesAgentStatusDataSource':
          return {
            ...state,
            SalesAgentStatusDataSource:action.SalesAgentStatusDataSource
           };
           break;
         case 'IsCRMDesignation':
          return {
            ...state,
            IsCRMDesignation:action.IsCRMDesignation
           };
         case 'IsSupervisorDesignation':
          return {
            ...state,
            IsSupervisorDesignation:action.IsSupervisorDesignation
           };
           break;
         case 'PaymentVerificationStatus':
          return {
            ...state,
            PaymentVerificationStatus:action.PaymentVerificationStatus
           };
           break;
         case 'DesignationNameDataSource':
          return {
            ...state,
            DesignationNameDataSource:action.DesignationNameDataSource
           };
           break;
         case 'CRMStatusDataSource':
          return {
            ...state,
            CRMStatusDataSource:action.CRMStatusDataSource
           };
           break;
         case 'ETA':
          return {
            ...state,
            ETA:action.ETA
           };
           break;
         case 'ETD':
          return {
            ...state,
            ETD:action.ETD
           };
           break;
           case 'DesignationName':
            return {
              ...state,
              DesignationName:action.DesignationName,
              DesignationID:action.DesignationID,
             
             };
             break;
           case 'CRMStatus':
            return {
              ...state,
              CRMStatus:action.CRMStatus,
              CRMStatusID:action.CRMStatusID,
             
             };
           case 'ReceiptMax':
            return {
              ...state,
              ReceiptMax:action.ReceiptMax,
              
             
             };
             break;
           case 'VoyageNo':
            return {
              ...state,
              VoyageNo:action.VoyageNo,
              
             
             };
             break;
             case 'BalanceUnAllocatedAmount':
        return {
          ...state,
         
          BalanceUnAllocatedAmount:action.BalanceUnAllocatedAmount,
      
        };
       break;
           case 'VesselName':
            return {
              ...state,
              VesselName:action.VesselName,
              
             
             };
             break;
           case 'CurrencySymbol':
            return {
              ...state,
              CurrencySymbol:action.CurrencySymbol,
              
             
             };
             break;
           case 'PaymentVerificationStatusDate':
            return {
              ...state,
              PaymentVerificationStatusDate:action.PaymentVerificationStatusDate,
              
             
             };
             break;
           case 'PaymentDetailDataSource':
            return {
              ...state,
              PaymentDetailDataSource:action.PaymentDetailDataSource,
              
             
             };
             break;
           case 'InitialPaymentPercent':
            return {
              ...state,
              InitialPaymentPercent:action.InitialPaymentPercent,
              
             
             };
             break;
           case 'InitialPaymentToleranceAmount':
            return {
              ...state,
              InitialPaymentToleranceAmount:action.InitialPaymentToleranceAmount,
              
             
             };
             break;
           case 'InitialPaymentToleranceAmount':
            return {
              ...state,
              InitialPaymentToleranceAmount:action.InitialPaymentToleranceAmount,
              
             
             };
           case 'GetInitialPaymentToleranceAmount':
            return {
              ...state,
              GetInitialPaymentToleranceAmount:action.GetInitialPaymentToleranceAmount,
              
             
             };
             break;
    default:
      return state;
  }
};
// let dataSoureGrid =[]
var storeInitialPaymentToleranceAmount = 0
var balanceUnAllocatedAmount = 0;
var PaymentTypeName = '';
var CheckReceiptMax =  0
var  IsMaxReceiptAmt = false
var  IsMaxShortAmt = false
var GenerateBookAmount = 0;
var temAmt = 0
var IsAddActionType =  false
var IsEditActionType =  false
var tempReceiptDate = false
let ProformaInvoiceDetailGrid: Grid
var isCrmStatusEdit = false
var  whenDestory = []
var holdBookAmt = 0
var initialPaymentToleranceAmount = 0
var isDuplicate = false
export default function PIAdd(props) {
var  isCrm =    props.type == "CRM" ? true : false;
  console.log('props',props)
  // console.log('props pi date ',  new Date(props.PIDate).toISOString().split('T')[0]
// )
  

  let PaymentDetailDataSourceTry = []

  const [state, dispatch] = useReducer(complexReducer, { 
    PIID:props.PIID,
    PIReferenceNo:props.PIReferenceNo ? props.PIReferenceNo : '',
     PINo: props.PINo ? props.PINo :'-',
     PIDate:props.PIDate ? props.PIDate : new Date(moment()),
    LoadingCustomer:true,
    CustomerDataSource: [],
    LoadingSupplier:true,
    SupplierDataSource: [],
    LoadingEmployee:true,
    EmployeeDataSource: [],
    LoadingCountryCityPort:true,
    CountryCityPortDataSource: [],
    LoadingShipmentType:true, 
    ShipmentTypeDataSource: [],
    LoadingCurrencyName:true,
    CurrencyNameDataSource: [],
    LoadingPurchaseSource:true,
    PurchaseSourceDataSource: [],
    LoadingTradeBoardLetterStatus:true,
    TradeBoardLetterStatusDataSource: [],
    PaymentVerificationStatusDataSource:[
      {id:1, value: 'Pending Verification'},
      {id:2, value: 'Verified'},
      {id:3, value: 'InValid'}
    ],
    SupplierRef: useRef(null),
    CustomerRef: useRef(null),
    EmployeeRef: useRef(null),
    CountryCityPortRef: useRef(null),
    ShipmentTypeRef: useRef(null),
    CurrencyNameRef: useRef(null),
    PurchaseSourceRef: useRef(null),
    TradeBoardLetterStatusRef: useRef(null),
    SupplierName: props.SupplierName ? props.SupplierName :'',
    CustomerName: props.CustomerEmail ? props.CustomerEmail :'',
    EmployeeName:props.SalesAgentName ? props.SalesAgentName :'',
    SupplierID:props.SupplierID ? props.SupplierID :0,
    CustomerID:props.CustomerID ? props.CustomerID :0,
    EmployeeID:props.SalesAgentID ? props.SalesAgentID :0,
    ConsigneeName: props.ConsigneeName ? props.ConsigneeName :'-',
    ConfirmConsigneeName:  props.ConfirmConsigneeName == "No" ? false : props.ConfirmConsigneeName == "Yes" ? true : false ,
    CountryCityPort:props.CountryCityPort ? props.CountryCityPort : '',
    CountryCityPortID:props.CountryCityPortID ? props.CountryCityPortID : '',
    ShipmentType: props.ShipmentType ? props.ShipmentType :'CNF',
    ShipmentTypeID: props.ShipmentTypeID  ? props.ShipmentTypeID : '1',
    CurrencyName: props.CurrencyName ? props.CurrencyName :'',
    CurrencySymbol: props.CurrencySymbol ? props.CurrencySymbol :'',
    CurrencyID:props.CurrencyID ? props.CurrencyID : '',
    CommissionAmountPerUnit: props.CommissionAmountPerUnit ? props.CommissionAmountPerUnit : '0',
    ApproveCommissionAmountPerUnit:props.ApproveCommissionAmountPerUnit ? props.ApproveCommissionAmountPerUnit :false,
    AbsoluteCommissionAmount:props.AbsoluteCommissionAmount ? props.AbsoluteCommissionAmount : '0',
    ApproveAbsoluteCommissionAmount:props.ApproveAbsoluteCommissionAmount? props.ApproveAbsoluteCommissionAmount :false,
    BookAmount: props.BookAmount ?props.BookAmount :'0',
    PrimaryCommissionPercentOnAdjustment:props.PrimaryCommissionPercentOnAdjustment ? props.PrimaryCommissionPercentOnAdjustment:0,
    ApprovePrimaryCommissionPercentOnAdjustment:false,
    SecondaryCommissionPercentOnAdjustment: props.SecondaryCommissionPercentOnAdjustment ? props.SecondaryCommissionPercentOnAdjustment : '0',
    ApproveSecondaryCommissionPercentOnAdjustment: false,
    AdjustmentAmount:props.AdjustmentAmount ? props.AdjustmentAmount :0,
    BasePriceAgent:props.BasePriceAgent ? props.BasePriceAgent :0,
    BasePriceCompany: props.BasePriceCompany ? props.BasePriceCompany :0,
    IsShowBasePriceCompany:false,
    PurchaseSource: props.PurchaseSource ? props.PurchaseSource :'',
    PurchaseSourceID: props.PurchaseSourceID ? props.PurchaseSourceID :0,
    TradeBoardLetterStatus:props.TradeBoardLetterStatus ? props.TradeBoardLetterStatus :'',
    TradeBoardLetterStatusID: props.TradeBoardLetterStatusID ? props.TradeBoardLetterStatusID : 1,
    Remarks:props.Remarks ? props.Remarks :'-',
    VesselName: props.VesselName ? props.VesselName :'-',
    VoyageNo:props.VoyageNo ? props.VoyageNo :'-',
    ETA: props.ETA != null? props.ETA  : '',
    ETD: props.ETD  != null? props.ETD  :  '',
    PaymentDetailDataSource:[],
    PaymentType:'',
    PaymentTypeID:'',
    PaymentTypeDataSource:[],
    ShortAmountRef:useRef(null),
    SalesAgentStatus:props.SalesAgentStatus  ? props.SalesAgentStatus :'',
    SalesAgentStatusID:props.SalesAgentStatusID ? props.SalesAgentStatusID :0,
    SalesAgentStatusDataSource:[],
    // SalesAgentStatusDate: props.SalesAgentStatusDate  ?  props.SalesAgentStatusDate  : new Date(moment()),
    SalesAgentStatusDate: new Date(moment()),
    IsCRMDesignation:props.type == "CRM" ? true : false,
    IsSupervisorDesignation:props.type == "Supervisor" ? true : false,
    ShortAmount: props.ShortAmount ? props.ShortAmount : 0,
    PaymentVerificationStatus:false,
    PaymentVerificationStatusID:1,
    // PaymentVerificationStatusDate:new Date(moment()),
    PaymentVerificationStatusDate:null,
    DesignationNameDataSource:[],
    DesignationName:'',
    DesignationID:null,
    CRMStatusDataSource:[],
    CRMStatus:props.CRMStatus ? props.CRMStatus :'',
    CRMStatusID:props.CRMStatusID ? props.CRMStatusID : 0,
    CRMStatusDate: props.CRMStatusDate ? props.CRMStatusDate : isCrm ? new Date(moment()) :  null,
    BalanceUnAllocatedAmount:null,
    ReceiptMax:100,
    InitialPaymentPercent: 0 ,
    InitialPaymentToleranceAmount: 0,
    GetInitialPaymentToleranceAmount: 0,
    BookAmt:props.BookAmount ? props.BookAmount : 0

    });
console.log(state)
  const [irtiza, setirtiza] = useState(10);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [piData, setPiData] = useState([]);
  const [dialogType, setDialogType] = useState('');
 
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  const [dataSoureGrid, setDataSoureGrid] = useState([]);
  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();
  const [PIBalance, setPIBalance] = useState(0);
let receiptRef = useRef(null)
let receiptDateRef = useRef(null)

useEffect(() => {
  // getEmployeeName( props.CustomerID )
   getEmployeeName()
  // getCustomerNameForConsigneeName(props.CustomerID )
  getCountryCityPortUsingCustomerID(props.CustomerID )
  if(props.isAdd == false){
    getPIBalanceByPIID()
    getCurrency(props.CustomerID )
  }
  console.log(new Date(moment()))
}, []);
    useEffect(() => {
      
      return () => {
        console.log('irtiza hassan rajput',whenDestory)
        
        let receiptArray = whenDestory.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ReceiptAmount }}).filter(item => item !== undefined)
        let shortArray = whenDestory.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ShortAmount }}).filter(item => item !== undefined)
        console.log(receiptArray)
        console.log(shortArray)
        const sumValue = receiptArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const shortArrayValue = shortArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        // let valueBookAmt = document.getElementById('BookAmount').value;
        var valueBookAmt =  state.BookAmount;
        console.log(sumValue)
        
        var sumGenerateBookAmout = sumValue - shortArrayValue
        console.log(sumGenerateBookAmout)
        console.log(holdBookAmt)
        let bookAmountGEN = holdBookAmt  -   initialPaymentToleranceAmount ; 
        console.log(initialPaymentToleranceAmount)
        console.log(bookAmountGEN)
        let resultOfSums = holdBookAmt == sumGenerateBookAmout || sumGenerateBookAmout  ==  bookAmountGEN;
        console.log(resultOfSums)
        props.popup(resultOfSums)
          //  if(resultOfSums ==  true && props.type == "CRM"){
            //  console.log(resultOfSums)
            //  let isHaveRelease = whenDestory.some((e)=>{e.SalesAgentStatus == "Release"  })
            //  let isHaveSurrenderBL = whenDestory.some((e)=>{e.SalesAgentStatus == "Surrender BL" })
            //  if(i)
            //  console.log(whenDestory)
             
            //  console.log(props.type)

            //  props.popup('Release')
          // }else{
          //    props.popup(resultOfSums)

          //  }
        // let generateBookAmount = 0
        // if (toString(valueBookAmt).includes(',')) {
        // console.log(valueBookAmt)
        //   // Remove the comma and convert it to a number
        //    let resultOfSums = Number(toString(valueBookAmt).replace(/,/g, '')) == sumGenerateBookAmout;
        //    console.log(resultOfSums)

        //   // generateBookAmount = Number(valueBookAmt.replace(/,/g, '')) - sumGenerateBookAmout;
        // } else {
        //   // Convert the string to a number and subtract
        //    let resultOfSums = Number(valueBookAmt) == sumGenerateBookAmout;
        //    console.log(resultOfSums)
        //   // generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
        // }
        // let generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
      //   console.log(shortArrayValue)
      //   console.log(generateBookAmount)
      // console.log( generateBookAmount -  shortArrayValue)
        // CheckReceiptMax = generateBookAmount  -  shortArrayValue ;
        // GenerateBookAmount =  args.ReceiptAmount ? args.ReceiptAmount : 0;
        // console.log(ProformaInvoiceDetailGrid.dataSource)
        // }
        // temAmt = args.ReceiptAmount ==  undefined ? 0 : args.ReceiptAmount
        // if(IsEditActionType == true){
//         var checkRecp = 0 ;
// console.log(shortArrayValue)
//         console.log(generateBookAmount)
// if (shortArrayValue >= 0) {
// // If shortArrayValue is non-negative, add it to generateBookAmount
// checkRecp = generateBookAmount + Math.abs(shortArrayValue);
// } else {
// // If shortArrayValue is negative, subtract its absolute value from generateBookAmount
// console.log(checkRecp)
// checkRecp = generateBookAmount + Math.abs(shortArrayValue);
// }
      };
    }, []);

    useEffect(() => {
      console.log(state.SupplierID)
      console.log(state.CurrencyID)
      if(state.SupplierID != '' && state.EmployeeID != ''){
        getPrimaryCommissionPercentOnAdjustment(state.SupplierID ,state.EmployeeID)
      }
      if(state.SupplierID != ''  && state.EmployeeID != ''){
        console.log(state.SupplierID)
        console.log(state.CurrencyID)
        // getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
      return  getCommissionAmountPerUnit(1 ,state.SupplierID ,state.EmployeeID)

      }
    }, [state.SupplierID ,state.CurrencyID ,state.EmployeeID]);
    useEffect(()=>{
      setTimeout(()=>{
        console.log(state.InitialPaymentToleranceAmount)
        return storeInitialPaymentToleranceAmount =  state.InitialPaymentToleranceAmount;
      },1000)
    },[state.InitialPaymentToleranceAmount])
    // useEffect(() => {
    //   console.log(state.SupplierID)
    //   console.log(state.CurrencyID)
    //   if(state.CurrencyID != '' && state.CustomerID != '' && state.PIDate != ''){
    //     getBalanceUnAllocatedAmount(state.PIDate,state.CurrencyID,state.CustomerID)
        
    //   }
      
    // }, [state.CurrencyID ,state.CustomerID, state.PIDate]);


  const textBoxChange = (e) => {
    console.log(e)
    dispatch({ type: e.target.name , [e.target.name]: e?.value })
   
};

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'SupplierName':
        dispatch({ type: 'SupplierName' ,SupplierName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'SupplierName' , 
            SupplierName: e?.itemData?.value ? e?.itemData?.value : '',
            SupplierID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                  getCommissionAmountPerUnit(1,e?.itemData?.id,state.EmployeeID)
                  // getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,e?.itemData?.id ,state.EmployeeID)
                    
                  break;
      case 'CustomerName':
        dispatch({ type: 'CustomerName' ,CustomerName: e?.itemData?.value ? e?.itemData?.value : ''})

        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CustomerName' , 
            CustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            CustomerID: e?.itemData?.id ? e?.itemData?.id : '',
           
                  })
                  // EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
                  // EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
                  getCurrency(e?.itemData?.id )
                  getEmployeeName( e?.itemData?.id )
                  getCustomerNameForConsigneeName(e?.itemData?.id )
                  getCountryCityPortUsingCustomerID(e?.itemData?.id )
                }
                  break;
      case 'EmployeeName':
        dispatch({ type: 'EmployeeName' ,EmployeeName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'EmployeeName' , 
            EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
            EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'CountryCityPort':
        dispatch({ type: 'CountryCityPort' ,CountryCityPort: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CountryCityPort' , 
            CountryCityPort: e?.itemData?.value ? e?.itemData?.value : '',
            CountryCityPortID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'ShipmentType':
        dispatch({ type: 'ShipmentType' ,ShipmentType: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'ShipmentType' , 
            ShipmentType: e?.itemData?.value ? e?.itemData?.value : '',
            ShipmentTypeID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'CurrencyName':
        dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CurrencyName' , 
            CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
            CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                  getCommissionAmountPerUnit(1,state.SupplierID,state.EmployeeID)
                  // getSecondaryCommissionPercentOnAdjustment(e?.itemData?.id ,state.SupplierID,state.EmployeeID)
                  setTimeout(() => {
                    CurrencyService.getCurrencySymbolForDD().then(res=>{
                        let symbolObj = res.data.recordset.filter(ress=>{ return ress.id == e?.itemData?.id});
                        console.log(symbolObj)
                        dispatch({type:`CurrencySymbol` , CurrencySymbol: symbolObj[0].value})
                      })
                    }, 200);
                  setTimeout(()=>{
                    PIService.getInitialPaymentPercent(state.CustomerID , e?.itemData?.id).then(res=>{
                        console.log(res)
                    dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: res.data.recordset[0].InitialPaymentPercent ?  res.data.recordset[0].InitialPaymentPercent : 0})
                    dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount: res.data.recordset[0].InitialPaymentToleranceAmount ?  res.data.recordset[0].InitialPaymentToleranceAmount : 0})
                    initialPaymentToleranceAmount = res.data.recordset[0].FinalPaymentToleranceAmount;
                    dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: 0})
                  }).catch((e)=>{
                    dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount:  0})
                    dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: 0})
                    dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: 0})

                    })
                  },1000)
                    break;
      case 'PurchaseSource':
        dispatch({ type: 'PurchaseSource' ,PurchaseSource: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'PurchaseSource' , 
            PurchaseSource: e?.itemData?.value ? e?.itemData?.value : '',
            PurchaseSourceID: e?.itemData?.id ? e?.itemData?.id : ''
                  })
                  if(e?.itemData?.value ==  'AUCTION'){
                    dispatch({ 
                      type: 'CommissionAmountPerUnit' , 
                      CommissionAmountPerUnit: 0
                    })
                  }
                }
                    break;
      case 'TradeBoardLetterStatus':
        dispatch({ type: 'TradeBoardLetterStatus' ,TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'TradeBoardLetterStatus' , 
            TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : '',
            TradeBoardLetterStatusID: e?.itemData?.id ? e?.itemData?.id : 1
                  })}
                    break;
      
            default:
        return state;
    }
   
   
};
const userId = SecurityService.getLoggedInUserId();
// const [isCrm, isCrm] = useState(false);
  
  useEffect(() => {
  setTimeout(() => {
    
  
    if(state.PINo  != '' || state.PIReferenceNo != ''  && checkDuplicate == true){
      console.log(props.dataList)
      console.log()
      var check = false
      if(props.isAdd ==  true){

         check = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
          PINo:state.PINo,
          PIReferenceNo:state.PIReferenceNo,
          
           })
      }else{
        let array = props.dataList.filter(obj => obj.PIID !== props.PIID)

        check = CheckDuplicate.withOutCombination(array,['PINo','PIReferenceNo'],{
          PINo:state.PINo,
          PIReferenceNo:state.PIReferenceNo,
          
           })
      }
     
      // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
       isDuplicate = check
      console.log(check)
      // setCheckDuplicate(check)
      if(!check ){
       if(
        state.PINo  !=  undefined
          && state.PINo  !=  ''
         && state.PIReferenceNo !=  undefined
         && state.PIReferenceNo !=  ''
         && state.PIDate !=  undefined 
         && state.SupplierName != '' 
         && state.CustomerName !=  '' 
         && state.EmployeeName != ''
         && state.ConsigneeName != undefined 
         && state.ConsigneeName != ''
         && state.CountryCityPort != '' 
         && state.ShipmentType  != '' 
         && state.CurrencyName != ''
         && state.BookAmount != undefined  
         && state.BookAmount != null 
         && state.AdjustmentAmount != null 
         && state.AdjustmentAmount != undefined 
         && state.AbsoluteCommissionAmount != null 
         && state.AbsoluteCommissionAmount != undefined 
         && state.SecondaryCommissionPercentOnAdjustment  != null 
         && state.SecondaryCommissionPercentOnAdjustment  != undefined 
         && state.PurchaseSource != '' 
         && state.TradeBoardLetterStatus != '' 
         && state.Remarks !=  undefined
         && state.Remarks !=  null
         && state.Remarks !=  ''
         && state.VesselName != null
         && state.VesselName != ''
         && state.VoyageNo !=  undefined
         && state.VoyageNo !=  ''
        ){

          if(ProformaInvoiceDetailGrid.dataSource.length > 0){
            console.log('hidden not' )

            document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
          }else{
            console.log('hidden1')

            document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
          }
        }else{
          console.log('hidden')

          console.log('state.PINo',state.PINo)
          console.log('state.PIReferenceNo',state.PIReferenceNo)
console.log('state.PIReferenceNo',state.PIReferenceNo)
console.log('state.PIDate',state.PIDate)
console.log('state.SupplierName',state.SupplierName)
console.log('state.CustomerName',state.CustomerName)
console.log('state.EmployeeName',state.EmployeeName)
console.log('state.ConsigneeName',state.ConsigneeName)
console.log('state.ConsigneeName',state.ConsigneeName)
console.log('state.CountryCityPort',state.CountryCityPort)
console.log('state.ShipmentType',state.ShipmentType)
console.log('state.CurrencyName',state.CurrencyName)
console.log('state.BookAmount',state.BookAmount)
console.log('state.BookAmount',state.BookAmount)
console.log('state.BookAmount',state.BookAmount)
console.log('state.AdjustmentAmount',state.AdjustmentAmount)
console.log('state.AdjustmentAmount',state.AdjustmentAmount)
console.log('state.AdjustmentAmount',state.AdjustmentAmount)
console.log('state.AbsoluteCommissionAmount',state.AbsoluteCommissionAmount)
console.log('state.AbsoluteCommissionAmount',state.AbsoluteCommissionAmount)
console.log('state.AbsoluteCommissionAmount',state.AbsoluteCommissionAmount)
console.log('state.SecondaryCommissionPercentOnAdjustment',state.SecondaryCommissionPercentOnAdjustment)
console.log('state.SecondaryCommissionPercentOnAdjustment',state.SecondaryCommissionPercentOnAdjustment)
console.log('state.SecondaryCommissionPercentOnAdjustment',state.SecondaryCommissionPercentOnAdjustment)
console.log('state.PurchaseSource',state.PurchaseSource)
console.log('state.TradeBoardLetterStatus',state.TradeBoardLetterStatus)
console.log('state.Remarks',state.Remarks)
console.log('state.Remarks',state.Remarks)
console.log('state.Remarks',state.Remarks)
console.log('state.VesselName',state.VesselName)
console.log('state.VesselName',state.VesselName)
console.log('state.VoyageNo',state.VoyageNo)
console.log('state.VoyageNo',state.VoyageNo)
          document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        }
        // setCheckDuplicateValue('')
         if(props.isAdd == false){
          document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';}
        
      }else{

        // setCheckDuplicateValue('Duplicate Value Detected!')
        document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        
    }
   }
  }, 500);
  }, [
    state.PINo ,
    state.PIReferenceNo ,
    state.PIDate, 
    state.SupplierName,
    state.CustomerName, 
    state.EmployeeName,
    state.ConsigneeName , 
    state.CountryCityPort, 
    state.ShipmentType , 
    state.CurrencyName ,
    state.BookAmount , 
    state.AdjustmentAmount, 
    state.AbsoluteCommissionAmount , 
    state.SecondaryCommissionPercentOnAdjustment , 
    state.PurchaseSource , 
    state.TradeBoardLetterStatus , 
    state.Remarks,
    state.VesselName,
    state.VoyageNo,
     ]);


useEffect(() => {
  
  return () => {

    props?.PIIDGet(state.PIID)
  };
}, [state.PIID]);

useEffect(() => {
//     setTimeout(() => {
//   if(state.PaymentDetailDataSource.length > 0){
//     document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
//   }else{
//     document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
//   }
// }, 800);
console.log(state.ConfirmConsigneeName)
setTimeout(() => {
  
  // props.ConfirmConsignee(state.ConfirmConsigneeName)
  console.log(props)
  
}, 500);
}, [state.ConfirmConsigneeName]);
  useEffect( async () => {
    setTimeout(() => {
      setCheckDuplicate(true)
      if(props.isAdd == false){
        getCurrency(props.CustomerID)
        document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }
    }, 1000);
    let SaleAgentID = await SecurityService.isSaleAgent(SecurityService.getLoggedInUserId());
    let saleAgentID = props.isAdd ==  false ? props.SalesAgentID : SaleAgentID.data.recordset[0]?.SaleAgentID
    getSupplier()
    getCustomer(saleAgentID)
    getEmployee()
    getAllEmployee()
    getCountryCityPort()
    getShipmentType()
    // getCurrency()
    getPurchaseSource()
    getTradeBoardLetterStatus()
    setTimeout(() => {
      getPaymentType()
      getSalesAgentStatus()
      getDesignation()
      getCRMStatus()
    }, 500);
    setTimeout(() => {
      
      // checkIsCRMDesignation()
      // checkIsSupervisorDesignation()
    }, 1500);
    // ProformaInvoiceDetailGrid.actionComplete()
    if(props.isAdd == false){
      holdBookAmt =  props.BookAmount
      setTimeout(()=>{
        PIService.getInitialPaymentPercent(state.CustomerID ,state.CurrencyID).then(res=>{
            console.log(res)
        dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: res.data.recordset[0].InitialPaymentPercent})
        dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount: res.data.recordset[0].InitialPaymentToleranceAmount})
        initialPaymentToleranceAmount = res.data.recordset[0].FinalPaymentToleranceAmount;
        // dispatch({ type: `BookAmt` , BookAmt: props.BookAmt - res.data.recordset[0].InitialPaymentToleranceAmount }) 
        dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: res.data.recordset[0].InitialPaymentToleranceAmount})
        let GenerateInitialPaymentToleranceAmount =  (res.data.recordset[0].InitialPaymentPercent / 100 *  props.BookAmount ) - res.data.recordset[0].InitialPaymentToleranceAmount
                            console.log(GenerateInitialPaymentToleranceAmount)
                            // dispatch({ type: `InitialPaymentToleranceAmount` , InitialsPaymentToleranceAmount: GenerateInitialPaymentToleranceAmount })  
      }).catch((e)=>{
        dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: 0})
        // dispatch({ type: `BookAmt` , BookAmt: props.BookAmt - 0 }) 
        initialPaymentToleranceAmount = 0
        dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount: 0})
        dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: 0})

        })
      },1000)
      
      dispatch({ type: `ReceiptMax` ,  ReceiptMax:props.BookAmount  ? props.BookAmount : 0})
    setTimeout(() => {
      console.log(props)
      dispatch({ type: `CommissionAmountPerUnit` ,  CommissionAmountPerUnit:props.CommissionAmountPerUnit  ? props.CommissionAmountPerUnit : 0})
      dispatch({ type: `SecondaryCommissionPercentOnAdjustment` ,  SecondaryCommissionPercentOnAdjustment:props.SecondaryCommissionPercentOnAdjustment ? props.SecondaryCommissionPercentOnAdjustment : 0})
      dispatch({ type: `PrimaryCommissionPercentOnAdjustment` ,  PrimaryCommissionPercentOnAdjustment:props.PrimaryCommissionPercentOnAdjustment ? props.PrimaryCommissionPercentOnAdjustment : 0})
      // getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
      // getPrimaryCommissionPercentOnAdjustment(state.SupplierID ,state.EmployeeID)
      // getCommissionAmountPerUnit(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
      console.log(props.PIID)
      
    }, 1000);
    setTimeout(()=>{
      // return PIService.getPaymentDetail(props.PIID)
      // .then((response) => {
      //   console.log('get Payment Detail response', response.data.recordset);
      //   // setPiData(response.data.recordset[0])
      //   // ProformaInvoiceDetailGrid.dataSourceChanged =  response.data.recordset[0];
      //   PaymentDetailDataSourceTry = response.data.recordset
      //   dispatch({ type: `PaymentDetailDataSource` ,  PaymentDetailDataSource:response.data.recordset})
  
      // })
      // .catch((e) => {
      //   console.log(e);
      //  dispatch({ type: `PaymentDetailDataSource`  , PaymentDetailDataSource:[]})
      // });
    return  getPaymentDetailForGrid(props.PIID)
    },1000)
  }
  setTimeout(() => {
    // console.log(dataSoureGrid)
  }, 4000);
    // getProductFinishSize();
    // getProductCategory();
    // getUOMs();
    // getProductDesc()

  }, []);
  // (function() {
  //   // Your code here
  //   if(props.isAdd == false){
  //     setTimeout(() => {
        
  //       return getPaymentDetailForGrid(props.PIID)
  //     }, 500);
  //   }
  // })();
// const [balanceUnAllocatedAmount, setBalanceUnAllocatedAmount] = useState(0);
// var balanceUnAllocatedAmount = 0
  const getBalanceUnAllocatedAmount = async () => {
    console.log( 'state.ToCustomerID,     state.CurrencyID,     state.TransferDate ' )
    console.log(state.TransferDate)
    let receiptDate =  document.getElementById('ReceiptDate').value
    console.log(receiptDate)
    // TransferUnAllocatedPaymentService.getBalanceUnAllocatedAmount(moment(receiptDate ).format('DD-MMM-YY'),state.CurrencyID, state.CustomerID)
    TransferUnAllocatedPaymentService.getBalanceUnAllocatedAmount(moment(receiptDate, 'DD-MM-YY').format('DD-MMM-YY') ,state.CurrencyID, state.CustomerID)
      .then((response) => {
        console.log('getBalanceUnAllocatedAmount', response.data.recordset);
        dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:response.data.recordset[0].BalanceAmount.toLocaleString()})
        balanceUnAllocatedAmount = response.data.recordset[0].BalanceAmount

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:null})
        balanceUnAllocatedAmount = null
      });
  };
  
  const getSecondaryCommissionPercentOnAdjustment = async (currencyID,supplierID,employeeID) => {
    PIService.getSecondaryCommissionPercentOnAdjustment(currencyID,supplierID,employeeID)
      .then((response) => {
       
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `SecondaryCommissionPercentOnAdjustment` ,  SecondaryCommissionPercentOnAdjustment:response.data.recordset[0].value ? response.data.recordset[0].value :0})

        console.log(state.SecondaryCommissionPercentOnAdjustment)
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `SecondaryCommissionPercentOnAdjustment`  , SecondaryCommissionPercentOnAdjustment:0})
        
      });
  };
  const getPrimaryCommissionPercentOnAdjustment = async (supplierID ,employeeID) => {
    PIService.getPrimaryCommissionPercentOnAdjustment(supplierID ,employeeID)
      .then((response) => {
       
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `PrimaryCommissionPercentOnAdjustment` ,  PrimaryCommissionPercentOnAdjustment:response.data.recordset[0].value ?response.data.recordset[0].value :0 })

        console.log(state.PrimaryCommissionPercentOnAdjustment)
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `PrimaryCommissionPercentOnAdjustment`  , PrimaryCommissionPercentOnAdjustment:0})
        
      });
  };
  const getCommissionAmountPerUnit = async (currencyID,supplierID,employeeID) => {
    PIService.getCommissionAmountPerUnit(1,supplierID,employeeID)
      .then((response) => {
       
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CommissionAmountPerUnit` ,  CommissionAmountPerUnit:response.data.recordset[0].value ?response.data.recordset[0].value :0 })

        console.log(state.CommissionAmountPerUnit)
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CommissionAmountPerUnit`  , CommissionAmountPerUnit:0})
        
      });
  };
  const getCustomerNameForConsigneeName = async (id) => {
    CustomerService.getCustomerNameForConsigneeName(id)
      .then((response) => {
                console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `ConsigneeName` ,  ConsigneeName:response.data.recordset[0].value})

        console.log(state.EmployeeID)
        
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `ConsigneeName`  , ConsigneeName:""})
      });
  };
  const getCountryCityPortUsingCustomerID = async (id) => {
    CustomerService.getCountryCityPortUsingCustomerID(id)
      .then((response) => {
                console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CountryCityPort` ,  CountryCityPort:response.data.recordset[0].value , CountryCityPortID:response.data.recordset[0].id})

        console.log(state.EmployeeID)
        
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CountryCityPort`  , CountryCityPort:""})
      });
  };
  const getCurrencyUsingCustomerID = async (id) => {
    CustomerService.getCurrencyUsingCustomerID(id)
      .then((response) => {
                console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CurrencyName` ,  CurrencyName:response.data.recordset[0].CurrencyName , CurrencyID:response.data.recordset[0].CurrencyID})

        console.log(state.EmployeeID)
        
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CurrencyName`  , CurrencyName:""})
      });
  };
  const getEmployeeName = async (id) => {
    if(props.isAdd == true){
    let SaleAgentID = await SecurityService.isSaleAgent(SecurityService.getLoggedInUserId());
    PIService.getEmployeeName(SaleAgentID.data.recordset[0]?.SaleAgentID)
      .then((response) => {
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData)
        dispatch({ type: `EmployeeName` ,  EmployeeName:decryptedData[0].value,EmployeeID : decryptedData[0].id})

        console.log(state.EmployeeID)
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `EmployeeName`  , EmployeeName:"", EmployeeID:""})
      });
    }
  };
  const getSupplier = async (type: string) => {
    PIService.getSupplierForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `SupplierDataSource` ,  SupplierDataSource:response.data.recordset,LoadingSupplier:false})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `SupplierDataSource` ,  SupplierDataSource:decryptedData,LoadingSupplier:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `SupplierDataSource`  , SupplierDataSource:[],LoadingSupplier:false})
      });
  };
  
  const getCustomer = async (id) => {
   
    PIService.getCustomerForDD(id)
      .then((response) => {
        console.log('productFinishSize get response', response.data);
        // dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:response.data.recordset,LoadingCustomer:false})
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
         dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:decryptedData,LoadingCustomer:false})
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };
  const getEmployee = async (type: string) => {
    EmployeeService.getEmployeeForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('productFinishSize get response', decryptedData);
        dispatch({ type: `EmployeeDataSource` ,  EmployeeDataSource:decryptedData,LoadingEmployee:false})
              })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `EmployeeDataSource`  , EmployeeDataSource:[],LoadingEmployee:false})
      });
  };
  const getAllEmployee = async (type: string) => {
    PIService.getAllEmployee()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('productFinishSize get response', decryptedData);
        console.log(userId)
        let isShowBasePriceCompany = decryptedData.filter((val)=> val.UserID == userId && val.DesignationID == 8).length == 1 ? true : false;
        console.log(isShowBasePriceCompany)       
        dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:isShowBasePriceCompany})
              })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:false})
      });
  };
  const getAllEmployeeUserName = async (type: string) => {
    PIService.getAllEmployee()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('productFinishSize get response', decryptedData);
        console.log(userId)
        // let employeeUserName = decryptedData.filter((val)=> val.UserID == userId && val.DesignationID == 2);
        let employeeUserName = decryptedData.filter((val)=> val.UserID == userId);
        console.log(employeeUserName)       
        //  dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:employeeUserName})
        dispatch({ type: `DesignationName` ,  DesignationID: employeeUserName[0]?.EmployeeID  ,DesignationName:employeeUserName[0]?.EmployeeName })
        document.getElementById('ProformaInvoiceDetailGridCRMName').value = employeeUserName[0]?.EmployeeName
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `DesignationName` ,  DesignationID:''  ,DesignationName:null })
        document.getElementById('ProformaInvoiceDetailGridCRMName').value = null
        // dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:false})
      });
  };
  const getCountryCityPort = async (type: string) => {
    PIService.getCountryCityPortForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `CountryCityPortDataSource` ,  CountryCityPortDataSource:response.data.recordset,LoadingCountryCityPort:false})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `CountryCityPortDataSource` ,  CountryCityPortDataSource:decryptedData,LoadingCountryCityPort:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CountryCityPortDataSource`  , CountryCityPortDataSource:[],LoadingCountryCityPort:false})
      });
  };
  const getShipmentType = async (type: string) => {
    PIService.getShipmentTypeForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `ShipmentTypeDataSource` ,  ShipmentTypeDataSource:response.data.recordset,LoadingShipmentType:false})

        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `ShipmentTypeDataSource` ,  ShipmentTypeDataSource:decryptedData,LoadingShipmentType:false})
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `ShipmentTypeDataSource`  , ShipmentTypeDataSource:[],LoadingShipmentType:false})
      });
  };

  const getCurrency = async (id) => {
    CustomerService.getCurrencyUsingCustomerID(id)
          .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:response.data.recordset,LoadingCurrencyName:false})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:decryptedData,LoadingCurrencyName:false})
        
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CurrencyNameDataSource`  , CurrencyNameDataSource:[],LoadingCurrencyName:false})
      });
  };
  const getPurchaseSource = async (type: string) => {
    PIService.getPurchaseSourceForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `PurchaseSourceDataSource` ,  PurchaseSourceDataSource:response.data.recordset,LoadingPurchaseSource:false})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `PurchaseSourceDataSource` ,  PurchaseSourceDataSource:decryptedData,LoadingPurchaseSource:false})
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `PurchaseSourceDataSource`  , PurchaseSourceDataSource:[],LoadingPurchaseSource:false})
      });
  };
  const getTradeBoardLetterStatus = async (type: string) => {
    PIService.getTradeBoardLetterStatusForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `TradeBoardLetterStatusDataSource` ,  TradeBoardLetterStatusDataSource:response.data.recordset,LoadingTradeBoardLetterStatus:false})
        
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `TradeBoardLetterStatusDataSource` ,  TradeBoardLetterStatusDataSource:decryptedData,LoadingTradeBoardLetterStatus:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `TradeBoardLetterStatusDataSource`  , TradeBoardLetterStatusDataSource:[],LoadingTradeBoardLetterStatus:false})
      });
  };
  const getPaymentType = async (type: string) => {
    PIService.getPaymentTypeForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `PaymentTypeDataSource` ,  PaymentTypeDataSource:response.data.recordset})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
         dispatch({ type: `PaymentTypeDataSource` ,  PaymentTypeDataSource:decryptedData})


      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `PaymentTypeDataSource`  , PaymentTypeDataSource:[]})
      });
  };
  const getSalesAgentStatus = async (type: string) => {
    PIService.getSalesAgentStatusForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `SalesAgentStatusDataSource` ,  SalesAgentStatusDataSource:response.data.recordset})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
         dispatch({ type: `SalesAgentStatusDataSource` ,  SalesAgentStatusDataSource:decryptedData})


      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `SalesAgentStatusDataSource`  , SalesAgentStatusDataSource:[]})
      });
  };
  const getDesignation = async (type: string) => {
    PIService.getDesignationForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `DesignationNameDataSource` ,  DesignationNameDataSource:response.data.recordset})
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `DesignationNameDataSource` ,  DesignationNameDataSource:decryptedData})


      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `DesignationNameDataSource`  , DesignationNameDataSource:[]})
      });
  };
  const getCRMStatus = async (type: string) => {
    PIService.getCRMStatusForDD()
      .then((response) => {
        // console.log('productFinishSize get response', response.data.recordset);
        // dispatch({ type: `CRMStatusDataSource` ,  CRMStatusDataSource:response.data.recordset})

        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        dispatch({ type: `CRMStatusDataSource` ,  CRMStatusDataSource:decryptedData})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CRMStatusDataSource`  , CRMStatusDataSource:[]})
      });
  };
  const getPaymentDetailForGrid = async (id) => {
    PIService.getPaymentDetail(id)
      .then((response) => {
        console.log('productFinishSize get response', response.data);
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log(decryptedData);
        console.log('get Payment Detail response', decryptedData);
        whenDestory = decryptedData;
  return       dispatch({ type: `PaymentDetailDataSource` ,  PaymentDetailDataSource:[...decryptedData]})
  // setDataSoureGrid( decryptedData)
  // ProformaInvoiceDetailGrid.dataSource = [...decryptedData]
      })
      .catch((e) => {
        console.log(e);
        // dispatch({ type: `PaymentDetailDataSource`  , PaymentDetailDataSource:[]})
        // setDataSoureGrid( [])
      });
  };
  const checkIsCRMDesignation = async (type: string) => {
    // PIService.getAllEmployee()
    UserService.getAll()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        console.log(userId)
        // let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId && val.DesignationID == 2).length == 1 ? true : false;
        let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId).length == 1 ? true : false;
        console.log(isCRMDesignation)       
        // dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:props.isAdd ? false : isCRMDesignation})
        isCrm = isCRMDesignation;
        dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:isCRMDesignation})
              })
      .catch((e) => {
        console.log(e);
        isCrm = false;

        dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:false})
      });
  };
  
  const checkIsSupervisorDesignation = async () => {
    // PIService.getAllEmployee()
    UserService.getAll()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        console.log(userId)
        let isSupervisorDesignation = response.data.recordset.filter((val)=> val.UserID == userId ).length == 1 ? true : false;
        console.log(isSupervisorDesignation)       
        // dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:props.isAdd ? false : isSupervisorDesignation})
        dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:isSupervisorDesignation})
              })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:false})
      });
  };
  
  const getPIBalanceByPIID = async () => {
    console.log(props.PIID)
    PIService.getPIBalance(props.PIID)
      .then((response) => {
        console.log('getPIBalance  response', response.data.recordset);
        setPIBalance(response.data.recordset[0].BalancePIAmount  == null ? 0 : response.data.recordset[0].BalancePIAmount)
      })
      .catch((e) => {
        console.log(e);
        setPIBalance(0)
        
      });
  };


  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      // case 'ProductCategory':
      //   // getProductCategory();
      // break;
      // case 'ProductDesc':
      //   // getProductDesc();
      // break;
      // case 'UnitOfMeasurement':
      //   // getUOMs();
      // break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }


let productDescRef = useRef(null)
      let uomRef = useRef(null)
      
  // const minDate: Date = new Date(moment().startOf('day'));
  const minDate: Date = new Date();
  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Normal',
    // allowEditing: SecurityService.canUserAccess('ProductEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
  }
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    {
      text: "Delete", tooltipText: "Delete", id: "ProformaInvoiceDetailGrid_delete", disabled:false
    },
   "Update",
    "Cancel",
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];
   const toolbarClick = (args: ClickEventArgs) => {
    console.log(args)
    if (ProformaInvoiceDetailGrid && args.item.properties.id === "ProformaInvoiceDetailGrid_add") {
      IsAddActionType =  true;
    }
    if (ProformaInvoiceDetailGrid && args.item.properties.id === "ProformaInvoiceDetailGrid_edit") {
      IsEditActionType =  true;
    }
    if (ProformaInvoiceDetailGrid && args.item.properties.id === 'ProformaInvoiceDetailGrid_delete') {
      console.log('toolbar Delete');
      if (ProformaInvoiceDetailGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', ProformaInvoiceDetailGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProformaInvoice_PaymentDetail`,
          ColumnName:`ProformaInvoicePaymentDetailID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        if(props.isAdd == true){
        console.log('delete: ', ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`ProformaInvoice_PaymentDetail`,
          ColumnName:`ProformaInvoicePaymentDetailID`,
          RowID:ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID,
          SelectedRow:true,
          SelectedRowData:ProformaInvoiceDetailGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
        }else if(props.isAdd == false){
          if(ProformaInvoiceDetailGrid.currentViewData.length == 1){
            let obj={
              TableName:`ProformaInvoice_PaymentDetail`,
              ColumnName:`ProformaInvoicePaymentDetailID`,
              RowID:undefined,
              SelectedRow:false,
              UnSelectedRowText:'THIS ROW CANNOT BE DELETED. PLEASE CONTACT ADMINISTRATOR'
              
            }
            setDeletingProps(obj)
            setShowDialogs(true)
          }else{
            console.log('delete: ', ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID)
            // deleteDialog.current.hide();
            let obj={
              TableName:`ProformaInvoice_PaymentDetail`,
              ColumnName:`ProformaInvoicePaymentDetailID`,
              RowID:ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID,
              SelectedRow:true,
              SelectedRowData:ProformaInvoiceDetailGrid.getSelectedRecords()[0]
              
              }
              setDeletingProps(obj)
            
            // deleteDialog.current.show();
            setShowDialogs(true)
          }
        }
      }
    }
  }
  const sortingOptions: SortSettingsModel = {
    // columns: [{ field: 'ProformaInvoicePaymentDetailID', direction: 'Descending' }]
    columns: [{ field: 'ProformaInvoicePaymentDetailID', direction: 'Ascending' }]
  }

  
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
  const [salesAgentStatus, setSalesAgentStatus] = useState('');
  const [salesAgentStatusID, setSalesAgentStatusID] = useState('');
 
  const [crmStatusID, setCrmStatusID] = useState(null);
  const [crmStatus, setCrmStatus] = useState(null);
  const [crmStatusDate, setCrmStatusDate] = useState(null);
  const actionComplete = (args: DialogEditEventArgs) => {
    console.log("data: ", args);
    console.log("data: ", args);
    console.log("data: ",  salesAgentStatusObj )
    if (args.form) {                     
      console.log("PaymentVerificationStatus: ", state.PaymentVerificationStatusDate);
      console.log("PaymentVerificationStatusID: ", state.PaymentVerificationStatusID);
      console.log("data: ", state);
      console.log("data: ", args);
      console.log("data: ",  crmStatusID  )
      console.log("data: ",  crmStatus  )
      console.log("data: ",  crmStatusDate  )
      console.log("data: ",  salesAgentStatusID  )
      console.log("data: ",  salesAgentStatus  )
      // if (args.requestType === "beginEdit" || args.requestType === "add"  &&  state.SalesAgentStatusID  != '' &&  state.SalesAgentStatusID  != undefined &&  state.SalesAgentStatusID  != null) {
        console.log(props.type)
        console.log(saleCheck)
      if (args.requestType === "beginEdit" || args.requestType === "add"  ) {
        console.log(props.type)
        if(props.type != 'CRM'){
        console.log('attaching blur event complete', document.getElementById('ShortAmount'));
        document
          .getElementById("ShortAmount")
          ?.addEventListener("blur", (e) => {
          
            if (
              saleCheck != 0
          ) {
            console.log('crm not blur')
            setTimeout(() => ProformaInvoiceDetailGrid?.endEdit(), 200);
          
          }
          });
      }else if(props.type == 'CRM'){
        
        document
          .getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus")
          ?.addEventListener("blur", (e) => {
          
            if (
              saleCheck != 0
          ) {
            console.log('crm blur')
            setTimeout(() => ProformaInvoiceDetailGrid?.endEdit(), 200);
          
          }
          });
      }
      }
      console.log("data: ", args);
    
    if(args.requestType ==  'save' || args.requestType == 'cancel'){
        setTimeout(() => {
          return document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';

          // return  getPaymentDetailForGrid(1006)
          }, 1000);
          
    }
    if(args.requestType ==  'save'){ 
      
      setTimeout(() => {
        dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:null})
        
        // document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        document.getElementById('error-1').style.display ='none'
        document.getElementById('error-2').style.display ='none'
      }, 200);
      setTimeout(() => {
        // ProformaInvoiceDetailGrid.refresh()
        console.log(state.PIID)
        if(state.PIID != undefined){

          getPaymentDetailForGrid(state.PIID)
        }
        
        // 0document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 1000);
    }
      
   
    if (args.requestType === 'save' && args.action ==  'add' && props.isAdd == true) {
      IsAddActionType = false
      console.log(args)
      const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const curtDate = `${year}-${month}-${day}`;
//       const inputDate = state.PaymentVerificationStatusDate;
// const parts = inputDate.split('/');
// const formattedDateAddDash = parts[2] + '-' + parts[1] + '-' + parts[0];

// console.log(formattedDateAddDash);
      let data = {
      PIID:state.PIID,
      PINo:state.PINo,	
      PIDate:moment(state.PIDate).format('YYYY-MM-DD'),	
      SupplierID:state.SupplierID,
      CommissionAmountPerUnit:state.CommissionAmountPerUnit,	
      ApproveCommissionAmountPerUnit:state.ApproveCommissionAmountPerUnit,	
      AbsoluteCommissionAmount:state.AbsoluteCommissionAmount,	
      ApproveAbsoluteCommissionAmount:state.ApproveAbsoluteCommissionAmount,	
      CustomerID:state.CustomerID,	
      SalesAgentID:state.EmployeeID,	
      ConsigneeName:state.ConsigneeName,	
      ConfirmConsigneeName:state.ConfirmConsigneeName,
      CountryCityPortID:state.CountryCityPortID,	
      ShipmentTypeID:state.ShipmentTypeID,	
      BookAmount:state.BookAmount,	
      CurrencyID:state.CurrencyID,	
      PrimaryCommissionPercentOnAdjustment:state.PrimaryCommissionPercentOnAdjustment,	
      // ApprovePrimaryCommissionPercentOnAdjustment:state.ApprovePrimaryCommissionPercentOnAdjustment,	
      ApprovePrimaryCommissionPercentOnAdjustment:false,	
      SecondaryCommissionPercentOnAdjustment:state.SecondaryCommissionPercentOnAdjustment,	
      // ApproveSecondaryCommissionPercentOnAdjustment:state.ApproveSecondaryCommissionPercentOnAdjustment,	
      ApproveSecondaryCommissionPercentOnAdjustment:false,
      AdjustmentAmount:state.AdjustmentAmount,	
      BasePriceAgent:state.BasePriceAgent,	
      BasePriceCompany:state.BasePriceCompany,	
      PurchaseSourceID:state.PurchaseSourceID,	
      TradeBoardLetterStatusID:state.TradeBoardLetterStatusID || 1,	
      Remarks:state.Remarks,	
      CreationDate:state.CreationDate,	
      CreatedByUserID:state.CreatedByUserID,	
      VesselName:state.VesselName,	
      VoyageNo:state.VoyageNo,	
      ETD:state.ETD != '' ?state.ETD : null,	
      ETA:state.ETA != '' ? state.ETA : null,	
      PIReferenceNo:state.PIReferenceNo,
      ShortAmount:state.ShortAmount,
      ...args.data,
      ReceiptDate:moment(args.data.ReceiptDate).format('YYYY-MM-DD'),	

      PaymentTypeID: state.PaymentTypeID,
      // SalesAgentStatusID: state.SalesAgentStatusID,
      SalesAgentStatusID: null,

      PaymentVerificationStatus:args.data.PaymentVerificationStatusID == 2 ? true : args.data.PaymentVerificationStatusID == 3 ? false : null,
      // PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      CreationDate:new Date(moment()),
      CreatedByUserID:userId,
      DesignationName:null,
      CRMStatusDate:args.data.CRMStatusDate ? args.data.CRMStatusDate : null ,
      CRMStatus:args.data.CRMStatus ? args.data.CRMStatus : null ,
      CRMID:args.data.CRMID ? args.data.CRMID : null ,
      CRMID:state.DesignationID,
      CRMStatus:crmStatus,
      CRMStatusID:null,
      // CRMID:userId,

      // CRMStatusID:crmStatusID,
      CRMStatusDate:crmStatusDate ,
      // PaymentDescription:'-',
      // PaymentReferenceNo:'-'

    }
    console.log(data)
    PIService.addProformaInvoice(data).then((res)=>{
      console.log(res)
      //  whenDestory = ProformaInvoiceDetailGrid.dataSource
      getPaymentDetailForGrid(res.data.params.id)
      dispatch({ type: `PIID`  , PIID:res.data.params.id})
      ProformaInvoiceDetailGrid.refresh()
      setTimeout(() => {
        return document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 300);

    }).catch((e)=>{
      console.log(e)
      if(ProformaInvoiceDetailGrid.dataSource.length <= 0){
       //document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
      }
    })
  }
    if (args.requestType === 'save' && args.action ==  'add' && props.isAdd == false) {
      IsAddActionType = false
      console.log(args)
      const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const curtDate = `${year}-${month}-${day}`;
//       const inputDate = state.PaymentVerificationStatusDate;
// const parts = inputDate.split('/');
// const formattedDateAddDash = parts[2] + '-' + parts[1] + '-' + parts[0];

// console.log(formattedDateAddDash);
      let data = {
      PIID:state.PIID,
      PINo:state.PINo,	
      PIDate:moment(state.PIDate).format('YYYY-MM-DD'),	
      SupplierID:state.SupplierID,
      CommissionAmountPerUnit:state.CommissionAmountPerUnit,	
      ApproveCommissionAmountPerUnit:state.ApproveCommissionAmountPerUnit,	
      AbsoluteCommissionAmount:state.AbsoluteCommissionAmount,	
      ApproveAbsoluteCommissionAmount:state.ApproveAbsoluteCommissionAmount,	
      CustomerID:state.CustomerID,	
      SalesAgentID:state.EmployeeID,	
      ConsigneeName:state.ConsigneeName,	
      ConfirmConsigneeName:state.ConfirmConsigneeName,
      CountryCityPortID:state.CountryCityPortID,	
      ShipmentTypeID:state.ShipmentTypeID,	
      BookAmount:state.BookAmount,	
      CurrencyID:state.CurrencyID,	
      PrimaryCommissionPercentOnAdjustment:state.PrimaryCommissionPercentOnAdjustment,	
      // ApprovePrimaryCommissionPercentOnAdjustment:state.ApprovePrimaryCommissionPercentOnAdjustment,	
      ApprovePrimaryCommissionPercentOnAdjustment:false,	
      SecondaryCommissionPercentOnAdjustment:state.SecondaryCommissionPercentOnAdjustment,	
      // ApproveSecondaryCommissionPercentOnAdjustment:state.ApproveSecondaryCommissionPercentOnAdjustment,	
      ApproveSecondaryCommissionPercentOnAdjustment:false,
      AdjustmentAmount:state.AdjustmentAmount,	
      BasePriceAgent:state.BasePriceAgent,	
      BasePriceCompany:state.BasePriceCompany,	
      PurchaseSourceID:state.PurchaseSourceID,	
      TradeBoardLetterStatusID:state.TradeBoardLetterStatusID || 1,	
      Remarks:state.Remarks,	
      CreationDate:state.CreationDate,	
      CreatedByUserID:state.CreatedByUserID,	
      VesselName:state.VesselName,	
      VoyageNo:state.VoyageNo,	
      ETD:state.ETD != '' ?state.ETD : null,	
      ETA:state.ETA != '' ? state.ETA : null,	
      PIReferenceNo:state.PIReferenceNo,
      ShortAmount:state.ShortAmount,
      ...args.data,
      ReceiptDate:moment(args.data.ReceiptDate).format('YYYY-MM-DD'),	

      PaymentTypeID: state.PaymentTypeID,
      // SalesAgentStatusID: state.SalesAgentStatusID,
      SalesAgentStatusID: null,

      PaymentVerificationStatus:args.data.PaymentVerificationStatusID == 2 ? true : args.data.PaymentVerificationStatusID == 3 ? false : null,
      // PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      CreationDate:new Date(moment()),
      CreatedByUserID:userId,
      DesignationName:null,
      CRMStatusDate:args.data.CRMStatusDate ? args.data.CRMStatusDate : null ,
      CRMStatus:args.data.CRMStatus ? args.data.CRMStatus : null ,
      CRMID:args.data.CRMID ? args.data.CRMID : null ,
      CRMID:state.DesignationID,
      CRMStatus:crmStatus,
      CRMStatusID:null,
      // CRMID:userId,

      // CRMStatusID:crmStatusID,
      CRMStatusDate:crmStatusDate ,
      // PaymentDescription:'-',
      // PaymentReferenceNo:'-'
        PIID : props.PIID
    }
    console.log(data)
    PIService.addProformaInvoiceGrid(data).then((res)=>{
      console.log(res)
      //  whenDestory = ProformaInvoiceDetailGrid.dataSource
      getPaymentDetailForGrid(res.data.params.id)
      dispatch({ type: `PIID`  , PIID:res.data.params.id})
      ProformaInvoiceDetailGrid.refresh()
      setTimeout(() => {
        return document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 300);

    }).catch((e)=>{
      console.log(e)
      // if(ProformaInvoiceDetailGrid.dataSource.length <= 0){
      //  document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
      // }
    })
  }
  if (args.requestType === 'save' && args.action ==  'edit') {
    IsEditActionType = false
    const inputDate = state.PaymentVerificationStatusDate;
  
// const parts = inputDate.split('/');
// const formattedDateAddDash = parts[2] + '-' + parts[1] + '-' + parts[0];

// console.log(formattedDateAddDash);

    let data = {
      PIID:state.PIID,
        ...args.data,
        PIDate:moment(state.PIDate).format('YYYY-MM-DD'),	
      PaymentVerificationStatus:state.PaymentVerificationStatus,
      // PaymentVerificationStatusDate:formattedDateAddDash,
      PaymentVerificationStatusDate:state.PaymentVerificationStatusDate,
      PaymentTypeID: state.PaymentTypeID,
      // SalesAgentStatusID : state.SalesAgentStatusID,
      SalesAgentStatusID : null,
      CRMID:args.data.DesignationID,
      CreationDate:new Date(moment()),
      UpdatedByUserID:userId,
      DesignationName:null,
      ReceiptDate:moment(args.data.ReceiptDate).format('YYYY-MM-DD'),	

      CRMStatusDate:args.data.CRMStatusDate ? args.data.CRMStatusDate : null ,
      CRMStatus:args.data.CRMStatus ? args.data.CRMStatus : null ,
      // CRMID:args.data.CRMID ? args.data.CRMID : null ,
      // CRMStatusID:args.data.CRMStatusID ? args.data.CRMStatusID : null ,
      CRMID:state.DesignationID,
      // CRMID:userId,w
      // CRMStatusID:crmStatusID,
      CRMStatusID:null,
      CRMStatus:crmStatus,
      CRMStatusDate:crmStatusDate ,
      // PaymentDescription:'-',
      // PaymentReferenceNo:'-'
   }
    console.log(data)
    PIService.updatePI(args.data.ProformaInvoicePaymentDetailID,data).then((res)=>{
      // whenDestory = ProformaInvoiceDetailGrid.dataSource
      
      getPaymentDetailForGrid(res.data.params.id)
      dispatch({ type: `PIID`  , PIID:res.data.params.id})

    })

  }
      // if (args.requestType === "beginEdit" || args.requestType === "add") {
      //   console.log('attaching blur event complete', document.getElementById('productDetailGridQuantityPerSet'));
      //   document
      //     .getElementById("productDetailGridQuantityPerSet")
      //     ?.addEventListener("blur", (e) => {
      //     let strr =   document
      //     .getElementById("productDetailGridQuantityPerSet").value
      //       if(strr > 0){

      //       setTimeout(() => productDetailGrid?.endEdit(), 200);
      //       }else{
      //         setTimeout(() =>  document
      //         .getElementById("productDetailGridQuantityPerSet").focus(),150)
      //       }
            
      //     });
      // }
    }
    }


    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////       params
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

  const paymentTypeParams: IEditCell = {
    create: () => {
      paymentTypeElem = document.createElement('input');
      return paymentTypeElem;
    },
    destroy: () => {
      paymentTypeObj?.destroy();
    },
    read: () => {
      return paymentTypeObj?.text;
    },
    write: (args) => {
      paymentTypeObj = new AutoComplete({
        created : async   (e) => {
        
          dispatch({ type: `PaymentType` ,  PaymentTypeID: args.rowData.PaymentTypeID})
          if( args.rowData?.PaymentTypeID == "1"){
            document.getElementById('ShortAmount').disabled = true
          getBalanceUnAllocatedAmount()
        }else{
          dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:null})
        }
        },
        select: (e) => {
          console.log('yes',args)
          console.log(e.itemData)
          // console.log(BalanceUnAllocatedAmount)
          if(e.itemData?.id == "1"){
            console.log('yes')
            document.getElementById('ShortAmount').disabled = true
            getBalanceUnAllocatedAmount()
          }else{
            dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:null})
          }
          console.log('PaymentTypeID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
          args.rowData.PaymentTypeID = e?.itemData?.id
          args.rowData.PaymentType = e?.itemData?.value
          PaymentTypeName = e?.itemData?.id

           
        },
        change:(e)=>{
          let resstr = paymentTypeObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `PaymentType` ,  PaymentTypeID: 0})
            args.rowData.PaymentTypeID =0
          }else{
            dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
            args.rowData.PaymentTypeID = e?.itemData?.id
            args.rowData.PaymentType = e?.itemData?.value
            PaymentTypeName = e?.itemData?.id

  
          }},        
        blur:(e)=>{
          console.log(state.PaymentTypeID)
          console.log(paymentTypeObj);
          if (
              paymentTypeObj?.itemData?.id == undefined
          ||  paymentTypeObj?.itemData?.id == '' 
          ||  paymentTypeObj?.itemData?.id == 0 
          ) {
              if(addNewClicked == false){
              paymentTypeObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.PaymentTypeDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Payment Type',
        highlight: true,
        value: args.rowData.PaymentType
      });
      paymentTypeObj?.appendTo(paymentTypeElem);
    }
  };

  const salesAgentStatusParams: IEditCell = {
    create: () => {
      salesAgentStatusElem = document.createElement('input');
      return salesAgentStatusElem;
    },
    destroy: () => {
      salesAgentStatusObj?.destroy();
    },
    read: () => {
      return salesAgentStatusObj?.text;
    },
    write: (args) => {
      salesAgentStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: args.rowData.SalesAgentStatusID})
        },
        select: (e) => {
          console.log('SalesAgentStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
          args.rowData.SalesAgentStatusID = e?.itemData?.id
        },
        change:(e)=>{
          let resstr = salesAgentStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 0})
            args.rowData.SalesAgentStatusID =0
            saleCheck = 0
          }else{
            dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
            args.rowData.SalesAgentStatusID = e?.itemData?.id
            saleCheck = e?.itemData?.id
  
          }},        
        blur:(e)=>{

          console.log(state.SalesAgentStatusID)
          console.log(salesAgentStatusObj);
          if (
              salesAgentStatusObj?.itemData?.id == undefined
          ||  salesAgentStatusObj?.itemData?.id == '' 
          ||  salesAgentStatusObj?.itemData?.id == 0 
          ||  salesAgentStatusObj.itemData?.value == ''
          ) {
              if(addNewClicked == false){
              salesAgentStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }

              },
        dataSource:state.SalesAgentStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Sales Agent Status',
        highlight: true,
        value: args.rowData.SalesAgentStatus
      });
      salesAgentStatusObj?.appendTo(salesAgentStatusElem);
    }
  };

  const paymentVerificationStatusParams: IEditCell = {
    create: () => {
      paymentVerificationStatusElem = document.createElement('input');
      return paymentVerificationStatusElem;
    },
    destroy: () => {
      paymentVerificationStatusObj?.destroy();
    },
    read: () => {
      return paymentVerificationStatusObj?.text;
    },
    write: (args) => {
      paymentVerificationStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: args.rowData.PaymentVerificationStatusID ,PaymentVerificationStatus:  args.rowData.PaymentVerificationStatusID == 3 ? false :  args.rowData.PaymentVerificationStatusID == 2 ? true : null   })
        },
        select: (e) => {
          console.log('PaymentVerificationStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: e?.itemData?.id , PaymentVerificationStatus: e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null   })
          args.rowData.PaymentVerificationStatusID = e?.itemData?.id
          args.rowData.PaymentVerificationStatus = e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null  
          // console.log( new Date(moment().format('DD-MM-YY')))
          // console.log( new Date(moment().format('dd/MM/YY')))
          // console.log( moment().format('DD/MM/YY'))
          
        },
        change:(e)=>{
          let resstr = paymentVerificationStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: 0})
            args.rowData.PaymentVerificationStatusID = 0
            saleCheck = 0
            document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = null
          args.rowData.PaymentVerificationStatusDate = null
          dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:null })
          dispatch({ type: `DesignationName` ,  DesignationID: ''  ,DesignationName:null })
        document.getElementById('ProformaInvoiceDetailGridCRMName').value = null

        }else{
            dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: e?.itemData?.id ,PaymentVerificationStatus:e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null   })
            args.rowData.PaymentVerificationStatusID = e?.itemData?.id
            args.rowData.PaymentVerificationStatus = e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null  
            saleCheck = e?.itemData?.id
          //   const currentDate = new Date();
          // const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          // const day = currentDate.getDate();
          // const year = currentDate.getFullYear();
          // const formattedDate = `${year}-${month}-${day}`;
          // console.log(formattedDate)
          // document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = formattedDate
          // args.rowData.PaymentVerificationStatusDate = formattedDate
          // dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:formattedDate })
          
          if( e?.itemData?.id == 2 ||  e?.itemData?.id == 3  ){
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const formattedDate = `${year}-${month}-${day}`;
          console.log(formattedDate)
          document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = formattedDate
          args.rowData.PaymentVerificationStatusDate = formattedDate
          dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:formattedDate })
          getAllEmployeeUserName()

          }
          if( e?.itemData?.id == 2 ){
            isCrmStatusEdit = false;

            console.log(document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value)
            let salesAgentStatusValue =  document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value;
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();
            var formattedDate2 = `${year}-${month}-${day}`;
            console.log(formattedDate2)
              document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value =  formattedDate2
              document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  null
              args.rowData.CRMStatusDate = formattedDate2
                setCrmStatusDate(formattedDate2)
            // if(salesAgentStatusValue ==  'Release' || salesAgentStatusValue == 'Surrender BL'){
              
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 5,CRMStatus: 'Valid Payment Complete' })
            //   args.rowData.CRMStatusID = 5
            //   args.rowData.CRMStatus = 'Valid Payment Complete'
            //   setCrmStatus('Valid Payment Complete')
            //   setCrmStatusID(5)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   args.rowData.CRMStatusDate = formattedDate2
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            
            // }else if(salesAgentStatusValue ==  'BL Hold'){
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 4,CRMStatus: 'Valid Sold' })
            //   args.rowData.CRMStatusID = 4
            //   args.rowData.CRMStatus = 'Valid Sold'
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  'Valid Sold'
            //   setCrmStatus('Valid Sold')
            //   setCrmStatusDate(`${year}-${month}-${day}`)
            //   setCrmStatusID(4)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   // document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate
            //                 args.rowData.CRMStatusDate = formattedDate2
            // }else if (salesAgentStatusValue == 'Booking-Partial Payment'){
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 1,CRMStatus: 'Pending Payment' })
            //   args.rowData.CRMStatusID = 1
            //   args.rowData.CRMStatus = 'Pending Payment'
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  'Pending Payment'
            //   setCrmStatus('Pending Payment')
            //   setCrmStatusDate(`${year}-${month}-${day}`)
            //                 setCrmStatusID(1)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            // }

            // console.log(salesAgentStatus)
            // console.log(salesAgentStatusID)
            // console.log(state.SalesAgentStatusID)
            // console.log(state.SalesAgentStatus)


          }else{
            isCrmStatusEdit = true
          }
          }},        
        blur:(e)=>{

          console.log(state.PaymentVerificationStatusID)
          console.log(paymentVerificationStatusObj);
          if (
              paymentVerificationStatusObj?.itemData?.id == undefined
          ||  paymentVerificationStatusObj?.itemData?.id == '' 
          ||  paymentVerificationStatusObj?.itemData?.id == 0 
          // ||  paymentVerificationStatusObj.itemData?.value == ''
          ) {
              if(addNewClicked == false){
              paymentVerificationStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }

              },
        // dataSource:isCrm == true ? state.PaymentVerificationStatusDataSource: [],
        dataSource:state.PaymentVerificationStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Payment Verification',
        highlight: true,
        enabled:{isCrm},
        
        value: args.rowData.PaymentVerificationStatus
        
      });
      paymentVerificationStatusObj?.appendTo(paymentVerificationStatusElem);
    }
  };
  const designationNameParams: IEditCell = {
    create: () => {
      designationNameElem = document.createElement('input');
      return designationNameElem;
    },
    destroy: () => {
      designationNameObj?.destroy();
    },
    read: () => {
      return designationNameObj?.text;
    },
    write: (args) => {
      designationNameObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `DesignationName` ,  DesignationID: args.rowData.DesignationID})
        },
        select: (e) => {
          console.log('DesignationID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
          args.rowData.DesignationID = e?.itemData?.id
        },
        change:(e)=>{
          let resstr = designationNameObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `DesignationName` ,  DesignationID: 0})
            args.rowData.DesignationID =0
          }else{
            dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
            args.rowData.DesignationID = e?.itemData?.id
  
          }},        
        blur:(e)=>{
          console.log(state.DesignationID)
          console.log(designationNameObj);
          if (
              designationNameObj.itemData.id == undefined
          ||  designationNameObj.itemData.id == '' 
          ||  designationNameObj.itemData.id == 0 
          ) {
              if(addNewClicked == false){
              designationNameObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.DesignationNameDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Select CRM',
        highlight: true,
        enabled:isCrm,
        value: args.rowData.CRMName
      });
      designationNameObj?.appendTo(designationNameElem);
    }
  };
  const cRMStatusParams: IEditCell = {
    create: () => {
      cRMStatusElem = document.createElement('input');
      return cRMStatusElem;
    },
    destroy: () => {
      cRMStatusObj?.destroy();
    },
    read: () => {
      return cRMStatusObj?.text;
    },
    write: (args) => {
      cRMStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `CRMStatus` ,  CRMStatusID: args.rowData.CRMStatusID})
        },
        select: (e) => {
          console.log('CRMStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
          args.rowData.CRMStatusID = e?.itemData?.id
        },
        change:(e)=>{

          let resstr = cRMStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `CRMStatus` ,  CRMStatusID: 0})
            args.rowData.CRMStatusID =0
            document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = null
          args.rowData.CRMStatusDate = null
          }else{
            dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
            args.rowData.CRMStatusID = e?.itemData?.id
            setCrmStatus(e?.itemData?.value)
              setCrmStatusID(e?.itemData?.id)
          const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          console.log(formattedDate)
          document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate
          args.rowData.CRMStatusDate = formattedDate
          }},        
        blur:(e)=>{
          console.log(state.CRMStatusID)
          console.log(cRMStatusObj);
          if (
              cRMStatusObj?.itemData?.id == undefined
          ||  cRMStatusObj?.itemData?.id == '' 
          ||  cRMStatusObj?.itemData?.id == 0 
          ) {
              if(addNewClicked == false){
              cRMStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.CRMStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Select CRM Status',
        highlight: true,
        // enabled:isCrm,
        value: args.rowData.CRMStatus
      });
      cRMStatusObj?.appendTo(cRMStatusElem);
    }
  };
  const actionBegin = (e)=>{
    console.log(e)
    // PaymentVerificationStatus
    // "VERIFIED"
    
    if (e.requestType == "beginEdit" && props.type == 'SalesAgent') {
      if(e.rowData.PaymentVerificationStatus == "VERIFIED"  ||  e.rowData.PaymentVerificationStatus == "INVALID"){

        e.cancel = true; // Prevent the editing process from starting
        alert('YOU ARE NOT ELIGIBLE TO EDIT THIS ROW')
      }
    }
    // if(e.requestType = "refresh"){
    //   getPaymentDetailForGrid(props.PIID)
    // }
  }
// const PIGrid = ()=>(

// )
// const updateDataSource = ()=>{
//   return getPaymentDetailForGrid(1006)
// }
let  PInoRef = useRef(null)
let  PiNoRefRef = useRef(null)
const deleteDialog = useRef();
const [showDialogOfProduct, setShowDialogOfProduct] = useState(false)
const deleteButtons = [
  {
    buttonModel: {
      content: 'Release',
      cssClass: 'e-flat',
      isPrimary: true,
    },
    click: (e) => {
      deleteDialog.current.hide();
      document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Release';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      setSalesAgentStatus('Release')
      setSalesAgentStatusID(4)
      dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 4 ,SalesAgentStatus:'Release' })
     
      // setTimeout(() => {
        // console.log(e);
        
      //   console.log('jd')
      //   // setProductInputValue(undefined)
      //   // document.getElementById('ProductInput').value =''
      //   setProductInputValue('')
      //   setProductDropdownID(0)
      //   // document.getElementById('Product').
      //   // productInput.current.focus()
      // },300);
      // setTimeout(  () => {

      //   let el =  document.getElementById('ProductInput')
      //   // console.(
      //   // productInput.current.focus()
      //   if(el != null){
      //   return el.focus();}

      // },700)
        // console.log(productInput)
        // setProductInputChecker(true)

      // deleteRow();

    },
  },
  {
    buttonModel: {
      content: 'Surrender BL',
      cssClass: 'e-flat',
    },
    click: () => {
      deleteDialog.current.hide();
      document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Surrender BL';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'SalesAgentStatus' })
      setSalesAgentStatus('Surrender BL')
      setSalesAgentStatusID(5)
      dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 5 ,SalesAgentStatus:'Surrender BL' })

      // props.showResult(false)
    },
  },
];
 

const deleteDialogClose = ()=>{
  setShowDialogOfProduct(false)
  if(isCrm){

    setTimeout(() => {
      
      document.getElementById('ShortAmount').focus()
  }, 600);
    }else{
      setTimeout(() => {
      
        // document.getElementById('ShortAmount').focus()
        ProformaInvoiceDetailGrid.endEdit()
    }, 600);
    }
}
  return (
    <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13  || e.keyCode == 27){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>PI - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
                <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='PINo'
                        name='PINo'
                        ref={PInoRef}
                        floatLabelType='Auto'
                        id='PINo'
                        value={state.PINo}
                        
                        onChange={textBoxChange}
                        created={(e)=>{
                          PInoRef.current.element.select()

                        }}
                        blur={(e)=>{
                          if(props.isAdd ==  true){
                            
                           let resultcheck = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
                             PINo:state.PINo
                             
                              })
                              console.log(resultcheck)
                              if(resultcheck == true){
                                PInoRef.current.element.focus()
                                document.getElementById('PINo').style.border = '2px solid red'
                                setTimeout(() => {
                                  document.getElementById('PINo').style.border = '1px solid #eee'
                                  
                                }, 2000);
                              }
                            }else{
                              let array = props.dataList.filter(obj => obj.PIID !== props.PIID)
                              
                              let resultcheck = CheckDuplicate.withOutCombination(array,['PINo','PIReferenceNo'],{
                                PINo:state.PINo
                              })
                              console.log(resultcheck)
                              if(resultcheck == true){
                                PInoRef.current.element.focus()
                                document.getElementById('PINo').style.border = '2px solid red'
                                setTimeout(() => {
                                  document.getElementById('PINo').style.border = '1px solid #eee'
                                  
                                }, 2000);
                                
                                PiNoRefRef.current.element.select()
                              }
                            }
                            
                            
                          // if(isDuplicate == true){
                          //   PiNoRefRef.current.element.focus()

                          // }

                        }}
                      />
                    </mat-form-field>
                  </div>
                <div className="col-sm-8">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='Reference No'
                        name='PIReferenceNo'
                        floatLabelType='Auto'
                        value={state.PIReferenceNo}
                      
                        multiline
                        ref={PiNoRefRef}
                        onChange={(e)=>{
                          
                          dispatch({type:`PIReferenceNo` ,PIReferenceNo:e.value })
                          piRefValue = e.value
                if(e.value == ''){
                        PiNoRefRef.current.element.focus()

                          }
                        }}
                        id='PIReferenceNo'
                        onBlur={()=>{
                          console.log(PiNoRefRef.current.inputPreviousValue)
                          if(PiNoRefRef.current.inputPreviousValue == '' ){
                            PiNoRefRef.current.element.focus()

                          }
                          if(props.isAdd ==  true){

                            let resultcheck = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
                              PIReferenceNo:PiNoRefRef.current.inputPreviousValue
                              
                               })
                               console.log(resultcheck)
                               if(resultcheck == true){
                                PiNoRefRef.current.element.focus()
                                 document.getElementById('PIReferenceNo').style.border = '2px solid red'
                                 setTimeout(() => {
                                   document.getElementById('PIReferenceNo').style.border = '1px solid #eee'
                                   
                                 }, 2000);
                                }
                          }else{
                            let array = props.dataList.filter(obj => obj.PIID !== props.PIID)
                    
                            let resultcheck = CheckDuplicate.withOutCombination(array,['PINo','PIReferenceNo'],{
                              PIReferenceNo:PiNoRefRef.current.inputPreviousValue
                            })
                            console.log(resultcheck)
                            if(resultcheck == true){
                              PiNoRefRef.current.element.focus()
                             document.getElementById('PIReferenceNo').style.border = '2px solid red'
                             setTimeout(() => {
                               document.getElementById('PIReferenceNo').style.border = '1px solid #eee'
                               
                             }, 2000);
                            }
                           }
                        }}
                        focus={(e)=>{
                          console.log(PiNoRefRef)
                          PiNoRefRef.current.element.select()
                          
                        }}


                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  <br/>
                <div className="col-sm-4">
                    <mat-form-field>
                    <DatePickerComponent
                          id='PIDate'
                          placeholder='Enter PI Date'
                          floatLabelType='Auto'
                          // max={minDate}
                          name='PIDate'
                          // disabled={true}
                          // readOnly={true}
                          // readonly={true}
                          // enabled={false}
                          // format='dd-MM-yy'
                          // format='dd-MM-yy'
                          // format='YYYY-MM-DD'
                          format='dd-MM-yyyy'

                          // value={  state.PIDate}
                          // value={new Date(state.PIDate).toISOString().split('T')[0]}
                          value={props.isAdd == false ? new Date(state.PIDate).toISOString().split('T')[0].split('-').reverse().join('-') : state.PIDate}

                          // value={new Date(state.PIDate).toLocaleDateString('en-GB')}


                          // value={moment.utc(state.PIDate).toDate()}  // Convert to UTC
                          // value={'2025-02-06'}  // Convert to UTC

                          // value={props.PIDate}
                          change={textBoxChange}
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingSupplier ? (
                        <div>... Loading Supplier </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="SupplierName" 
                            
                            dataSource={state.SupplierDataSource}
                            placeholder="Select Supplier" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="SupplierName"
                            
                            select={(e)=>AutoCompleteChange(e,'SupplierName')}
                            change={(e)=>AutoCompleteChange(e,'SupplierName')}
                            value={state.SupplierName}
                            ref={state.SupplierRef}
                            blur={()=>{
                              console.log('dfdfblur',state.SupplierID)
                              console.log('dfdfblur',state.SupplierName)
                              if (state.SupplierID  == 0  || state.SupplierRef.current.value == null || state.SupplierRef.current.value == '' || state.SupplierID == undefined || state.SupplierID == null) {
                               if(state.SupplierName != ''){
                                 let resstr = state.SupplierDataSource.some(e=>e.value ==  state.SupplierName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.supplier.SupplierName} Value Not Find ` )
                                   document.getElementById("SupplierName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                               setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("SupplierName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.SupplierRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='SupplierID'
                            floatLabelType='Auto'
                            value={state.SupplierID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCustomer ? (
                        <div>... Loading  Sales Agent </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={state.CustomerDataSource}
                            placeholder="Select Customer" 
                              fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>AutoCompleteChange(e,'CustomerName')}
                            change={(e)=>AutoCompleteChange(e,'CustomerName')}
                            value={state.CustomerName}
                            ref={state.CustomerRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CustomerID)
                              console.log('dfdfblur',state.CustomerName)
                              if (state.CustomerID  == 0  || state.CustomerRef.current.value == null || state.CustomerRef.current.value == '' || state.CustomerID == undefined || state.CustomerID == null) {
                               if(state.CustomerName != ''){
                                 let resstr = state.CustomerDataSource.some(e=>e.value ==  state.CustomerName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.Customer.CustomerName} Value Not Find ` )
                                   document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CustomerRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={state.CustomerID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <br/>
                  <br/>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingEmployee ? (
                        <div>... Loading Sales Agent </div>
                      ) : (
                        <div>
                         <TextBoxComponent
                            id="EmployeeName" 
                            // dataSource={state.EmployeeDataSource}
                            placeholder=" Sales Agent Name" 
                            // fields={ddlFields}
                            floatLabelType='Auto' 
                            disabled={true}
                            readOnly={true}
                            readonly={true}
                            // highlight={true}
                            name="EmployeeName"
                            // select={(e)=>AutoCompleteChange(e,'EmployeeName')}
                            // change={(e)=>AutoCompleteChange(e,'EmployeeName')}
                            value={state.EmployeeName}
                            // ref={state.EmployeeRef}
                          //   blur={()=>{
                          //     console.log('dfdfblur',state.EmployeeID)
                          //     console.log('dfdfblur',state.EmployeeName)
                          //     if (state.EmployeeID  == 0  || state.EmployeeRef.current.value == null || state.EmployeeRef.current.value == '' || state.EmployeeID == undefined || state.EmployeeID == null) {
                          //      if(state.EmployeeName != ''){
                          //        let resstr = state.EmployeeDataSource.some(e=>e.value ==  state.EmployeeName);
                          //        console.log(resstr)
                          //        if(resstr === false){
                          //         //  setProductGroupErorr(`${state.Employee.EmployeeName} Value Not Find ` )
                          //          document.getElementById("EmployeeName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                          //          setTimeout(() => {
                          //           //  setProductItemTypeError('')
                          //            document.getElementById("EmployeeName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                          //          }, 1000);
                          //        }
                          //      }
                               
                          //      if (addNewClicked == false){
                          //       state.EmployeeRef.current.element.focus()
                   
                          //      }
                          //    }}
                          
                          // }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='EmployeeID'
                            floatLabelType='Auto'
                            value={state.EmployeeID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='Consignee Name'
                        name='ConsigneeName'
                        floatLabelType='Auto'
                        
                        value={state.ConsigneeName}
                        onChange={textBoxChange}
                      />
                      <TextBoxComponent
                        className='e-input'
                        placeholder='Consignee Name'
                        name='ConfirmConsigneeNames'
                        id='ConfirmConsigneeNames'
                        type='hidden'
                        floatLabelType='Auto'
                        
                        value={state.ConfirmConsigneeName}
                        onChange={textBoxChange}
                      />
                    </mat-form-field>
                  </div>

               
                  <div className="col-sm-4" id='ConfirmConsigneeName'>
                    <mat-form-field>
                    <CheckBoxComponent
                      className='e-input'
                      placeholder='Confirm Consignee Name'
                      name='ConfirmConsigneeName'
                      id='ConfirmConsigneeName'
                      floatLabelType='Auto'
                      label='Confirm Consignee Name'
                      checked={state.ConfirmConsigneeName}
                      change={(e)=>{
                        console.log(e)
                           dispatch({ type:`ConfirmConsigneeName` , ConfirmConsigneeName: e?.checked })
                           setTimeout(() => {
                            // props.ConfirmConsignee(state.ConfirmConsigneeName)
                           }, 300);
                          }}
                      readOnly={false}
                    />
                    </mat-form-field>
                  </div>
                  <br/>
                  <br/>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCountryCityPort ? (
                        <div>... Loading Country City Port </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CountryCityPort" 
                            dataSource={state.CountryCityPortDataSource}
                            placeholder="Select Country City Port" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                         
                            name="CountryCityPort"
                            select={(e)=>AutoCompleteChange(e,'CountryCityPort')}
                            change={(e)=>AutoCompleteChange(e,'CountryCityPort')}
                            value={state.CountryCityPort}
                            ref={state.CountryCityPortRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CountryCityPortID)
                              console.log('dfdfblur',state.CountryCityPort)
                              if (state.CountryCityPortID  == 0  || state.CountryCityPortRef.current.value == null || state.CountryCityPortRef.current.value == '' || state.CountryCityPortID == undefined || state.CountryCityPortID == null) {
                               if(state.CountryCityPort != ''){
                                 let resstr = state.CountryCityPortDataSource.some(e=>e.value ==  state.CountryCityPort);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.CountryCityPort.CountryCityPort} Value Not Find ` )
                                   document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CountryCityPortRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CountryCityPortID'
                            floatLabelType='Auto'
                            value={state.CountryCityPortID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingShipmentType ? (
                        <div>... Loading ShipmentType </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="ShipmentType" 
                            dataSource={state.ShipmentTypeDataSource}
                            placeholder="Select Shipment Type" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            
                            name="ShipmentType"
                            select={(e)=>AutoCompleteChange(e,'ShipmentType')}
                            change={(e)=>AutoCompleteChange(e,'ShipmentType')}
                            value={state.ShipmentType}
                            ref={state.ShipmentTypeRef}
                            blur={()=>{
                              console.log('dfdfblur',state.ShipmentTypeID)
                              console.log('dfdfblur',state.ShipmentType)
                              if (state.ShipmentTypeID  == 0  || state.ShipmentTypeRef.current.value == null || state.ShipmentTypeRef.current.value == '' || state.ShipmentTypeID == undefined || state.ShipmentTypeID == null) {
                               if(state.ShipmentType != ''){
                                 let resstr = state.ShipmentTypeDataSource.some(e=>e.value ==  state.ShipmentType);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.ShipmentType.ShipmentType} Value Not Find ` )
                                   document.getElementById("ShipmentType").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("ShipmentType").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.ShipmentTypeRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='ShipmentTypeID'
                            floatLabelType='Auto'
                            value={state.ShipmentTypeID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCurrencyName  || state.CurrencyNameDataSource.length == 0 ? (
                  <TextBoxComponent
                    type='text'
                    placeholder='Select Currency Name'
                    floatLabelType='Auto' 
                    highlight={true}
                    disabled={true}
            
                  />
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CurrencyName" 
                            dataSource={state.CurrencyNameDataSource}
                            placeholder="Select Currency Name" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                           
                            name="CurrencyName"
                            select={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            change={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            value={state.CurrencyName}
                            ref={state.CurrencyNameRef}
                            // disabled={state.CurrencyNameDataSource == [] ? true :  false}
                            // disabled={true}
                            disabled={state.CurrencyNameDataSource == [] ? true :  false}
                            readonly={state.CurrencyNameDataSource == [] ? true :  false}
                            readOnly={state.CurrencyNameDataSource == [] ? true :  false}
                            blur={()=>{
                              console.log('dfdfblur',state.CurrencyID)
                              console.log('dfdfblur',state.CurrencyName)
                              if (state.CurrencyID  == 0  || state.CurrencyNameRef.current.value == null || state.CurrencyNameRef.current.value == '' || state.CurrencyID == undefined || state.CurrencyID == null) {
                               if(state.CurrencyName != ''){
                                 let resstr = state.CurrencyNameDataSource.some(e=>e.value ==  state.CurrencyName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.CurrencyName.CurrencyName} Value Not Find ` )
                                   document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CurrencyNameRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CurrencyID'
                            floatLabelType='Auto'
                            value={state.CurrencyID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <br/>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {/* <TextBoxComponent
                        className='e-input'
                        type='hidden'
                        placeholder='Book Amount'
                        id='BookAmount'
                        name='BookAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        value={state.BookAmount}
                        /> */}
                      <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Book Amount'
                        id='BookAmount'
                        name='BookAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={state.BookAmount}
             
                        onChange={(e)=>{
                          // dispatch({ type: `BookAmt` , BookAmt: e?.value })  
                          dispatch({ type: `BookAmount` , BookAmount: e?.value})  
                          if(e.value >  0){
                            holdBookAmt = e.value
                            console.log(state.InitialPaymentToleranceAmount)
                            console.log(holdBookAmt)
                            console.log(state.InitialPaymentPercent)
                            let GenerateInitialPaymentToleranceAmount =  (state.InitialPaymentPercent / 100 * e.value ) - state.GetInitialPaymentToleranceAmount
                            console.log(GenerateInitialPaymentToleranceAmount)
                            // dispatch({ type: `InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount: GenerateInitialPaymentToleranceAmount })  

                            
                          }
                        }}
                        blur={()=>{
                          if(state.BookAmount  <= 0 ){
                            document.getElementById('BookAmount').style.border = '1px solid red'
                          }else{
                            document.getElementById('BookAmount').style.border = '1px solid #eee'

                          }
                        }}
                        
                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Adjustment Amount'
                        id='AdjustmentAmount'
                        name='AdjustmentAmount'
                        floatLabelType='Auto'
                    
                        format={'N0'}
                        showSpinButton={false}
                        value={state.AdjustmentAmount}
                        onChange={textBoxChange}
                        
                      />
                    </mat-form-field>
                  </div>
                  {/* <div className="col-sm-4"> */}
                    <mat-form-field>
                      <TextBoxComponent
                        type='hidden'
                        className='e-input'
                        placeholder='Commission Amount Per Unit'
                        id='CommissionAmountPerUnit'
                        name='CommissionAmountPerUnit'
                        floatLabelType='Auto'
                        format={'N0'}
                        
                        showSpinButton={false}
                        value={state.CommissionAmountPerUnit}
                        onChange={textBoxChange}
                       
                        
                      />
                    </mat-form-field>
                  {/* </div> */}
                  {/* <div className="col-sm-4" id='ApproveCommissionAmountPerUnit'>
                    <mat-form-field>
                    <CheckBoxComponent
                      className='e-input'
                      placeholder='Approve Commission Amount Per Unit'
                      name='ApproveCommissionAmountPerUnit'
                      floatLabelType='Auto'
                      label='Approve Commission Amount Per Unit'
                      checked={state.ApproveCommissionAmountPerUnit}
                      change={(e)=>     dispatch({ type:`ApproveCommissionAmountPerUnit` , ApproveCommissionAmountPerUnit: e?.checked })}
                      readOnly={false}
                    />
                    </mat-form-field>
                  </div> */}
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                       
                       className='e-input'
                        placeholder='Absolute Commission Amount'
                        id='AbsoluteCommissionAmount'
                        name='AbsoluteCommissionAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={state.AbsoluteCommissionAmount}
                        onChange={textBoxChange}
                       
                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  <br/>
                  {/* <div className="col-sm-4" id='ApproveAbsoluteCommissionAmount'>
                    <mat-form-field>
                    <CheckBoxComponent
                      className='e-input'
                      placeholder='Approve Absolute Commission Amount'
                      name='ApproveAbsoluteCommissionAmount'
                      floatLabelType='Auto'
                      label='Approve Absolute Commission Amount'
                      checked={state.ApproveAbsoluteCommissionAmount}
                      change={(e)=>     dispatch({ type:`ApproveAbsoluteCommissionAmount` , ApproveAbsoluteCommissionAmount: e?.checked })}
                      readOnly={false}
                    />
                    </mat-form-field>
                  </div> */}
                 
                  {/* <div className="col-sm-4"> */}
                    <mat-form-field>
                      <TextBoxComponent
                      type='hidden'
                        className='e-input'
                        placeholder='Primary Commission % On Adjustment'
                        id='PrimaryCommissionPercentOnAdjustment'
                        name='PrimaryCommissionPercentOnAdjustment'
                        floatLabelType='Auto'
                        format={'N2'}
                        showSpinButton={false}
                        value={state.PrimaryCommissionPercentOnAdjustment}
                        onChange={textBoxChange}
                     
                        
                      />
                    </mat-form-field>
                  {/* </div> */}
                  <br/>
                  <br/>
                  {/* <div className="col-sm-4" id='ApprovePrimaryCommissionPercentOnAdjustment'>
                    <mat-form-field>
                    <CheckBoxComponent
                      className='e-input'
                      placeholder='Approve Primary Commission % On Adjustment'
                      name='ApprovePrimaryCommissionPercentOnAdjustment'
                      floatLabelType='Auto'
                      label="Approve Primary Comm'n % On Adjustment"
                      checked={state.ApprovePrimaryCommissionPercentOnAdjustment}
                      change={(e)=>     dispatch({ type:`ApprovePrimaryCommissionPercentOnAdjustment` , ApprovePrimaryCommissionPercentOnAdjustment: e?.checked })}
                      readOnly={false}
                    />
                    </mat-form-field>
                  </div> */}
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                      // type=''
                        className='e-input'
                        placeholder='Secondary Commission % On Adjustment'
                        id='SecondaryCommissionPercentOnAdjustment'
                        name='SecondaryCommissionPercentOnAdjustment'
                        floatLabelType='Auto'
                        format={'N2'}
                        showSpinButton={false}
                        value={state.SecondaryCommissionPercentOnAdjustment}
                        onChange={textBoxChange}
                      
                      />
                    </mat-form-field>
                    </div>
                  {/* <div className="col-sm-4" id='ApproveSecondaryCommissionPercentOnAdjustment'>
                    <mat-form-field>
                    <CheckBoxComponent
                      className='e-input'
                      placeholder='Approve Secondary Commission % On Adjustment'
                      name='ApproveSecondaryCommissionPercentOnAdjustment'
                      floatLabelType='Auto'
                      label="Approve Secondary Comm'n % On Adjustment"
                      checked={state.ApproveSecondaryCommissionPercentOnAdjustment}
                      change={(e)=>     dispatch({ type:`ApproveSecondaryCommissionPercentOnAdjustment` , ApproveSecondaryCommissionPercentOnAdjustment: e?.checked })}
                      readOnly={false}
                    />
                    </mat-form-field>
                  </div> */}
                  {/* <br/> */}
                  {/* <br/> */}
                  {/* {state.IsSupervisorDesignation == true ? */}
                   
                  <br/>
                  <br/>
                  {/* </>
                    )} */}
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingPurchaseSource ? (
                        <div>... Loading Purchase Source </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="PurchaseSource" 
                            dataSource={state.PurchaseSourceDataSource}
                            placeholder="Select Purchase Source" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="PurchaseSource"
                            select={(e)=>AutoCompleteChange(e,'PurchaseSource')}
                            change={(e)=>AutoCompleteChange(e,'PurchaseSource')}
                         
                            value={state.PurchaseSource}
                            ref={state.PurchaseSourceRef}
                            blur={()=>{
                              console.log('dfdfblur',state.PurchaseSourceID)
                              console.log('dfdfblur',state.PurchaseSource)
                              if (state.PurchaseSourceID  == 0  || state.PurchaseSourceRef.current.value == null || state.PurchaseSourceRef.current.value == '' || state.PurchaseSourceID == undefined || state.PurchaseSourceID == null) {
                               if(state.PurchaseSource != ''){
                                 let resstr = state.PurchaseSourceDataSource.some(e=>e.value ==  state.PurchaseSource);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.PurchaseSource.PurchaseSource} Value Not Find ` )
                                   document.getElementById("PurchaseSource").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("PurchaseSource").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.PurchaseSourceRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='PurchaseSourceID'
                            floatLabelType='Auto'
                            value={state.PurchaseSourceID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  {/* <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingTradeBoardLetterStatus ? (
                        <div>... Loading Trade Board Letter Status </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="TradeBoardLetterStatus" 
                            dataSource={state.TradeBoardLetterStatusDataSource}
                            placeholder="Select Trade Board Letter Status" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                        
                            highlight={true}
                            name="TradeBoardLetterStatus"
                            select={(e)=>AutoCompleteChange(e,'TradeBoardLetterStatus')}
                            change={(e)=>AutoCompleteChange(e,'TradeBoardLetterStatus')}
                            value={state.TradeBoardLetterStatus}
                            ref={state.TradeBoardLetterStatusRef}
                            blur={()=>{
                              console.log('dfdfblur',state.TradeBoardLetterStatusID)
                              console.log('dfdfblur',state.TradeBoardLetterStatus)
                              if (state.TradeBoardLetterStatusID  == 0  || state.TradeBoardLetterStatusRef.current.value == null || state.TradeBoardLetterStatusRef.current.value == '' || state.TradeBoardLetterStatusID == undefined || state.TradeBoardLetterStatusID == null) {
                               if(state.TradeBoardLetterStatus != ''){
                                 let resstr = state.TradeBoardLetterStatusDataSource.some(e=>e.value ==  state.TradeBoardLetterStatus);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.TradeBoardLetterStatus.TradeBoardLetterStatus} Value Not Find ` )
                                   document.getElementById("TradeBoardLetterStatus").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("TradeBoardLetterStatus").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.TradeBoardLetterStatusRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='TradeBoardLetterStatusID'
                            floatLabelType='Auto'
                            value={1}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div> */}
                  <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='TradeBoardLetterStatusID'
                            floatLabelType='Auto'
                            value={1}
                          />
                  <div className="col-sm-8">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='Remarks'
                        name='Remarks'
                        floatLabelType='Auto'
                        value={state.Remarks}
                        onChange={textBoxChange}
                       
                      />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-3">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='VesselName'
                        name='VesselName'
                        floatLabelType='Auto'
                        value={state.VesselName}
                        onChange={textBoxChange}
                        disabled={props.type != 'CRM'}
                        readonly={props.type != 'CRM'}    
                        readOnly={props.type != 'CRM'}
                        enabled={props.type == 'CRM'}

                      />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-3">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='VoyageNo'
                        name='VoyageNo'
                        floatLabelType='Auto'
                        value={state.VoyageNo}
                        onChange={textBoxChange}
                        disabled={props.type != 'CRM'}
                        readonly={props.type != 'CRM'}    
                        readOnly={props.type != 'CRM'}
                        enabled={props.type == 'CRM'}
                      />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-3">
                    <mat-form-field>
                    <DatePickerComponent
                          id='ETA'
                          placeholder='Enter ETA date'
                          floatLabelType='Auto'
                          // min={props.isAdd ? minDate :{}}
                          name='ETA'
                          format='dd-MM-yyyy'
                          // value={state.ETA}
                           value={props.isAdd == false  && props.type == 'CRM' && props.ETA != null ? new Date(props.ETA).toISOString().split('T')[0].split('-').reverse().join('-') : state.ETA}

                          onChange={textBoxChange}
                          disabled={props.type != 'CRM'}
                          readonly={props.type != 'CRM'}    
                          readOnly={props.type != 'CRM'}
                          enabled={props.type == 'CRM'}

                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                    <div className="col-sm-3">
                    <mat-form-field>
                    <DatePickerComponent
                          id='ETD'
                          placeholder='Enter ETD date'
                          floatLabelType='Auto'
                          // min={props.isAdd ? minDate :{}}
                          name='ETD'
                          format='dd-MM-yyyy'
                          // value={state.ETD}
                          // value={props.isAdd == false && props.type == 'CRM' ? new Date(state.ETD).toISOString().split('T')[0].split('-').reverse().join('-') : state.ETD}
                          value={props.isAdd == false  && props.type == 'CRM' && props.ETD != null ? new Date(props.ETD).toISOString().split('T')[0].split('-').reverse().join('-') : state.ETD}
                          onChange={textBoxChange}
                          disabled={props.type != 'CRM'}
                        readonly={props.type != 'CRM'}    
                        readOnly={props.type != 'CRM'}
                        enabled={props.type == 'CRM'}

                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                </div>
                <div class='row'>
                <div className="col-sm-4">
                     <mat-form-field>
                      <TextBoxComponent
                        type={props.type == 'Supervisor'? 'number':'hidden'}
                        className='e-input'
                        placeholder={props.type == 'Supervisor'
                         ? 'Base Price Agent':''}
                        id='BasePriceAgent'
                        name='BasePriceAgent'
                        floatLabelType='Auto' 
                        format={'N0'}
                    
                        // readonly={!state.IsSupervisorDesignation}
                        // readOnly={!state.IsSupervisorDesignation}
                        // enabled={state.IsSupervisorDesignation}
                        showSpinButton={false}
                        value={state.BasePriceAgent}
                        onChange={textBoxChange}
                        
                        />
                    </mat-form-field>
                  </div> 
                  {/* :null} */}

                  {/* 
                      {state.IsSupervisorDesignation == false ? null :
                    // state.IsShowBasePriceCompany == false? null :
                    (
                      
                      <>
                    */}
                  <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type={props.type == 'Supervisor' ? 'number':'hidden'}
                        placeholder={props.type == 'Supervisor' ? 'Base Price Company':''}
// placeholder='Base Price Company'
                        id='BasePriceCompany'
                        name='BasePriceCompany'
                        floatLabelType='Auto'                        format={'N0'}
                        
                        showSpinButton={false}
                        value={state.BasePriceCompany}
                        // disabled={props.type != 'Supervisor'}
                        
                        // readonly={!state.IsSupervisorDesignation}
                        // readOnly={!state.IsSupervisorDesignation}
                        // enabled={state.IsSupervisorDesignation}
                      
                        onChange={textBoxChange}
                        
                      />
                    </mat-form-field>
                  </div>
                  </div>
                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>
      {/* if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            } */}
                            <div>
       {checkDuplicateValue != ''? <h3 className="duplicates " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>:null}
       <div style={{display:'flex',justifyContent:'space-around'}}>
       {state.BalanceUnAllocatedAmount  != null? <h4 id='Account-amt'> ACCOUNT BALANCE : {state.CurrencySymbol} {state.BalanceUnAllocatedAmount}</h4>:<h4 id='Account-amt' style={{visibility:'hidden'}}>...</h4>}
          <h5 id='error-1'>TOTAL ALLOCATION AMOUNT CANNOT EXCEED BOOKING AMOUNT</h5>
          <h5 id='error-2'>ALLOCATION AMOUNT CANNOT EXCEED UNALLOCATED AMOUNT</h5>
       </div>
       </div>
{/* <PIGrid /> */}

{/* <button onClick={(e) =>{ e.preventDefault(); updateDataSource()}}>Update Data</button> */}
{showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                getPaymentDetailForGrid(props.PIID ? props.PIID : state.PIID)
                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
 <div>
 <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={showDialogOfProduct}
                close={deleteDialogClose}
                content={() => (<div>DO WANT TO TO RELEAES OF SURRENDER BL?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              </div>

              <div>
              {props.isAdd == false && PIBalance != 0 ? <p className='pi-balance-amount'>BALANCE AMOUNT : {PIBalance.toLocaleString()}</p>: ''}
              </div>
<GridComponent
      ref={(grid) => (ProformaInvoiceDetailGrid = grid)}
      id='ProformaInvoiceDetailGrid'
      dataSource={state.PaymentDetailDataSource}
      // dataSource={[]}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true,  }}
      // allowPaging={true}
      // filterSettings={filterOptions}
      sortSettings={sortingOptions}
      // allowPaging={true}
      allowSorting={true}
      
      allowPdfExport={false}
      allowExcelExport={false}
      //toolbarClick={toolbarClick}
      // allowFiltering={true}
      // showColumnChooser={true}
      // allowReordering={true}
      // visible={false}
      allowTextWrap={true}
      actionComplete={actionComplete}
      actionBegin={actionBegin}
      toolbarClick={toolbarClick}
      >
      <ColumnsDirective>
      <ColumnDirective
      field='ProformaInvoicePaymentDetailID'
      visible={false}

      />
        <ColumnDirective
          headerText='Receipt Date'
          field='ReceiptDate'
          width='170'
          headerTextAlign='CENTER'
          textAlign='right'
         editType='datepickeredit'
         
         ref={receiptDateRef}

        //  format={formatOptions}
        type='date'
            format='dd/MM/yy'
            editTemplate={(props) => {
              console.log(props)
              // const handleChange = (e) => {
              //   // const inputElement = e.target;
              //   console.log(e)
              //   if (!e.value) {
              //     // inputElement.classList.add('red-border');

              //     document.getElementById('ReceiptDate').style.border =  '1px solid red'
              //     receiptDateRef.current.element.focus()
              //   } else {
              //     // inputElement.classList.remove('red-border');
              //     document.getElementById('ReceiptDate').style.border =  '1px solid #eee'

              //   }
              //   // props.onChange(e);
              // };
          
              return (
                <DatePickerComponent
                          id='ReceiptDate'
                          placeholder='Receipt Date'
                          floatLabelType='Auto'
                          // min={minDate}
                          max={minDate}
                          ref={receiptDateRef}
                          name='ReceiptDate'
                          format='dd/MM/yy'
                          // disabled
                          value={props.ReceiptDate}
                          created={()=>{
                            receiptDateRef.current.element.focus()

                          }}
                          change={(e)=>{ 

                            
                            // const date = new Date(e.value);
                            
                              console.log(e.value)
                          if (e.value != '') {
                            return  tempReceiptDate =  true;
                          }else{

                          return   tempReceiptDate = false;}
                        }

                           }
                          blur={()=>{
                            // console.log(typeof document.getElementById('ReceiptDate').value ) )
                            let checking = /^[^A-Za-z]+$/.test(document.getElementById('ReceiptDate').value)
                            if(document.getElementById('ReceiptDate').value == '' || checking == false){
                                  document.getElementById('ReceiptDate').parentElement.style.border =  '2px solid red'
                              receiptDateRef.current.element.focus()
                            }else{
                                    document.getElementById('ReceiptDate').parentElement.style.border =  '1px solid #eee'
                                    getPIBalanceByPIID()
                            }
                          }}
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
              );
            }}
          
            />
        <ColumnDirective
          headerText='ID'
          field='PaymentTypeID'
          visible={false}
        />
        <ColumnDirective
          headerText='Payment Type'
          field='PaymentType'
          width='130'
          textAlign='Left'
          edit={paymentTypeParams}
          editType='dropdownedit'
        />
        <ColumnDirective
          headerText='Bank Account'
          field='PaymentReferenceNo'
          width='110'
          defaultValue='-'
          headerTextAlign='Left'
          // visible={false}
        />
          <ColumnDirective
            defaultValue='-'
            headerText='TT Amount'
            field='PaymentDescription'
            width='110'
            // visible={false}
            headerTextAlign='Left'
          />
        <ColumnDirective
          headerText='Payment Sender'
          field='PaymentSenderName'
          defaultValue='-'
          width='110'
          headerTextAlign='Left'
        />
        <ColumnDirective
          headerText='ALLOCATION AMOUNT'
          field='ReceiptAmount'
          width='120'
          textAlign='right'
          edit={{ params :{showSpinButton:false,format:'n0'}}}
          editType='numericedit'
          format='n0'
          headerTextAlign='right'
          editTemplate={(args)=>{
            console.log(args)
            return (
              <NumericTextBoxComponent
              className='e-input'
              placeholder='ALLOCATION AMOUNT'
              id='ReceiptAmount'
              name='ReceiptAmount'
              floatLabelType='Auto'
              format={'N0'}
              // min={1}
              ref={receiptRef}
              showSpinButton={false}
              // max={state.ReceiptMax}
              value={args.ReceiptAmount}
              created={(argsaa)=>{
                console.log(argsaa)
                console.log(ProformaInvoiceDetailGrid.dataSource)
                // if(IsAddActionType == 'ddf'){

                //   let valueBookAmt = document.getElementById('BookAmount').value;
                //   console.log(GenerateBookAmount)
                //   let generateBookAmount = Number(valueBookAmt) - Number(GenerateBookAmount);
                // console.log(generateBookAmount)
                // CheckReceiptMax = Number(generateBookAmount);
                // }
                // if(IsEditActionType == true){
                  console.log(args.ReceiptAmount)
                  let receiptArray = ProformaInvoiceDetailGrid.dataSource.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ReceiptAmount }}).filter(item => item !== undefined)
                  let shortArray = ProformaInvoiceDetailGrid.dataSource.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ShortAmount }}).filter(item => item !== undefined)
                  console.log(receiptArray)
                  console.log(shortArray)
                  const sumValue = receiptArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                  const shortArrayValue = shortArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                  console.log(sumValue)
                  let valueBookAmt = document.getElementById('BookAmount').value;
                  let recieptAmts =  args.ReceiptAmount  == undefined ? 0 : args.ReceiptAmount 
                  console.log(recieptAmts)
                  let sumGenerateBookAmout = sumValue - recieptAmts;
                  console.log(sumGenerateBookAmout)
                  console.log(valueBookAmt)
                  let generateBookAmount = 0
                  if (valueBookAmt.includes(',')) {
                    // Remove the comma and convert it to a number
                    generateBookAmount = Number(valueBookAmt.replace(/,/g, '')) - sumGenerateBookAmout;
                  } else {
                    // Convert the string to a number and subtract
                    generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
                  }
                  // let generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
                  console.log(shortArrayValue)
                  console.log(generateBookAmount)
                console.log( generateBookAmount -  shortArrayValue)
                  // CheckReceiptMax = generateBookAmount  -  shortArrayValue ;
                  GenerateBookAmount =  args.ReceiptAmount ? args.ReceiptAmount : 0;
                  // console.log(ProformaInvoiceDetailGrid.dataSource)
                  // }
                  temAmt = args.ReceiptAmount ==  undefined ? 0 : args.ReceiptAmount
                  // if(IsEditActionType == true){
                  var checkRecp = 0 ;
console.log(shortArrayValue)
                  console.log(generateBookAmount)
if (shortArrayValue >= 0) {
  // If shortArrayValue is non-negative, add it to generateBookAmount
  checkRecp = generateBookAmount + Math.abs(shortArrayValue);
} else {
  // If shortArrayValue is negative, subtract its absolute value from generateBookAmount
  console.log(checkRecp)
  checkRecp = generateBookAmount + Math.abs(shortArrayValue);
}
  console.log(checkRecp)
CheckReceiptMax =  checkRecp;
            
                // }
              }}

              change={(e)=>{
              console.log(document.getElementById('ShortAmount').value)

              // let  shortAmountValue = document.getElementById('ShortAmount').value

                if(PaymentTypeName == '1'){
                  console.log(Number(CheckReceiptMax),balanceUnAllocatedAmount)
                  let  shortAmountValue = document.getElementById('ShortAmount').value
                  let geneCheckReceiptMax = Number(CheckReceiptMax) - Number(shortAmountValue)
                  console.log(geneCheckReceiptMax)
                  if(e.value <= geneCheckReceiptMax || e.value <= 0){

                  
                  if(e.value > balanceUnAllocatedAmount ){
                    // IsMaxReceiptAmt =  true
                    // document.getElementById('error-2').style.display ='inline'
                    
                    IsMaxReceiptAmt =  false;
                    temAmt =  Number(e.value)
                  }else{
                    IsMaxReceiptAmt =  false;
                    // balanceUnAllocatedAmount = balanceUnAllocatedAmount - e.value
                    console.log(state.ReceiptAmount)
                    temAmt =  Number(e.value)
                    // temAmt = Number(GenerateBookAmount ) + Number(e.value);
                    // GenerateBookAmount =  genVal
                    
                    // let  newValues =  e.value - CheckReceiptMax
                    // console.log(newValues)
                    // let generateBookAmount = Number(newValues);
                    // CheckReceiptMax = generateBookAmount;
              }
            }else{
              // IsMaxReceiptAmt =  true
              
              IsMaxReceiptAmt =  false
              if(e.value > 0){

                // document.getElementById('error-1').style.display ='inline'
              }

            }
                }else{
                  
                  console.log(CheckReceiptMax)
                  setTimeout(() => {
                    IsAddActionType =  false
                  }, 200);
                  let  shortAmountValue = document.getElementById('ShortAmount').value
                  let geneCheckReceiptMax = Number(CheckReceiptMax) - Number(shortAmountValue)
                  console.log(geneCheckReceiptMax)
                  if(e.value > geneCheckReceiptMax || e.value <= 0){
                    // IsMaxReceiptAmt =  true
                    if(e.value > 0){

                      document.getElementById('error-1').style.display = 'block'
                    }

                  }else{
                    var bookAmts = document.getElementById('BookAmount').value;
                    IsMaxReceiptAmt =  false;
                    console.log(state.ReceiptAmount)
                    temAmt =  Number(e.value)
                    // storeInitialPaymentToleranceAmount
                    // console.log(GenerateBookAmount)
                    // let genVal = Number(GenerateBookAmount ) + Number(e.value);
                    // console.log(genVal)
                    // GenerateBookAmount =  genVal 
                    // let  newValues =  Number(CheckReceiptMax) - Number(e.value)  
                    // console.log(newValues)
                    // console.log(bookAmts)
                    // let generateBookAmount =newValues;
                    // console.log(generateBookAmount)
                    // CheckReceiptMax = generateBookAmount;
              }
            }
              }}
              blur={()=>{
                console.log(IsMaxReceiptAmt)
                console.log(temAmt)
                console.log(state.InitialPaymentToleranceAmount)
                console.log(storeInitialPaymentToleranceAmount)
                if(IsMaxReceiptAmt == true || temAmt == 0){
                receiptRef.current.element.focus()
                  document.getElementById('ReceiptAmount').style.border ='2px solid red'

                }else{
                      let genVal = Number(GenerateBookAmount ) + Number(temAmt);
                    GenerateBookAmount =  genVal

                    console.log(isCrm)
                    if(isCrm == false){
                      setTimeout(() => {
                        ProformaInvoiceDetailGrid.endEdit()
                      }, 700);

                    }
                    // if(storeInitialPaymentToleranceAmount 
                //     let receiptArray = ProformaInvoiceDetailGrid.dataSource.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ReceiptAmount }}).filter(item => item !== undefined)
                //   console.log(receiptArray)
                //   const sumValue = receiptArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                //     console.log(temAmt)
                //   console.log(sumValue)
                  
                //   let SumOfReceiptAmount =  IsEditActionType == true ? sumValue : sumValue + temAmt;
                //   console.log(
                //     'TEMP AMOUTN',temAmt,
                //     'SUM OF gRID BOTH TIME',SumOfReceiptAmount ,
                //     'TOLERANCE AMOUNT', storeInitialPaymentToleranceAmount )
                  
                  
                //   // let SumOfReceiptAmount =  sumValue ;
                //   console.log(storeInitialPaymentToleranceAmount)
                //   let generateBookAmount2 = 0
                //   let valueBookAmt = document.getElementById('BookAmount').value;
                //   if (valueBookAmt.includes(',')) {
                //     // Remove the comma and convert it to a number
                //     generateBookAmount2 = Number(valueBookAmt.replace(/,/g, ''));
                //   } else {
                //     // Convert the string to a number and subtract
                //     generateBookAmount2 = Number(valueBookAmt) ;
                //   }
                //   console.log(SumOfReceiptAmount)
                //   console.log(generateBookAmount2)
                // // let makeTempAmt = IsAddActionType == true ? temAmt : 0;
                //   // if(SumOfReceiptAmount  + makeTempAmt ==  generateBookAmount2){
                //   if(SumOfReceiptAmount   ==  generateBookAmount2){
                //         console.log('book Amount is Same to sum of ALLOCATION AMOUNT')
                //         setShowDialogOfProduct(true)
                    
                //     }else{
                //       console.log(SumOfReceiptAmount ,storeInitialPaymentToleranceAmount )
                //       if(SumOfReceiptAmount < storeInitialPaymentToleranceAmount){
                //       console.log('receipt kam hai store intional tolerance sai');
                //     document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Booking-Partial Payment';
                //     // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
                //     // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'SalesAgentStatus' })
                //     setSalesAgentStatus('Booking-Partial Payment')
                //     setSalesAgentStatusID(2)
                    
                //     dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'Booking-Partial Payment' })
                //     if(isCrm == false){
                      
                //       setTimeout(() => {
                      
                //         // document.getElementById('ShortAmount').focus()
                //         ProformaInvoiceDetailGrid.endEdit()
                //     }, 600);
                   
                //   }
                //   }else if(SumOfReceiptAmount >= storeInitialPaymentToleranceAmount){
                //     document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'BL Hold';
                //     // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
                //     // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'SalesAgentStatus' })
                //     setSalesAgentStatus('BL Hold')
                //     setSalesAgentStatusID(3)
                //     dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 3 ,SalesAgentStatus:'BL Hold' })
                //     if(isCrm == false){
                      
                //         setTimeout(() => {
                        
                //           // document.getElementById('ShortAmount').focus()
                //           ProformaInvoiceDetailGrid.endEdit()
                //       }, 600);
                     
                    // }
// 
                  // }
                    // }
                    // console.log(temAmt)

                  document.getElementById('ReceiptAmount').style.border ='1px solid #eee'
                  document.getElementById('error-1').style.display ='none'
                  document.getElementById('error-2').style.display ='none'
                }
              }}  
            />
            )
          }}
          />
      
      
         <ColumnDirective
          headerText='Short Amount'
          field='ShortAmount'
          ref={state.ShortAmountRef}
          width='100'
          allowEditing={isCrm}
          defaultValue={0}
          edit={{ params :{showSpinButton:false,format:'n0',min:0}}}
          // editType='numericedit'
          min={0}
          headerTextAlign='Center'
          textAlign='right'
          editTemplate={(args)=>{
            console.log(args)
            return (
              <TextBoxComponent
              className='e-input'
              type='number'
              name='ShortAmount'
              id='ShortAmount'
              readOnly={!isCrm}
               disabled={!isCrm}
              floatLabelType='Auto'
              value={args.ShortAmount}
              ref={state.ShortAmountRef}
              
              edit={{ params :{showSpinButton:false,format:'n0',min:0}}}
              change={
                (e)=>{
              if(e.value < 0 ){
                IsMaxShortAmt =  true
                document.getElementById('error-2').style.display ='inline'
                
              }else{
                IsMaxShortAmt =  false;
              }}}

              blur={(e)=>{
                // let receiptAmt = document.getElementById('ReceiptAmount').value
                let shortAmt =   document.getElementById('ShortAmount').value
                console.log(temAmt  )
                console.log(shortAmt  )
                if(shortAmt > temAmt || IsMaxShortAmt == true ){
                  state.ShortAmountRef.current.element.focus()
                  document.getElementById('ShortAmount').style.border= '1px solid red'
                  // console.log(irtiza)
                }else{
                  document.getElementById('ShortAmount').style.border= '1px solid #eaeaea'
                }
              }}
            />
            )
          }}
        /> 
        <ColumnDirective
          headerText='ID'
          field='SalesAgentStatusID'
          visible={false}
        />
        <ColumnDirective
          headerText='Sales Agent Status'
          field='SalesAgentStatus'
          width='200'
          textAlign='Left'
          // edit={salesAgentStatusParams}
          allowEditing={false}
          // editType='dropdownedit'
        />
          <ColumnDirective
          headerText='Sales Agent Status Date'
          field='SalesAgentStatusDate'
          width='135'
          allowEditing={false}
          // defaultValue={state.SalesAgentStatusDate}
          defaultValue={new Date(moment())}
          headerTextAlign='Right'
          editType='datepickeredit'
          // format={formatOptions}
          textAlign='right'
          type='date'
            format='dd/MM/yy'
        />

{/* { isCrm == true ?  */}
          {/* <ColumnDirective
          headerText='Payment Verification Status'
          field='PaymentVerificationStatus'
          width='150'
          allowEditing={isCrm}
          headerTextAlign='Center'
          editTemplate={(args)=>{
            console.log(args)
            return (
                <label className="checkbox-container">
                <input
                  type='checkbox'
                  className={`checkbox ${props.PaymentVerificationStatus ? 'yellow': ''}`}
                  id={`checkbox_${props.CostingProductRowID}`}
                  checked={props.PaymentVerificationStatus}
                  readOnly={!isCrm}
                  disabled={!isCrm}
                  
                  onChange={(e) => {
                    dispatch({ type: 'PaymentVerificationStatus' ,PaymentVerificationStatus: e.target.checked})
                    console.log(e, props)
                  }}
                />
              <span className="checkmark"></span>
              </label>
            )
          }}
          /> */}
          <ColumnDirective
          headerText='Payment Verification Status'
          field='PaymentVerificationStatus'
          width='150'
          allowEditing={isCrm}
          headerTextAlign='Center'
          textAlign='Left'
          // visible={isCrm}
          edit={paymentVerificationStatusParams}
          editType='dropdownedit'
          />
          {/* // :null} */}
          
       {/* {isCrm == true ?  */}
        <ColumnDirective
          headerText='Payment Verification Status Date'
          field='PaymentVerificationStatusDate'
          width='145'
          
          textAlign='right'
          type='date'
            format='dd/MM/yy'
          allowEditing={false}
          // defaultValue={state.PaymentVerificationStatusDate}
          headerTextAlign='Center'
          //  format={formatOptions}
          // editType='datepickeredit'
          // type='date'
              // format='dd/MM/yy'
        />
        {/* :<></>} */}
        <ColumnDirective
          headerText='ID'
          field='DesignationID'
          visible={false}
        />
        {/* {isCrm == true ? */}
        <ColumnDirective
          headerText='CRM'
          field='CRMName'
          allowEditing={false}
        
          width='140'
          textAlign='Center'
          // edit={designationNameParams}
          // editType='dropdownedit'
        />
        {/* :<></>} */}
        <ColumnDirective
          headerText='ID'
          field='CRMStatusID'
          visible={false}
        />
      {/* {isCrm == true ?  */}

        <ColumnDirective
        //  allowEditing={isCrmStatusEdit}
         allowEditing={false}
         headerText='CRM Status' 
          field='CRMStatus'
          // visible={isCrm}


          width='140'
          
          textAlign='Center'
          // edit={cRMStatusParams}
          // editType='dropdownedit'
        />
        {/* :<></>} */}
        {/* {isCrm == true ?  */}
         <ColumnDirective
          headerText='CRM Status Date'
          field='CRMStatusDate'
          width='145'
          textAlign='right'
          type='date'
            format='dd/MM/yy'
          // allowEditing={false}
          // defaultValue={state.CRMStatusDate}
          allowEditing={false}
          headerTextAlign='Center'
          // editType='datepickeredit'
          //  format={formatOptions}
          // allowEditing={false}
          // type='date'
              // format='dd/MM/yy'
              
        />
        
         {/* : <></>} */}
      </ColumnsDirective>
    <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    format={'n0'}

                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    format={'n0'}

                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              {/* <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    // groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    // groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              */}
           </AggregatesDirective> 
      <Inject
          services={[Edit, Toolbar, Page, Sort, Filter, Aggregate, Reorder]}
      />
    </GridComponent>
    </div>

   
   
    <br/>
    <br/>
    <br/>
    <br/>
        {/* <PIGrid actionComplete={actionComplete} /> */}
    </div>
  )
  
}






























// import React, { useEffect, useState, useRef, useReducer } from 'react';
// import ProductService from '../../services/product.service';
// import SetupService from '../../services/setup.service';
// import './PI.scss';
// // import PIGrid from './pi-grid'
 

// import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
// import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
// import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import SetupAdd from '../setup/setup-add';
// import SecurityService from '../security/security.service';
// import CustomerService from '../../services/customer.service';
// import CheckDuplicate from '../check-duplicate/check-duplicate';
// import { DatePicker } from 'formik-material-ui-pickers';
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
// import moment from 'moment';
// import PIService from '../../services/PI.service';
// import EmployeeService from '../../services/employee.service';
// import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
// import { AutoComplete } from '@syncfusion/ej2-dropdowns';
// import { CheckBox } from '@syncfusion/ej2-react-buttons';
// import TransferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';

// import {
//   GridComponent,
//   Inject,
//   Toolbar,
//   ToolbarItems,
//   Edit,
//   EditSettingsModel,
//   Page,
//   Sort,
//   PdfExport,
//   PdfExportProperties,
//   ExcelExport,
//   Grid,
//   Resize,
//   FilterSettingsModel,
//   Filter,
//   Group,
//   GroupSettingsModel,
//   AggregateColumnDirective,
//   ColumnDirective,
//   ColumnsDirective,
//   AggregateColumnsDirective,
//   AggregateDirective,
//   AggregatesDirective,
//   Aggregate,
//   Freeze,
//   ColumnChooser,
//   Reorder,
//   DialogEditEventArgs,
//   CommandColumn,
//   CommandModel,
//   actionBegin,
// } from '@syncfusion/ej2-react-grids';
// import { format } from 'date-fns';

// let paymentTypeElem: HTMLElement;
// let paymentTypeObj: AutoComplete;
// let salesAgentStatusElem: HTMLElement;
// let salesAgentStatusObj: AutoComplete;
// let designationNameElem: HTMLElement;
// let designationNameObj: AutoComplete;
// let cRMStatusElem: HTMLElement;
// let cRMStatusObj: AutoComplete;
// const complexReducer = (state, action) => {
    // console.log(state)
    // console.log(action)
//   switch (action.type) {
//     case 'PINo':
//       return {...state,PINo: action.PINo  };
//       break;
//     case 'PIReferenceNo':
//       return {...state, PIReferenceNo: action.PIReferenceNo};
//       break;
//     case 'PIDate':
//       return {...state, PIDate: action.PIDate};
//       break;
//     case 'lierNSuppame':
//       return {
//         ...state,
       
//         SupplierName:action.SupplierName,
//         SupplierID:action.SupplierID
//        };
//        break;
//     case 'SupplierDataSource':
//       return {
      
//         ...state,
//         LoadingSupplier:action.LoadingSupplier,
//         SupplierDataSource: action.SupplierDataSource,
//        };
//        break;
//        case 'CustomerName':
//         return {
//           ...state,
         
//           CustomerName:action.CustomerName,
//           CustomerID:action.CustomerID
          

//          };
//        break;
//     case 'CustomerDataSource':
//       return {
//         ...state,
       
//         LoadingCustomer:action.LoadingCustomer,
//         CustomerDataSource: action.CustomerDataSource,
//        };
//        break;
//     case 'EmployeeDataSource':
//       return {
//         ...state,
       
//         LoadingEmployee:action.LoadingEmployee,
//         EmployeeDataSource: action.EmployeeDataSource,
//        };
//        break;
//     case 'CountryCityPortDataSource':
//       return {
//         ...state,
       
//         LoadingCountryCityPort:action.LoadingCountryCityPort,
//         CountryCityPortDataSource: action.CountryCityPortDataSource,
//        };
//        break;
//     case 'ShipmentTypeDataSource':
//       return {
//         ...state,
       
//         LoadingShipmentType:action.LoadingShipmentType,
//         ShipmentTypeDataSource: action.ShipmentTypeDataSource,
//        };
//        break;
//     case 'CurrencyNameDataSource':
//       return {
//         ...state,
       
//         LoadingCurrencyName:action.LoadingCurrencyName,
//         CurrencyNameDataSource: action.CurrencyNameDataSource,
//        };
//        break;
//     case 'PurchaseSourceDataSource':
//       return {
//         ...state,
       
//         LoadingPurchaseSource:action.LoadingPurchaseSource,
//         PurchaseSourceDataSource: action.PurchaseSourceDataSource,
//        };
//        break;
//     case 'TradeBoardLetterStatusDataSource':
//       return {
//         ...state,
       
//         LoadingTradeBoardLetterStatus:action.LoadingTradeBoardLetterStatus,
//         TradeBoardLetterStatusDataSource: action.TradeBoardLetterStatusDataSource,
//        };
//        break;
//        case 'EmployeeName':
//         return {
//           ...state,
         
//           EmployeeName:action.EmployeeName,
//           EmployeeID:action.EmployeeID
//          };
//          break;
//        case 'ConsigneeName':
//         return {
//           ...state,
//           ConsigneeName:action.ConsigneeName
//          };
//          break;
//        case 'ConfirmConsigneeName':
//         return {
//           ...state,
//           ConfirmConsigneeName:action.ConfirmConsigneeName
//          };
//          break;
//        case 'CountryCityPort':
//         return {
//           ...state,
//           CountryCityPort:action.CountryCityPort,
//           CountryCityPortID:action.CountryCityPortID
//          };
//          break;
//        case 'ShipmentType':
//         return {
//           ...state,
//           ShipmentType:action.ShipmentType,
//           ShipmentTypeID:action.ShipmentTypeID
//          };
//          break;
//        case 'CurrencyName':
//         return {
//           ...state,
//           CurrencyName:action.CurrencyName,
//           CurrencyID:action.CurrencyID
//          };
//          break;
//        case 'CommissionAmountPerUnit':
//         return {
//           ...state,
//           CommissionAmountPerUnit:action.CommissionAmountPerUnit
          
//          };
//          break;
//        case 'ApproveCommissionAmountPerUnit':
//         return {
//           ...state,
//           ApproveCommissionAmountPerUnit:action.ApproveCommissionAmountPerUnit
          
//          };
//          break;
//        case 'AbsoluteCommissionAmount':
//         return {
//           ...state,
//           AbsoluteCommissionAmount:action.AbsoluteCommissionAmount
          
//          };
//          break;
//        case 'ApproveAbsoluteCommissionAmount':
//         return {
//           ...state,
//           ApproveAbsoluteCommissionAmount:action.ApproveAbsoluteCommissionAmount
          
//          };
//          break;
//        case 'BookAmount':
//         return {
//           ...state,
//           BookAmount:action.BookAmount
          
//          };
//          break;
//        case 'PrimaryCommissionPercentOnAdjustment':
//         return {
//           ...state,
//           PrimaryCommissionPercentOnAdjustment:action.PrimaryCommissionPercentOnAdjustment
          
//          };
//          break;
//        case 'ApprovePrimaryCommissionPercentOnAdjustment':
//         return {
//           ...state,
//           ApprovePrimaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment,
//           ApproveSecondaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment ? false : true
          
//          };
//          break;
//        case 'SecondaryCommissionPercentOnAdjustment':
//         return {
//           ...state,
//           SecondaryCommissionPercentOnAdjustment:action.SecondaryCommissionPercentOnAdjustment
          
//          };
//          break;
//        case 'ApproveSecondaryCommissionPercentOnAdjustment':
//         return {
//           ...state,
//           ApproveSecondaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment,
//           ApprovePrimaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment ? false: true,
//          };
//          break;
//        case 'AdjustmentAmount':
//         return {
//           ...state,
//           AdjustmentAmount:action.AdjustmentAmount
//          };
//          break;
//        case 'BasePriceAgent':
//         return {
//           ...state,
//           BasePriceAgent:action.BasePriceAgent
//          };
//        case 'ShowBasePriceCompany':
//         return {
//           ...state,
//           IsShowBasePriceCompany:action.IsShowBasePriceCompany
//          };
//          break;
//        case 'PurchaseSource':
//         return {
//           ...state,
//           PurchaseSource:action.PurchaseSource,
//           PurchaseSourceID:action.PurchaseSourceID
//          };
//          break;
//        case 'TradeBoardLetterStatus':
//         return {
//           ...state,
//           TradeBoardLetterStatus:action.TradeBoardLetterStatus,
//           TradeBoardLetterStatusID:action.TradeBoardLetterStatusID
//          };
//          break;
//        case 'Remarks':
//         return {
//           ...state,
//           Remarks:action.Remarks,
         
//          };
//          break;
//        case 'PaymentType':
//         return {
//           ...state,
//           PaymentType:action.PaymentType,
//           PaymentTypeID:action.PaymentTypeID,
         
//          };
//          break;
//        case 'PaymentTypeDataSource':
//         return {
//           ...state,
//           PaymentTypeDataSource:action.PaymentTypeDataSource
//          };
//          break;
      
//        case 'ReceiptAmount':
//         return {
//           ...state,
//           ReceiptAmount:action.ReceiptAmount
//          };
//          break;
//          case 'SalesAgentStatus':
//           return {
//             ...state,
//             SalesAgentStatus:action.SalesAgentStatus,
//             SalesAgentStatusID:action.SalesAgentStatusID,
           
//            };
//            break;
//          case 'SalesAgentStatusDataSource':
//           return {
//             ...state,
//             SalesAgentStatusDataSource:action.SalesAgentStatusDataSource
//            };
//            break;
//          case 'IsCRMDesignation':
//           return {
//             ...state,
//             IsCRMDesignation:action.IsCRMDesignation
//            };
//            break;
//          case 'PaymentVerificationStatus':
//           return {
//             ...state,
//             PaymentVerificationStatus:action.PaymentVerificationStatus
//            };
//            break;
//          case 'DesignationNameDataSource':
//           return {
//             ...state,
//             DesignationNameDataSource:action.DesignationNameDataSource
//            };
//            break;
//          case 'CRMStatusDataSource':
//           return {
//             ...state,
//             CRMStatusDataSource:action.CRMStatusDataSource
//            };
//            break;
//          case 'ETA':
//           return {
//             ...state,
//             ETA:action.ETA
//            };
//            break;
//          case 'ETD':
//           return {
//             ...state,
//             ETD:action.ETD
//            };
//            break;
//            case 'DesignationName':
//             return {
//               ...state,
//               DesignationName:action.DesignationName,
//               DesignationID:action.DesignationID,
             
//              };
//              break;
//            case 'CRMStatus':
//             return {
//               ...state,
//               CRMStatus:action.CRMStatus,
//               CRMStatusID:action.CRMStatusID,
             
//              };
//              break;
//            case 'VoyageNo':
//             return {
//               ...state,
//               VoyageNo:action.VoyageNo,
              
             
//              };
//              break;
//              case 'BalanceUnAllocatedAmount':
//         return {
//           ...state,
         
//           BalanceUnAllocatedAmount:action.BalanceUnAllocatedAmount,
      
//         };
//        break;
//            case 'VesselName':
//             return {
//               ...state,
//               VesselName:action.VesselName,
              
             
//              };
//              break;
//            case 'PaymentDetailDataSource':
//             return {
//               ...state,
//               PaymentDetailDataSource:action.PaymentDetailDataSource,
              
             
//              };
//              break;
//     default:
//       return state;
//   }
// };
// // let dataSoureGrid =[]
// let ProformaInvoiceDetailGrid: Grid
// export default function PIAdd(props) {
  // console.log('props',props)

//   let PaymentDetailDataSourceTry = []

//   const [state, dispatch] = useReducer(complexReducer, { 
//     PIID:props.PIID,
//     PIReferenceNo:props.PIReferenceNo ? props.PIReferenceNo : '',
//      PINo: props.PINo ? props.PINo :'',
//      PIDate:new Date(moment()),
//      LoadingCustomer:true,
//      CustomerDataSource: [],
//     LoadingSupplier:true,
//     SupplierDataSource: [],
//     LoadingEmployee:true,
//     EmployeeDataSource: [],
//     LoadingCountryCityPort:true,
//     CountryCityPortDataSource: [],
//     LoadingShipmentType:true,
//     ShipmentTypeDataSource: [],
//     LoadingCurrencyName:true,
//     CurrencyNameDataSource: [],
//     LoadingPurchaseSource:true,
//     PurchaseSourceDataSource: [],
//     LoadingTradeBoardLetterStatus:true,
//     TradeBoardLetterStatusDataSource: [],
//     SupplierRef: useRef(null),
//     CustomerRef: useRef(null),
//     EmployeeRef: useRef(null),
//     CountryCityPortRef: useRef(null),
//     ShipmentTypeRef: useRef(null),
//     CurrencyNameRef: useRef(null),
//     PurchaseSourceRef: useRef(null),
//     TradeBoardLetterStatusRef: useRef(null),
//     SupplierName: props.SupplierName ? props.SupplierName :'',
//     CustomerName: props.CustomerName ? props.CustomerName :'',
//     EmployeeName:props.SalesAgentName ? props.SalesAgentName :'',
//     SupplierID:props.SupplierID ? props.SupplierID :'',
//     CustomerID:props.CustomerID ? props.CustomerID :'',
//     EmployeeID:props.SalesAgentID ? props.SalesAgentID :'',
//     ConsigneeName: props.ConsigneeName ? props.ConsigneeName :'',
//     ConfirmConsigneeName: props.ConfirmConsigneeName ? props.ConfirmConsigneeName :false,
//     CountryCityPort:props.CountryCityPort ? props.CountryCityPort : '',
//     CountryCityPortID:props.CountryCityPortID ? props.CountryCityPortID : '',
//     ShipmentType: props.ShipmentType ? props.ShipmentType :'',
//     ShipmentTypeID: props.ShipmentTypeID  ? props.ShipmentTypeID : '',
//     CurrencyName: props.CurrencyName ? props.CurrencyName :'',
//     CurrencyID:props.CurrencyID ? props.CurrencyID :'',
//     CommissionAmountPerUnit: props.CommissionAmountPerUnit ? props.CommissionAmountPerUnit :0,
//     ApproveCommissionAmountPerUnit:props.ApproveCommissionAmountPerUnit ? props.ApproveCommissionAmountPerUnit :false,
//     AbsoluteCommissionAmount:props.AbsoluteCommissionAmount ? props.AbsoluteCommissionAmount : '',
//     ApproveAbsoluteCommissionAmount:props.ApproveAbsoluteCommissionAmount? props.ApproveAbsoluteCommissionAmount :false,
//     BookAmount: props.BookAmount ?props.BookAmount :'',
//     PrimaryCommissionPercentOnAdjustment:props.PrimaryCommissionPercentOnAdjustment ? props.PrimaryCommissionPercentOnAdjustment:0,
//     ApprovePrimaryCommissionPercentOnAdjustment:false,
//     SecondaryCommissionPercentOnAdjustment: props.SecondaryCommissionPercentOnAdjustment ? props.SecondaryCommissionPercentOnAdjustment :0,
//     ApproveSecondaryCommissionPercentOnAdjustment: props.ApproveSecondaryCommissionPercentOnAdjustment ? props.ApproveSecondaryCommissionPercentOnAdjustment :false,
//     AdjustmentAmount:props.AdjustmentAmount ? props.AdjustmentAmount :'',
//     BasePriceAgent:props.BasePriceAgent ? props.BasePriceAgent :'0',
//     BasePriceCompany: props.BasePriceCompany ? props.BasePriceCompany :0,
//     IsShowBasePriceCompany:false,
//     PurchaseSource: props.PurchaseSource ? props.PurchaseSource :'',
//     PurchaseSourceID: props.PurchaseSourceID ? props.PurchaseSourceID :'',
//     TradeBoardLetterStatus:props.TradeBoardLetterStatus ? props.TradeBoardLetterStatus :'',
//     TradeBoardLetterStatusID: props.TradeBoardLetterStatusID ? props.TradeBoardLetterStatusID : '',
//     Remarks:props.Remarks ? props.Remarks :'',
//     VesselName: props.VesselName ? props.VesselName :'-',
//     VoyageNo:props.VoyageNo ? props.VoyageNo :'-',
//     ETA: props.ETA ? new Date(props.ETA) :'',
//     ETD: props.ETD ? new Date(props.ETD) : '',
//     PaymentDetailDataSource:[],
//     PaymentType:'',
//     PaymentTypeID:'',
//     PaymentTypeDataSource:[],
//     ShortAmountRef:useRef(null),
//     SalesAgentStatus:props.SalesAgentStatus  ? props.SalesAgentStatus :'',
//     SalesAgentStatusID:props.SalesAgentStatusID ? props.SalesAgentStatusID :'',
//     SalesAgentStatusDataSource:[],
//     SalesAgentStatusDate: props.SalesAgentStatusDate  ?  props.SalesAgentStatusDate  : new Date(moment()),
//     IsCRMDesignation:false,
//     ShortAmount: props.ShortAmount ? props.ShortAmount : 0,
//     PaymentVerificationStatus:false,
//     PaymentVerificationStatusDate:new Date(moment()),
//     DesignationNameDataSource:[],
//     DesignationName:'',
//     DesignationID:'',
//     CRMStatusDataSource:[],
//     CRMStatus:props.CRMStatus ? props.CRMStatus :'',
//     CRMStatusID:props.CRMStatusID ? props.CRMStatusID : '',
//     CRMStatusDate: props.CRMStatusDate ? props.CRMStatusDate : new Date(moment()),
//     BalanceUnAllocatedAmount:'',
//     });
// console.log(state)
//   const [irtiza, setirtiza] = useState(10);
//   const [showAddNewDialog, setShowAddNewDialog] = useState(false);
//   const [piData, setPiData] = useState([]);
//   const [dialogType, setDialogType] = useState('');
 
//   const [addNewClicked, setAddNewClicked] = useState(false); 
//   const [checkDuplicate, setCheckDuplicate] = useState(false);
//   const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
//   const [dataSoureGrid, setDataSoureGrid] = useState([]);
//     useEffect(() => {
      // console.log(state.SupplierID)
      // console.log(state.CurrencyID)
//       if(state.SupplierID != '' && state.EmployeeID != ''){
//         getPrimaryCommissionPercentOnAdjustment(state.SupplierID ,state.EmployeeID)
//       }
//       if(state.SupplierID != '' && state.CurrencyID != '' && state.EmployeeID != ''){
        // console.log(state.SupplierID)
        // console.log(state.CurrencyID)
//         getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
//       return  getCommissionAmountPerUnit(state.CurrencyID ,state.SupplierID ,state.EmployeeID)

//       }
//     }, [state.SupplierID ,state.CurrencyID ,state.EmployeeID]);

//     // useEffect(() => {
      // console.log(state.SupplierID)
      // console.log(state.CurrencyID)
//     //   if(state.CurrencyID != '' && state.CustomerID != '' && state.PIDate != ''){
//     //     getBalanceUnAllocatedAmount(state.PIDate,state.CurrencyID,state.CustomerID)
        
//     //   }
      
//     // }, [state.CurrencyID ,state.CustomerID, state.PIDate]);


//   const textBoxChange = (e) => {
    // console.log(e)
//     dispatch({ type: e.target.name , [e.target.name]: e?.value })
   
// };

//   const AutoCompleteChange = (e,name) => {
    // console.log(e,name)
//     switch (name) {
//       case 'SupplierName':
//         dispatch({ type: 'SupplierName' ,SupplierName: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'SupplierName' , 
//             SupplierName: e?.itemData?.value ? e?.itemData?.value : '',
//             SupplierID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                   getCommissionAmountPerUnit(state.CurrencyID,e?.itemData?.id,state.EmployeeID)
//                   getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,e?.itemData?.id ,state.EmployeeID)

//                   break;
//       case 'CustomerName':
//         dispatch({ type: 'CustomerName' ,CustomerName: e?.itemData?.value ? e?.itemData?.value : ''})

//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'CustomerName' , 
//             CustomerName: e?.itemData?.value ? e?.itemData?.value : '',
//             CustomerID: e?.itemData?.id ? e?.itemData?.id : '',
           
//                   })
//                   // EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
//                   // EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
//                   getEmployeeName( e?.itemData?.id )
//                   getCustomerNameForConsigneeName(e?.itemData?.id )
//                 }
//                   break;
//       case 'EmployeeName':
//         dispatch({ type: 'EmployeeName' ,EmployeeName: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'EmployeeName' , 
//             EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
//             EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                     break;
//       case 'CountryCityPort':
//         dispatch({ type: 'CountryCityPort' ,CountryCityPort: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'CountryCityPort' , 
//             CountryCityPort: e?.itemData?.value ? e?.itemData?.value : '',
//             CountryCityPortID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                     break;
//       case 'ShipmentType':
//         dispatch({ type: 'ShipmentType' ,ShipmentType: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'ShipmentType' , 
//             ShipmentType: e?.itemData?.value ? e?.itemData?.value : '',
//             ShipmentTypeID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                     break;
//       case 'CurrencyName':
//         dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'CurrencyName' , 
//             CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
//             CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                   getCommissionAmountPerUnit(e?.itemData?.id,state.SupplierID,state.EmployeeID)
//                   getSecondaryCommissionPercentOnAdjustment(e?.itemData?.id ,state.SupplierID,state.EmployeeID)
//                     break;
//       case 'PurchaseSource':
//         dispatch({ type: 'PurchaseSource' ,PurchaseSource: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'PurchaseSource' , 
//             PurchaseSource: e?.itemData?.value ? e?.itemData?.value : '',
//             PurchaseSourceID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                     break;
//       case 'TradeBoardLetterStatus':
//         dispatch({ type: 'TradeBoardLetterStatus' ,TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : ''})
//         if(e?.e?.srcElement?.id)  {
          // console.log('uom change: ', );
//           dispatch({ 
//             type: 'TradeBoardLetterStatus' , 
//             TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : '',
//             TradeBoardLetterStatusID: e?.itemData?.id ? e?.itemData?.id : ''
//                   })}
//                     break;
      
//             default:
//         return state;
//     }
   
   
// };
// const userId = SecurityService.getLoggedInUserId();
//   useEffect(() => {
//   setTimeout(() => {
    
  
//     if(state.PINo  != '' && state.PIReferenceNo != ''  && checkDuplicate == true){
      // console.log(props.dataList)
      // console.log()
//       var check = false
//       if(props.isAdd ==  true){

//          check = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
//           PINo:state.PINo,
//           PIReferenceNo:state.PIReferenceNo,
          
//            })
//       }
     
//       // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
      // console.log(check)
//       // setCheckDuplicate(check)
//       if(!check ){
//         setCheckDuplicateValue('')
//         // if(state.PaymentDetailDataSource.length > 0){document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';}
//         document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
//       }else{

//         setCheckDuplicateValue('Duplicate Value Detected!')
//         document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        
//     }
//    }
//   }, 500);
//   }, [state.PINo ,state.PIReferenceNo ]);
// useEffect(() => {
//     setTimeout(() => {
//   if(state.PaymentDetailDataSource.length > 0){
//     document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
//   }else{
//     document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
//   }
// }, 800);
// }, [state.PaymentDetailDataSource]);
//   useEffect(() => {
//     setTimeout(() => {
//       setCheckDuplicate(true)
//       if(props.isAdd == false && state.PaymentDetailDataSource.length > 0){
//         document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
  
//       }
//     }, 500);

  
//     getSupplier()
//     getCustomer()
//     getEmployee()
//     getAllEmployee()
//     getCountryCityPort()
//     getShipmentType()
//     getCurrency()
//     getPurchaseSource()
//     getTradeBoardLetterStatus()
//     setTimeout(() => {
//       getPaymentType()
//       getSalesAgentStatus()
//       checkIsCRMDesignation()
//       getDesignation()
//       getCRMStatus()
//     //   setPiData([{
//     //     CRMID: null,
//     // CRMName: null,
//     // CRMStatus: null,
//     // CRMStatusDate: null,
//     // CRMStatusID: null,
//     // CreatedByUserID: 1,
//     // CreatedByUserName: "ADMIN",
//     // CreationDate: "2023-09-29T11:01:00.000Z",
//     // DeletedByUserID: null,
//     // DeletedByUserName: null,
//     // IsDeleted: false,
//     // PIID: 1006,
//     // PaymentDescription: "paydescu ",
//     // PaymentReferenceNo: "ssms",
//     // PaymentSenderName: "paysend u",
//     // PaymentType: "UnAllocated",
//     // PaymentTypeID: 1,
//     // PaymentVerificationStatus: false,
//     // PaymentVerificationStatusDate: "2023-09-29T15:19:00.000Z",
//     // ProformaInvoicePaymentDetailID: 1015,
//     // ReceiptAmount: 10,
//     // ReceiptDate: "2023-09-28T21:30:00.000Z",
//     // SalesAgentStatus: "Booking-Non-Payment",
//     // SalesAgentStatusDate: "2023-09-29T10:57:00.000Z",
//     // SalesAgentStatusID: 1,
//     // ShortAmount: 0,
//     // UpdatedByUserID: 1,
//     // UpdatedByUserName: "ADMIN",
//     //   }])
//     }, 500);
//     // ProformaInvoiceDetailGrid.actionComplete()
//   //   if(props.isAdd == false){
//   //   setTimeout(() => {

//   //     // dispatch({ type: `CommissionAmountPerUnit` ,  CommissionAmountPerUnit:props.CommissionAmountPerUnit})
//   //     // dispatch({ type: `SecondaryCommissionPercentOnAdjustment` ,  SecondaryCommissionPercentOnAdjustment:props.SecondaryCommissionPercentOnAdjustment ? props.SecondaryCommissionPercentOnAdjustment : 0})
//   //     // dispatch({ type: `PrimaryCommissionPercentOnAdjustment` ,  PrimaryCommissionPercentOnAdjustment:props.PrimaryCommissionPercentOnAdjustment ? props.PrimaryCommissionPercentOnAdjustment : 0})
      // console.log(props.PIID)
//   //     getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
//   //     getPrimaryCommissionPercentOnAdjustment(state.SupplierID ,state.EmployeeID)
//   //     getCommissionAmountPerUnit(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
//   // },1000)}
//   // return PIService.getPaymentDetail(props.PIID)
//   // .then((response) => {
      // console.log('get Payment Detail response', response.data.recordset);
//     //   // setPiData(response.data.recordset[0])
//     //   // ProformaInvoiceDetailGrid.dataSourceChanged =  response.data.recordset[0];
//     //   PaymentDetailDataSourceTry = response.data.recordset
//     //   dispatch({ type: `PaymentDetailDataSource` ,  PaymentDetailDataSource:response.data.recordset})

//     // })
//     // .catch((e) => {
        // console.log(e);
//       //  dispatch({ type: `PaymentDetailDataSource`  , PaymentDetailDataSource:[]})
//     // });
//   //     setTimeout(()=>{
//   // return  getPaymentDetailForGrid(1006)
//   // },3000)
//   // setTimeout(() => {
    // console.log(dataSoureGrid)
//   // }, 4000);
//     // getProductFinishSize();
//     // getProductCategory();
//     // getUOMs();
//     // getProductDesc()

//   }, []);
//   // (function() {
//   //   // Your code here
//   //   if(props.isAdd == false){
//   //     setTimeout(() => {
        
//   //       return getPaymentDetailForGrid(props.PIID)
//   //     }, 500);
//   //   }
//   // })();
  
//   const getBalanceUnAllocatedAmount = async () => {
    // console.log( 'state.ToCustomerID,     state.CurrencyID,     state.TransferDate ' )
    // console.log(state.TransferDate)
//     TransferUnAllocatedPaymentService.getBalanceUnAllocatedAmount(moment(state.PIDate ).format('DD-MMM-YY'),state.CurrencyID, state.CustomerID)
//       .then((response) => {
        // console.log('getBalanceUnAllocatedAmount', response.data.recordset);
//         dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:response.data.recordset[0].BalanceUnAllocatedAmount.toLocaleString()})

//       })
//       .catch((e) => {
        // console.log(e);
//         dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:''})
//       });
//   };
  
//   const getSecondaryCommissionPercentOnAdjustment = async (currencyID,supplierID,employeeID) => {
//     PIService.getSecondaryCommissionPercentOnAdjustment(currencyID,supplierID,employeeID)
//       .then((response) => {
       
        // console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `SecondaryCommissionPercentOnAdjustment` ,  SecondaryCommissionPercentOnAdjustment:response.data.recordset[0].value ? response.data.recordset[0].value : 0})

        // console.log(state.SecondaryCommissionPercentOnAdjustment)
//       })
//       .catch((e) => {
        // console.log(e);
//         dispatch({ type: `SecondaryCommissionPercentOnAdjustment`  , SecondaryCommissionPercentOnAdjustment:0})
        
//       });
//   };
//   const getPrimaryCommissionPercentOnAdjustment = async (supplierID ,employeeID) => {
//     PIService.getPrimaryCommissionPercentOnAdjustment(supplierID ,employeeID)
//       .then((response) => {
       
        // console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `PrimaryCommissionPercentOnAdjustment` ,  PrimaryCommissionPercentOnAdjustment: response.data.recordset[0].value ? response.data.recordset[0].value :0})

        // console.log(state.PrimaryCommissionPercentOnAdjustment)
//       })
//       .catch((e) => {
        // console.log(e);
//         dispatch({ type: `PrimaryCommissionPercentOnAdjustment`  , PrimaryCommissionPercentOnAdjustment:0})
        
//       });
//   };
//   const getCommissionAmountPerUnit = async (currencyID,supplierID,employeeID) => {
//     PIService.getCommissionAmountPerUnit(currencyID,supplierID,employeeID)
//       .then((response) => {
       
        // console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `CommissionAmountPerUnit` ,  CommissionAmountPerUnit:response.data.recordset[0].value ? response.data.recordset[0].value : 0})

//         console.log(state.CommissionAmountPerUnit)
//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `CommissionAmountPerUnit`  , CommissionAmountPerUnit:0})
        
//       });
//   };
//   const getCustomerNameForConsigneeName = async (id) => {
//     CustomerService.getCustomerNameForConsigneeName(id)
//       .then((response) => {
//                 console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `ConsigneeName` ,  ConsigneeName:response.data.recordset[0].value})

//         console.log(state.EmployeeID)
        
//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `ConsigneeName`  , ConsigneeName:""})
//       });
//   };
//   const getEmployeeName = async (id) => {
//     PIService.getEmployeeName(id)
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `EmployeeName` ,  EmployeeName:response.data.recordset[0].value,EmployeeID : response.data.recordset[0].id})

//         console.log(state.EmployeeID)
//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `EmployeeName`  , EmployeeName:"", EmployeeID:""})
//       });
//   };
//   const getSupplier = async (type: string) => {
//     PIService.getSupplierForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `SupplierDataSource` ,  SupplierDataSource:response.data.recordset,LoadingSupplier:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `SupplierDataSource`  , SupplierDataSource:[],LoadingSupplier:false})
//       });
//   };
  
//   const getCustomer = async (type: string) => {
//     PIService.getCustomerForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:response.data.recordset,LoadingCustomer:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
//       });
//   };
//   const getEmployee = async (type: string) => {
//     EmployeeService.getEmployeeForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `EmployeeDataSource` ,  EmployeeDataSource:response.data.recordset,LoadingEmployee:false})
//               })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `EmployeeDataSource`  , EmployeeDataSource:[],LoadingEmployee:false})
//       });
//   };
//   const getAllEmployee = async (type: string) => {
//     PIService.getAllEmployee()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         console.log(userId)
//         let isShowBasePriceCompany = response.data.recordset.filter((val)=> val.UserID == userId && val.DesignationID == 8).length == 1 ? true : false;
//         console.log(isShowBasePriceCompany)       
//         dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:isShowBasePriceCompany})
//               })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `ShowBasePriceCompany` ,  IsShowBasePriceCompany:false})
//       });
//   };
//   const getCountryCityPort = async (type: string) => {
//     PIService.getCountryCityPortForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `CountryCityPortDataSource` ,  CountryCityPortDataSource:response.data.recordset,LoadingCountryCityPort:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `CountryCityPortDataSource`  , CountryCityPortDataSource:[],LoadingCountryCityPort:false})
//       });
//   };
//   const getShipmentType = async (type: string) => {
//     PIService.getShipmentTypeForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `ShipmentTypeDataSource` ,  ShipmentTypeDataSource:response.data.recordset,LoadingShipmentType:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `ShipmentTypeDataSource`  , ShipmentTypeDataSource:[],LoadingShipmentType:false})
//       });
//   };
//   const getCurrency = async (type: string) => {
//     PIService.getCurrencyForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:response.data.recordset,LoadingCurrencyName:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `CurrencyNameDataSource`  , CurrencyNameDataSource:[],LoadingCurrencyName:false})
//       });
//   };
//   const getPurchaseSource = async (type: string) => {
//     PIService.getPurchaseSourceForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `PurchaseSourceDataSource` ,  PurchaseSourceDataSource:response.data.recordset,LoadingPurchaseSource:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `PurchaseSourceDataSource`  , PurchaseSourceDataSource:[],LoadingPurchaseSource:false})
//       });
//   };
//   const getTradeBoardLetterStatus = async (type: string) => {
//     PIService.getTradeBoardLetterStatusForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `TradeBoardLetterStatusDataSource` ,  TradeBoardLetterStatusDataSource:response.data.recordset,LoadingTradeBoardLetterStatus:false})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `TradeBoardLetterStatusDataSource`  , TradeBoardLetterStatusDataSource:[],LoadingTradeBoardLetterStatus:false})
//       });
//   };
//   const getPaymentType = async (type: string) => {
//     PIService.getPaymentTypeForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `PaymentTypeDataSource` ,  PaymentTypeDataSource:response.data.recordset})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `PaymentTypeDataSource`  , PaymentTypeDataSource:[]})
//       });
//   };
//   const getSalesAgentStatus = async (type: string) => {
//     PIService.getSalesAgentStatusForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `SalesAgentStatusDataSource` ,  SalesAgentStatusDataSource:response.data.recordset})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `SalesAgentStatusDataSource`  , SalesAgentStatusDataSource:[]})
//       });
//   };
//   const getDesignation = async (type: string) => {
//     PIService.getDesignationForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `DesignationNameDataSource` ,  DesignationNameDataSource:response.data.recordset})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `DesignationNameDataSource`  , DesignationNameDataSource:[]})
//       });
//   };
//   const getCRMStatus = async (type: string) => {
//     PIService.getCRMStatusForDD()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         dispatch({ type: `CRMStatusDataSource` ,  CRMStatusDataSource:response.data.recordset})

//       })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `CRMStatusDataSource`  , CRMStatusDataSource:[]})
//       });
//   };
//   const getPaymentDetailForGrid = async (id) => {
//     PIService.getPaymentDetail(id)
//       .then((response) => {
//         console.log('get Payment Detail response', response.data.recordset);
//   return       dispatch({ type: `PaymentDetailDataSource` ,  PaymentDetailDataSource:[...response.data.recordset]})
//   // setDataSoureGrid( response.data.recordset)
//   // ProformaInvoiceDetailGrid.dataSource = [...response.data.recordset]
//       })
//       .catch((e) => {
//         console.log(e);
//         // dispatch({ type: `PaymentDetailDataSource`  , PaymentDetailDataSource:[]})
//         // setDataSoureGrid( [])
//       });
//   };
//   const checkIsCRMDesignation = async (type: string) => {
//     PIService.getAllEmployee()
//       .then((response) => {
//         console.log('productFinishSize get response', response.data.recordset);
//         console.log(userId)
//         let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId && val.DesignationID == 2).length == 1 ? true : false;
//         console.log(isCRMDesignation)       
//         dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:props.isAdd ? false : isCRMDesignation})
//               })
//       .catch((e) => {
//         console.log(e);
//         dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:false})
//       });
//   };
  

//   const ddlFields: object = { value: 'value' };

//   const onAddNewClick = (type) => {
//     console.log('type: ', type);
//     if(type !== ''){
//       setAddNewClicked(true)
//       setShowAddNewDialog(false);
//       setDialogType(type);
//       setTimeout(() => {
//         setShowAddNewDialog(true);
//       },100)
//     }
//   };

//   const header = () => {
//     if(showAddNewDialog) {
//       return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
//     }
//     else {
//       return (<></>);
//     }
//   };

//   const onClose = () => {
//     setAddNewClicked(false)
//     setShowAddNewDialog(false);
//     switch(dialogType){
//       // case 'ProductCategory':
//       //   // getProductCategory();
//       // break;
//       // case 'ProductDesc':
//       //   // getProductDesc();
//       // break;
//       // case 'UnitOfMeasurement':
//       //   // getUOMs();
//       // break;
//       default:
//         break;
//     } 
//   }

//   const footerTemplate = () => {
//     if(showAddNewDialog){
//       return (<div>
//         <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
//       </div>);
//     }
//     else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
//        return (<></>);
//     }
//   };

//   const contentTemplate = (props) => {
//     console.log('props: ', props);
//     if(showAddNewDialog){
//       console.log('dialog template');
//       return (
//         <div>
//           <SetupAdd type={dialogType} isDialog={true}
//           /> 
//           {/* <SetupAdd onChange={value => setWord(value)}*/}
//         </div>
//       );  
//     }
//     else {
//       return (
//         <></>
//       );
//     }
//   };


//   const AddNewSecurity = (addNewType) => {
//     var res =""
//       switch(SecurityService.canUserAccess(`${addNewType}List`)){
//       case true:
//         //('true')
//        return res = 'ADD NEW';
//       default:
//         break;
//     } 
//    return res
//   }


// let productDescRef = useRef(null)
//       let uomRef = useRef(null)
      
//   const minDate: Date = new Date(moment().startOf('day'));
//   const filterOptions: FilterSettingsModel = {
//     type: 'Excel',
//   };

//   const editSettings: EditSettingsModel = {
//     allowEditing: true,
//     allowAdding: true,
//     allowDeleting: false,
//     mode: 'Normal',
//     // allowEditing: SecurityService.canUserAccess('ProductEdit'),
//     // allowAdding: SecurityService.canUserAccess('ProductAdd'),
//     // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
//   }
//   const toolbarOptions: ToolbarItems[] = [
//     "Add",
//     "Edit",
//     "Delete",
//    "Update",
//     "Cancel",
//     // 'PDFExport',
//     // 'ExcelExport',
//     // 'Search',
//     // 'ColumnChooser',
//   ];
//    const toolbarClick = (args: ClickEventArgs) => {
//     console.log('args.item.properties :', args.item.properties);
//   }
//   const sortingOptions: SortSettingsModel = {
//     columns: [{ field: 'ProformaInvoicePaymentDetailID', direction: 'Descending' }]
//   }

  
//   const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
//   const actionComplete = (args: DialogEditEventArgs) => {
//     console.log("data: ", args);
//     if (args.form) {
//       if (args.requestType === "beginEdit" || args.requestType === "add") {
//         console.log('attaching blur event complete', document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus'));
//         document
//           .getElementById("ProformaInvoiceDetailGridSalesAgentStatus")
//           ?.addEventListener("blur", (e) => {
          

//             setTimeout(() => ProformaInvoiceDetailGrid?.endEdit(), 200);
          
            
//           });
//       }
//       console.log("data: ", args);
    
//     if(args.requestType ==  'save' || args.requestType == 'cancel'){
//       setTimeout(() => {
        
//       return  getPaymentDetailForGrid(1006)
//       }, 1000);
//     }
//     if(args.requestType ==  'save'){ 
//       setTimeout(() => {
//         dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:''})
        
//         document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
//       }, 200);
//     }
      
//     if (args.requestType === 'save' && args.action ==  'add') {
      
//     let data = {
//       PIID:state.PIID,
//       PINo:state.PINo,	
//       PIDate:moment(state.PIDate).format('YYYY-MM-DD'),	
//       SupplierID:state.SupplierID,
//       CommissionAmountPerUnit:state.CommissionAmountPerUnit ,	
//       ApproveCommissionAmountPerUnit:state.ApproveCommissionAmountPerUnit,	
//       AbsoluteCommissionAmount:state.AbsoluteCommissionAmount,	
//       ApproveAbsoluteCommissionAmount:state.ApproveAbsoluteCommissionAmount,	
//       CustomerID:state.CustomerID,	
//       SalesAgentID:state.EmployeeID,	
//       ConsigneeName:state.ConsigneeName,	
//       ConfirmConsigneeName:state.ConfirmConsigneeName,
//       CountryCityPortID:state.CountryCityPortID,	
//       ShipmentTypeID:state.ShipmentTypeID,	
//       BookAmount:state.BookAmount,	
//       CurrencyID:state.CurrencyID,	
//       PrimaryCommissionPercentOnAdjustment:state.PrimaryCommissionPercentOnAdjustment,	
//       ApprovePrimaryCommissionPercentOnAdjustment:state.ApprovePrimaryCommissionPercentOnAdjustment,	
//       SecondaryCommissionPercentOnAdjustment:state.SecondaryCommissionPercentOnAdjustment,	
//       ApproveSecondaryCommissionPercentOnAdjustment:state.ApproveSecondaryCommissionPercentOnAdjustment,	
//       AdjustmentAmount:state.AdjustmentAmount,	
//       BasePriceAgent:state.BasePriceAgent,	
//       BasePriceCompany:state.BasePriceCompany,	
//       PurchaseSourceID:state.PurchaseSourceID,	
//       TradeBoardLetterStatusID:state.TradeBoardLetterStatusID,	
//       Remarks:state.Remarks,	
//       CreationDate:state.CreationDate,	
//       CreatedByUserID:state.CreatedByUserID,	
//       VesselName:state.VesselName,	
//       VoyageNo:state.VoyageNo,	
//       ETD:state.ETD,	
//       ETA:state.ETA,	
//       PIReferenceNo:state.PIReferenceNo,
//       ShortAmount:state.ShortAmount,
//       ...args.data,
//       PaymentVerificationStatus:state.PaymentVerificationStatus,
//       PaymentVerificationStatusDate:state.PaymentVerificationStatusDate,
//       CRMID:args.data.DesignationID,
//       CreationDate:new Date(moment()),
//       CreatedByUserID:userId,
//       DesignationName:null,
//       CRMStatusDate:null,
//       CRMStatus:null,
//       CRMID:null,
//       CRMStatusID:null


//     }
//     console.log(data)
//     PIService.addProformaInvoice(data).then((res)=>{
//       console.log(res)
//       getPaymentDetailForGrid(res.data.params.id)

//     })
//   }
//   if (args.requestType === 'save' && args.action ==  'edit') {
//     let data = {
//       PIID:state.PIID,
//         ...args.data,
//       PaymentVerificationStatus:state.PaymentVerificationStatus,
//       PaymentVerificationStatusDate:state.PaymentVerificationStatusDate,
//       CRMID:args.data.DesignationID,
//       CreationDate:new Date(moment()),
//       UpdatedByUserID:userId,
//       DesignationName:null,
//       CRMStatusDate:null,
//       CRMStatus:null,
//       CRMID:null,
//       CRMStatusID:null
//    }
//     console.log(data)
//     PIService.updatePI(args.data.ProformaInvoicePaymentDetailID,data).then((res)=>{
//       console.log(res)
//       getPaymentDetailForGrid(res.data.params.id)

//     })

//   }
//       // if (args.requestType === "beginEdit" || args.requestType === "add") {
//       //   console.log('attaching blur event complete', document.getElementById('productDetailGridQuantityPerSet'));
//       //   document
//       //     .getElementById("productDetailGridQuantityPerSet")
//       //     ?.addEventListener("blur", (e) => {
//       //     let strr =   document
//       //     .getElementById("productDetailGridQuantityPerSet").value
//       //       if(strr > 0){

//       //       setTimeout(() => productDetailGrid?.endEdit(), 200);
//       //       }else{
//       //         setTimeout(() =>  document
//       //         .getElementById("productDetailGridQuantityPerSet").focus(),150)
//       //       }
            
//       //     });
//       // }
//     }
//     }


//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////       params
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////
//     //////////////////////////////////////////////////////////////////////////

//   const paymentTypeParams: IEditCell = {
//     create: () => {
//       paymentTypeElem = document.createElement('input');
//       return paymentTypeElem;
//     },
//     destroy: () => {
//       paymentTypeObj?.destroy();
//     },
//     read: () => {
//       return paymentTypeObj?.text;
//     },
//     write: (args) => {
//       paymentTypeObj = new AutoComplete({
//         created : async   (e) => {
        
//         dispatch({ type: `PaymentType` ,  PaymentTypeID: args.rowData.PaymentTypeID})
//         if( args.rowData?.PaymentTypeID == "1"){
//           console.log('yes')
//           getBalanceUnAllocatedAmount()
//         }else{
//           dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:''})
//         }
//         },
//         select: (e) => {
//           console.log(e.itemData)
//           if(e.itemData?.value == "UnAllocated"){
//             console.log('yes')
//             getBalanceUnAllocatedAmount()
//           }else{
//             dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:''})
//           }
//           console.log('PaymentTypeID - e?.itemData?.id :', e?.itemData?.id)
//           dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
//           args.rowData.PaymentTypeID = e?.itemData?.id

           
//         },
//         change:(e)=>{
//           let resstr = paymentTypeObj.dataSource.some(s=>s.value ==  e.value);
//           console.log(resstr)
//           if(resstr === false){
//             dispatch({ type: `PaymentType` ,  PaymentTypeID: 0})
//             args.rowData.PaymentTypeID =0
//           }else{
//             dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
//             args.rowData.PaymentTypeID = e?.itemData?.id
  
//           }},        
//         blur:(e)=>{
//           console.log(state.PaymentTypeID)
//           console.log(paymentTypeObj);
//           if (
//               paymentTypeObj.itemData.id == undefined
//           ||  paymentTypeObj.itemData.id == '' 
//           ||  paymentTypeObj.itemData.id == 0 
//           ) {
//               if(addNewClicked == false){
//               paymentTypeObj.element.focus()
//               document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='3px solid red'
//               setTimeout(() => {
//               document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='1px solid #c8c8c8'
//                   }, 1000);
//                   }
//                 }
//               },
//         dataSource:state.PaymentTypeDataSource,
//         fields: { id: "id", value: "value" },
//         floatLabelType: 'Never',
//         placeholder: 'Payment Type',
//         highlight: true,
//         value: args.rowData.PaymentType
//       });
//       paymentTypeObj?.appendTo(paymentTypeElem);
//     }
//   };

//   const salesAgentStatusParams: IEditCell = {
//     create: () => {
//       salesAgentStatusElem = document.createElement('input');
//       return salesAgentStatusElem;
//     },
//     destroy: () => {
//       salesAgentStatusObj?.destroy();
//     },
//     read: () => {
//       return salesAgentStatusObj?.text;
//     },
//     write: (args) => {
//       salesAgentStatusObj = new AutoComplete({
//         created : async   (e) => {
        
//         dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: args.rowData.SalesAgentStatusID})
//         },
//         select: (e) => {
//           console.log('SalesAgentStatusID - e?.itemData?.id :', e?.itemData?.id)
//           dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
//           args.rowData.SalesAgentStatusID = e?.itemData?.id
//         },
//         change:(e)=>{
//           let resstr = salesAgentStatusObj.dataSource.some(s=>s.value ==  e.value);
//           console.log(resstr)
//           if(resstr === false){
//             dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 0})
//             args.rowData.SalesAgentStatusID =0
//           }else{
//             dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
//             args.rowData.SalesAgentStatusID = e?.itemData?.id
  
//           }},        
//         blur:(e)=>{

//           console.log(state.SalesAgentStatusID)
//           console.log(salesAgentStatusObj);
//           if (
//               salesAgentStatusObj.itemData.id == undefined
//           ||  salesAgentStatusObj.itemData.id == '' 
//           ||  salesAgentStatusObj.itemData.id == 0 
//           ) {
//               if(addNewClicked == false){
//               salesAgentStatusObj.element.focus()
//               document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='3px solid red'
//               setTimeout(() => {
//               document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='1px solid #c8c8c8'
//                   }, 1000);
//                   }
//                 }

//               },
//         dataSource:state.SalesAgentStatusDataSource,
//         fields: { id: "id", value: "value" },
//         floatLabelType: 'Never',
//         placeholder: 'Sales Agent Status',
//         highlight: true,
//         value: args.rowData.SalesAgentStatus
//       });
//       salesAgentStatusObj?.appendTo(salesAgentStatusElem);
//     }
//   };
//   const designationNameParams: IEditCell = {
//     create: () => {
//       designationNameElem = document.createElement('input');
//       return designationNameElem;
//     },
//     destroy: () => {
//       designationNameObj?.destroy();
//     },
//     read: () => {
//       return designationNameObj?.text;
//     },
//     write: (args) => {
//       designationNameObj = new AutoComplete({
//         created : async   (e) => {
        
//         dispatch({ type: `DesignationName` ,  DesignationID: args.rowData.DesignationID})
//         },
//         select: (e) => {
//           console.log('DesignationID - e?.itemData?.id :', e?.itemData?.id)
//           dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
//           args.rowData.DesignationID = e?.itemData?.id
//         },
//         change:(e)=>{
//           let resstr = designationNameObj.dataSource.some(s=>s.value ==  e.value);
//           console.log(resstr)
//           if(resstr === false){
//             dispatch({ type: `DesignationName` ,  DesignationID: 0})
//             args.rowData.DesignationID =0
//           }else{
//             dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
//             args.rowData.DesignationID = e?.itemData?.id
  
//           }},        
//         blur:(e)=>{
//           console.log(state.DesignationID)
//           console.log(designationNameObj);
//           if (
//               designationNameObj.itemData.id == undefined
//           ||  designationNameObj.itemData.id == '' 
//           ||  designationNameObj.itemData.id == 0 
//           ) {
//               if(addNewClicked == false){
//               designationNameObj.element.focus()
//               document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='3px solid red'
//               setTimeout(() => {
//               document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='1px solid #c8c8c8'
//                   }, 1000);
//                   }
//                 }
//               },
//         dataSource:state.DesignationNameDataSource,
//         fields: { id: "id", value: "value" },
//         floatLabelType: 'Never',
//         placeholder: 'Select CRM',
//         highlight: true,
//         value: args.rowData.CRMName
//       });
//       designationNameObj?.appendTo(designationNameElem);
//     }
//   };
//   const cRMStatusParams: IEditCell = {
//     create: () => {
//       cRMStatusElem = document.createElement('input');
//       return cRMStatusElem;
//     },
//     destroy: () => {
//       cRMStatusObj?.destroy();
//     },
//     read: () => {
//       return cRMStatusObj?.text;
//     },
//     write: (args) => {
//       cRMStatusObj = new AutoComplete({
//         created : async   (e) => {
        
//         dispatch({ type: `CRMStatus` ,  CRMStatusID: args.rowData.CRMStatusID})
//         },
//         select: (e) => {
//           console.log('CRMStatusID - e?.itemData?.id :', e?.itemData?.id)
//           dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
//           args.rowData.CRMStatusID = e?.itemData?.id
//         },
//         change:(e)=>{
//           let resstr = cRMStatusObj.dataSource.some(s=>s.value ==  e.value);
//           console.log(resstr)
//           if(resstr === false){
//             dispatch({ type: `CRMStatus` ,  CRMStatusID: 0})
//             args.rowData.CRMStatusID =0
//           }else{
//             dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
//             args.rowData.CRMStatusID = e?.itemData?.id
  
//           }},        
//         blur:(e)=>{
//           console.log(state.CRMStatusID)
//           console.log(cRMStatusObj);
//           if (
//               cRMStatusObj.itemData.id == undefined
//           ||  cRMStatusObj.itemData.id == '' 
//           ||  cRMStatusObj.itemData.id == 0 
//           ) {
//               if(addNewClicked == false){
//               cRMStatusObj.element.focus()
//               document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='3px solid red'
//               setTimeout(() => {
//               document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='1px solid #c8c8c8'
//                   }, 1000);
//                   }
//                 }
//               },
//         dataSource:state.CRMStatusDataSource,
//         fields: { id: "id", value: "value" },
//         floatLabelType: 'Never',
//         placeholder: 'Select CRM Status',
//         highlight: true,
//         value: args.rowData.CRMStatus
//       });
//       cRMStatusObj?.appendTo(cRMStatusElem);
//     }
//   };
//   const actionBegin = (e)=>{
//     console.log(e)
//     // if(e.requestType = "refresh"){
//     //   getPaymentDetailForGrid(props.PIID)
//     // }
//   }
// // const PIGrid = ()=>(

// // )
// // const updateDataSource = ()=>{
// //   return getPaymentDetailForGrid(1006)
// // }
//   return (
//     <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
//       console.log(e)
//       if(e.keyCode == 13){
//         e.stopPropagation()

//       }
//     }}>
//       <div>
//         <div>
//           <div className='heading'>
//             <h3>PI - {props.isAdd?`Add`:`Edit`}</h3>
//           </div>
//         </div>
//         <div className='App' id='targetElement'>
          
//           <DialogComponent
//             width='800px'
//             visible={showAddNewDialog}
//             close={showAddNewDialog}
//             content={contentTemplate}
//             isModal={true}
//             header={header}
//             footerTemplate = {footerTemplate}
//           />
//         </div>

//         <div>
//           <div>
//             <mat-card>
//               <mat-card-content>
//                 <div className="row">
//                 <div className="col-sm-4">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='PINo'
//                         name='PINo'
//                         floatLabelType='Auto'
//                         value={state.PINo}
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>
//                 <div className="col-sm-8">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='Reference No'
//                         name='PIReferenceNo'
//                         floatLabelType='Auto'
//                         value={state.PIReferenceNo}
//                         multiline
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>
//                   <br/>
//                   <br/>
//                 <div className="col-sm-4">
//                     <mat-form-field>
//                     <DatePickerComponent
//                           id='PIDate'
//                           placeholder='Enter PI Date'
//                           floatLabelType='Auto'
//                           min={minDate}
//                           name='PIDate'
//                           format='dd-MM-yy'
//                           value={state.PIDate}
//                           onChange={textBoxChange}
//                           // value={moment().add(0, 'd').format('DD-MM-YY')}
//                         />
//                     </mat-form-field>
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingSupplier ? (
//                         <div>... Loading Supplier </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="SupplierName" 
                            
//                             dataSource={state.SupplierDataSource}
//                             placeholder="Select Supplier" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="SupplierName"
//                             select={(e)=>AutoCompleteChange(e,'SupplierName')}
//                             change={(e)=>AutoCompleteChange(e,'SupplierName')}
//                             value={state.SupplierName}
//                             ref={state.SupplierRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.SupplierID)
//                               console.log('dfdfblur',state.SupplierName)
//                               if (state.SupplierID  == 0  || state.SupplierRef.current.value == null || state.SupplierRef.current.value == '' || state.SupplierID == undefined || state.SupplierID == null) {
//                                if(state.SupplierName != ''){
//                                  let resstr = state.SupplierDataSource.some(e=>e.value ==  state.SupplierName);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.supplier.SupplierName} Value Not Find ` )
//                                    document.getElementById("SupplierName").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("SupplierName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.SupplierRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='SupplierID'
//                             floatLabelType='Auto'
//                             value={state.SupplierID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingCustomer ? (
//                         <div>... Loading  Sales Agent </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="CustomerName" 
//                             dataSource={state.CustomerDataSource}
//                             placeholder="Select Customer" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="CustomerName"
//                             select={(e)=>AutoCompleteChange(e,'CustomerName')}
//                             change={(e)=>AutoCompleteChange(e,'CustomerName')}
//                             value={state.CustomerName}
//                             ref={state.CustomerRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.CustomerID)
//                               console.log('dfdfblur',state.CustomerName)
//                               if (state.CustomerID  == 0  || state.CustomerRef.current.value == null || state.CustomerRef.current.value == '' || state.CustomerID == undefined || state.CustomerID == null) {
//                                if(state.CustomerName != ''){
//                                  let resstr = state.CustomerDataSource.some(e=>e.value ==  state.CustomerName);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.Customer.CustomerName} Value Not Find ` )
//                                    document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.CustomerRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='CustomerID'
//                             floatLabelType='Auto'
//                             value={state.CustomerID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <br/>
//                   <br/>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingEmployee ? (
//                         <div>... Loading Sales Agent </div>
//                       ) : (
//                         <div>
//                          <TextBoxComponent
//                             id="EmployeeName" 
//                             // dataSource={state.EmployeeDataSource}
//                             placeholder=" Sales Agent Name" 
//                             // fields={ddlFields}
//                             floatLabelType='Auto' 
//                             disabled={true}
//                             readOnly={true}
//                             readonly={true}
//                             // highlight={true}
//                             name="EmployeeName"
//                             // select={(e)=>AutoCompleteChange(e,'EmployeeName')}
//                             // change={(e)=>AutoCompleteChange(e,'EmployeeName')}
//                             value={state.EmployeeName}
//                             // ref={state.EmployeeRef}
//                           //   blur={()=>{
//                           //     console.log('dfdfblur',state.EmployeeID)
//                           //     console.log('dfdfblur',state.EmployeeName)
//                           //     if (state.EmployeeID  == 0  || state.EmployeeRef.current.value == null || state.EmployeeRef.current.value == '' || state.EmployeeID == undefined || state.EmployeeID == null) {
//                           //      if(state.EmployeeName != ''){
//                           //        let resstr = state.EmployeeDataSource.some(e=>e.value ==  state.EmployeeName);
//                           //        console.log(resstr)
//                           //        if(resstr === false){
//                           //         //  setProductGroupErorr(`${state.Employee.EmployeeName} Value Not Find ` )
//                           //          document.getElementById("EmployeeName").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                           //          setTimeout(() => {
//                           //           //  setProductItemTypeError('')
//                           //            document.getElementById("EmployeeName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                           //          }, 1000);
//                           //        }
//                           //      }
                               
//                           //      if (addNewClicked == false){
//                           //       state.EmployeeRef.current.element.focus()
                   
//                           //      }
//                           //    }}
                          
//                           // }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='EmployeeID'
//                             floatLabelType='Auto'
//                             value={state.EmployeeID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='Consignee Name'
//                         name='ConsigneeName'
//                         floatLabelType='Auto'
//                         value={state.ConsigneeName}
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>

               
//                   <div className="col-sm-4" id='ConfirmConsigneeName'>
//                     <mat-form-field>
//                     <CheckBoxComponent
//                       className='e-input'
//                       placeholder='Confirm Consignee Name'
//                       name='ConfirmConsigneeName'
//                       floatLabelType='Auto'
//                       label='Confirm Consignee Name'
//                       checked={state.ConfirmConsigneeName}
//                       change={(e)=>     dispatch({ type:`ConfirmConsigneeName` , ConfirmConsigneeName: e?.checked })}
//                       readOnly={false}
//                     />
//                     </mat-form-field>
//                   </div>
//                   <br/>
//                   <br/>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingCountryCityPort ? (
//                         <div>... Loading Country City Port </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="CountryCityPort" 
//                             dataSource={state.CountryCityPortDataSource}
//                             placeholder="Select Country City Port" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="CountryCityPort"
//                             select={(e)=>AutoCompleteChange(e,'CountryCityPort')}
//                             change={(e)=>AutoCompleteChange(e,'CountryCityPort')}
//                             value={state.CountryCityPort}
//                             ref={state.CountryCityPortRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.CountryCityPortID)
//                               console.log('dfdfblur',state.CountryCityPort)
//                               if (state.CountryCityPortID  == 0  || state.CountryCityPortRef.current.value == null || state.CountryCityPortRef.current.value == '' || state.CountryCityPortID == undefined || state.CountryCityPortID == null) {
//                                if(state.CountryCityPort != ''){
//                                  let resstr = state.CountryCityPortDataSource.some(e=>e.value ==  state.CountryCityPort);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.CountryCityPort.CountryCityPort} Value Not Find ` )
//                                    document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.CountryCityPortRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='CountryCityPortID'
//                             floatLabelType='Auto'
//                             value={state.CountryCityPortID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingShipmentType ? (
//                         <div>... Loading ShipmentType </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="ShipmentType" 
//                             dataSource={state.ShipmentTypeDataSource}
//                             placeholder="Select Shipment Type" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="ShipmentType"
//                             select={(e)=>AutoCompleteChange(e,'ShipmentType')}
//                             change={(e)=>AutoCompleteChange(e,'ShipmentType')}
//                             value={state.ShipmentType}
//                             ref={state.ShipmentTypeRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.ShipmentTypeID)
//                               console.log('dfdfblur',state.ShipmentType)
//                               if (state.ShipmentTypeID  == 0  || state.ShipmentTypeRef.current.value == null || state.ShipmentTypeRef.current.value == '' || state.ShipmentTypeID == undefined || state.ShipmentTypeID == null) {
//                                if(state.ShipmentType != ''){
//                                  let resstr = state.ShipmentTypeDataSource.some(e=>e.value ==  state.ShipmentType);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.ShipmentType.ShipmentType} Value Not Find ` )
//                                    document.getElementById("ShipmentType").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("ShipmentType").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.ShipmentTypeRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='ShipmentTypeID'
//                             floatLabelType='Auto'
//                             value={state.ShipmentTypeID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingCurrencyName ? (
//                         <div>... Loading Currency Name </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="CurrencyName" 
//                             dataSource={state.CurrencyNameDataSource}
//                             placeholder="Select Currency Name" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="CurrencyName"
//                             select={(e)=>AutoCompleteChange(e,'CurrencyName')}
//                             change={(e)=>AutoCompleteChange(e,'CurrencyName')}
//                             value={state.CurrencyName}
//                             ref={state.CurrencyNameRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.CurrencyID)
//                               console.log('dfdfblur',state.CurrencyName)
//                               if (state.CurrencyID  == 0  || state.CurrencyNameRef.current.value == null || state.CurrencyNameRef.current.value == '' || state.CurrencyID == undefined || state.CurrencyID == null) {
//                                if(state.CurrencyName != ''){
//                                  let resstr = state.CurrencyNameDataSource.some(e=>e.value ==  state.CurrencyName);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.CurrencyName.CurrencyName} Value Not Find ` )
//                                    document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.CurrencyNameRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='CurrencyID'
//                             floatLabelType='Auto'
//                             value={state.CurrencyID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <br/>
//                   <br/>
                  
//                     <mat-form-field>
//                       <TextBoxComponent
//                         type='hidden'
//                         className='e-input'
//                         placeholder='Commission Amount Per Unit'
//                         id='CommissionAmountPerUnit'
//                         name='CommissionAmountPerUnit'
//                         floatLabelType='Auto'
//                         format={'N0'}
                        
//                         showSpinButton={false}
//                         value={state.CommissionAmountPerUnit}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
                  
//                   {/* <div className="col-sm-4" id='ApproveCommissionAmountPerUnit'>
//                     <mat-form-field>
//                     <CheckBoxComponent
//                       className='e-input'
//                       placeholder='Approve Commission Amount Per Unit'
//                       name='ApproveCommissionAmountPerUnit'
//                       floatLabelType='Auto'
//                       label='Approve Commission Amount Per Unit'
//                       checked={state.ApproveCommissionAmountPerUnit}
//                       change={(e)=>     dispatch({ type:`ApproveCommissionAmountPerUnit` , ApproveCommissionAmountPerUnit: e?.checked })}
//                       readOnly={false}
//                     />
//                     </mat-form-field>
//                   </div> */}
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       <NumericTextBoxComponent
//                         className='e-input'
//                         placeholder='Absolute Commission Amount'
//                         id='AbsoluteCommissionAmount'
//                         name='AbsoluteCommissionAmount'
//                         floatLabelType='Auto'
//                         format={'N0'}
//                         showSpinButton={false}
//                         value={state.AbsoluteCommissionAmount}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   </div>
//                   <br/>
//                   <br/>
//                   {/* <div className="col-sm-4" id='ApproveAbsoluteCommissionAmount'>
//                     <mat-form-field>
//                     <CheckBoxComponent
//                       className='e-input'
//                       placeholder='Approve Absolute Commission Amount'
//                       name='ApproveAbsoluteCommissionAmount'
//                       floatLabelType='Auto'
//                       label='Approve Absolute Commission Amount'
//                       checked={state.ApproveAbsoluteCommissionAmount}
//                       change={(e)=>     dispatch({ type:`ApproveAbsoluteCommissionAmount` , ApproveAbsoluteCommissionAmount: e?.checked })}
//                       readOnly={false}
//                     />
//                     </mat-form-field>
//                   </div> */}
//                   <div className="col-sm-4">
//                     <mat-form-field>
//        00               <NumericTextBoxComponent
//                         className='e-input'
//                         placeholder='Book Amount'
//                         id='BookAmount'
//                         name='BookAmount'
//                         floatLabelType='Auto'
//                         format={'N0'}
//                         showSpinButton={false}
//                         value={state.BookAmount}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   </div>
//                   {/* <div className="col-sm-4"> */}
//                     <mat-form-field>
//                       <NumericTextBoxComponent
//                       // type=''
//                         className='e-input'
//                         placeholder='Primary Commission % On Adjustment'
//                         id='PrimaryCommissionPercentOnAdjustment'
//                         name='PrimaryCommissionPercentOnAdjustment'
//                         floatLabelType='Auto'
//                         format={'N2'}
//                         showSpinButton={false}
//                         value={state.PrimaryCommissionPercentOnAdjustment}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   {/* </div> */}
//                   <br/>
//                   <br/>
//                   {/* <div className="col-sm-4" id='ApprovePrimaryCommissionPercentOnAdjustment'>
//                     <mat-form-field>
//                     <CheckBoxComponent
//                       className='e-input'
//                       placeholder='Approve Primary Commission % On Adjustment'
//                       name='ApprovePrimaryCommissionPercentOnAdjustment'
//                       floatLabelType='Auto'
//                       label="Approve Primary Comm'n % On Adjustment"
//                       checked={state.ApprovePrimaryCommissionPercentOnAdjustment}
//                       change={(e)=>     dispatch({ type:`ApprovePrimaryCommissionPercentOnAdjustment` , ApprovePrimaryCommissionPercentOnAdjustment: e?.checked })}
//                       readOnly={false}
//                     />
//                     </mat-form-field>
//                   </div> */}
                  
//                     <mat-form-field>
//                       <TextBoxComponent
//                       type='hidden'
//                         className='e-input'
//                         placeholder='Secondary Commission % On Adjustment'
//                         id='SecondaryCommissionPercentOnAdjustment'
//                         name='SecondaryCommissionPercentOnAdjustment'
//                         floatLabelType='Auto'
//                         format={'N2'}
//                         showSpinButton={false}
//                         value={state.SecondaryCommissionPercentOnAdjustment}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
                  
//                   {/* <div className="col-sm-4" id='ApproveSecondaryCommissionPercentOnAdjustment'>
//                     <mat-form-field>
//                     <CheckBoxComponent
//                       className='e-input'
//                       placeholder='Approve Secondary Commission % On Adjustment'
//                       name='ApproveSecondaryCommissionPercentOnAdjustment'
//                       floatLabelType='Auto'
//                       label="Approve Secondary Comm'n % On Adjustment"
//                       checked={state.ApproveSecondaryCommissionPercentOnAdjustment}
//                       change={(e)=>     dispatch({ type:`ApproveSecondaryCommissionPercentOnAdjustment` , ApproveSecondaryCommissionPercentOnAdjustment: e?.checked })}
//                       readOnly={false}
//                     />
//                     </mat-form-field>
//                   </div> */}
//                   <br/>
//                   <br/>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       <NumericTextBoxComponent
//                         className='e-input'
//                         placeholder='Adjustment Amount'
//                         id='AdjustmentAmount'
//                         name='AdjustmentAmount'
//                         floatLabelType='Auto'
//                         format={'N0'}
//                         showSpinButton={false}
//                         value={state.AdjustmentAmount}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   </div>
//                   {/* <div className="col-sm-4">
//                     <mat-form-field>
//                       <NumericTextBoxComponent
//                         className='e-input'
//                         placeholder='Base Price Agent'
//                         id='BasePriceAgent'
//                         name='BasePriceAgent'
//                         floatLabelType='Auto'
//                         format={'N0'}
//                         showSpinButton={false}
//                         value={state.BasePriceAgent}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   </div> */}
//                   {
//                     state.IsShowBasePriceCompany == false? null :(
//                       <>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       <NumericTextBoxComponent
//                         className='e-input'
//                         placeholder='Base Price Company'
//                         id='BasePriceCompany'
//                         name='BasePriceCompany'
//                         floatLabelType='Auto'
//                         format={'N0'}
//                         showSpinButton={false}
//                         value={state.BasePriceCompany}
//                         onChange={textBoxChange}
                        
//                       />
//                     </mat-form-field>
//                   </div>
//                   <br/>
//                   <br/>
//                   </>
//                     )}

//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingPurchaseSource ? (
//                         <div>... Loading Purchase Source </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="PurchaseSource" 
//                             dataSource={state.PurchaseSourceDataSource}
//                             placeholder="Select Purchase Source" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="PurchaseSource"
//                             select={(e)=>AutoCompleteChange(e,'PurchaseSource')}
//                             change={(e)=>AutoCompleteChange(e,'PurchaseSource')}
//                             value={state.PurchaseSource}
//                             ref={state.PurchaseSourceRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.PurchaseSourceID)
//                               console.log('dfdfblur',state.PurchaseSource)
//                               if (state.PurchaseSourceID  == 0  || state.PurchaseSourceRef.current.value == null || state.PurchaseSourceRef.current.value == '' || state.PurchaseSourceID == undefined || state.PurchaseSourceID == null) {
//                                if(state.PurchaseSource != ''){
//                                  let resstr = state.PurchaseSourceDataSource.some(e=>e.value ==  state.PurchaseSource);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.PurchaseSource.PurchaseSource} Value Not Find ` )
//                                    document.getElementById("PurchaseSource").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("PurchaseSource").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.PurchaseSourceRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='PurchaseSourceID'
//                             floatLabelType='Auto'
//                             value={state.PurchaseSourceID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-4">
//                     <mat-form-field>
//                       {state.LoadingTradeBoardLetterStatus ? (
//                         <div>... Loading Trade Board Letter Status </div>
//                       ) : (
//                         <div>
//                          <AutoCompleteComponent
//                             id="TradeBoardLetterStatus" 
//                             dataSource={state.TradeBoardLetterStatusDataSource}
//                             placeholder="Select Trade Board Letter Status" 
//                             fields={ddlFields}
//                             floatLabelType='Auto' 
//                             highlight={true}
//                             name="TradeBoardLetterStatus"
//                             select={(e)=>AutoCompleteChange(e,'TradeBoardLetterStatus')}
//                             change={(e)=>AutoCompleteChange(e,'TradeBoardLetterStatus')}
//                             value={state.TradeBoardLetterStatus}
//                             ref={state.TradeBoardLetterStatusRef}
//                             blur={()=>{
//                               console.log('dfdfblur',state.TradeBoardLetterStatusID)
//                               console.log('dfdfblur',state.TradeBoardLetterStatus)
//                               if (state.TradeBoardLetterStatusID  == 0  || state.TradeBoardLetterStatusRef.current.value == null || state.TradeBoardLetterStatusRef.current.value == '' || state.TradeBoardLetterStatusID == undefined || state.TradeBoardLetterStatusID == null) {
//                                if(state.TradeBoardLetterStatus != ''){
//                                  let resstr = state.TradeBoardLetterStatusDataSource.some(e=>e.value ==  state.TradeBoardLetterStatus);
//                                  console.log(resstr)
//                                  if(resstr === false){
//                                   //  setProductGroupErorr(`${state.TradeBoardLetterStatus.TradeBoardLetterStatus} Value Not Find ` )
//                                    document.getElementById("TradeBoardLetterStatus").parentElement.parentElement.firstChild.style.border ='3px solid red'
//                                    setTimeout(() => {
//                                     //  setProductItemTypeError('')
//                                      document.getElementById("TradeBoardLetterStatus").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
//                                    }, 1000);
//                                  }
//                                }
                               
//                                if (addNewClicked == false){
//                                 state.TradeBoardLetterStatusRef.current.element.focus()
                   
//                                }
//                              }}
                          
//                           }
//                           />

//                           <TextBoxComponent
//                             className='e-input'
//                             type='hidden'
//                             placeholder='UOM Id'
//                             name='TradeBoardLetterStatusID'
//                             floatLabelType='Auto'
//                             value={state.TradeBoardLetterStatusID}
//                           />
//                           <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
//                           {AddNewSecurity('UnitOfMeasurementss')}
//                               </span>
//                         </div>
//                       )}
//                     </mat-form-field>
                    
//                   </div>
//                   <div className="col-sm-12">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='Remarks'
//                         name='Remarks'
//                         floatLabelType='Auto'
//                         value={state.Remarks}
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>
//                   <div className="col-sm-3">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='VesselName'
//                         name='VesselName'
//                         floatLabelType='Auto'
//                         value={state.VesselName}
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>
//                   <div className="col-sm-3">
//                     <mat-form-field>
//                       <TextBoxComponent
//                         className='e-input'
//                         placeholder='VoyageNo'
//                         name='VoyageNo'
//                         floatLabelType='Auto'
//                         value={state.VoyageNo}
//                         onChange={textBoxChange}
//                       />
//                     </mat-form-field>
//                   </div>
//                   <div className="col-sm-3">
//                     <mat-form-field>
//                     <DatePickerComponent
//                           id='ETA'
//                           placeholder='Enter ETA date'
//                           floatLabelType='Auto'
//                           min={props.isAdd ? minDate :{}}
//                           name='ETA'
//                           format='dd-MM-yy'
//                           value={state.ETA}
//                           onChange={textBoxChange}
//                           // value={moment().add(0, 'd').format('DD-MM-YY')}
//                         />
//                     </mat-form-field>
//                   </div>
//                     <div className="col-sm-3">
//                     <mat-form-field>
//                     <DatePickerComponent
//                           id='ETD'
//                           placeholder='Enter ETD date'
//                           floatLabelType='Auto'
//                           min={props.isAdd ? minDate :{}}
//                           name='ETD'
//                           format='dd-MM-yy'
//                           value={state.ETD}
//                           onChange={textBoxChange}
//                           // value={moment().add(0, 'd').format('DD-MM-YY')}
//                         />
//                     </mat-form-field>
//                   </div>
//                 </div>

//                 <br />
//               </mat-card-content>
//               <mat-card-action>
//               </mat-card-action>
//             </mat-card>
//           </div>
//         </div>
        
//       </div>
//       {/* if(checkDuplicateValue == ''){
//                               setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

//                             } */}
//        {checkDuplicateValue != ''? <h3 className="duplicates " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>:null}
//        {state.BalanceUnAllocatedAmount  != ''? <h3> ACCOUNT AMOUNT : {state.CurrencyName} {state.BalanceUnAllocatedAmount}`</h3>:<h3></h3>}
// {/* <PIGrid /> */}
// {/* <button onClick={(e) =>{ e.preventDefault(); updateDataSource()}}>Update Data</button> */}
//  <div>
// <GridComponent
//       ref={(grid) => (ProformaInvoiceDetailGrid = grid)}
//       id='ProformaInvoiceDetailGrid'
//       dataSource={state.PaymentDetailDataSource}
//       // dataSource={[]}
//       editSettings={editSettings}
//       toolbar={toolbarOptions}
//       pageSettings={{ pageCount: 4, pageSizes: true,  }}
//       // allowPaging={true}
//       // filterSettings={filterOptions}
//       // sortSettings={sortingOptions}
//       // allowPaging={true}
//       allowSorting={true}
      
//       allowPdfExport={false}
//       allowExcelExport={false}
//       //toolbarClick={toolbarClick}
//       // allowFiltering={true}
//       // showColumnChooser={true}
//       // allowReordering={true}
//       // visible={false}
//       allowTextWrap={true}
//       actionComplete={actionComplete}
//       // actionBegin={actionBegin}
//       toolbarClick={toolbarClick}
//       >
//       <ColumnsDirective>
//       <ColumnDirective
//       field='ProformaInvoicePaymentDetailID'
//       visible={false}

//       />
//         <ColumnDirective
//           headerText='Receipt Date'
//           field='ReceiptDate'
//           width='140'
//           headerTextAlign='CENTER'
//           textAlign='right'
//          editType='datepickeredit'
//          format={formatOptions}
//         />
//         <ColumnDirective
//           headerText='ID'
//           field='PaymentTypeID'
//           visible={false}
//         />
//         <ColumnDirective
//           headerText='Payment Type'
//           field='PaymentType'
//           width='140'
//           textAlign='Left'
//           edit={paymentTypeParams}
//         />
//         <ColumnDirective
//           headerText='Payment Ref No'
//           field='PaymentReferenceNo'
//           width='120'
//           headerTextAlign='Left'
//         />
//         <ColumnDirective
//           headerText='Payment Sender'
//           field='PaymentSenderName'
//           width='130'
//           headerTextAlign='Left'
//         />
//         <ColumnDirective
//           headerText='Payment Desc'
//           field='PaymentDescription'
//           width='130'
//           headerTextAlign='Left'
//         />
//         <ColumnDirective
//           headerText='Receipt Amt'
//           field='ReceiptAmount'
//           width='100'
//           edit={{ params :{showSpinButton:false,format:'n0' }}}
//           editType='numericedit'
//           headerTextAlign='right'
//         />
//         {/* <ColumnDirective
//           headerText='Short Amt'
//           field='ShortAmount'
//           ref={state.ShortAmountRef}
//           width='100'
//           defaultValue={0}
//           edit={{ params :{showSpinButton:false,format:'n0'}}}
//           // editType='numericedit'
//           headerTextAlign='Center'
//           editTemplate={(args)=>{
//             console.log(args)
//             return (
//               <TextBoxComponent
//               className='e-input'
//               type='number'
//               name='ShortAmount'
//               id='ShortAmount'
              
//               // disabled={state.IsCRMDesignation}
//               floatLabelType='Auto'
//               value={args.ShortAmount}
//               ref={state.ShortAmountRef}
//               edit={{ params :{showSpinButton:false,format:'n0'}}}
//               blur={(e)=>{
//                 let receiptAmt = document.getElementById('ProformaInvoiceDetailGridReceiptAmount').value
//                 let shortAmt =   document.getElementById('ShortAmount').value
//                 if(shortAmt > receiptAmt ){
//                   state.ShortAmountRef.current.element.focus()
//                   document.getElementById('ShortAmount').style.border= '1px solid red'
//                   console.log(irtiza)
//                 }else{
//                   document.getElementById('ShortAmount').style.border= '1px solid #eaeaea'
//                 }
//               }}
//             />
//             )
//           }}
//         /> */}
//         <ColumnDirective.
//           headerText='ID'
//           field='SalesAgentStatusID'
//           visible={false}
//         />
//         <ColumnDirective
//           headerText='Sales Agent Status'
//           field='SalesAgentStatus'
//           width='230'
//           textAlign='Left'
//           edit={salesAgentStatusParams}
//           editType='dropdownedit'
//         />
//           <ColumnDirective
//           headerText='Sales Agent Status Date'
//           field='SalesAgentStatusDate'
//           width='145'
//           allowEditing={false}
//           defaultValue={state.SalesAgentStatusDate}
//           headerTextAlign='Right'
//           editType='datetimepickeredit'
//           format={formatOptions}
//         />




//           {/* <ColumnDirective
//           headerText='Payment Verification Status'
//           field='PaymentVerificationStatus'
//           width='150'
//           headerTextAlign='Center'
//           editTemplate={(args)=>{
//             console.log(args)
//             return (
//                 <label className="checkbox-container">
//                 <input
//                   type='checkbox'
//                   className={`checkbox ${props.PaymentVerificationStatus ? 'yellow': ''}`}
//                   id={`checkbox_${props.CostingProductRowID}`}
//                   checked={props.PaymentVerificationStatus}
//                   // disabled={state.IsCRMDesignation}
//                   onChange={(e) => {
//                     dispatch({ type: 'PaymentVerificationStatus' ,PaymentVerificationStatus: e.target.checked})
//                     console.log(e, props)
//                   }}
//                 />
//               <span className="checkmark"></span>
//               </label>
//             )
//           }}
//       />
//        <ColumnDirective
//           headerText='Payment Verification Status Date'
//           field='PaymentVerificationStatusDate'
//           width='145'
//           allowEditing={state.IsCRMDesignation}
//           defaultValue={state.PaymentVerificationStatusDate}
//           headerTextAlign='Center'
//           editType='datetimepickeredit'
//           format={formatOptions}
//         />
//         <ColumnDirective
//           headerText='ID'
//           field='DesignationID'
//           visible={false}
//         />
//         <ColumnDirective
//           headerText='CRM'
//           field='CRMName'
//           width='140'
//           textAlign='Center'
//           edit={designationNameParams}
//           editType='dropdownedit'
//         />
//         <ColumnDirective
//           headerText='ID'
//           field='CRMStatusID'
//           visible={false}
//         />
//         <ColumnDirective
//           headerText='CRM Status' 
//           field='CRMStatus'
//           width='140'
//           textAlign='Center'
//           edit={cRMStatusParams}
//           editType='dropdownedit'
//         />
//          <ColumnDirective
//           headerText='CRM Status Date'
//           field='CRMStatusDate'
//           width='145'
//           allowEditing={false}
//           defaultValue={state.CRMStatusDate}
//           headerTextAlign='Center'
//           editType='datetimepickeredit'
//           format={formatOptions}
//         />
//          */}
//       </ColumnsDirective>

//       <Inject
//           services={[Edit, Toolbar, Page, Sort, Filter, Aggregate, Reorder]}
//       />
//     </GridComponent>
//     </div>

//     <br/>
//             {/* <PIGrid actionComplete={actionComplete} /> */}
//     </div>
//   )
  
// }