import React from 'react';

export default function Footer(props) {    
    return (
        <div>
            {/* Added alignment just for now */}
            <footer className="main-footer" style={{ textAlign: 'right' ,padding:'7px'}}>
                <strong style={{fontSize:'12px'}}>Copyright © .</strong>
                All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.1.0-rc
                    </div> */}
            </footer>
        </div>

    )
    }

