import React, { useEffect, useState , useRef } from 'react';
// import CommissionCalculatorService from '../../services/product.service';
import SecurityService from '../security/security.service';
import PIService from '../../services/PI.service';
import './commission-details.scss';
import HardDelete from '../hard-delete/hard-delete';
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { decryptData } from '../decryption/decryption';
// import CheckDuplicate  from '../check-duplicate/check-duplicate';
// import AuditTableInput from '../audit-table/audit-table';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperfties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import TransferUnAllocatedPaymentAdd from './Transfer-unAllocated-payment-add';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
// import {image} from './image';
// import logoUrl from './logo192.png';
import CommissionDetailsService from '../../services/commission-details.service';
import EmployeeService from '../../services/employee.service';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
var selectedRows = []
export default function CommissionDetailsList(props) {
  
    // var img =  imagess;
  
  const [CommissionDetailsDataSource, setCommissionDetailsDataSource] = useState([]);
  const [salesAgent, setSalesAgent] = useState('');
  const [loadingSalesAgent, setLoadingSalesAgent] = useState('');
  const [salesAgentID, setSalesAgentID] = useState(0);
  const [salesAgentValue, setSalesAgentValue] = useState('');
  const [customerDataSource, setCustomerDataSource] = useState([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customerID, setCustomerID] = useState(0);
  const [customerName, setCustomerName] = useState('');

  const [PIIDDataSource, setPIIDDataSource] = useState([]);
  const [loadingPIID, setLoadingPIID] = useState(false);
  const [PIID, setPIID] = useState(0);
  const [PIIDName, setPIIDName] = useState('');

  const [supplierDataSource, setSupplierDataSource] = useState([]);
  const [loadingSupplier, setLoadingSupplier] = useState(false);
  const [supplierID, setSupplierID] = useState(0);
  const [supplierName, setSupplierName] = useState('');

  const [purchaseSourceDataSource, setPurchaseSourceDataSource] = useState([]);
  const [loadingPurchaseSource, setLoadingPurchaseSource] = useState(false);
  const [purchaseSourceID, setPurchaseSourceID] = useState(0);
  const [purchaseSourceName, setPurchaseSourceName] = useState('');

  const [commissionStatusDataSource, setCommissionStatusDataSource] = useState([]);
  const [loadingCommissionStatus, setLoadingCommissionStatus] = useState(false);
  const [commissionStatus, setCommissionStatus] = useState(0);
  const [commissionStatusName, setCommissionStatusName] = useState('');

  const [monthYearPIDate, setMonthYearPIDate] = useState('');


  const [showDropDown, setShowDropDown] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [deletingProps, setDeletingProps] = useState();
  // const [showDialog, setShowDialog] = useState(false);
  // const [showDialogs, setShowDialogs] = useState(false);
  // const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  // const [showDialogEmail, setShowDialogEmail] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  let CommissionDetailsGrid: Grid;
  const ddlFields: object = { value: 'value' };

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      CommissionDetailsGrid &&
      args.item.properties.id === 'CommissionDetailsGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.pdf`
      // };
      // console.log('clicked on pdf export');
      // CommissionDetailsGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_commission-details.pdf`
    };
    console.log('clicked on pdf export');
    
      
       CommissionDetailsGrid.pdfExport(exportProperties);

    }
    if (
      CommissionDetailsGrid &&
      args.item.properties.id === 'CommissionDetailsGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_commission-details.xlsx`
    };
    CommissionDetailsGrid.excelExport(exportProperties);
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // // const customizeHeader = ({ element }) => {
      // //   // Insert the logo in the header
      // //   const logoImage = new Image();
      // //   logoImage.src = image;
      // //   logoImage.width = 100; // Adjust the width as per your requirement
      // //   logoImage.height = 50; // Adjust the height as per your requirement
    
      // //   // Add the logo to the header cell
      // //   const headerCell = element.sheet.rows[0].cells[0];
      // //   headerCell.appendChild(logoImage);
    
      // //   // Merge cells for the logo
      // //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // // };
      
      // CommissionDetailsGrid.excelExport(exportProperties);
      // // CommissionDetailsGrid.beforeDataBound(customizeHeader)
      
    }
    if (
      CommissionDetailsGrid &&
      args.item.properties.id === 'CommissionDetailsGrid_columnchooser'
    ) {
    }
    if (CommissionDetailsGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (CommissionDetailsGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', CommissionDetailsGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        // setDeletingProps(obj)
        // setShowDialogs(true)
       
      } else {
        console.log('delete: ', CommissionDetailsGrid.getSelectedRecords()[0]?.TransferID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`TransferUnAllocatedPayment`,
          ColumnName:`TransferID`,
          RowID:CommissionDetailsGrid.getSelectedRecords()[0]?.TransferID,
          SelectedRow:true,
          SelectedRowData:{
            ...CommissionDetailsGrid.getSelectedRecords()[0]
          }
          
          }
        //   setDeletingProps(obj)
        
        // // deleteDialog.current.show();
        // setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    // getCommissionDetails();
    SecurityService.getUserAccessList(userId).then((res)=>{
      console.log(res)
      const decryptedData = decryptData(res.data); // Decrypt response
      console.log(decryptedData[0])
      setPrintedByUserName(decryptedData[0].UserName)
      setPrintedByEmail(decryptedData[0].Email)
     });
     getPIID()
     getSupplier()
     getPurchaseSource()
     getCommissionStatus()
     getCustomer()
     getSalesAgent()
  }, []);

  // const getCommissionDetails = async () => {
  //   // :customerid/:salesAgentID
  //   // const monthYearPIDateTest = moment(monthYearPIDate).format('MMM-YY').toString();
  //   console.log(monthYearPIDate)
  //   // console.log(monthYearPIDateTest)
  //   console.log(customerID)
  //   console.log(salesAgentID)
  //   console.log(PIID)
  //   console.log(supplierID)
  //   console.log(purchaseSourceID)
  //   console.log(commissionStatus)
  //   // CommissionDetailsService.getList(monthYearPIDate, customerID, salesAgentID, PIID, supplierID, purchaseSourceID, commissionStatus)
  //       CommissionDetailsService.getList(("JAN-23"), 0, 0, 0, 0, 0, 'RECEIVED')
  //     .then((response) => {
  //       console.log('getCommissionDetails get response', decryptedData);
  //       setCommissionDetailsDataSource(decryptedData);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getCommissionDetails = async () => {
    console.log(monthYearPIDate);
    console.log(customerID);
    console.log(salesAgentID);
    console.log(PIID);
    console.log(supplierID);
    console.log(purchaseSourceID);
    console.log(commissionStatus);
  
    // Ensure that null values are passed for parameters with no value
    const monthYearPIDateParam = monthYearPIDate || null;
    const customerIDParam = customerID;
    const salesAgentIDParam = salesAgentID;
    const PIIDParam = PIID;
    const supplierIDParam = supplierID;
    const purchaseSourceIDParam = purchaseSourceID;
    const commissionStatusParam = commissionStatus || null;
  
    CommissionDetailsService.getList(
      monthYearPIDateParam,
      customerIDParam,
      salesAgentIDParam,
      PIIDParam,
      supplierIDParam,
      purchaseSourceIDParam,
      commissionStatusParam
    )
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('getCommissionDetails get response', decryptedData);
        setCommissionDetailsDataSource(decryptedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  

  // const getUnProcessedCommissionCalculator = async () => {
  //   // :customerid/:salesAgentID
  //   console.log(customerID)
  //   console.log(salesAgentID)
  //   CommissionCalculatorService.getList(customerID, salesAgentID, 'UnProcessed')
  //     .then((response) => {
  //       console.log('getUnProcessedCommissionCalculator get response', response.data.recordset);
  //       setCommissionCalculatorDataSource(response.data.recordset);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getSalesAgent = async () => {
    setLoadingSalesAgent(true);
    EmployeeService.getEmployeeForList()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('SalesAgent get response', decryptedData);
        let data = decryptedData.filter((Res)=>{return Res.DesignationID == 3})
        let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSalesAgent(mapData);
        setLoadingSalesAgent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCustomer = async (type: string) => {
    PIService.getCustomerForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response
        console.log('productFinishSize get response', decryptedData);

        setCustomerDataSource(decryptedData)
        setLoadingCustomer(false)
        // dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:decryptedData,LoadingCustomer:false})
        
      })
      .catch((e) => {
        console.log(e);
        setCustomerDataSource([])
        setLoadingCustomer(true)
        // dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };

  const getPurchaseSource = async (type: string) => {
    PIService.getPurchaseSourceForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('productFinishSize get response', decryptedData);

        setPurchaseSourceDataSource(decryptedData)
        setLoadingPurchaseSource(false)
        // dispatch({ type: `PurchaseSourceDataSource` ,  PurchaseSourceDataSource:decryptedData,LoadingPurchaseSource:false})
        
      })
      .catch((e) => {
        console.log(e);
        setPurchaseSourceDataSource([])
        setLoadingPurchaseSource(true)
        // dispatch({ type: `PurchaseSourceDataSource`  , PurchaseSourceDataSource:[],LoadingPurchaseSource:false})
      });
  };

  const getCommissionStatus = async (type: string) => {
    CommissionDetailsService.getCommissionStatusForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('CommissionStatus get response', decryptedData);

        setCommissionStatusDataSource(decryptedData)
        setLoadingCommissionStatus(false)
        // dispatch({ type: `CommissionStatusDataSource` ,  CommissionStatusDataSource:decryptedData,LoadingCommissionStatus:false})
        
      })
      .catch((e) => {
        console.log(e);
        setCommissionStatusDataSource([])
        setLoadingCommissionStatus(true)
        // dispatch({ type: `CommissionStatusDataSource`  , CommissionStatusDataSource:[],LoadingCommissionStatus:false})
      });
  };


  const getSupplier = async (type: string) => {
    PIService.getSupplierForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('productFinishSize get response', decryptedData);

        setSupplierDataSource(decryptedData)
        setLoadingSupplier(false)
        // dispatch({ type: `SupplierDataSource` ,  SupplierDataSource:decryptedData,LoadingSupplier:false})
        
      })
      .catch((e) => {
        console.log(e);
        setSupplierDataSource([])
        setLoadingSupplier(true)
        // dispatch({ type: `SupplierDataSource`  , SupplierDataSource:[],LoadingSupplier:false})
      });
  };

  const getPIID = async (type: string) => {
    CommissionDetailsService.getPIIDForDD()
      .then((response) => {
        const decryptedData = decryptData(response.data); // Decrypt response

        console.log('productFinishSize get response', decryptedData);

        setPIIDDataSource(decryptedData)
        setLoadingPIID(false)
        // dispatch({ type: `PIIDDataSource` ,  PIIDDataSource:decryptedData,LoadingPIID:false})
        
      })
      .catch((e) => {
        console.log(e);
        setPIIDDataSource([])
        setLoadingPIID(true)
        // dispatch({ type: `PIIDDataSource`  , PIIDDataSource:[],LoadingPIID:false})
      });
  };

  // const inputDateChange = (e) => {
  //   console.log('e: ', e.value);
  //   const formattedDate = moment(e.value).format('MMM-YY').toString();
  //   console.log('e formattedDate: ', formattedDate);
  //   setMonthYearPIDate(formattedDate);
  // }

  const inputDateChange = (e) => {
    const formattedDate = moment(e.value).format('MMM-YY').toString();
    
    if (moment(formattedDate, 'MMM-YY', true).isValid()) {
      console.log('e formattedDate: ', formattedDate);
      setMonthYearPIDate(formattedDate);
    } else {
      console.log('Invalid date: ', e.value);
      setMonthYearPIDate(null);
    }
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    // {
    //   // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
    //   text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false},
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ? (
      // <div dataList={CommissionDetailsDataSource} {...props}> </div>
      // <TransferUnAllocatedPaymentAdd dataList={CommissionDetailsDataSource} {...props}> </TransferUnAllocatedPaymentAdd>
      <></>
      
    ) :
      (
        // <PIAdd dataList={productFinishSize} {...props}> </PIAdd>
        <></>
      );
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess('ProductFinishSizeEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductFinishSizeAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductFinishSizeDelete'),
    allowEditing: false,
    // allowAdding: true,
    // allowDeleting: true,
    mode: 'Dialog',
    // showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  // console.log(userName)
  
  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      CommissionDetailsGrid?.autoFitColumns();
    }
    if (args.form) {
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'){
        console.log(args.data)
        const data = {
          CreatedByUserID : userId,
          ...args.data,
          TransferDate: moment(args.data.TransferDate).format('YYYY-MM-DD')
        }
        CommissionDetailsService.addTransferUnAllocatedPayment(data)
          .then((response) => {
        getCommissionDetails()
          })
      }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     console.log('args: ', data)
    //     // delete productFinishSize[0]

      
    //   CommissionDetailsService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setCommissionDetailsDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     console.log('args: ', data)
    //     CommissionDetailsService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    //                   console.log(totalColumns)
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getCommissionDetails();
    //         console.log(response)});
    //   }

    }

  };
  const databound = () =>
  {
      if (CommissionDetailsGrid) {
        CommissionDetailsGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);
//       console.log('command click args: ', args.commandColumn.buttonOption.id);
//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };
let refreshColumn =(columnName) =>{
  if (CommissionDetailsGrid) {
    CommissionDetailsGrid.columns.forEach(column => {
      if (column.field === columnName) {
        CommissionDetailsGrid.refreshColumns(column);
      }
    });
  }
}
const handleCheckboxChange = (e,data) => {
   console.log(e)
   console.log(data)

   
  CommissionDetailsService.updateApproveCommissionAmountPerUnit(data.PIID,userId).then((res)=>{

    let dataFin = CommissionDetailsGrid.map((Res)=>{
      if(data.PIID == Res.PIID){
        console.log(Res)
        if(Res.PaymentVerificationStatus == true){
          Res.PaymentVerificationStatus = false;
          Res.CRMID = 0;
          Res.CRMName = null
          Res.PaymentVerificationStatusDate = null
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
 
        }else{
          Res.PaymentVerificationStatus = true;
          Res.CRMID = userId
          Res.CRMName = printedByUserName
          Res.PaymentVerificationStatusDate = new Date()
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
        }
      }
    })
    console.log(dataFin)

  })
};

let RemarksUseRef =  useRef(null)
let FinalCommissionAmountPerUnitApprovedUseRef =  useRef(null)
  const CommissionDetailsGridComponent = ({ restrictPageSize = 12 }) => (


    <GridComponent
      ref={(grid) => (CommissionDetailsGrid = grid)}
      id='CommissionDetailsGrid'
      dataSource={CommissionDetailsDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
    
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
          
                    setTimeout(() => {
            console.log('ss')
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='PI ID'
          field='PIID'
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='PI No'
          field='PINo'
          width='120'
          headerTextAlign='CENTER'
          textAlign='right'
        />
        <ColumnDirective
          headerText='Month Year 
          PI Date '
          headerTextAlign='CENTER'    
          field='MonthYearPIDate'
          width='140'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        <ColumnDirective
          headerText="PI Date"
          headerTextAlign='CENTER'    
          field='PIDate'
          width='145'
          textAlign='Right'
          format={formatOptions}
          type='date'
          
        />
        <ColumnDirective
          headerText='PI Reference No '
          headerTextAlign='CENTER'    
          field='PIReferenceNo'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Sales Agent'
          headerTextAlign='CENTER'    
          field='SalesAgent'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Customer Name'
          headerTextAlign='CENTER'    
          field='CustomerName'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Customer Email'
          headerTextAlign='CENTER'    
          field='CustomerEmail'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Supplier Name'
          headerTextAlign='CENTER'    
          field='SupplierName'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Purchase Source'
          headerTextAlign='CENTER'    
          field='PurchaseSource'
          width='145'
          textAlign='Right'
          format='n0'
        />
        <ColumnDirective
          headerText='Currency Name'
          headerTextAlign='CENTER'    
          field='CurrencyName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Adjustment Amount'
          headerTextAlign='CENTER'    
          field='AdjustmentAmount'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Primary Commission 
          % On Adjustment'
          headerTextAlign='CENTER'    
          field='PrimaryCommissionPercentOnAdjustment'
          width='160'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Secondary Commission 
          % On Adjustment'
          headerTextAlign='CENTER'    
          field='SecondaryCommissionPercentOnAdjustment'
          width='160'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Absolute Commission 
          Amount'
          headerTextAlign='CENTER'    
          field='AbsoluteCommissionAmount'
          width='160'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Final Commission Amount 
          Per Unit Approved'
          headerTextAlign='CENTER'    
          field='FinalCommissionAmountPerUnitApproved'
          width='160'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Final Commission 
          Amount Approved'
          headerTextAlign='CENTER'    
          field='FinalCommissionAmountApproved'
          width='160'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Remarks'
          headerTextAlign='CENTER'    
          field='Remarks'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Commission Status'
          headerTextAlign='CENTER'    
          field='CommissionStatus'
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Max Receipt Month'
          headerTextAlign='CENTER'    
          field='MaxReceiptMonth'
          width='140'
          textAlign='Right'
          format={formatOptions}
          type='date'
        />
        {/* <ColumnDirective
          headerText='Approve 
          Commission
           Amount 
           Per Unit'
          headerTextAlign='CENTER'    
          field='ApproveCommissionAmountPerUnit'
          width='165'
        
          textAlign='Left'
        /> */}
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
        <div className='drop-downs-div'>
        {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='CustomerID Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
            {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              // ref={salesAgentRef}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  console.log('SalesAgent change: ', e?.itemData?.id);
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}

                        <button id='get-data-btn' onClick={getCommissionDetails}> Get Processed Commission</button>
                        {/* <button id='get-data-btn' onClick={getUnProcessedCommissionDetails}> Get UnProcessed Commission</button> */}
                        </div>
                 {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCommissionDetails()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
       {CommissionDetailsDataSource.length > 0  ? <CommissionDetailsGridComponent restrictPageSize={5} /> : null}
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            {/* {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null} */}
            <div>
            {/* <button id='get-data-btn' onClick={()=>{
                setShowDropDown(!showDropDown)
              }}>Per Unit</button> */}
              <h3> Commission Details  - Report</h3>
              {showDropDown == true ?
              <>
        <div className='drop-downs-div'>
           <DatePickerComponent
                          id='MonthYearPIDate'
                          placeholder='Select Date'
                          floatLabelType='Auto'
                          // min={minDate}
                          name='monthYearPIDate'
                          format='MMM-yy'
                          width={150}
                          change={inputDateChange}
                          // value={state.PIDate}
                          // onChange={textBoxChange}
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
              {loadingCustomer ? (
                        <div>... Loading  Customer</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={customerDataSource}
                            placeholder="Select Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='CustomerID Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
            {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              // ref={salesAgentRef}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  console.log('SalesAgent change: ', e?.itemData?.id);
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}
                                                        {loadingPIID ? (
                        <div>... Loading  PIID</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="PIIDName" 
                            dataSource={PIIDDataSource}
                            placeholder="Select PI No." 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="PIIDName"
                            select={(e)=>{
                                setPIIDName(e?.itemData?.value ? e?.itemData?.value : '');
                              setPIID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setPIID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setPIIDName(e?.itemData?.value ? e?.itemData?.value : '');
                              setPIID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setPIID(e?.itemData?.id);
                              }
                            }}
                            value={PIIDName}
                            // ref={PIIDRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='PIID'
                            name='PIID'
                            floatLabelType='Auto'
                            value={PIID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
                      
                      </div>

                      <div className='drop-downs-div'>

                              {loadingSupplier ? (
                        <div>... Loading  Supplier</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="SupplierName" 
                            dataSource={supplierDataSource}
                            placeholder="Select Supplier" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="SupplierName"
                            select={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                              setSupplierID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSupplierID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setSupplierName(e?.itemData?.value ? e?.itemData?.value : '');
                              setSupplierID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSupplierID(e?.itemData?.id);
                              }
                            }}
                            value={supplierName}
                            // ref={SupplierRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='SupplierID Id'
                            name='SupplierID'
                            floatLabelType='Auto'
                            value={supplierID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
                              {loadingPurchaseSource ? (
                        <div>... Loading  PurchaseSource</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="PurchaseSourceName" 
                            dataSource={purchaseSourceDataSource}
                            placeholder="Select Purchase Source" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="PurchaseSourceName"
                            select={(e)=>{
                                setPurchaseSourceName(e?.itemData?.value ? e?.itemData?.value : '');
                              setPurchaseSourceID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setPurchaseSourceID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setPurchaseSourceName(e?.itemData?.value ? e?.itemData?.value : '');
                              setPurchaseSourceID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setPurchaseSourceID(e?.itemData?.id);
                              }
                            }}
                            value={purchaseSourceName}
                            // ref={PurchaseSourceRef}
                            
                          />
                          </div>
                      )}
                      {loadingCommissionStatus ? (
                        <div>... Loading  CommissionStatus</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CommissionStatusName" 
                            dataSource={commissionStatusDataSource}
                            placeholder="Select Commission Status" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CommissionStatusName"
                            select={(e)=>{
                                setCommissionStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCommissionStatus('');
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.value);
                                setCommissionStatus(e?.itemData?.value);
                              }
                            }}
                            change={(e)=>{
                                setCommissionStatusName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCommissionStatus('');
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.value);
                                setCommissionStatus(e?.itemData?.value);
                              }
                            }}
                            value={commissionStatusName}
                            // ref={CommissionStatusRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='CommissionStatus'
                            name='CommissionStatus'
                            floatLabelType='Auto'
                            value={commissionStatus}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
                                                <button id='get-data-btn' onClick={getCommissionDetails}> Get Processed Commission</button>
                                                {/* <button id='get-data-btn' onClick={getUnProcessedCommissionDetails}> Get UnProcessed Commission</button> */}

                        </div> </>: <></>}
              {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCommissionDetails()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
              <div>
              </div>
              {CommissionDetailsDataSource.length > 0  ? <CommissionDetailsGridComponent /> : null}
            </div>
          </div>
          
        </div>
      )
  );
}
